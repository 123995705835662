import { FormError, TextField } from "@davo/portal-common";
import { validateNotNull } from "@davo/types";
import { Button, Theme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { testLavuCredentials } from "../services/boarding";
import { BoardingContainer, sharedButtonContainer } from "./BoardingContainer";
import { LavuPosInfo } from "./types/PosInfoTypes";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
    ...sharedButtonContainer,
}));

export function LavuBoarding() {
    const classes = useStyles();
    const [dataname, setDataname] = useState<string | undefined>(undefined);
    const [key, setKey] = useState<string | undefined>(undefined);
    const [token, setToken] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const navigate = useNavigate();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const doCall = async () => {
        if (isBusy) {
            return;
        }

        if (key && token && dataname) {
            setMessage(undefined);
            setIsBusy(true);
            const result = await testLavuCredentials(key, token, dataname);
            setIsBusy(false);
            if (result.success.valid === true) {
                const posData: LavuPosInfo = {
                    key,
                    token,
                    dataname,
                };
                navigate("/invitation/create/lavu", {
                    state: posData,
                });
            } else {
                setMessage("Invalid Lavu credentials.");
            }
        } else {
            setMessage("Please fill out all fields.");
        }
    };

    return (
        <BoardingContainer posType={"lavu"}>
            <>
                <TextField
                    label="Lavu dataname"
                    value={dataname ?? ""}
                    onChange={setDataname}
                    onEnterPress={doCall}
                    inputProps={{
                        [`data-testid`]: "datanameInput",
                    }}
                    isDisabled={isBusy}
                    isRequired
                    validate={validateNotNull}
                    margin={"dense"}
                />
                <TextField
                    label="Lavu api key"
                    value={key ?? ""}
                    onChange={setKey}
                    onEnterPress={doCall}
                    inputProps={{
                        [`data-testid`]: "keyInput",
                    }}
                    isDisabled={isBusy}
                    isRequired
                    validate={validateNotNull}
                    margin={"dense"}
                />
                <TextField
                    label="Lavu token"
                    value={token ?? ""}
                    onChange={setToken}
                    onEnterPress={doCall}
                    inputProps={{
                        [`data-testid`]: "tokenInput",
                    }}
                    isDisabled={isBusy}
                    isRequired
                    validate={validateNotNull}
                    margin={"dense"}
                />
                {message && <FormError message={message} />}
                <div className={classes.submitContainer}>
                    <Button
                        type="submit"
                        data-testid={"submitBtn"}
                        variant="contained"
                        color="primary"
                        onClick={doCall}
                        disabled={!key?.trim() || !token?.trim() || !dataname?.trim() || isBusy}
                        startIcon={
                            isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                        }>
                        Connect with Lavu
                    </Button>
                </div>
            </>
        </BoardingContainer>
    );
}
