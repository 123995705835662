import { Location } from "@davo/types";
import React from "react";

export interface ILocationContext {
    location?: Location;

    switch: (locationId: string | undefined) => Promise<void>;
    refresh: () => Promise<void>;
}

export const LocationContextDefaultValue = {} as ILocationContext;
export const LocationContext = React.createContext(LocationContextDefaultValue);
export const useLocationContext = () => React.useContext(LocationContext);
