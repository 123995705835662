import { StatusDot } from "@davo/portal-common";
import { getLocationsInfo, StatusLevel, TaxProfile } from "@davo/types";
import React from "react";

export interface ITaxProfileStatusType {
    taxProfile?: TaxProfile;
    taxProfiles?: TaxProfile[];
    showToolTipOnly?: boolean;
}

export function TaxProfileStatus({ taxProfiles, taxProfile, showToolTipOnly }: ITaxProfileStatusType) {
    if (taxProfile) {
        taxProfiles = [taxProfile];
    }

    let level: StatusLevel = "ok";
    let message = "OK";

    const { hasFailingTaxInfo, hasUntestedTaxCredentials } = getLocationsInfo([], null, taxProfiles);

    if (!taxProfiles || taxProfiles.length === 0) {
        level = "error";
        message = "Missing tax profile";
    } else if (hasFailingTaxInfo) {
        level = "error";
        message = "Failing tax credentials";
    } else if (hasUntestedTaxCredentials) {
        level = "warning";
        message = "Credentials to be verified";
    }

    return (
        <StatusDot
            isFocusable={true}
            level={level}
            message={!showToolTipOnly ? message : ""}
            toolTip={showToolTipOnly ? message : ""}
        />
    );
}
