export const PLACEHOLDER_PREFIX = "DRAFT";

export const PLACEHOLDER_VALUES = {
    CREDENTIALS: {},
    NAME: `${PLACEHOLDER_PREFIX}_PROFILE`,
    STATE_TAX_ID: `${PLACEHOLDER_PREFIX}_STATE_TAX_ID`,
    FILING_FREQUENCY: "month",
    LEGAL_NAME: `${PLACEHOLDER_PREFIX}_LEGAL_NAME`,
    FEIN: `${PLACEHOLDER_PREFIX}_FEIN`,
    ADDITIONAL_METADATA_1: `${PLACEHOLDER_PREFIX}_ADDITIONAL_STATE_METADATA_1`,
    ADDITIONAL_METADATA_2: `${PLACEHOLDER_PREFIX}_ADDITIONAL_STATE_METADATA_2`,
    ADDITIONAL_METADATA_3: `${PLACEHOLDER_PREFIX}_ADDITIONAL_STATE_METADATA_3`,
};
