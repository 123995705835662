import {
    auth,
    ConfigCommonContext,
    getPortalConfig,
    lightTheme,
    LoadingWithLogo,
    LoginContext,
} from "@davo/portal-common";
import "@davo/portal-common/lib/toast.css";
import { ICommonConfigType, IPermission, IPortalConfigType, User } from "@davo/types";
import { ThemeProvider } from "@mui/material";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAsyncEffect from "use-async-effect";
import "./App.css";
import { ConfigMerchantContext } from "./context";
import { MerchantRouter } from "./Routes";
import { getPermissionsForCurrentUser } from "./services/permissions";
import { setReferralCookie } from "./services/reftracking";
import { FullStory } from "./third-party/FullStory";
import { GoogleAnalytics } from "./third-party/GoogleAnalytics";
import { HubSpot } from "./third-party/HubSpot";

export function Preload() {
    const [user, setUser] = useState<User>();
    const [userPermissions, setUserPermissions] = useState<string[]>();

    const [isInitializing, setIsInitializing] = useState<boolean>(true);
    const [merchantConfigInfo, setMerchantConfigInfo] = useState<IPortalConfigType>();
    const [commonConfigInfo, setCommonConfigInfo] = useState<ICommonConfigType>();

    useAsyncEffect(async () => {
        const config = await getPortalConfig();
        setMerchantConfigInfo(config);
        setCommonConfigInfo(config);
    }, []);

    useAsyncEffect(async () => {
        if (!user) {
            setUserPermissions(undefined);
            return;
        }
        const permissions: string[] = (await getPermissionsForCurrentUser()).map((p: IPermission) => p.permissionTag);
        setUserPermissions(permissions);
    }, [user]);

    useAsyncEffect(async () => {
        // Initialize page and setup account auto-refresh
        const params = new URLSearchParams(window.location.search);

        const marketingInfo = {
            email: params.get("email"),
            firstName: params.get("firstName"),
            lastName: params.get("lastName"),
            phone: params.get("phone"),
        };
        try {
            localStorage.setItem("marketingInfo", JSON.stringify(marketingInfo));
        } catch {
            // ignore these errors
        }

        const refSource = params.get("refSource");
        const refId = params.get("refId");
        const refDealId = params.get("refDealId");
        if (refSource) {
            await setReferralCookie(
                refSource,
                refId === null ? undefined : refId,
                refDealId === null ? undefined : refDealId
            );
        }

        try {
            const { user: theUser } = await auth.whoAmI();
            if (!theUser) {
                setUser(undefined);
            } else {
                setUser(theUser);
            }
        } catch (e: any) {
            setUser(undefined);
        }
        setIsInitializing(false);
    }, []);

    if (!commonConfigInfo || !merchantConfigInfo) {
        return null;
    }

    return (
        <ConfigMerchantContext.Provider
            value={{
                merchantConfigInfo,
            }}>
            <ConfigCommonContext.Provider
                value={{
                    commonConfigInfo,
                }}>
                <LoginContext.Provider
                    value={{
                        user,
                        setUser: setUser,
                        permissions: userPermissions,
                    }}>
                    <ThemeProvider theme={lightTheme}>
                        <GoogleAnalytics />
                        <HubSpot />
                        <FullStory />

                        {isInitializing ? <LoadingWithLogo /> : <MerchantRouter />}

                        <ToastContainer />
                    </ThemeProvider>
                </LoginContext.Provider>
            </ConfigCommonContext.Provider>
        </ConfigMerchantContext.Provider>
    );
}
