const hostname = window && window.location && window.location.hostname;

let apiHost;
let portalHost;
if (hostname.endsWith("localhost")) {
    apiHost = `${window.location.protocol}//localhost:4000`;
    portalHost = `${window.location.protocol}//localhost:4234`;
} else {
    const apihost = hostname.replace(/(portal)|(partner-portal)|(ops-portal)/, "d30api");
    apiHost = `https://${apihost}`;
    portalHost = `https://${hostname.replace(/ops-/, "")}`;
    portalHost = portalHost.replace(/partner-/, "");
}

export const apiUrl = apiHost;
export const portalUrl = portalHost;
