import { initialCap, INotice, moneyFromCents, noop, NoticeStatuses, toDisplayDateString } from "@davo/types";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoteIcon from "@mui/icons-material/Note";
import NoteAddIcon from "@mui/icons-material/NoteOutlined";
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { ReactTable } from "./ReactTable";
import { cancelNotice } from "./services";
import { d30Toast, d30ToastError } from "./Toast";

export function NoticesTable({
    notices,
    includeCancelButton,
    includeNotes,
    actionLabel,
    action,
    refresh,
}: {
    notices: INotice[] | null | undefined;
    includeCancelButton: boolean;
    includeNotes: boolean;
    actionLabel?: string;
    action?: (noticeId: string) => void;
    refresh?: () => void;
}) {
    const cancel = (rowId: string) => {
        cancelNotice(rowId)
            .then(() => {
                d30Toast("Notice Cancelled");
                if (refresh) {
                    refresh();
                }
            })
            .catch(() => {
                d30ToastError("Error cancelling notice, please try again later");
            });
    };

    const getDetailPaneData = (data: any) => {
        return (
            <div style={{ padding: "32px" }}>
                <Grid container spacing={2} rowSpacing={4}>
                    <Grid item container spacing={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Stack direction="row" spacing={2}>
                                    <Card sx={{ flexGrow: 1 }}>
                                        <CardHeader
                                            data-testid={"davo-card"}
                                            className={"truncated-card-header"}
                                            title={moneyFromCents(data.row.original.taxDue)}
                                            subheader={"Tax Due"}
                                        />
                                    </Card>
                                    <Card sx={{ flexGrow: 1 }}>
                                        <CardHeader
                                            data-testid={"davo-card"}
                                            className={"truncated-card-header"}
                                            title={moneyFromCents(data.row.original.penalties)}
                                            subheader={"Penalties"}
                                        />
                                    </Card>
                                    <Card sx={{ flexGrow: 1 }}>
                                        <CardHeader
                                            data-testid={"davo-card"}
                                            className={"truncated-card-header"}
                                            title={moneyFromCents(data.row.original.interest)}
                                            subheader={"Interest"}
                                        />
                                    </Card>
                                    {data.row.original.noteFromMerchant && (
                                        <Card sx={{ flexGrow: 1 }}>
                                            <CardContent data-testid={"content"}>
                                                Your context for us:{" "}
                                                <Tooltip title={data.row.original.noteFromMerchant}>
                                                    <span>
                                                        <IconButton
                                                            aria-label="Context"
                                                            onClick={() => {
                                                                noop();
                                                            }}
                                                            size="large">
                                                            <NoteAddIcon color="primary" />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </CardContent>
                                        </Card>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    };

    const getColumns = () => {
        const cols = [];
        cols.push(
            {
                id: "expander", // Make sure expand columns have an ID
                Cell: (data: any) => {
                    return (
                        <span
                            {...data.row.getToggleRowExpandedProps({
                                style: {
                                    paddingLeft: "8px",
                                },
                            })}
                            className={"mui-expand-toggle-container"}>
                            {data.row.isExpanded ? (
                                <span className={"expandLess"}>
                                    <ExpandLessIcon />
                                </span>
                            ) : (
                                <span className={"expandMore"}>
                                    <ExpandMoreIcon />
                                </span>
                            )}
                        </span>
                    );
                },
                disableSortBy: true,
            },
            {
                Header: "Period",
                accessor: "periodStart",
                disableSortBy: true,
                Cell: (data: any) => (
                    <div style={{ textAlign: "left" }}>
                        {DateTime.fromSQL(data.cell.row.original.periodStart).toFormat("LLL  yyyy")}
                    </div>
                ),
            },
            {
                Header: "Status",
                accessor: "status",
                disableSortBy: true,
                Cell: (data: any) => (
                    <div style={{ textAlign: "left" }}>{NoticeStatuses[data.cell.row.original.status]}</div>
                ),
            },
            {
                Header: "Type",
                accessor: "type",
                disableSortBy: true,
                Cell: (data: any) => <div style={{ textAlign: "left" }}>{initialCap(data.cell.row.original.type)}</div>,
            },
            {
                Header: "Updated Date",
                accessor: "updated",
                disableSortBy: true,
                Cell: (data: any) => (
                    <div style={{ textAlign: "left" }}>
                        {toDisplayDateString(data.cell.row.original.updated.toSQLDate())}
                    </div>
                ),
            }
        );
        if (includeNotes) {
            cols.push({
                Header: "Note",
                accessor: "noteToMerchant",
                disableSortBy: true,
                Cell: (data: any) => (
                    <span
                        style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            marginLeft: "10px",
                            marginRight: "10px",
                        }}>
                        {data.cell.row.original.noteToMerchant && (
                            <Tooltip title={data.cell.row.original.noteToMerchant}>
                                <span>
                                    <IconButton
                                        aria-label="Notes"
                                        onClick={() => {
                                            noop();
                                        }}
                                        size="large">
                                        {data.cell.row.original.noteToMerchant && <NoteAddIcon color="primary" />}
                                        {!data.cell.row.original.noteToMerchant && <NoteIcon color="primary" />}
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                    </span>
                ),
            });
        }
        if (includeCancelButton) {
            cols.push({
                id: "cancel",
                Header: "",
                disableSortBy: true,
                Cell: (data: any) => {
                    if (!data.cell.row.original.status.includes("closed")) {
                        return (
                            <div style={{ textAlign: "left" }}>
                                <Button color="secondary" onClick={() => cancel(data.cell.row.original.id)}>
                                    Cancel
                                </Button>
                            </div>
                        );
                    } else {
                        return <></>;
                    }
                },
            });
        }
        if (actionLabel && action) {
            cols.push({
                id: "actions",
                Header: "",
                disableSortBy: true,
                Cell: (data: any) => (
                    <div style={{ textAlign: "left" }}>
                        <Button onClick={() => action(data.cell.row.original.id)}>{actionLabel}</Button>
                    </div>
                ),
            });
        }
        return cols;
    };

    if (!notices) {
        return null;
    }

    return (
        <>
            <div>
                <ReactTable
                    title={"Notices"}
                    columns={getColumns()}
                    data={notices ?? []}
                    options={{
                        hideToolbar: true,
                    }}
                    renderRowSubComponent={getDetailPaneData}
                />
            </div>
        </>
    );
}
