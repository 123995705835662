import { d30ToastError, LoadingWithLogo, POSAccountLogo } from "@davo/portal-common";
import { IMerchantInvitationInfo } from "@davo/types";
import { Alert, Button, createTheme, Switch, Theme, ThemeProvider, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import preAccount2 from "../../resources/preAccount/preAccount2-Big-Mama-Quote.png";
import preAccount3 from "../../resources/preAccount/preAccount3-Dashboard.png";
import preAccount4 from "../../resources/preAccount/preAccount4-Discounts.png";
import preAccountBanner from "../../resources/preAccount/preAccount5-Clover-PreInstall-Banner.png";
import preAccountBannerMobile from "../../resources/preAccount/preAccount6-Mobile-Opt.png";
import { getMerchantInvitationInfo, preAccountInvitation } from "../../services/invitation";

let localTheme = createTheme();
localTheme = createTheme(localTheme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    margin: "10px",
                    [localTheme.breakpoints.up("sm")]: {
                        minWidth: "250px",
                        minHeight: "50px",
                        fontSize: "26px",
                    },
                },
            },
        },
    },
    typography: {
        subtitle1: {
            fontSize: "14px",
            display: "inline-block",
        },
        subtitle2: {
            fontWeight: "450",
            fontSize: "1.0rem",
        },
    },
});

const useStyles = makeStyles((theme: Theme) => ({
    control: {
        padding: theme.spacing(2),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    POSPreAccountLogo: {
        [theme.breakpoints.down("sm")]: {
            width: "90%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "50%",
            maxWidth: "400px",
        },
    },
    bannerLogo: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        [theme.breakpoints.only("sm")]: {
            width: "600px",
        },
        [theme.breakpoints.only("md")]: {
            width: "750px",
        },
        [theme.breakpoints.up("lg")]: {
            width: "1200px",
        },
        borderRadius: "15px",
        boxShadow: "2px 2px 2px 0px rgba(0, 0, 0, 0.19)",
    },
    mobileBannerLogo: {
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
        width: "330px",
        borderRadius: "15px",
        boxShadow: "2px 2px 2px 0px rgba(0, 0, 0, 0.19)",
    },
    tagLine: {
        [theme.breakpoints.down("sm")]: {
            marginTop: "10px",
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "10px",
        },
        width: "800px",
        textAlign: "center",
        margin: "auto",
    },
    listPlacement: {
        width: "420px",
        margin: "auto",
        [theme.breakpoints.only("xs")]: {
            width: "340px",
        },
        marginTop: "10px",
        textAlign: "left",
    },
    listItem: {
        padding: "0px",
        display: "flex",
        margin: "8px",
    },
    icon: {
        marginRight: "7px",
        height: "17px",
    },
    bubbleStyle: {
        backgroundColor: "#fbfbfb",
        border: "1px solid #e5e5e5",
        borderRadius: "10px",
        boxSizing: "border-box",
        flexDirection: "row",
        width: "100%",
        padding: "5px 5px",
        textAlign: "center",
    },
    termsContainer: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        marginTop: "5px",
        textAlign: "center",
        fontSize: "10px",
    },
    bannerZoomedImg: {
        borderRadius: "15px",
        width: "330px",
        [theme.breakpoints.only("sm")]: {
            width: "600px",
        },
        [theme.breakpoints.only("md")]: {
            width: "750px",
        },
        [theme.breakpoints.up("lg")]: {
            width: "350px",
        },
        margin: "5px",
        boxShadow: "2px 2px 2px 0px rgba(0, 0, 0, 0.19)",
    },
    buttonPlacement: {
        width: "600px",
        margin: "auto",
        [theme.breakpoints.only("xs")]: {
            width: "340px",
        },
        textAlign: "center",
    },
}));

export function PreAccount() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [invitationInfo, setInvitationInfo] = useState<IMerchantInvitationInfo>();
    const { code } = useParams();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const [areTermsOfUseAccepted, setAreTermsOfUseAccepted] = useState<boolean>(false);

    useAsyncEffect(async () => {
        if (!code) {
            return;
        }
        setInvitationInfo(await getMerchantInvitationInfo(code));
    }, [code]);

    useEffect(() => {
        if (invitationInfo && code && invitationInfo.status === "redeemed") {
            navigate(`/invitation/location/${code}`);
        }
    }, [invitationInfo, code]);

    const process = () => {
        if (!invitationInfo || !invitationInfo.invitationId || isBusy || !code) {
            return;
        }
        setIsBusy(true);
        preAccountInvitation(invitationInfo.invitationId)
            .then(() => {
                navigate(`/invitation/location/${code}`);
            })
            .catch((e) => {
                setIsBusy(false);
                d30ToastError("Operation failed", e);
            });
    };

    if (!invitationInfo || !invitationInfo.type) {
        return <LoadingWithLogo />;
    }

    return (
        <ThemeProvider theme={localTheme}>
            <div style={{ textAlign: "center", verticalAlign: "top" }}>
                <POSAccountLogo
                    className={classes.POSPreAccountLogo}
                    alt={`${invitationInfo.type} plus DAVO`}
                    posType={invitationInfo.type}
                />
            </div>
            <>
                <div style={{ textAlign: "center", verticalAlign: "top", marginBottom: "10px" }}>
                    <img
                        id={"preAccountBanner"}
                        className={classes.bannerLogo}
                        alt="Clover-Preinstall-Banner"
                        src={preAccountBanner}
                    />
                    <img
                        id={"preAccountBannerMobile"}
                        className={classes.mobileBannerLogo}
                        alt="Clover-Preinstall-Banner"
                        src={preAccountBannerMobile}
                    />
                </div>
                <div className={classes.listPlacement}>
                    <div className={classes.bubbleStyle}>
                        <Typography variant="body2" color="textSecondary">
                            First monthly filing free, then $54.99/month. <br /> No hidden fees or contracts.
                        </Typography>
                    </div>
                </div>
                {invitationInfo.canSubscribe &&
                    invitationInfo.hasPermissionToSubscribe &&
                    invitationInfo.hasValidExternalSubscription &&
                    invitationInfo.hasValidExternalPlan &&
                    invitationInfo.hasTaxRates && (
                        <div className={classes.termsContainer} data-testid={"termsAndConditionsForm"}>
                            <Typography variant={"subtitle1"} color="textSecondary">
                                I have read and accept the{" "}
                                <a
                                    href="https://legal.avalara.com/#serviceterms"
                                    target="_blank"
                                    style={{ overflow: "e" }}
                                    rel="noreferrer">
                                    Avalara Service Terms and Conditions
                                </a>{" "}
                                .
                            </Typography>
                            <Switch
                                checked={areTermsOfUseAccepted}
                                onChange={(event: any, isChecked: boolean) => setAreTermsOfUseAccepted(isChecked)}
                            />
                        </div>
                    )}
                <div className={classes.buttonPlacement}>
                    <Tooltip title={!areTermsOfUseAccepted ? "You'll need to accept the T&Cs before continuing." : ""}>
                        <span>
                            <Button
                                data-testid={"preAccountSubmitBtn"}
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={
                                    !areTermsOfUseAccepted ||
                                    !invitationInfo.canSubscribe ||
                                    !invitationInfo.hasPermissionToSubscribe ||
                                    !invitationInfo.hasValidExternalSubscription ||
                                    !invitationInfo.hasValidExternalPlan ||
                                    !invitationInfo.hasTaxRates ||
                                    isBusy
                                }
                                onClick={process}>
                                {"START SUBSCRIPTION"}
                            </Button>
                        </span>
                    </Tooltip>
                    {!invitationInfo.hasPermissionToSubscribe && (
                        <>
                            <Alert
                                severity="error"
                                style={{ textAlign: "left" }}
                                data-testid={"hasPermissionToSubscribe"}>
                                <Typography variant={"body1"} color="textSecondary">
                                    Only a Manager user level or above can install DAVO Sales Tax.
                                </Typography>
                            </Alert>
                        </>
                    )}
                    {invitationInfo.hasPermissionToSubscribe && !invitationInfo.hasValidExternalSubscription && (
                        <>
                            <Alert
                                severity="error"
                                style={{ textAlign: "left" }}
                                data-testid={"hasValidExternalSubscription"}>
                                <Typography variant={"body1"} color="textSecondary">
                                    Your Clover subscription is not active. Please contact Clover to resolve.
                                </Typography>
                            </Alert>
                        </>
                    )}
                    {invitationInfo.hasPermissionToSubscribe &&
                        invitationInfo.hasValidExternalSubscription &&
                        !invitationInfo.hasValidExternalPlan && (
                            <>
                                <Alert
                                    severity="error"
                                    style={{ textAlign: "left" }}
                                    data-testid={"hasValidExternalPlan"}>
                                    <Typography variant={"body1"} color="textSecondary">
                                        Your current Clover plan does not support sales tax collection. Please contact
                                        Clover to upgrade your plan before subscribing to DAVO.
                                    </Typography>
                                </Alert>
                            </>
                        )}
                    {invitationInfo.hasPermissionToSubscribe &&
                        invitationInfo.hasValidExternalSubscription &&
                        invitationInfo.hasValidExternalPlan &&
                        !invitationInfo.hasTaxRates && (
                            <>
                                <Alert severity="error" style={{ textAlign: "left" }} data-testid={"hasTaxRates"}>
                                    <Typography variant={"body1"} color="textSecondary">
                                        <a
                                            href="https://www.clover.com/setupapp/m/NHPQYVK9MTEX1/taxes/"
                                            target="_blank"
                                            rel="noreferrer">
                                            Set up your tax rates
                                        </a>{" "}
                                        on your POS before installing DAVO Sales Tax.
                                    </Typography>
                                </Alert>
                            </>
                        )}
                </div>
                <div className={classes.tagLine}>
                    <Typography variant="subtitle2" color="textSecondary">
                        DAVO Automated Sales Tax uses your Clover sales data to set your sales tax aside daily, then
                        files and pays for you - on time and in full. Stop worrying about having enough money set aside
                        or missed payments. Put your sales tax on autopilot and win back valuable time to focus on
                        running your business.
                    </Typography>
                </div>
                <div
                    style={{
                        marginTop: "10px",
                        marginBottom: "30px",
                        textAlign: "center",
                    }}>
                    <Zoom wrapElement={"span"}>
                        <img className={classes.bannerZoomedImg} alt="Dashboard" src={preAccount3} />
                    </Zoom>
                    <Zoom wrapElement={"span"}>
                        <img className={classes.bannerZoomedImg} alt="Playa-Quote" src={preAccount2} />
                    </Zoom>
                    <Zoom wrapElement={"span"}>
                        <img className={classes.bannerZoomedImg} alt="Discounts" src={preAccount4} />
                    </Zoom>
                </div>
            </>
        </ThemeProvider>
    );
}
