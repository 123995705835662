import { LoginCredentialValidationStatus } from "@davo/types";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";

export interface IValidateTaxCredentialsButton {
    areAllCredentialFieldsValid: boolean;
    validateCredentials: () => void;
    credsValidationStatus: LoginCredentialValidationStatus;
    jurisdictionFilingTypeId: string | null | undefined;
    areExistingCredentialsReused: boolean;
}

export const ValidateTaxCredentialsButton: React.FunctionComponent<IValidateTaxCredentialsButton> = ({
    areAllCredentialFieldsValid,
    validateCredentials,
    credsValidationStatus,
    jurisdictionFilingTypeId,
    areExistingCredentialsReused,
}) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    useEffect(() => {
        setIsDisabled(
            !areAllCredentialFieldsValid ||
                credsValidationStatus === "validating" ||
                !jurisdictionFilingTypeId ||
                areExistingCredentialsReused
        );
    }, [areExistingCredentialsReused, areAllCredentialFieldsValid, credsValidationStatus, jurisdictionFilingTypeId]);

    return (
        <div data-testid={"validateTaxCredsBtnContainer"} style={{ display: "flex", alignItems: "center" }}>
            <Button
                fullWidth={true}
                data-testid={"validateTaxCredsBtn"}
                startIcon={
                    credsValidationStatus === "validating" ? (
                        <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                    ) : null
                }
                onClick={validateCredentials}
                variant={
                    credsValidationStatus === "invalid" || credsValidationStatus === undefined
                        ? "contained"
                        : "outlined"
                }
                disabled={isDisabled}>
                Test your login
            </Button>
        </div>
    );
};
