import Paper, { PaperProps } from "@mui/material/Paper";
import React from "react";
import Draggable from "react-draggable";

export function PaperComponent(props: PaperProps) {
    const dragRef = React.createRef<HTMLDivElement>();
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"], [class*="MuiDialogActions-root"]'}
            nodeRef={dragRef}>
            <Paper ref={dragRef} {...props} />
        </Draggable>
    );
}
