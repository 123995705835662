import { PartnerLogo, Select } from "@davo/portal-common";
import { AccountRecord } from "@davo/types";
import { InputBase } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAccountContext, useAccountsContext } from "../context";

const CompanySelectInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        border: "none",
        boxShadow: "none",
        padding: "5px",
        marginRight: "8px",
        paddingLeft: "6px",
        position: "relative",
        fontSize: "20px",
    },
}))(InputBase);

export const CompanySelector = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const accountsContext = useAccountsContext();
    const accountContext = useAccountContext();

    function onChangeAccount(acct: AccountRecord) {
        if (location.pathname.includes("company/")) {
            // strip off the location
            navigate(`/${acct.id}/settings/company`);
        } else {
            // maintain the section betweeen accounts
            const chopped = location.pathname.split("/");
            chopped[1] = acct.id;
            navigate(chopped.join("/"));
        }
    }

    if (accountsContext.accounts && accountsContext.accounts.length > 1) {
        return (
            <Select<AccountRecord>
                data-testid={"company-selector"}
                style={{ border: "none" }}
                options={accountsContext.accounts}
                value={accountContext.account}
                onChange={(val: AccountRecord | undefined) => val && onChangeAccount(val)}
                label={(acct: AccountRecord) => acct.name || "Unknown"}
                showFullWidth={false}
                input={<CompanySelectInput />}
            />
        );
    } else {
        if (!accountContext.account) {
            return null;
        }
        return (
            <div
                data-testid={"account-info"}
                style={{ display: "inline-flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>{accountContext.account.name}</div>
                {accountContext.partner && (
                    <PartnerLogo
                        data-testid={"partner-logo"}
                        partnerTag={accountContext.partner.tag}
                        partnerName={accountContext.partner.name}
                        style={{ width: "80px", margin: "-10px 10px -10px 50px" }}
                    />
                )}
            </div>
        );
    }
};
