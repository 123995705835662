import { auth, getPartners, Loading, Select, useLoginContext } from "@davo/portal-common";
import { IMerchantInvitationInfo, upToLast } from "@davo/types";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import { Button, Link, Tooltip } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { useAccountsContext } from "../../context";
import { NEW_ACCOUNT_ID } from "../types/Account";

export interface ISelectAccountType {
    invitationInfo: IMerchantInvitationInfo;
    onContinue: (selectedAccountId: string | undefined) => void;
    onCancel: () => void;
}

export function SelectAccount({ invitationInfo, onContinue, onCancel }: ISelectAccountType) {
    const accountsContext = useAccountsContext();
    const loginContext = useLoginContext();

    const [selectableAccountIds, setSelectableAccountIds] = useState<string[]>();
    const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>();

    useAsyncEffect(async () => {
        if (isNil(accountsContext.accounts)) {
            return;
        }

        if (isEmpty(accountsContext.accounts)) {
            // If we're on the account selection step, but we don't have any existing accounts to select then skip to redemption
            onContinue(undefined);
            return;
        }

        // invitation restricted to a partner...only show accounts of that partner
        if (invitationInfo.shouldRestrictBoardingFlowToPartner && invitationInfo.partnerId) {
            const filteredAccounts = accountsContext.accounts.filter((a) => a.partnerId === invitationInfo.partnerId);
            if (isEmpty(filteredAccounts)) {
                onContinue(undefined);
                return;
            }
            setSelectableAccountIds([...filteredAccounts.map((a) => a.id), NEW_ACCOUNT_ID]);
            return;
        }

        // else available account list should filter partners with  restrictBoardingFlow
        let partnerIds = accountsContext.accounts.map((a) => a.partnerId).filter((i) => !isNil(i));
        // make the list unique
        partnerIds = Array.from(new Set(partnerIds).values());
        if (partnerIds.length > 0) {
            const _partners = await getPartners(partnerIds);
            const nonRestrictedAccounts = accountsContext.accounts.filter(
                (a) =>
                    !a.partnerId || _partners.filter((p) => p.id === a.partnerId && p.restrictBoardingFlow).length !== 1
            );
            if (isEmpty(nonRestrictedAccounts)) {
                onContinue(undefined);
                return;
            }
            setSelectableAccountIds([...nonRestrictedAccounts.map((a) => a.id), NEW_ACCOUNT_ID]);
        } else {
            setSelectableAccountIds([...accountsContext.accounts.map((a) => a.id), NEW_ACCOUNT_ID]);
        }
    }, [accountsContext.accounts]);

    if (isNil(accountsContext.accounts) || isNil(selectableAccountIds)) {
        return <Loading />;
    }

    const getAccountName = (accountId: string) => {
        const companyName = invitationInfo && invitationInfo.name && upToLast(invitationInfo.name, " - ");
        if (accountId === NEW_ACCOUNT_ID) {
            return `Create new account: ${companyName || "New Account"}`;
        }
        const theAccount = accountsContext.accounts?.find((a) => a.id === accountId);
        return theAccount?.name ?? "";
    };

    const _onContinue = (_selectedAccountId: string | undefined) => {
        if (_selectedAccountId === NEW_ACCOUNT_ID) {
            onContinue(undefined);
        } else {
            onContinue(_selectedAccountId);
        }
    };

    return (
        <div style={{ justifyContent: "stretch" }} data-testid={"selectAccountContainer"}>
            <div style={{ paddingBottom: "10px" }}>
                You can add this location to your existing account or choose &quot;Create new account&quot; in the
                dropdown.
            </div>
            <div style={{ paddingBottom: "10px" }}>
                Multiple locations in an account see aggregate reporting and can be filed together.
                <Tooltip
                    title={
                        "Businesses that file separately should be independent DAVO accounts. One user/login can have access to multiple accounts. "
                    }
                    placement="top">
                    <HelpTwoToneIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} />
                </Tooltip>
            </div>

            <div style={{ padding: "10px" }}>
                <Select<string>
                    options={selectableAccountIds}
                    value={selectedAccountId}
                    onChange={setSelectedAccountId}
                    noneLabel="Select an account"
                    label={getAccountName}
                    data-testid="selectAccount"
                    inputProps={{
                        [`data-testid`]: "selectAccountInput",
                    }}
                />
            </div>
            <div style={{ textAlign: "center" }}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => _onContinue(selectedAccountId)}
                    style={{ margin: "20px" }}
                    disabled={isNil(selectedAccountId)}>
                    Continue
                </Button>
                <div style={{ textAlign: "center" }}>
                    <Link
                        onClick={async () => {
                            await auth.logout().finally(() => {
                                loginContext.setUser(undefined);
                            });
                            onCancel();
                        }}>
                        Logout
                    </Link>
                </div>
            </div>
        </div>
    );
}
