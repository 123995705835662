import {
    ConfirmationModal,
    d30Toast,
    d30ToastError,
    expireCurrentInvitation,
    getUserPermissionsForAccount,
    inviteUser,
    ReactTable,
    removeAccountPermissions,
    removeUserInvite,
    TextField,
    updateAccountEmailPreferences,
    updateAccountPermissions,
    updateUserSummaryPreferences,
    useLoginContext,
    useModalEditor,
} from "@davo/portal-common";
import {
    isEmailValid,
    IUserAndPermissions,
    toDisplayDateString,
    UserInvitation,
    UserRole,
    validateEmail,
} from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Switch as MaterialSwitch,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { CellProps } from "react-table";
import useAsyncEffect from "use-async-effect";
import { useAccountContext } from "./context";
import { getUserInvitationsForAccount } from "./services/invitation";

export function Users() {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const loginContext = useLoginContext();
    const accountContext = useAccountContext();

    const [users, setUsers] = useState<IUserAndPermissions[]>();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [canAdd, setCanAdd] = useState<boolean>(false);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [canInviteAsAdmin, setCanInviteAsAdmin] = useState<boolean>(false);
    const [invitations, setInvitations] = useState<UserInvitation[]>();
    const [inactives, setInactives] = useState<UserInvitation[]>();

    const [showConfirmationModal, confirmationModalProps] = useModalEditor<string>((userId?: string) => {
        if (!userId || !accountContext.account) {
            return;
        }
        // If userId is defined then delete the account access and show a success message
        removeAccountPermissions(userId, accountContext.account.id)
            .then(() => {
                refresh()
                    .then(() => {
                        d30Toast("User removed from account.");
                    })
                    .catch((e) => {
                        d30ToastError("Unable to remove user from account.", e);
                    });
            })
            .catch((e) => {
                d30ToastError("Unable to remove user from account.", e);
            });
    });

    const refresh = async () => {
        if (!accountContext.account) {
            return;
        }

        const usersPromise = getUserPermissionsForAccount(accountContext.account.id);
        const userInvitationsPromise = getUserInvitationsForAccount(accountContext.account.id);
        const [uList, invitationList] = await Promise.all([usersPromise, userInvitationsPromise]);
        setUsers(uList);

        const expd: UserInvitation[] | ((prevState: never[]) => never[]) = [];
        const unexpired: any[] | ((prevState: UserInvitation[]) => UserInvitation[]) = [];
        for (const i of invitationList) {
            if (i.expires > DateTime.now()) {
                unexpired.push(i);
            } else {
                expd.push(i);
            }
        }
        setInactives(expd);
        setInvitations(unexpired);
    };

    useAsyncEffect(async () => {
        if (!accountContext.account) {
            return;
        }
        await refresh();
    }, [accountContext.account]);

    useAsyncEffect(() => {
        if (!loginContext.user || !users) {
            return;
        }
        const currentUser = users.filter((u: IUserAndPermissions) => u.id === loginContext.user?.id);

        if (currentUser.length === 1 && currentUser[0].type === "Merchant" && currentUser[0].role === "user") {
            setIsAdmin(false);
        } else {
            setIsAdmin(true);
        }
    }, [loginContext.user, users]);

    if (!accountContext.account || !users || !invitations || !inactives) {
        return null;
    }

    const invite = async (emailArg?: string, invitationId?: string) => {
        if (!accountContext.account) {
            return;
        }
        let thisEmail = "";
        if (emailArg && invitationId) {
            thisEmail = emailArg;
        } else {
            thisEmail = email ?? "";
        }
        if (thisEmail === "") {
            return;
        }
        const thisInvite = invitations.find((i) => i.originalEmail === thisEmail);

        if (thisInvite?.id && !isInvitationExpired(thisInvite)) {
            await expireCurrentInvitation(accountContext.account.id, thisInvite.id);
            removeInvite(thisInvite.id);
        }
        await inviteUser(accountContext.account.id, thisEmail, canInviteAsAdmin ? "admin" : "user");
        if (emailArg && invitationId) {
            removeInvite(invitationId);
        }
        await refresh();
        setCanAdd(false);
        setEmail(undefined);
        d30Toast("User invite sent!");
    };

    const handleRoleChange = (userId: string, oldRole: string) => () => {
        if (!accountContext.account) {
            return;
        }
        let newRole = "user";
        if (oldRole === "user") {
            newRole = "admin";
        }
        updateAccountPermissions(userId, accountContext.account.id, newRole)
            .then(() => {
                refresh()
                    .then(() => {
                        d30Toast("User permissions updated!");
                    })
                    .catch((e) => {
                        d30ToastError("Could Not Update Account Permissions.", e);
                    });
            })
            .catch((e) => {
                d30ToastError("Could Not Update Account Permissions.", e);
            });
    };

    const handleEmailOptOutChange = (userId: string, isOldEmailOptOut: boolean) => () => {
        if (!accountContext.account) {
            return;
        }
        updateAccountEmailPreferences(userId, accountContext.account.id, !isOldEmailOptOut)
            .then(() => {
                refresh()
                    .then(() => {
                        d30Toast("User email preferences updated!");
                    })
                    .catch((e) => {
                        d30ToastError("Could Not Update Email Preferences.", e);
                    });
            })
            .catch((e) => {
                d30ToastError("Could Not Update Email Preferences.", e);
            });
    };

    const handleSummaryOptInChange = (userId: string, isOldSummaryOptIn: boolean) => () => {
        if (!accountContext.account) {
            return;
        }
        updateUserSummaryPreferences(userId, accountContext.account.id, !isOldSummaryOptIn)
            .then(() => {
                refresh()
                    .then(() => {
                        d30Toast("User summary preferences updated!");
                    })
                    .catch((e) => {
                        d30ToastError("Could Not Update Summary Preferences.", e);
                    });
            })
            .catch((e) => {
                d30ToastError("Could Not Update Summary Preferences.", e);
            });
    };

    const removeInvite = (inviteId: string) => {
        if (!accountContext.account) {
            return;
        }
        removeUserInvite(accountContext.account.id, inviteId)
            .then(() => {
                refresh()
                    .then(() => {
                        d30Toast("Done");
                    })
                    .catch((e) => {
                        d30ToastError("Problem removing user invite.", e);
                    });
            })
            .catch((e) => d30ToastError("Problem removing user invite.", e));
    };

    const isInvitationExpired = (inviteArg: UserInvitation) => {
        return inviteArg.expires <= DateTime.now();
    };

    const removeUser = (userId: string) => {
        showConfirmationModal(userId);
    };

    const sendInvitePressed = async (id?: string) => {
        if (id) {
            const em = inactives.find((i) => i.id === id)?.originalEmail;
            if (em) {
                return invite(em, id);
            }
        }
        if (!isAdmin || !email || !!validateEmail(email)) {
            return;
        }
        if (invitations.filter((i) => i.originalEmail === email && i.expires > DateTime.now()).length > 0) {
            d30ToastError("Cannot send invitation while one is already active!");
            return;
        }
        await invite(accountContext.account?.id);
    };

    const isOwnAccount = (rowUserId: string, globalUserId?: string) => rowUserId === globalUserId;

    const isLastAdmin = (rowUser: IUserAndPermissions) => {
        return rowUser.role === "admin" && users.filter((u) => u.role === "admin").length === 1;
    };

    const createDisplayName = (userRoleObj: UserRole) => {
        const displayNameParts = [userRoleObj.firstName];
        !!userRoleObj.lastName && displayNameParts.push(userRoleObj.lastName);
        return displayNameParts.join(" ");
    };

    const createAccessibleLabel = (u: CellProps<UserRole, any>, controlType: "toggle" | "button") => {
        return `${u.cell.column.Header} ${controlType} for ${createDisplayName(u.cell.row.original)}`;
    };

    return (
        <>
            <Accordion
                data-testid={"usersAccordion"}
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ boxShadow: "none", marginBottom: "unset" }}
                defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                    Users Details
                </AccordionSummary>
                {isAdmin && (
                    <div style={{ textAlign: "right" }}>
                        <Button
                            data-testid={"addUserInvitationLink"}
                            size={"small"}
                            variant={"contained"}
                            onClick={() => {
                                setCanAdd(true);
                            }}>
                            Add User
                        </Button>
                    </div>
                )}

                <ReactTable
                    data={users}
                    columns={[
                        {
                            Header: "Name",
                            accessor: "firstName",
                            Cell: (u: any) => (
                                <div className="fs-exclude">
                                    <span id={u.cell.row.original.id}>{createDisplayName(u.cell.row.original)}</span>
                                    <br />
                                    <span style={{ fontSize: ".9em" }}>{u.cell.row.original.email}</span>
                                </div>
                            ),
                        },
                        {
                            Header: "Admin",
                            accessor: "role",
                            id: "admin",
                            Cell: (u: any) => (
                                <MaterialSwitch
                                    disabled={
                                        !isAdmin ||
                                        isOwnAccount(u.cell.row.original.id, loginContext.user?.id) ||
                                        isLastAdmin(u.cell.row.original)
                                    }
                                    checked={u.cell.row.original.role === "admin"}
                                    value="isAdmin"
                                    color="primary"
                                    data-testid={`isAdminRoleToggle-${u.cell.row.original.id}`}
                                    aria-label={createAccessibleLabel(u, "toggle")}
                                    onChange={handleRoleChange(u.cell.row.original.id, u.cell.row.original.role)}
                                />
                            ),
                        },
                        {
                            Header: "Receiving daily sales tax email",
                            id: "daily",
                            accessor: "role",
                            Cell: (u: any) => (
                                <Tooltip
                                    title={
                                        "This email includes information about sales and sales tax for location(s) associated with a given bank account."
                                    }
                                    placement="top">
                                    <MaterialSwitch
                                        disabled={
                                            !isAdmin && !isOwnAccount(u.cell.row.original.id, loginContext.user?.id)
                                        }
                                        checked={!u.cell.row.original.emailOptOut}
                                        color="primary"
                                        data-testid={`dailySalesTaxEmailToggle-${u.cell.row.original.id}`}
                                        aria-label={createAccessibleLabel(u, "toggle")}
                                        onChange={handleEmailOptOutChange(
                                            u.cell.row.original.id,
                                            u.cell.row.original.emailOptOut
                                        )}
                                    />
                                </Tooltip>
                            ),
                        },
                        {
                            Header: "Receiving daily summary email",
                            id: "summary",
                            accessor: "role",
                            Cell: (u: any) => (
                                <Tooltip
                                    title={
                                        "This email includes information about sales and set aside amounts for all locations associated with this account."
                                    }
                                    placement="top">
                                    <MaterialSwitch
                                        disabled={
                                            !isAdmin && !isOwnAccount(u.cell.row.original.id, loginContext.user?.id)
                                        }
                                        checked={u.cell.row.original.summaryOptIn}
                                        color="primary"
                                        data-testid={`dailySummaryEmailToggle-${u.cell.row.original.id}`}
                                        aria-label={createAccessibleLabel(u, "toggle")}
                                        onChange={handleSummaryOptInChange(
                                            u.cell.row.original.id,
                                            u.cell.row.original.summaryOptIn
                                        )}
                                    />
                                </Tooltip>
                            ),
                        },
                        {
                            Header: "",
                            accessor: "id",
                            Cell: (u: any) => (
                                <Button
                                    disabled={
                                        !isAdmin ||
                                        isOwnAccount(u.cell.row.original.id, loginContext.user?.id) ||
                                        isLastAdmin(u.cell.row.original)
                                    }
                                    onClick={() => removeUser(u.cell.row.original.id)}
                                    variant="outlined"
                                    color="primary"
                                    data-testid={`removeUserBtn-${u.cell.row.original.id}`}
                                    aria-describedby={u.cell.row.original.id}
                                    size="small">
                                    Remove
                                </Button>
                            ),
                            disableSortBy: true,
                        },
                        {
                            Header: "",
                            accessor: "lastName",
                        },
                        {
                            Header: "",
                            accessor: "email",
                        },
                    ]}
                    options={{
                        hiddenColumns: ["lastName", "email"],
                    }}
                />
            </Accordion>
            {invitations.length > 0 && (
                <Accordion
                    data-testid={"pendingInvitationsAccordion"}
                    slotProps={{ transition: { unmountOnExit: true } }}
                    style={{ boxShadow: "none" }}
                    defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                        Pending Invitations
                    </AccordionSummary>
                    <ReactTable
                        data={invitations}
                        columns={[
                            {
                                Header: "Invitation Email",
                                accessor: "originalEmail",
                                Cell: (t: any) => <span className="fs-exclude">{t.value}</span>,
                            },
                            {
                                Header: "Expiration Date",
                                accessor: "expires",
                                Cell: (t: any) => (
                                    <span style={t.value < DateTime.now() ? { color: "red" } : { color: "primary" }}>
                                        {toDisplayDateString(t.value)}
                                    </span>
                                ),
                            },
                            {
                                Header: "",
                                accessor: "id",
                                Cell: (t: any) => (
                                    <Button
                                        onClick={() => removeInvite(t.value)}
                                        variant="outlined"
                                        color="primary"
                                        size="small">
                                        Remove
                                    </Button>
                                ),
                            },
                        ]}
                    />
                </Accordion>
            )}
            {inactives.length > 0 && (
                <Accordion
                    data-testid={"inactiveUsersAccordion"}
                    slotProps={{ transition: { unmountOnExit: true } }}
                    style={{ boxShadow: "none" }}
                    defaultExpanded={false}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                        Expired Invitations
                        <Tooltip
                            title={"Invitations that have expired in the last 30 days will appear here. "}
                            placement="right-end">
                            <HelpTwoToneIcon fontSize="small" color="info" style={{ marginLeft: "10px" }} />
                        </Tooltip>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "flex", justifyContent: "space-around" }}>
                        <ReactTable
                            title={"Expired Invitations"}
                            columns={[
                                {
                                    Header: "Invitation Email",
                                    accessor: "originalEmail",
                                    Cell: (t: any) => <span className="fs-exclude">{t.value}</span>,
                                },
                                {
                                    Header: "Expiration Date",
                                    accessor: "expires",
                                    Cell: (t: any) => (
                                        <span
                                            style={t.value < DateTime.now() ? { color: "red" } : { color: "primary" }}>
                                            {toDisplayDateString(t.value)}
                                        </span>
                                    ),
                                },
                                {
                                    Header: "",
                                    accessor: "id",
                                    Cell: (t: any) => (
                                        <Button
                                            onClick={() => sendInvitePressed(t.value)}
                                            variant="contained"
                                            color="primary"
                                            size="small">
                                            Resend Invitation
                                        </Button>
                                    ),
                                },
                            ]}
                            data={inactives}
                        />
                    </AccordionDetails>
                </Accordion>
            )}
            {canAdd && (
                <Dialog
                    data-testid={"addUserInvitationModal"}
                    fullScreen={makeFullScreen}
                    open={true}
                    onClose={() => setCanAdd(false)}
                    aria-labelledby="responsive-dialog-title"
                    style={{ boxShadow: "none", padding: "10px" }}>
                    <DialogTitle id="responsive-dialog-title">Invite user</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter the email of the person you would like to invite. We will send them an invitation with
                            a link to connect.
                        </DialogContentText>
                        <TextField
                            className="fs-exclude"
                            label="Email Address"
                            isDisabled={!isAdmin}
                            value={email ?? ""}
                            onChange={setEmail}
                            validate={validateEmail}
                            onEnterPress={sendInvitePressed}
                            inputProps={{
                                [`data-testid`]: "userInvitationEmail",
                            }}
                        />
                        <FormControlLabel
                            style={{ marginLeft: "0px", color: "rgba(0, 0, 0, 0.54)" }}
                            control={
                                <MaterialSwitch
                                    checked={canInviteAsAdmin}
                                    value="inviteAdmin"
                                    color="primary"
                                    onChange={() => setCanInviteAsAdmin(!canInviteAsAdmin)}
                                />
                            }
                            label="Invite as admin?"
                            labelPlacement="start"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCanAdd(false)} variant="outlined" color="primary">
                            Cancel
                        </Button>
                        <Button
                            data-testid={"inviteUserButton"}
                            disabled={!isAdmin || !isEmailValid(email)}
                            onClick={() => invite()}
                            variant="contained"
                            color="primary">
                            Invite
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {confirmationModalProps.isDialogOpen && (
                <ConfirmationModal
                    message="Are you sure you want to remove this user's access to your account?"
                    title="Remove user?"
                    {...confirmationModalProps}
                />
            )}
        </>
    );
}
