import isNil from "lodash/isNil";
import { DateTime } from "luxon";
import { BankAccount, Location, TaxProfile } from "./Account";
import { toDisplayDateString } from "./Utils";

export function getLocationsInfo(
    locations: Location[],
    bankAccount?: BankAccount | null,
    taxProfiles?: TaxProfile[] | null
) {
    const hasMultipleLocations = locations && locations.length > 0 ? locations.length > 1 : false;
    const hasMissingBankInfo = locations && locations.length > 0 ? locations.some((l) => !l.bankAccountId) : false;
    const hasMissingTaxInfo =
        locations.length > 0 ? locations.some((l) => l.taxProfileIds.length === 0 && l.bankAccountId) : false;
    const hasFailingTaxInfo = taxProfiles
        ? taxProfiles.some((tp) => tp.credentialsFailing === "failing" && tp.filingMethod !== "take-give-back")
        : locations && locations.length > 0
          ? locations.some((l) =>
                l.taxProfiles.some((tp) => tp.credentialsFailing === "failing" && tp.filingMethod !== "take-give-back")
            )
          : false;
    const hasUntestedTaxCredentials = taxProfiles
        ? taxProfiles.some((tp) => tp.credentialsFailing === "untested" && tp.filingMethod !== "take-give-back")
        : locations && locations.length > 0
          ? locations.some((l) =>
                l.taxProfiles.some((tp) => tp.credentialsFailing === "untested" && tp.filingMethod !== "take-give-back")
            )
          : false;
    // specific to bank being passed in

    const arePausedBankAccountsInUse = locations.length > 0 ? bankAccountConnectionStatus(locations) : false;

    const isBankAccountPaused = bankAccount ? bankAccount.paused : false;
    const bankAccountUnpauseDate =
        bankAccount?.paused && bankAccount.unpauseDate ? toDisplayDateString(bankAccount.unpauseDate) : "";
    const hasAccountLocationWithoutFileOnlyOrMerchantPay =
        locations && locations.length > 0
            ? locations.some((l) =>
                  l.taxProfiles.some((tp) => tp.filingMethod !== "file-only" && tp.filingMethod !== "merchant-pay")
              )
            : false;

    // Converting created to a string due to full location objects being put on the queue (which deserializes created as a string vs. object). Once daily sales tax report is fixed, this can be removed
    const hasAccountLocationWithFirstMonthMerchantPay =
        locations.length > 0
            ? locations.some(
                  (l) =>
                      l.firstMonthMerchantPay &&
                      l.created &&
                      DateTime.fromISO(l.created.toString()).startOf("month") >= DateTime.utc().startOf("month")
              )
            : false;

    const isActionRequired =
        hasMissingTaxInfo ||
        hasFailingTaxInfo ||
        hasMissingBankInfo ||
        (hasAccountLocationWithoutFileOnlyOrMerchantPay && isBankAccountPaused && !bankAccountUnpauseDate);

    return {
        hasMultipleLocations,
        hasMissingBankInfo,
        hasMissingTaxInfo,
        isBankAccountPaused,
        bankAccountUnpauseDate,
        hasFailingTaxInfo,
        hasUntestedTaxCredentials,
        hasAccountLocationWithoutFileOnlyOrMerchantPay,
        hasAccountLocationWithFirstMonthMerchantPay,
        arePausedBankAccountsInUse,
        isActionRequired,
    };
}

const bankAccountConnectionStatus = (allLocations: Location[]) =>
    allLocations.some(
        (loc) =>
            loc.active &&
            isNil(loc.disconnected) &&
            loc.bankAccount?.paused &&
            isNil(loc.bankAccount?.unpauseDate) &&
            (!loc.firstMonthMerchantPay ||
                (loc.created && loc.created.startOf("month") < DateTime.utc().startOf("month"))) &&
            !(
                loc.taxProfiles.length > 0 &&
                loc.taxProfiles.every(
                    (tp: TaxProfile) =>
                        tp.filingMethod && (tp.filingMethod === "file-only" || tp.filingMethod === "merchant-pay")
                )
            )
    );

export interface IConnectionStatus {
    singleLocationIsInactive: boolean;
    hasMissingBankInfo: boolean;
    arePausedBankAccountsInUse: boolean;
    areBankAccountsRestricted: boolean;
    hasMissingTaxInfo: boolean;
    hasFailingTaxInfo: boolean;
    hasUntestedTaxCredentials: boolean;
    pausedWithUnpauseDate: DateTime | null | undefined;
}
