import * as Sentry from "@sentry/browser";
import isNil from "lodash/isNil";
import { toast, ToastOptions } from "react-toastify";

export const d30Toast = (message: string, options?: ToastOptions) => {
    return toast(message, {
        position: "bottom-right",
        ...options,
    });
};

export const d30ToastError = (message: string, error?: Error) => {
    if (error) {
        if (
            error.name === "MissingError" ||
            error.name === "ValidationError" ||
            error.name === "AbortError" ||
            error.message.includes("aborted")
        ) {
            console.debug(`${message}; ${error.message}`); // eslint-disable-line no-console
        } else {
            console.error(`${message}; ${error.message}`); // eslint-disable-line no-console
            Sentry.captureException(error);
        }
    }

    if (isNil(error) || (error.name !== "AbortError" && !error.message.includes("aborted"))) {
        toast(message, {
            position: "bottom-right",
            className: "toast-error",
            bodyClassName: "toast-body",
            progressClassName: "toast-progress",
        });
    }
};
