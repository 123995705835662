import {
    DisconnectReasonCodes,
    IAvaTaxValidatedAddressWithAdditionalFields,
    IConnectionStatus,
    Location,
    LocationRecord,
    MetaLocation,
    MetaLocationRecord,
    PartialAddress,
    TaxProfileTaxRate,
    toJSON,
    toPartial,
} from "@davo/types";
import { get, getArray, post, put } from "./api";

export const getLocation = async (accountId: string, locationId: string): Promise<LocationRecord> => {
    return get(`api/common/account/${accountId}/locations/${locationId}/`, true, MetaLocationRecord);
};

export const getLocationEnhanced = async (accountId: string, locationId: string): Promise<Location> => {
    return get(`api/common/account/${accountId}/locations/enhanced/${locationId}`, true, MetaLocation);
};

export const getLocations = async (accountId: string): Promise<LocationRecord[]> => {
    return getArray(`api/common/account/${accountId}/locations/`, true, MetaLocationRecord);
};

export const getLocationsAll = async (accountId: string): Promise<LocationRecord[]> => {
    return getArray(`api/common/account/${accountId}/locations/all`, true, MetaLocationRecord);
};

export const getLocationsEnhanced = async (accountId: string): Promise<Location[]> => {
    return getArray(`api/common/account/${accountId}/locations/enhanced`, true, MetaLocation);
};

export const getLocationsEnhancedAll = async (accountId: string): Promise<Location[]> => {
    return getArray(`api/common/account/${accountId}/locations/enhanced/all`, true, MetaLocation);
};

export const updateLocation = async (
    locationId: string,
    locationUpdate: Partial<Omit<Location, "bankAccountId" | "taxProfileId">>,
    accountId?: string
) => {
    await put(
        `api/common/account/${accountId}/locations/enhanced/${locationId}`,
        toJSON(toPartial(MetaLocation), locationUpdate),
        true
    );
};

export const getTaxRatesForLocations = async (accountId: string, locationIds: string[]) => {
    return post<TaxProfileTaxRate[]>(
        `api/common/account/${accountId}/tax-profiles/tax-rates/locations`,
        { locationIds },
        true
    );
};

export const getConnectionStatusForLocation = async (accountId: string, locationId: string) => {
    return get<IConnectionStatus>(`api/common/account/${accountId}/locations/${locationId}/connection-status`, true);
};

export async function disconnectLocation(
    locationId: string,
    disconnectReasonCode?: DisconnectReasonCodes,
    disconnectReasonNote?: string
) {
    await post(
        `api/common/account/location/${locationId}/disconnect`,
        { disconnectReasonCode, disconnectReasonNote },
        true
    );
}

export async function runDisconnectAutomation(
    locationId: string,
    disconnectReasonCode: DisconnectReasonCodes
): Promise<string> {
    return post<string>(
        `api/common/account/location/${locationId}/disconnect-automation`,
        { disconnectReasonCode },
        true
    );
}

export const setLocationBankAccount = async (accountId: string, locationId: string, bankAccountId: string) => {
    return put<{}>(
        `api/common/account/${accountId}/locations/${locationId}/bank-account`,
        {
            bankAccountId,
        },
        true
    );
};

export const setStateExternalLocation = async (
    accountId: string,
    locationId: string,
    taxProfileID: string,
    externalStateLocationId: string
) => {
    await put(
        `api/common/account/${accountId}/locations/${locationId}/tax-profiles/${taxProfileID}/externalLocation`,
        {
            externalStateLocationId,
        },
        true
    );
};

export async function verifyLocationAddress(
    location: LocationRecord
): Promise<IAvaTaxValidatedAddressWithAdditionalFields> {
    return post(`api/common/account/location/${location.id}/verify-location-address`, { location }, true);
}

export const updateLocationAddress = async (
    accountId: string,
    locationId: string,
    address?: PartialAddress,
    withValidation = false
) => {
    await put(
        `api/common/account/${accountId}/location/${locationId}/address`,
        { locationId, address, withValidation },
        true
    );
};

export async function markFirstFundingDeclined(locationIds: string[], reason: string) {
    return post<string[]>(`api/common/account/locations/mark-first-funding-declined`, { locationIds, reason }, true);
}

export async function initiateFirstFundingForLocations(locationIds: string[]) {
    return post<string[]>(`api/common/account/locations/initiate-first-funding`, { locationIds }, true);
}

export async function getFirstFundingBreakoutForLocations(
    locationIds: string[]
): Promise<{ outstandingAmount: number; locationId: string }[]> {
    return post<{ outstandingAmount: number; locationId: string }[]>(
        `api/common/account/locations/first-funding-breakout`,
        { locationIds },
        true
    );
}
