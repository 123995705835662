export interface IMarketplaceFacilitator {
    id: string;
    name: string;
}

export interface IMarketplaceFacilitatorJurisdiction {
    marketplaceFacilitatorId: string;
    jurisdictionId: string;
    includesLocal: boolean;
    includesRestaurant: boolean;
}

export interface IMarketplaceFacilitatorJurisdictionJoin {
    marketplaceFacilitatorName: string;
    includesLocal: boolean;
    includesRestaurant: boolean;
}
