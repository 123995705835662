import { Partner } from "@davo/types";
import { get, post } from "./api";

export const getPartner = async (partnerId: string) => {
    return get<Partner>(`api/common/partner/${partnerId}/`, true);
};

export const getPartners = async (partnerIds: string[]) => {
    return post<Partner[]>(`api/common/partner`, { partnerIds }, true);
};
