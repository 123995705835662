import cloneDeep from "lodash/cloneDeep";
import isNil from "lodash/isNil";
import { TaxProfileFailureReason } from "./TPFailureReasons";
import { automationDisplayName, User } from "./User";
import { titleCase } from "./Utils";

export type SmsReasonCodesTypes =
    | "password"
    | "twofactor"
    | "registration"
    | "pin"
    | "state"
    | "webfile"
    | "bankAccountPaused"
    | "dor"
    | "expectReconcileDebit"
    | "updateLocalEfileCredentials"
    | "boardingStuck"
    | "noAvailableFilings"
    | "lapsedCloverBilling";

export enum SupportedLanguages {
    "EN",
    "ES",
}

export type SupportedLanguage = keyof typeof SupportedLanguages;

export type SmsReasonCodes = SmsReasonCodesTypes | TaxProfileFailureReason;

export interface ISmsTemplates {
    displayTitle: string;
    reason: string;
    EN: string;
    ES?: string;
}

export const smsTemplates = {
    password: {
        displayTitle: "Invalid username/password",
        reason: "password",
        EN: `This is a courtesy message from DAVO Sales Tax informing you that the credentials you supplied do not allow us to log into your >!(*state*)!< Department of Revenue account. Please update this information at https://portal.davosalestax.com to avoid missed or late filings.`,
        ES: `Soy >!(*opsUser*)!< con DAVO Sales Tax. El usuario y la contraseña proporcionados para la cuenta de su estado no nos permiten acceder al sitio web >!(*state*)!< para presentar y pagar impuestos sobre las ventas en nombre de su empresa. Actualice su perfil con nosotros en el portal o contáctenos para obtener ayuda.`,
    },
    twofactor: {
        displayTitle: "Two factor authentication required",
        reason: "twofactor",
        EN: `This is a courtesy message from DAVO Sales Tax. We are unable to access your state Department of Revenue account because the two-factor authentication code is not set up to be sent to DAVO. Please open a chat with us at https://portal.davosalestax.com so that we can coordinate adding DAVO’s contact information to your state account to avoid missed or late filings.`,
        ES: `Soy >!(*opsUser*)!< con DAVO Sales Tax. Para tener acceso completamente a su cuenta de impuestos, el Departamento de Ingresos de >!(*state*)!< requiere una autenticación de dos factores. Estos códigos son sensibles al tiempo. ¿Está disponible para coordinar el acceso a su cuenta?`,
    },
    registration: {
        displayTitle: "Business not registered",
        reason: "registration",
        EN: `This is a courtesy message from DAVO Sales Tax. We are unable to file your sales return because your state account is not registered for sales and use tax. Please contact your state Department of Revenue to complete account setup.`,
        ES: `Soy >!(*opsUser*)!< con DAVO Sales Tax. Las credenciales que tenemos para el sitio web de su estado funcionan, pero no pudimos acceder a la empresa asociada dentro de la cuenta. Esto puede deberse a que la empresa asociada no está registrada para declarar el impuesto sobre las ventas. Actualice sus credenciales a continuación`,
    },
    pin: {
        displayTitle: "PIN required",
        reason: "pin",
        EN: `This is a courtesy message from DAVO Sales Tax. The PIN associated with your state Department of Revenue account does not currently let us submit a return. Please update this information at https://portal.davosalestax.com to avoid missed or late filings.`,
        ES: `Soy >!(*opsUser*)!< con DAVO Sales Tax. El PIN por su declaración electrónica que tenemos registrado actualmente no nos permite presentar su declaración con el Departamento de Ingresos de >!(*state*)!<. Actualice su perfil con nosotros con el PIN válido o contáctenos para obtener ayuda.`,
    },
    state: {
        displayTitle: "Invalid state account",
        reason: "state",
        EN: `This is a courtesy message from DAVO Sales Tax. The state tax ID for your business is invalid. Please update this information at https://portal.davosalestax.com to avoid missed or late filings.`,
        ES: `Soy >!(*opsUser*)!< con DAVO Sales Tax. Para tener acceso completo a su cuenta de impuestos sobre las ventas, DAVO necesita el código de autenticación que le acaba de enviar el Departamento de Ingresos de >!(*state*)!<. Responda con el código lo antes posible.`,
    },
    webfile: {
        displayTitle: "Invalid TX webfile number",
        reason: "webfile",
        EN: "This is a courtesy message from DAVO Sales Tax. The webfile number for your business is invalid. Please update this information at https://portal.davosalestax.com to avoid missed or late filings. If you need help finding this number, please contact the Texas Comptroller’s office.",
    },
    bankAccountPaused: {
        displayTitle: "Bank account paused",
        reason: "paused",
        EN: `This is a courtesy message from DAVO Sales Tax. Your bank account is paused and we are not able to debit your daily sales tax. Please log into https://portal.davosalestax.com and unpause your bank when you are ready to resume daily debits.`,
    },
    dor: {
        displayTitle: "State DOR password update",
        reason: "dor",
        EN: `This is a courtesy message from DAVO Sales Tax. Due to a required update on the >!(*state*)!< Department of Revenue website, your password has been changed to 'NEW PASSWORD'. If you have any questions, please review this information in your DAVO portal at https://portal.davosalestax.com.`,
    },
    expectReconcileDebit: {
        displayTitle: "Expect Reconcile Debit",
        reason: "expectReconcileDebit",
        EN: "This is a courtesy message from DAVO Sales Tax. DAVO has made full payment towards your tax return. As there was a funding shortage during this period, please expect a reconciliation debit for the unfunded amount(s).",
    },
    draft: {
        displayTitle: "Missing required fields",
        reason: "draft",
        EN: "This is a courtesy message from DAVO Sales Tax. A DAVO representative has drafted a new tax profile for you. Please go to https://portal.davosalestax.com and fill in the required fields to avoid missed or late filings.",
    },
    updateLocalEfileCredentials: {
        displayTitle: "Update E-file Credentials - Local",
        reason: "taxLoginLocal",
        EN: "This is a courtesy message from DAVO Sales Tax. The username and password you supplied do not allow us to log into your local tax website. Please update this information at https://portal.davosalestax.com to avoid missed or late filings.",
    },
    boardingStuck: {
        displayTitle: "Boarding: Stuck",
        reason: "boardingStuck",
        EN: "This is a courtesy message from DAVO Sales Tax. Your DAVO account set up is incomplete. For us to file your sales tax, there are a few more steps you need to take. Please complete this information at https://portal.davosalestax.com so that we can take sales tax off your plate.",
    },
    noAvailableFilings: {
        displayTitle: "No Available Filings",
        reason: "noAvailableFilings",
        EN: "This is a courtesy message from DAVO Sales Tax. When attempting to file for the current period we discovered that there is no sales return available. Please contact the state Department of Revenue to ensure that the current period is added to your account to avoid missed or late filings.",
    },
    lapsedCloverBilling: {
        displayTitle: "Lapsed Clover Billing",
        reason: "lapsedCloverBilling",
        EN: "This is a courtesy message from DAVO Sales Tax.  Clover, who bills the DAVO monthly app fee on our behalf, has reported that they cannot collect the $54.99 DAVO app fee from your bank account due to incorrect banking details.  To resolve, please contact Clover customer service at 1-855-853-8340.  Failure to fix your banking details with Clover will result in service termination of DAVO automated sales tax service.",
    },
} as { [reason: string]: ISmsTemplates };

export const SmsMessageReasonKeys = [...Object.keys(smsTemplates)] as SmsReasonCodes[];

export const getSMSTemplateLanguages = (key: SmsReasonCodes) => {
    const { reason, displayTitle, ...languages } = smsTemplates[key];
    return Object.keys(languages);
};

export const getSMSTemplate = (
    smsReason: SmsReasonCodes,
    smsLanguage: string | undefined,
    recipient: User,
    state?: string | undefined,
    opsUser?: User | undefined
) => {
    const clonedTemplate = cloneDeep(smsTemplates[smsReason]);
    if ((!isNil(smsLanguage) && smsLanguage in clonedTemplate) || isNil(smsLanguage)) {
        const { displayTitle, reason, ...languages } = clonedTemplate;
        for (const language in languages) {
            let smsMessageText = clonedTemplate[language as keyof (typeof smsTemplates)[typeof language]]!.replace(
                ">!(*state*)!<",
                !isNil(state) ? state : language === "EN" ? "state's" : "estado"
            );

            smsMessageText = smsMessageText.replace(
                ">!(*recipient*)!<",
                recipient.firstName ? titleCase(recipient.firstName) : ""
            );
            smsMessageText = smsMessageText.replace(
                ">!(*opsUser*)!<",
                !isNil(opsUser) && opsUser.firstName
                    ? titleCase(opsUser.id === "automation" ? automationDisplayName : opsUser.firstName)
                    : ""
            );
            clonedTemplate[language as keyof (typeof smsTemplates)[typeof language]] = smsMessageText;
        }
        return clonedTemplate;
    } else {
        throw new Error(`${smsReason} template does not include ${smsLanguage} translation.`);
    }
};

export interface IMessageTemplateText {
    language: string;
    text: string;
}

export interface IMessageTemplate {
    id: number;
    displayTitle: string;
    texts: IMessageTemplateText[];
}

export const filingMerchantNotes = [
    {
        id: 2,
        displayTitle: "Mismatched Tax Rates",
        texts: [
            {
                language: "EN",
                text: "Please check the rate(s) programmed in your POS. They do not match the rate calculated on the return.",
            },
        ],
    },
    {
        id: 3,
        displayTitle: "Outstanding Filings",
        texts: [
            {
                language: "EN",
                text: "Please note there are outstanding filings from prior periods on your sales tax account. Feel free to reach out if you'd like DAVO to assist in processing those returns.",
            },
        ],
    },
    {
        id: 4,
        displayTitle: "Rejected Transactions / Balance Due",
        texts: [
            {
                language: "EN",
                text: "Due to unfunded transactions, DAVO does not have all the funds to process the return payment in full. Please note the outstanding balance due amount and complete payment to avoid penalty and interest fees.",
            },
        ],
    },
    {
        id: 5,
        displayTitle: "Expect Reconcile Debit",
        texts: [
            {
                language: "EN",
                text: "This is a courtesy message from DAVO Sales Tax. DAVO has made full payment towards your tax return. As there was a funding shortage during this period, please expect a reconciliation debit for the unfunded amount(s). ",
            },
        ],
    },
];

export const returnMerchantNotes = [
    {
        id: 1,
        displayTitle: "Cancelled Service / Returned Funds",
        texts: [{ language: "EN", text: "Return of held collections per your service cancellation." }],
    },
];
