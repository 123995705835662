import { DateTime } from "luxon";

export interface IBillingSalesTax {
    id: string;
    billingId: string;
    amount: number; // pennies
    sales: number; // pennies
    created: DateTime;
    taxRateId: string;
    locationId?: string | null;
}

export interface IBillingSalesTaxWithRate extends IBillingSalesTax {
    taxRate: ISalesTaxRate;
}

export interface ISalesTaxRate {
    id: string;
    state: string; // state code - 2 characters
    county?: null | string;
    city?: null | string;
    rate: number;
    description: string;
    created: DateTime;
}
