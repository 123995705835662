import { TextField, useModalEditor } from "@davo/portal-common";
import { BankAccount, LocationRecord } from "@davo/types";
import { Autocomplete, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAccountContext } from "../context";
import { formatBankLabel } from "../util";
import { AddBankAccountModal } from "./AddBankAccountModal";
import { TermsAndConditionsForm } from "./TermsAndConditionsForm";

export interface IBankAccountSelector {
    bankAccountId?: string | null;
    header?: any;
    isDisabled?: boolean;
    setBankAccountIdCallback: (id: string | undefined) => void;
    location?: LocationRecord;
    hasAcceptedTermsOfUse?: boolean;
    setAcceptedTermsOfUse: (acceptedTermsOfUse: boolean) => void;
    onCancel?: () => void;
}

export const BankAccountSelector: React.FunctionComponent<IBankAccountSelector> = ({
    bankAccountId,
    setBankAccountIdCallback,
    header,
    isDisabled,
    hasAcceptedTermsOfUse,
    setAcceptedTermsOfUse,
    onCancel,
}) => {
    const accountContext = useAccountContext();
    const [nonHiddenBankAccounts, setNonHiddenBankAccounts] = useState<BankAccount[]>();
    const [selectedBankAccountId, setSelectedBankAccountId] = useState<string>();
    const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();

    const [showAddBankAccountModal, addBankAccountModalProps] = useModalEditor<string>(
        async (newBankAccountId?: string) => {
            if (!newBankAccountId) {
                onCancel && onCancel();
                return;
            }
            await accountContext.refresh();
            if (newBankAccountId) {
                setSelectedBankAccountId(newBankAccountId);
                setBankAccountIdCallback(newBankAccountId);
                setAcceptedTermsOfUse(false);
            }
        }
    );

    useEffect(() => {
        if (!accountContext.bankAccounts) {
            return;
        }
        const banks = accountContext.bankAccounts.filter((x: BankAccount) => !x.hidden);
        setNonHiddenBankAccounts(banks);
    }, [accountContext.bankAccounts]);

    useEffect(() => {
        if (nonHiddenBankAccounts && nonHiddenBankAccounts.length === 0) {
            showAddBankAccountModal();
        }
    }, [nonHiddenBankAccounts, showAddBankAccountModal]);

    useEffect(() => {
        if (bankAccountId) {
            setSelectedBankAccountId(bankAccountId);
        }
    }, [bankAccountId]);

    useEffect(() => {
        const selectedBank = nonHiddenBankAccounts?.find((ba: BankAccount) => ba.id === selectedBankAccountId);
        setSelectedBankAccount(selectedBank);
    }, [nonHiddenBankAccounts, selectedBankAccountId]);

    if (!accountContext.account || !nonHiddenBankAccounts) {
        return null;
    }

    return (
        <div data-testid={"bankAccountSelectorContainer"}>
            <div>
                <div>
                    {header}
                    <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                        {nonHiddenBankAccounts && nonHiddenBankAccounts.length > 0 && (
                            <Autocomplete
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            data-testid={"bankAccountSelectorInputContainer"}
                                            inputProps={{
                                                ...params.inputProps,
                                                [`data-testid`]: "bankAccountSelectorInput",
                                            }}
                                            label={"Bank Account"}
                                            value={params.inputProps.value}
                                        />
                                    );
                                }}
                                data-testid={"bankAccountDropdown"}
                                options={nonHiddenBankAccounts}
                                value={selectedBankAccount || null}
                                getOptionLabel={(bankOption) =>
                                    formatBankLabel({ ba: bankOption, withStarredPrefix: true })
                                }
                                renderOption={(props: object, bankOption: BankAccount) => {
                                    return (
                                        <div {...props} key={bankOption.id} data-testid={`ba-${bankOption.id}`}>
                                            {formatBankLabel({ ba: bankOption, withStarredPrefix: true })}
                                        </div>
                                    );
                                }}
                                onChange={(event, ba) => {
                                    setBankAccountIdCallback(ba?.id);
                                    setAcceptedTermsOfUse(false);
                                }}
                                disabled={isDisabled}
                                disablePortal={false}
                                autoSelect
                                id={"selectedBankInput"}
                            />
                        )}
                        <div style={{ textAlign: "left" }}>
                            <Button
                                data-testid={"addBankAccountBtn"}
                                color="primary"
                                variant="outlined"
                                size="small"
                                onClick={() => showAddBankAccountModal()}>
                                Add Bank Account
                            </Button>
                        </div>
                        <div style={{ paddingTop: "10px", textAlign: "left" }}>
                            <TermsAndConditionsForm
                                hasAcceptedTermsOfUse={hasAcceptedTermsOfUse}
                                onChangeAcceptedTermsOfUse={(hasAccepted: boolean) =>
                                    setAcceptedTermsOfUse(hasAccepted)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            {addBankAccountModalProps.isDialogOpen && (
                <AddBankAccountModal
                    showTermsAndConditions={false}
                    showSuccessToast={false}
                    {...addBankAccountModalProps}
                />
            )}
        </div>
    );
};
