import { NUMBER, ResolveObject, STRING } from "./MetaType";
import { POSType } from "./POS";

export const MetaDashboardSubscriptionSummary = {
    totalSubs: NUMBER(),
    totalSubsWithTp: NUMBER(),
    todaySubs: NUMBER(),
    bank: NUMBER(),
    tp: NUMBER(),
    disconnects: NUMBER(),
    quickDisconnects: NUMBER(),
};

export type DashboardSubscriptionSummary = ResolveObject<typeof MetaDashboardSubscriptionSummary>;

export const MetaDashboardCleanUp = {
    awaitingDisconnect: NUMBER(),
    cloverLapsed: NUMBER(),
    noAccountClover: NUMBER(),
    noBankClover: NUMBER(),
    noTp: NUMBER(),
    bankPaused: NUMBER(),
    tpFailing: NUMBER(),
};

export type DashboardCleanUp = ResolveObject<typeof MetaDashboardCleanUp>;

export const MetaDashboardSubscriptionsByPos = {
    posType: STRING<POSType>(),
    totalSubs: NUMBER(),
    todaySubs: NUMBER(),
    bank: NUMBER(),
    tp: NUMBER(),
    fromDevice: NUMBER(),
    disconnects: NUMBER(),
    quickDisconnects: NUMBER(),
};

export type DashboardSubscriptionsByPos = ResolveObject<typeof MetaDashboardSubscriptionsByPos>;

export const MetaDashboardFilingsByUserByDay = {
    day: STRING<string>(),
    user: STRING<string>(),
    filed: NUMBER(),
};

export type DashboardFilingsByUserByDay = ResolveObject<typeof MetaDashboardFilingsByUserByDay>;

export const MetaDashboardFilingEditsByUser = {
    user: STRING<string>(),
    edits: NUMBER(),
};

export type DashboardFilingEditsByUser = ResolveObject<typeof MetaDashboardFilingEditsByUser>;

export const MetaDashboardFilingsByUser = {
    user: STRING<string>(),
    filed: NUMBER(),
};

export type DashboardFilingsByUser = ResolveObject<typeof MetaDashboardFilingsByUser>;

export const MetaDashboardFilingsByDay = {
    day: STRING<string>(),
    filed: NUMBER(),
};

export type DashboardFilingsByDay = ResolveObject<typeof MetaDashboardFilingsByDay>;

export const MetaDashboardFilingsTotal = {
    filingDueDate: STRING<string>(),
    unfiled: NUMBER(),
    filed: NUMBER(),
};

export type DashboardFilingsTotal = ResolveObject<typeof MetaDashboardFilingsTotal>;

export const MetaDashboardUnFilingsByDueDate = {
    filingDueDate: STRING<string>(),
    unfiled: NUMBER(),
    unassigned: NUMBER(),
    jurisdiction: STRING<string>(),
};

export type DashboardUnFilingsByDueDate = ResolveObject<typeof MetaDashboardUnFilingsByDueDate>;
