import { auth, get, post } from "@davo/portal-common";
import { IMerchantInvitationInfo, IUserInvitationInfo, UserInvitation } from "@davo/types";

export const getMerchantInvitationInfo = (invitationId: string) =>
    get<IMerchantInvitationInfo>(`invitation/location/${invitationId}/`, false);

export const preAccountInvitation = async (invitationId: string) => {
    await post<{ status: string }>(`api/public/invitation/pre-account/${invitationId}`, {}, false);
};

export const reconnectAccountInvitation = async (invitationId: string) => {
    await post<{ status: string }>(`api/public/invitation/reconnect/${invitationId}`, {}, false);
};

export const redeemInvitation = async (invitationId: string, accountId: string | undefined, locationIds: string[]) => {
    const response = await post<{ status: string; accountId: string }>(
        `api/public/invitation/location/${invitationId}`,
        {
            accountId,
            locationIds,
        },
        true
    );
    await auth.whoAmI();
    return response;
};

export const getUserInvitationInfo = async (code: string) => {
    return get<IUserInvitationInfo>(`invitation/user/${code}/`, false);
};

export const getUserInvitationsForAccount = (accountId: string) => {
    return get<UserInvitation[]>(`api/common/account/${accountId}/users/invitations`, true);
};

export const redeemUserInvitation = async (code: string) => {
    await post(`api/public/invitation/user/${code}`, {}, true);
    await auth.whoAmI();
};
