import { IJurisdictionUIField } from "./JurisdictionUIField";

export interface IJurisdictionFilingType {
    id: string;
    jurisdictionId: string;
    name: string;
    url?: null | string;
    note?: null | string;
    twoFactorRequired: boolean;
    posTypeRequired: boolean;
    tenderTypeRequired: boolean;
    rateTagsRequired: boolean;
    siteName?: null | string;
    defaultFee?: null | number;
    defaultFeeDescription?: null | string;
    canAutofile: boolean;
    stopAutofile: boolean;
    isDefault: boolean;
    tag: string;
    fields: IJurisdictionUIField[];
    skyscraperCheckCreds: boolean;
    skyscraperTaxFormId?: string | null;
    davoCheckCreds: boolean;
    active: boolean;
}
