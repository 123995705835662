import { BOOLEAN, DATETIME, ResolveObject, STRING, UUID } from "./MetaType";

export const MetaSmsMessage = {
    id: UUID(),
    twilioId: STRING().optional(),
    created: DATETIME(),
    fromMerchant: BOOLEAN(),
    merchantUserId: STRING().optional(),
    opsUserId: STRING().optional(),
    message: STRING().optional(),
    undeliverable: BOOLEAN(),
    hasMedia: BOOLEAN(),
    accountLocationInvitationId: STRING().optional(),
    phone: STRING().optional(),
    status: STRING(),
};

export type SmsMessage = ResolveObject<typeof MetaSmsMessage>;

export type NotificationContactInfo = { id: string; phone: string; firstName: string; lastName: string };

export type MerchantNotificationInfo = Array<{ phones: NotificationContactInfo[]; accountId: string }>;

export const MetaSmsMessageDisplay = {
    ...MetaSmsMessage,
    opsUserDisplay: STRING().optional(),
    media: STRING().array().optional(),
    merchantFullname: STRING().optional(),
};
export type SmsMessageDisplay = ResolveObject<typeof MetaSmsMessageDisplay>;
export type TwilioStatuses =
    | "accepted"
    | "queued"
    | "sending"
    | "sent"
    | "failed"
    | "delivered"
    | "undelivered"
    | "receiving"
    | "received"
    | "read";

export interface ITwilioWebhook {
    AccountSid: string;
    Body: string;
    From: string;
    MediaContentType?: null | string; // sequential: MediaContentType0, MediaContentType1, etc.
    MediaUrl: string[]; // same as MediaContentType: MediaUrl0, MediaUrl1, etc.
    MessageSid: string;
    MessagingServiceSid: string;
    NumMedia: number;
    Status: TwilioStatuses;
    To: string;
}

export interface ITwilioStatus {
    AccountSid: string;
    ApiVersion: string;
    From: string; // typically "+12072808583",
    MessageSid: string;
    MessageStatus: TwilioStatuses;
    SmsSid: string;
    SmsStatus: TwilioStatuses;
    To: string; // "+12076180476"
}

export interface ITwilioResponse {
    accountSid: string;
    apiVersion: string;
    body: string;
    dateCreated: Date; // 2020-09-02T03:58:38.000Z
    dateSent: Date;
    dateUpdated: Date;
    direction: string;
    errorCode: number;
    errorMessage: string;
    from: string;
    messagingServiceSid: string;
    numMedia: string;
    numSegments: string;
    price: string;
    priceUnit: string;
    sid: string;
    status: string;
    subresourceUris: string;
    to: string;
    uri: string;
}
