import { d30ToastError, getNotices, Header, NoticesTable, useRefresh } from "@davo/portal-common";
import { AddNoticeModal } from "@davo/portal-common/lib/AddNoticeModal";
import { BusinessDay, INotice, toSQLDate } from "@davo/types";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import InfoIcon from "@mui/icons-material/Info";
import { Alert, Button, Paper, Tooltip } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { LimitedTimeMerchantBanner } from "./LimitedTimeMerchantBanner";
import { MerchantBanner } from "./MerchantBanner";
import { useAccountContext } from "./context";

export function NoticesTab() {
    const accountContext = useAccountContext();

    const [periodStart, setPeriodStart] = useState<BusinessDay>();
    const [periodEnd, setPeriodEnd] = useState<BusinessDay>();
    const [notices, setNotices] = useState<INotice[]>();
    const [hasError, setHasError] = useState<boolean>(false);

    const [refresh] = useRefresh(() => {
        searchByDate().catch((e) => {
            d30ToastError("Could not refresh data!", e);
        });
    });

    useAsyncEffect(async () => {
        if (!accountContext.account) {
            return;
        }

        const endDate = toSQLDate(DateTime.now());
        const endBusinessDay = BusinessDay.fromSQLDate(endDate);
        setPeriodEnd(endBusinessDay);

        const startBusinessDay = BusinessDay.fromSQLDate(
            DateTime.fromSQL(endDate).get("month") === 1
                ? toSQLDate(DateTime.now().minus({ year: 1 }).startOf("year"))
                : toSQLDate(DateTime.now().startOf("year"))
        );
        setPeriodStart(startBusinessDay);
        await searchForNotices(accountContext.account.id, startBusinessDay, endBusinessDay);
    }, [accountContext.account]);

    const searchForNotices = async (accountId: string, startDay: BusinessDay, endDay: BusinessDay) => {
        const n = await getNotices(accountId, startDay, endDay);
        setNotices(n);
    };

    const searchByDate = async () => {
        if (!accountContext.account) {
            return;
        }
        if (periodStart && periodEnd) {
            await searchForNotices(accountContext.account.id, periodStart, periodEnd);
        }
    };

    useEffect(() => {
        if (!periodStart || !periodEnd || periodStart.toDateTime() > periodEnd.toDateTime()) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [periodStart, periodEnd]);

    if (!accountContext.account || !accountContext.taxProfiles) {
        return null;
    }

    return (
        <div>
            <MerchantBanner />
            <LimitedTimeMerchantBanner />
            <Paper
                style={{
                    padding: "24px",
                    paddingBottom: "50px",
                    boxShadow: "4px 4px 5px -3px rgba(219,219,219,1)",
                    marginBottom: "50px",
                }}>
                <Header>
                    <span style={{ color: "rgb(3, 187, 156)", marginLeft: "15px" }}>
                        Tell us about a notice you received
                    </span>
                </Header>
                <h4>
                    <div
                        style={{
                            color: "#1a2f5b",
                            width: "50%",
                            marginLeft: "15px",
                            display: "flex",
                            flexDirection: "row",
                        }}>
                        <span>
                            This could include tax discrepancies, changes to rates, penalties, or something else.
                        </span>
                        <Tooltip title="You’ll be asked to choose a tax profile, and fill out the dates the notice applies to.  We also ask that you upload the file you received if possible.">
                            <div style={{ textAlign: "right", marginTop: "2px" }}>
                                <InfoIcon fontSize={"small"} color="primary" />
                            </div>
                        </Tooltip>
                    </div>
                </h4>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "5px",
                    }}>
                    <div style={{ margin: "5px 10px" }}>
                        <AddNoticeModal accountId={accountContext.account.id} isDisabled={false} refresh={refresh} />
                    </div>
                </div>
            </Paper>

            <Paper style={{ padding: "24px", paddingBottom: "0px", boxShadow: "none" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {periodStart && periodEnd && (
                                <div
                                    style={{
                                        textAlign: "left",
                                        alignSelf: "center",
                                        flexGrow: 10,
                                        color: "rgb(3, 187, 156)",
                                        fontWeight: 600,
                                    }}></div>
                            )}
                            <Tooltip
                                title={"Search by periods to see a history of notices you have reported to us"}
                                placement="left-start">
                                <HelpTwoToneIcon fontSize="small" color="primary" style={{ alignSelf: "center" }} />
                            </Tooltip>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ marginLeft: "10px" }}>
                                        <MobileDatePicker
                                            views={["year", "month"]}
                                            label="Start Date"
                                            value={periodStart ? periodStart.toDateTime().toJSDate() : null}
                                            closeOnSelect={true}
                                            onChange={(x) => {
                                                setPeriodStart(
                                                    x
                                                        ? BusinessDay.fromSQLDate(toSQLDate(DateTime.fromJSDate(x)))
                                                        : undefined
                                                );
                                            }}
                                            format="MM-yyyy"
                                            minDate={DateTime.now().minus({ years: 4 }).toJSDate()}
                                            maxDate={DateTime.now().toJSDate()}
                                            slotProps={{
                                                textField: { style: { width: "100%", margin: "20px 0" } },
                                                toolbar: { hidden: true },
                                            }}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            marginLeft: "10px",
                                            flexDirection: "column",
                                            marginBottom: "unset",
                                        }}>
                                        <MobileDatePicker
                                            views={["year", "month"]}
                                            label="End Date"
                                            value={periodEnd ? periodEnd.toDateTime().toJSDate() : null}
                                            closeOnSelect={true}
                                            onChange={(x) => {
                                                setPeriodEnd(
                                                    x
                                                        ? BusinessDay.fromSQLDate(toSQLDate(DateTime.fromJSDate(x)))
                                                        : undefined
                                                );
                                            }}
                                            format="MM-yyyy"
                                            minDate={DateTime.now().minus({ years: 4 }).toJSDate()}
                                            maxDate={DateTime.now().plus({ years: 1 }).toJSDate()}
                                            slotProps={{
                                                textField: { style: { width: "100%", margin: "20px 0" } },
                                                toolbar: { hidden: true },
                                            }}
                                        />
                                    </div>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        style={{
                                            marginLeft: "10px",
                                            height: "55px",
                                            display: "flex",
                                            alignSelf: "center",
                                        }}
                                        color="primary"
                                        onClick={() => searchByDate()}
                                        disabled={hasError}>
                                        Search
                                    </Button>
                                </div>
                                <span
                                    style={{
                                        color: "#1a2f5b",
                                        fontSize: "12px",
                                        fontStyle: "italic",
                                        alignSelf: "center",
                                    }}></span>
                            </div>
                        </LocalizationProvider>
                    </div>
                    <span
                        style={{
                            fontSize: "14px",
                            fontWeight: "light",
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "11px",
                        }}>
                        {hasError && <Alert severity="error">Start date must be before end date!</Alert>}
                        {(!notices || notices.length === 0) && (
                            <Alert severity="info">No data available for these dates. Please search again.</Alert>
                        )}
                    </span>
                    <NoticesTable
                        notices={notices}
                        includeCancelButton={true}
                        includeNotes={true}
                        refresh={searchByDate}
                    />
                </div>
            </Paper>
        </div>
    );
}
