import { Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAccountContext } from "./context";
import filing from "./resources/davo_illustrations_calendar.png";

export const SettingsTaxProfileEmptyState: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const accountContext = useAccountContext();

    return (
        <div style={{ textAlign: "center" }}>
            <img src={filing} style={{ maxWidth: "100%" }} alt={"Filing"} />
            <Typography style={{ fontSize: "20px", margin: "10px", maxWidth: "100%" }}>
                Set up automated filing
            </Typography>
            <Tooltip
                title={
                    "We can save you hours each month by automatically filing your sales taxes and transferring the funds directly to tax agencies."
                }>
                <Typography
                    style={{
                        fontSize: "14px",
                        maxWidth: "100%",
                        color: "#252631",
                        margin: "auto",
                        marginBottom: "30px",
                    }}>
                    {
                        "We can save you hours each month by automatically filing your sales taxes and transferring the funds directly to tax agencies."
                    }
                </Typography>
            </Tooltip>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => navigate(`/${accountContext.account?.id}/settings/company`)}>
                    Set up automated filing
                </Button>
            </div>
        </div>
    );
};
