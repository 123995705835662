import { Header, POSLogo, qbReconnectBtn, TaxProfileEdit, useLoginContext, useModalEditor } from "@davo/portal-common";
import { TaxProfile } from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Grid,
    Tooltip,
    Typography,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { BankAccountStatus, LocationInfo, TaxProfileStatus } from "./components";
import { ActionsRequiredMessage } from "./components/ActionsRequiredMessage";
import { useAccountContext, useConfigMerchantContext, useLocationContext } from "./context";
import { disconnectQuickbooksLocation } from "./services/account";
import "./SettingsSingleLocation.css";

export function SingleLocation() {
    const navigate = useNavigate();

    const { merchantConfigInfo: configInfo } = useConfigMerchantContext();
    const loginContext = useLoginContext();
    const accountContext = useAccountContext();
    const locationContext = useLocationContext();

    // NOTE: extract /:accountId/settings/company and remove suffix
    const url = useLocation().pathname.split("/").slice(0, 4).join("/");
    const { locationId } = useParams();

    const [areActionsRequired, setAreActionsRequired] = useState<boolean>(false);
    const [restrictedMsg, setRestrictedMsg] = useState<string>("Our partner manages your banking information");
    const [isRestrictedBtnDisabled, setIsRestrictedBtnDisabled] = useState<boolean>(true);
    const [sortedTaxProfileStates, setSortedTaxProfileStates] = useState<string[]>();

    const [showTaxProfileEdit, taxProfileEditProps] = useModalEditor<string[]>(async () => {
        await accountContext.refresh();
        await locationContext.refresh();
    });

    useAsyncEffect(async () => {
        if (locationId && locationId !== locationContext.location?.id) {
            await locationContext.switch(locationId);
        }
    }, [locationId, locationContext.location]);

    useAsyncEffect(async () => {
        if (
            locationContext.location &&
            accountContext.locationsNotFullyConnected &&
            accountContext.locationsNotFullyConnected.has(locationContext.location.id)
        ) {
            setAreActionsRequired(true);
        } else {
            setAreActionsRequired(false);
        }
    }, [locationContext.location, accountContext.locationsNotFullyConnected]);

    useEffect(() => {
        if (loginContext.user && loginContext.user.type !== "Merchant") {
            setRestrictedMsg("Merchant is restricted from editing this information");
            setIsRestrictedBtnDisabled(false);
        }
    }, [loginContext.user]);

    useEffect(() => {
        if (locationContext.location?.taxProfiles.length) {
            const tpStates = locationContext.location?.taxProfiles.map((tp) => tp.state);
            tpStates.sort();
            const uniqueStates = [...new Set(tpStates)];
            setSortedTaxProfileStates(uniqueStates);
        }
    }, [locationContext.location?.taxProfiles]);

    if (!accountContext.account) {
        return null;
    }

    const doDisconnect = async () => {
        if (!accountContext.account || !locationContext.location) {
            return;
        }
        await disconnectQuickbooksLocation(accountContext.account.id, locationContext.location.id);
    };

    const renderBankLink = (hasBankAccount: boolean, locationUrl: string) => {
        if (accountContext.partner?.restrictEditBankInfo) {
            return (
                <>
                    <Button
                        disabled={isRestrictedBtnDisabled}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => navigate(`${locationUrl}/bank`)}>
                        {hasBankAccount ? "Change" : "Fix"}
                    </Button>
                    <Tooltip title={restrictedMsg} placement="top">
                        <HelpTwoToneIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} />
                    </Tooltip>
                </>
            );
        }
        return (
            <Button
                variant={!locationContext.location?.bankAccount ? "contained" : "outlined"}
                color="primary"
                size="small"
                onClick={() => navigate(`${locationUrl}/bank`)}>
                {hasBankAccount ? "Change" : "Fix"}
            </Button>
        );
    };

    if (locationContext.location && accountContext.locations && accountContext.locations.filter((l) => l.active)) {
        const locationUrl = `${url}/${locationContext.location.id}`;
        return (
            <>
                <Header>
                    <span style={{ display: "inline", fontSize: "20px", marginBottom: "16px" }}>Location Details</span>
                    <span style={{ margin: "0 14px" }}>|</span>
                    {`${locationContext.location.name} ${!locationContext.location.active ? " - Inactive" : ""}`}
                    <POSLogo posType={locationContext.location.posType} width={125} shouldFloatRight={true} />
                </Header>

                <LocationInfo />

                <ActionsRequiredMessage showBanner={areActionsRequired} />

                <Typography style={{ fontSize: "20px", margin: "20px 0" }}>Banking and Tax</Typography>

                <Grid className={"financial-settings-container"} spacing={{ xs: 2, md: 3 }} container>
                    <Grid item xs={12} md={6}>
                        <Card variant={"outlined"}>
                            <CardHeader
                                title={
                                    <div className={"da-settings-header-content"}>
                                        <BankAccountStatus
                                            accountId={accountContext.account.id}
                                            bankAccount={locationContext.location.bankAccount}
                                            showToolTipOnly={true}
                                        />
                                        <Typography
                                            variant={"h4"}
                                            style={{ fontSize: "16px", display: "inline-block" }}>
                                            Bank Account
                                        </Typography>
                                    </div>
                                }
                            />
                            <CardContent>
                                <div className={"da-finance-item"}>
                                    <strong>Name:</strong>{" "}
                                    {locationContext.location.bankAccount?.nickname
                                        ? locationContext.location.bankAccount?.nickname + " - "
                                        : ""}{" "}
                                    {locationContext.location.bankAccount?.name ?? "None"}
                                </div>
                                <div className={"da-finance-item"}>
                                    <strong>Account:</strong>{" "}
                                    {locationContext.location.bankAccount?.accountStarred
                                        ? locationContext.location.bankAccount?.accountStarred
                                        : "None"}
                                </div>
                            </CardContent>
                            <div style={{ flexGrow: "1" }} />
                            <CardActions>
                                {renderBankLink(!!locationContext.location.bankAccount, locationUrl)}
                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card variant={"outlined"}>
                            <CardHeader
                                title={
                                    <div className={"da-settings-header-content"}>
                                        <TaxProfileStatus
                                            taxProfiles={locationContext.location.taxProfiles}
                                            showToolTipOnly={true}
                                        />
                                        <Typography
                                            variant={"h4"}
                                            style={{ fontSize: "16px", display: "inline-block" }}>
                                            Tax Profile
                                        </Typography>
                                    </div>
                                }
                            />

                            <CardContent>
                                <div className={"da-finance-item"}>
                                    <strong>Tax Profiles:</strong>
                                    {locationContext.location.taxProfiles.length
                                        ? locationContext.location.taxProfiles.map((tp: TaxProfile) => (
                                              <Chip
                                                  style={{ margin: "3px" }}
                                                  label={tp.name}
                                                  key={tp.id}
                                                  variant="outlined"
                                                  size={"small"}
                                              />
                                          ))
                                        : "None"}
                                </div>
                                <div className={"da-finance-item"}>
                                    <strong>Tax Profile States:</strong>
                                    {sortedTaxProfileStates ? sortedTaxProfileStates.join(", ") : "None"}
                                </div>
                            </CardContent>
                            <div style={{ flexGrow: "1" }} />
                            <CardActions>
                                {locationContext.location.taxProfiles.length === 1 && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={() =>
                                            showTaxProfileEdit([locationContext.location!.taxProfiles[0].id])
                                        }
                                        style={{ margin: "0 6px" }}>
                                        {"Edit"}
                                    </Button>
                                )}

                                <Tooltip
                                    title={
                                        isEmpty(locationContext.location.taxProfiles) &&
                                        !locationContext.location.bankAccount
                                            ? "Please add a bank account before adding a tax profile"
                                            : ""
                                    }
                                    placement="top">
                                    <span>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={() => navigate(`${locationUrl}/tax`)}
                                            disabled={
                                                isEmpty(locationContext.location.taxProfiles) &&
                                                !locationContext.location.bankAccount
                                            }>
                                            Select Tax Profiles
                                        </Button>
                                    </span>
                                </Tooltip>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>

                {locationContext.location && locationContext.location.posType === "quickbooks" && (
                    <>
                        <Accordion style={{ border: "none", boxShadow: "none" }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                                More Info
                            </AccordionSummary>
                            <AccordionDetails style={{ display: "flex", flexFlow: "column" }}>
                                {!locationContext.location.disconnected && (
                                    <Button
                                        variant="outlined"
                                        onClick={doDisconnect}
                                        size="small"
                                        color="primary"
                                        style={{ width: "225px", display: "block", margin: "0 auto" }}>
                                        Disconnect Quickbooks
                                    </Button>
                                )}
                                {locationContext.location.disconnected && (
                                    <>
                                        <Typography>
                                            {`Disconnected: ${locationContext.location.disconnected.toFormat(
                                                "dd MMM yyyy"
                                            )} We will be in contact to understand what to do with any tax we are currently holding.  Click below to reconnect.`}
                                        </Typography>
                                        <div
                                            style={{
                                                display: "block",
                                                margin: "0 auto",
                                                marginTop: "20px",
                                            }}>
                                            <a
                                                href={configInfo.quickBooksConnectLink}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <img src={qbReconnectBtn} alt="QuickBooks reconnect button" />
                                            </a>
                                        </div>
                                    </>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </>
                )}

                <Outlet />
                {taxProfileEditProps.isDialogOpen && (
                    <TaxProfileEdit accountId={accountContext.account.id} {...taxProfileEditProps} />
                )}
            </>
        );
    }
}
