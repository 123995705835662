import { BusinessDay, MerchantDailyDetail, money, toDateTime } from "@davo/types";
import isEmpty from "lodash/isEmpty";
import reverse from "lodash/reverse";
import startCase from "lodash/startCase";
import React, { useEffect, useState } from "react";
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis } from "recharts";

const TooltipContent = ({ payload = [] }: { payload?: any[] }) => {
    return (
        <div
            style={{
                background: "white",
                padding: "5px",
                borderRadius: "4px",
                boxShadow: "0 2px 16px 0 rgba(153,155,168,0.12)",
            }}>
            <table>
                <tbody>
                    {reverse(payload || [])
                        .filter((item) => item.name !== "fakeSales")
                        .map((item, i) => (
                            <tr key={i}>
                                <td>
                                    <div
                                        style={{
                                            display: "span",
                                            background: item.color,
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "2px",
                                        }}>
                                        &nbsp;
                                    </div>
                                </td>
                                <td align="right" style={{ fontWeight: "bold" }}>
                                    &nbsp;${money(+item.value / 100)}
                                </td>
                                <td>&nbsp;{startCase(item.name)}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};

interface IMerchantDailyDetailAndFakeSales extends MerchantDailyDetail {
    day: number;
    fakeSales?: number;
}

function getDataToDisplay(data: MerchantDailyDetail[]): IMerchantDailyDetailAndFakeSales[] | [] {
    if (isEmpty(data)) {
        return [];
    }

    const sales = data.map((d) => d.sales).filter((v) => v !== 0);
    let min = 1000000;
    let max = -1000000;
    if (!isEmpty(sales)) {
        min = Math.min(min, ...sales);
        max = Math.max(max, ...sales);
    }
    const today = BusinessDay.today();

    return data.map((d) => {
        const fakeSales =
            d.date <= today || d.sales ? 0 : Math.round((Math.random() * Math.max(max - min, 0) + min) * 100) / 100;

        return {
            ...d,
            fakeSales,
            day: toDateTime(d.date).day,
        };
    });
}

export const TaxChart = ({ height, data }: { height: number; data: MerchantDailyDetail[] }) => {
    const [focusBar, setFocusBar] = useState<number | undefined>(undefined);
    const [dataToDisplay, setDataToDisplay] = useState<IMerchantDailyDetailAndFakeSales[]>([]);

    useEffect(() => {
        setDataToDisplay(getDataToDisplay(data));
    }, [data]);

    return (
        <ResponsiveContainer height={height} width="99%">
            <BarChart
                barCategoryGap={"15%"}
                data={dataToDisplay}
                margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
                onMouseMove={(state: any) => {
                    if (state.isTooltipActive) {
                        setFocusBar(state.activeTooltipIndex);
                    } else {
                        setFocusBar(undefined);
                    }
                }}>
                <XAxis dataKey="day" axisLine={false} tickLine={false} interval={1} />
                <Tooltip cursor={{ fill: "white" }} animationDuration={500} content={<TooltipContent />} />
                <Bar dataKey="salesTax" isAnimationActive={false} animationDuration={400} stackId="a" fill="#FE4D97">
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={focusBar === index ? "#FE4D97" : "#e8d3ef"} />
                    ))}
                </Bar>
                <Bar
                    dataKey="sales"
                    isAnimationActive={false}
                    animationDuration={400}
                    stackId="a"
                    fill="#4D7CFE"
                    radius={[2, 2, 0, 0]}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={focusBar === index ? "#4D7CFE" : "#e4ebff"} />
                    ))}
                </Bar>
                <Bar
                    dataKey="fakeSales"
                    isAnimationActive={false}
                    animationDuration={400}
                    stackId="a"
                    fill="#E8ECEF"
                    radius={[2, 2, 0, 0]}
                />
            </BarChart>
        </ResponsiveContainer>
    );
};
