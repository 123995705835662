import { get, post } from "@davo/portal-common";
import { IInvitation, IOnboardingResult, LocationRecord, POSType } from "@davo/types";

export const boardLocation = async (body: any, postype: POSType): Promise<IOnboardingResult> => {
    return post(`api/boarding/create/${postype}`, body, true);
};

export const testRevelCredentials = async (prefix: string, apiKey: string, apiSecret: string) => {
    return post(`api/public/boarding/credentials/revel`, { prefix, apiKey, apiSecret }, false);
};

export const testRestaurant365Credentials = async (username: string, password: string) => {
    return post(`api/public/boarding/credentials/restaurant365`, { username, password }, false);
};

export const testUSeriesCredentials = async (username: string, password: string) => {
    return post(`api/public/boarding/credentials/lightspeed-u-series`, { username, password }, false);
};

export const testSpotOnRestaurantCredentials = async (spotOnLocId: string) => {
    return post(`api/public/boarding/credentials/spoton-restaurant`, { spotOnLocId }, false);
};

export const testSpotOnRetailCredentials = async (spotOnLocId: string) => {
    return post(`api/public/boarding/credentials/spoton-retail`, { spotOnLocId }, false);
};

export const testHeartlandRestaurantCredentials = async (apiKey: string) => {
    return post(`api/public/boarding/credentials/heartland-restaurant`, { apiKey }, false);
};

export const testQuickBooksCredentials = async (realmId: string) => {
    return post(`api/public/boarding/credentials/quickbooks`, { realmId }, false);
};

export const testMindbodyCreds = async (siteId: string) => {
    return post(`api/public/boarding/credentials/mindbody`, { siteId }, false);
};

export const testLavuCredentials = async (key: string, token: string, dataname: string) => {
    return post(`api/public/boarding/credentials/lavu`, { key, token, dataname }, false);
};

export const getMindbodyActivationLink = async (siteId: string) => {
    return post(`api/public/boarding/preboard-pos/mindbody`, { siteId }, false);
};

export const getIncompleteBoarding = async () => {
    try {
        return await get<IInvitation>("api/common/boarding/incomplete", true, undefined, undefined, false);
    } catch {
        return undefined;
    }
};

export const getInvitationByPOSMerchant = async (posType: POSType, posMerchant: string) => {
    return post<IInvitation | undefined>(`api/common/boarding/invitation`, { posType, posMerchant }, true);
};

export const getLocationsByPOSMerchant = async (posType: POSType, posMerchant: string) => {
    return post<LocationRecord[] | undefined>(`api/common/boarding/locations`, { posType, posMerchant }, true);
};

export const checkBoardingHasAccess = async (invitationId: string, posLocation: string): Promise<boolean> => {
    const result = await post<{ value: boolean }>(
        `api/common/boarding/has-access/${invitationId}`,
        { posLocation },
        true,
        undefined,
        undefined,
        undefined,
        false
    );
    return result.value;
};
