import { LocationRecord } from "@davo/types";
import { InputBase } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import orderBy from "lodash/orderBy";
import React, { useEffect, useState } from "react";
import { Select } from "./Select";

const SubtleSelectInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        padding: "0px",
        border: "none",
        boxShadow: "none",
        marginRight: "8px",
        paddingLeft: "6px",
        position: "relative",
        fontSize: "20px",
        color: "#778ca2",
    },
}))(InputBase);

export interface ILocationSelectorProps {
    accountId: string;
    locations: LocationRecord[];
    location?: LocationRecord;
    onLocationChange: (v: LocationRecord | undefined) => void;
    locationFilter?: (location: LocationRecord) => boolean;
    onSetLocationsFiltered?: (locations: LocationRecord[]) => void;
    forceRefilter?: any; // this item can be monitored and caused a refilter
}

const defaultLocationFilter = (location: LocationRecord) => location.active;

export function LocationSelector({
    accountId,
    locations,
    location,
    onLocationChange: parentOnLocationChange,
    locationFilter: parentLocationFilter,
    forceRefilter,
    onSetLocationsFiltered,
}: ILocationSelectorProps) {
    const [currentLocation, setCurrentLocation] = useState<LocationRecord | undefined>();
    const [filteredLocations, setFilteredLocations] = useState<LocationRecord[]>([]);

    useEffect(() => {
        const locationFilter = parentLocationFilter ? parentLocationFilter : defaultLocationFilter;
        const locationsToShow = locations.filter(locationFilter);
        setFilteredLocations(orderBy(locationsToShow, ["active", "name"], ["desc", "asc"]));
    }, [forceRefilter]);

    useEffect(() => {
        // ensure the location requested is in the list of filteredLocations
        const locationToUse = filteredLocations.filter((_location) => _location.id === location?.id);
        setCurrentLocation(locationToUse ? locationToUse[0] : undefined);
        if (onSetLocationsFiltered) {
            onSetLocationsFiltered(filteredLocations);
        }
    }, [location, filteredLocations]);

    const getLocationLabel = (loc: LocationRecord) => {
        const locationName = loc.name.length > 55 ? `${loc.name.substring(0, 54)}...` : loc.name;
        const locationSuffix = !loc.active ? " - Inactive" : "";
        return `${locationName}${locationSuffix}`;
    };

    const onSetLocation = (loc: LocationRecord | undefined) => {
        setCurrentLocation(loc);
        if (parentOnLocationChange) {
            parentOnLocationChange(loc);
        }
    };

    return (
        <>
            {accountId && filteredLocations.length > 1 && (
                <Select<LocationRecord>
                    data-testid={"location-picker"}
                    noneLabel={`All ${filteredLocations.length} locations`}
                    value={currentLocation}
                    onChange={onSetLocation}
                    options={filteredLocations}
                    label={(loc: LocationRecord) => getLocationLabel(loc)}
                    showFullWidth={false}
                    input={<SubtleSelectInput />}
                />
            )}
        </>
    );
}
