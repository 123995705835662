export interface ITTRSRate {
    name: string;
    jurisdictionType: string;
    rate: number;
}

export interface ITTRSRateInfo {
    totalRate: number;
    rates: ITTRSRate[];
}
