import mapValues from "lodash/mapValues";
import set from "lodash/set";
import { DateTime } from "luxon";
import { BusinessDay } from "./BusinessDay";
import { ValidationError } from "./Error";

const identity = <T>(x: T) => x;
const undef =
    <T>(fn: (v: T) => any) =>
    (v: T | undefined | null) =>
        v && fn(v);
const error = () => {
    throw new Error("Invalid method for this configuration.");
};

export class FieldMeta<T> {
    public static create<T>() {
        return new FieldMeta<NonNullable<T>>();
    }

    constructor(
        public readonly config: {
            readonly array?: boolean;
            readonly column?: null | string;
            readonly columnType?: null | string;
            readonly optional: boolean;
            readonly system: boolean;
            readonly fromSQL: (value: any) => T;
            readonly toSQL: (value: T) => any;
            readonly fromJSON: (value: any) => T;
            readonly toJSON: (value: T) => any;
            readonly deserializationDefault: () => T | undefined | null;
        } = {
            array: false,
            optional: false,
            system: false,
            fromSQL: identity,
            toSQL: identity,
            fromJSON: identity,
            toJSON: identity,
            deserializationDefault: () => undefined,
        }
    ) {}

    public optional() {
        return new FieldMeta<T | undefined | null>({
            ...this.config,
            optional: true,
            toSQL: undef(this.config.toSQL),
            toJSON: undef(this.config.toJSON),
        });
    }

    public sql(conv: {
        column?: null | string;
        columnType?: null | string;
        system?: boolean;
        fromSQL?: (value: any) => T;
        toSQL?: (value: T) => any;
    }) {
        return new FieldMeta<T>({ ...this.config, ...conv });
    }

    public json(conv: { fromJSON?: (value: any) => T; toJSON?: (value: T) => any }) {
        return new FieldMeta<T>({ ...this.config, ...conv });
    }

    public deserializationDefault(deserializationDefault: () => T) {
        return new FieldMeta<T>({ ...this.config, deserializationDefault });
    }

    public array(deserializationDefaultValue?: T[]) {
        if (this.config.array) {
            throw new Error("Cannot call array() twice");
        }
        return new FieldMeta<T[]>({
            ...this.config,
            array: true,
            fromSQL: error,
            toSQL: error,
            fromJSON: (json: any) => {
                if (!Array.isArray(json)) {
                    throw new Error("Expected an array here");
                }
                return json.map(this.config.fromJSON);
            },
            toJSON: (array: T[]) => {
                if (!Array.isArray(array)) {
                    throw new Error("Expected an array here");
                }
                return array.map(this.config.toJSON);
            },
            deserializationDefault: () => {
                if (deserializationDefaultValue) {
                    return deserializationDefaultValue;
                } else {
                    const val = this.config.deserializationDefault();
                    if (val) {
                        return [val];
                    } else {
                        return undefined;
                    }
                }
            },
        });
    }
}

export const STRING = <T = string>(length = 255) => FieldMeta.create<T>().sql({ columnType: `VARCHAR(${length})` });

export const STRING_ARRAY = () =>
    FieldMeta.create<string[]>().sql({
        fromSQL: (value: string) => {
            return [...value.split(",")];
        },
        toSQL: (value: string[]) => value && value.toString(),
    });

export const BOOLEAN = () => FieldMeta.create<boolean>();

export const NUMBER = () =>
    FieldMeta.create<number>().json({
        toJSON: (v) => v,
        fromJSON: (v) => +v,
    });

export const JSON_FIELD = <T = object>() =>
    FieldMeta.create<T>().sql({
        fromSQL: (v: any) => JSON.parse(v) as NonNullable<T>,
        toSQL: (v: T) => JSON.stringify(v),
    });

const ensureInteger = (num: number, message: string) => {
    if (!Number.isInteger(num)) {
        throw new Error(`Non-integer value in integer field during ${message} : ${num}`);
    }
    return num;
};

export const INTEGER = () =>
    NUMBER()
        .sql({
            fromSQL: (v) => ensureInteger(v, "read from db"),
            toSQL: (v) => ensureInteger(v, "write to db"),
        })
        .json({
            fromJSON: (v) => ensureInteger(v, "convert from JSON"),
            toJSON: (v) => ensureInteger(v, "convert to JSON"),
        });

export const DATETIME = () =>
    FieldMeta.create<DateTime>()
        .sql({
            fromSQL: (value: string) => DateTime.fromSQL(value, { zone: "utc" }),
            toSQL: (value: DateTime) => value.toUTC().toSQL({ includeZone: false, includeOffset: false }),
        })
        .json({
            toJSON: (value: DateTime) => value.toISO(),
            fromJSON: (value: string) => DateTime.fromISO(value, { zone: "utc" }),
        });

export const BUSINESS_DAY = () =>
    FieldMeta.create<BusinessDay>()
        .sql({
            fromSQL: (value: string) => BusinessDay.fromSQLDate(value),
            toSQL: (value: BusinessDay) => value.toSQLDate(),
        })
        .json({
            fromJSON: (value: string) => BusinessDay.fromSQLDate(value),
            toJSON: (value: BusinessDay) => value.toSQLDate(),
        });

export const UUID = () => FieldMeta.create<string>();

export const JSON_BLOB = () =>
    FieldMeta.create<any>().sql({
        fromSQL: (value: string) => value && JSON.parse(value),
        toSQL: (value: any) => value && JSON.stringify(value),
    });

export const OBJECT = <T extends IObjectMeta>(meta: T) =>
    FieldMeta.create<ResolveObject<T>>()
        .json({
            toJSON: (value: ResolveObject<T>) => toJSON(meta, value),
            fromJSON: (value: any) => fromJSON(meta, value),
        })
        .sql({
            toSQL: error,
            fromSQL: error,
        });

export interface IObjectMeta {
    [key: string]: FieldMeta<any>;
}

export type NonNullablePropertyNames<T extends IObjectMeta> = {
    [K in keyof T]: undefined extends ResolveField<T[K]> ? never : K;
}[keyof T];

export type NonNullableProperties<T extends IObjectMeta> = Pick<T, NonNullablePropertyNames<T>>;

export type NullablePropertyNames<T extends IObjectMeta> = {
    [K in keyof T]: undefined extends ResolveField<T[K]> ? K : never;
}[keyof T];

export type NullableProperties<T extends IObjectMeta> = Pick<T, NullablePropertyNames<T>>;

export type ResolveField<T extends FieldMeta<any>> = T extends FieldMeta<infer X> ? X : never;

export type ResolveObject<T extends IObjectMeta> = {
    [K in keyof NonNullableProperties<T>]: T[K] extends FieldMeta<infer X> ? X : never;
} & { [K in keyof NullableProperties<T>]?: T[K] extends FieldMeta<infer X> ? X : never };

export function toJSON<T extends IObjectMeta>(meta: T, object: ResolveObject<T>) {
    return Object.keys(meta).reduce((p, k) => {
        const m = meta[k];
        const v = (object as any)[k];
        if (v === undefined) {
            if (!m.config.optional) {
                throw new Error(`Missing required value for key ${k} in object!`);
            }
            return p;
        }
        return set(p, k, meta[k].config.toJSON((object as any)[k]));
    }, {} as any);
}

export function fromJSON<T extends IObjectMeta>(meta: T, json: any): ResolveObject<T> {
    return Object.keys(meta).reduce((p, k) => {
        const m = meta[k];
        const v = json[k] ?? m.config.deserializationDefault();
        if (v === undefined) {
            if (!m.config.optional) {
                throw new ValidationError(`Missing required value for key '${k}' in json.`);
            }
            return p;
        }
        return set(p, k, m.config.fromJSON(v));
    }, {} as any) as ResolveObject<T>;
}

export const toPartial = <T extends IObjectMeta>(
    objectMeta: T
): { [K in keyof T]: FieldMeta<ResolveField<T[K]> | undefined> } => {
    return mapValues(objectMeta, (fieldMeta) => fieldMeta.optional());
};
