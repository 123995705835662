import { INotice } from "@davo/types";
import { getNoticeFile } from "../services";

export const downloadNoticeFile = async (notice: INotice) => {
    const file = await getNoticeFile(notice.fileId);
    const a = document.createElement("a");
    a.href = URL.createObjectURL(file);
    const dlFilename = `notice_${notice.periodStart}_${notice.periodEnd}_${notice.type}`;
    a.download = dlFilename.replace(/\W/g, "");
    a.click();
    URL.revokeObjectURL(a.href);
};
