import { Loading, ReactTable, ViewBillingInvoiceModal, getBillingsForAccount } from "@davo/portal-common";
import { IBillingWithBank, LocationRecord, initialCap, money, subscriptionManagedByPOS } from "@davo/types";
import { Typography } from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { useAccountContext } from "./context";
import { SettingsBillingEmptyState } from "./SettingsBillingEmptyState";

export function Billing() {
    const accountContext = useAccountContext();
    const [billings, setBillings] = useState<IBillingWithBank[]>();

    useAsyncEffect(async () => {
        if (!accountContext.account) {
            return;
        }
        setBillings(await getBillingsForAccount(accountContext.account.id));
    }, [accountContext.account]);

    if (
        !accountContext.account ||
        !accountContext.locations ||
        (accountContext.locations && !accountContext.locations.filter((l) => l.active))
    ) {
        return null;
    }

    const colors = {
        pending: "#FFAB2B",
        posted: "#FFAB2B",
        settling: "#6DD230",
        funded: "#6DD230",
        settled: "#6DD230",
        rejected: "#FE4D97",
        voided: "#FE4D97",
        returned: "#FF4D97",
    } as { [key: string]: string };

    const amountFormat = (data: any) => {
        const factor = data.cell.row.original.isRefunded ? -1 : 1;
        const totalAmount = +data.cell.row.original.amount * factor + +data.cell.row.original.totalSalesTax;
        return totalAmount ? money(totalAmount / 100) : "0.00";
    };

    const renderStatus = (data: any) => {
        let s = data.cell.row.original.achStatus as string;
        if (!data.cell.row.original.achStatus) {
            s = "";
        }
        if (data.cell.row.original.isRefunded) {
            s = "refunded";
        }
        return (
            <div
                style={{
                    borderRadius: "4px",
                    fontWeight: "bold",
                    color: s && colors[s.toLowerCase()],
                }}>
                {s && initialCap(s)}
            </div>
        );
    };

    const renderActions = (data: any) => {
        return <ViewBillingInvoiceModal info={data.cell.row.original} accountId={accountContext.account?.id} />;
    };

    const externalBilling =
        accountContext.account?.partnerId ||
        accountContext.locations.some((x: LocationRecord) => {
            if (x.active) {
                return subscriptionManagedByPOS.includes(x.posType);
            }
            return false;
        });

    return (
        <>
            <Typography variant={"h2"} style={{ display: "inline-block", fontSize: "20px", marginBottom: "15px" }}>
                Billing Details
            </Typography>
            {!billings && <Loading />}
            {billings && billings.length > 0 && (
                <div style={{ paddingTop: "20px" }}>
                    <ReactTable
                        columns={[
                            { Header: "Period", accessor: "period" },
                            {
                                Header: "Bank Account",
                                accessor: "bank",
                                disableSortBy: true,
                                Cell: (data: any) => {
                                    const { bank } = data.cell.row.original;
                                    return <div style={{ textAlign: "left" }}>{bank ? <span>{bank}</span> : ""}</div>;
                                },
                            },
                            { Header: "Location", accessor: "locationName", id: "locationName" },
                            {
                                Header: "Amount",
                                accessor: "amount",
                                disableSortBy: true,
                                Cell: (data: any) => <div style={{ textAlign: "left" }}>{amountFormat(data)}</div>,
                            },
                            { Header: "Status", accessor: "achStatus", id: "status", Cell: renderStatus },
                            { Header: "Actions", accessor: "achStatus", id: "actions", Cell: renderActions },
                        ]}
                        data={billings}
                        options={{
                            hideToolbar: true,
                        }}
                    />
                </div>
            )}
            {!billings && <SettingsBillingEmptyState externalBilling={externalBilling} billings={billings} />}
        </>
    );
}
