import { BankAccount, LocationRecord } from "@davo/types";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from "@mui/material";
import React from "react";
import { formatBankLabel } from "../util";
import { BankSummaryActions } from "./BankSummaryActions";

export interface IBankDetailsExpanded {
    shouldRestrictMerchantEdit: boolean;
    restrictMerchantEditMessage: string;
    bankAccount: BankAccount;
    associatedLocations: LocationRecord[];
}

export const BankDetailsExpanded: React.FunctionComponent<IBankDetailsExpanded> = ({
    shouldRestrictMerchantEdit,
    restrictMerchantEditMessage,
    bankAccount,
    associatedLocations,
}) => {
    return (
        <div style={{ padding: "32px" }}>
            <Typography style={{ display: "inline-block", fontSize: "20px" }}>
                <Tooltip
                    title={`Locations associated with ${formatBankLabel({
                        ba: bankAccount,
                        withStarredPrefix: false,
                    })}`}
                    placement={"top"}>
                    <span>Locations associated with this bank account</span>
                </Tooltip>
            </Typography>

            <List sx={{ width: "100%", bgcolor: "background.paper" }} dense className={"da-row-expand-content"}>
                {associatedLocations.map((loc) => {
                    return (
                        <ListItem
                            sx={{
                                boxShadow: 1,
                                mb: "4px",
                            }}
                            key={loc.id}
                            data-testid={`associated-location-${loc.id}`}
                            secondaryAction={
                                <BankSummaryActions
                                    name={loc.name}
                                    locId={loc.id}
                                    shouldRestrictMerchantEdit={shouldRestrictMerchantEdit}
                                    restrictMerchantEditMessage={restrictMerchantEditMessage}
                                />
                            }>
                            <ListItemAvatar>
                                <LocationOnIcon />
                            </ListItemAvatar>
                            <ListItemText primary={loc.name} secondary={loc.address1} />
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};
