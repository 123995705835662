import { BodyText, d30ToastError, LoadingWithLogo } from "@davo/portal-common";
import { IMerchantInvitationInfo } from "@davo/types";
import CheckIcon from "@mui/icons-material/Check";
import { Alert, Button, Switch, Theme, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { getMerchantInvitationInfo, reconnectAccountInvitation } from "../../services/invitation";
import { BoardingContainer } from "../BoardingContainer";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: "20px",
        verticalAlign: "middle",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    control: {
        padding: theme.spacing(2),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    accountLogo: {
        [theme.breakpoints.down("sm")]: {
            width: "90%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "50%",
            maxWidth: "400px",
        },
    },
    tagLine: {
        [theme.breakpoints.only("xs")]: {
            width: "340px",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "30px",
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "50px",
        },
        width: "85%",
        textAlign: "left",
        margin: "auto",
    },
    textLine: {
        [theme.breakpoints.only("xs")]: {
            width: "340px",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "10px",
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "30px",
        },
        width: "85%",
        textAlign: "left",
        margin: "auto",
    },
    listPlacement: {
        width: "85%",
        margin: "auto",
        [theme.breakpoints.only("xs")]: {
            width: "340px",
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "0px",
        },
        [theme.breakpoints.up("md")]: {
            marginTop: "20px",
        },
        textAlign: "left",
    },
    listItem: {
        padding: "0px",
        display: "flex",
    },
    icon: {
        color: "green",
        marginRight: "10px",
    },
    termsContainer: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        marginTop: "10px",
        textAlign: "center",
    },
    terms: {
        fontSize: "10px",
    },
    buttonPlacement: {
        width: "600px",
        margin: "auto",
        [theme.breakpoints.only("xs")]: {
            width: "340px",
        },
        marginTop: "10px",
        textAlign: "center",
    },
}));

export function Reconnect() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [invitationInfo, setInvitationInfo] = useState<IMerchantInvitationInfo>();
    const { code } = useParams();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const [areTermsOfUseAccepted, setAreTermsOfUseAccepted] = useState<boolean>(false);

    useAsyncEffect(async () => {
        if (!code) {
            return;
        }
        setInvitationInfo(await getMerchantInvitationInfo(code));
    }, [code]);

    const process = () => {
        if (!invitationInfo || isBusy || !code) {
            return;
        }
        setIsBusy(true);
        reconnectAccountInvitation(code)
            .then(() => {
                navigate(`/invitation/location/${code}?reconnect=true`);
            })
            .catch((e) => {
                setIsBusy(false);
                d30ToastError("Operation failed", e);
            });
    };

    if (!invitationInfo || !invitationInfo.type) {
        return <LoadingWithLogo />;
    }

    return (
        <BoardingContainer posType={`${invitationInfo.type}`} tagLine={""}>
            <div className={classes.tagLine}>
                <BodyText>
                    We are excited to automate your sales tax again. To reconnect your service, please confirm the
                    following information in the DAVO Merchant Portal:
                </BodyText>
            </div>
            <div className={classes.listPlacement}>
                <div style={{ marginTop: "20px" }}>
                    <ol className={classes.listItem}>
                        <CheckIcon className={classes.icon} />
                        <Typography variant="body2" color="textSecondary">
                            Bank account information to ensure it is current. If your bank account is on pause, you will
                            need to unpause your bank account to begin daily set asides again.
                        </Typography>
                    </ol>
                    <ol className={classes.listItem}>
                        <CheckIcon className={classes.icon} />
                        <Typography variant="body2" color="textSecondary">
                            Tax profile information to certify it is current. If you’ve made any modifications to your
                            login information or filing frequency please update it.
                        </Typography>
                    </ol>
                    <ol className={classes.listItem}>
                        <CheckIcon className={classes.icon} />
                        <Typography variant="body2" color="textSecondary">
                            If you now need additional filings in more jurisdictions, please add any new tax profile
                            information to your “Tax Settings“.
                        </Typography>
                    </ol>
                </div>
            </div>
            <div className={classes.textLine}>
                <BodyText>
                    As always, if you have any questions you can reach out to our DAVO Customer Support team at
                    888-659-8432 Monday through Thursday 9am-6pm EST and Friday 9am-5pm EST, schedule a time to meet
                    with us{" "}
                    <a
                        href="https://help.davosalestax.com/meetings/customersuccess-/scheduled-call"
                        target="_blank"
                        style={{ overflow: "e" }}
                        rel="noreferrer">
                        here
                    </a>
                    , or email us at support@davosalestax.com.
                </BodyText>
            </div>
            <div className={classes.textLine}>
                <BodyText>
                    Thank you again for choosing to automate your sales tax through DAVO Sales Tax. Review our terms
                    below and click “Start Subscription” to begin automating your sales tax for $54.99/month per
                    location.
                </BodyText>
            </div>
            {invitationInfo.canSubscribe &&
                invitationInfo.hasPermissionToSubscribe &&
                invitationInfo.hasValidExternalSubscription &&
                invitationInfo.hasValidExternalPlan &&
                invitationInfo.hasTaxRates && (
                    <div className={classes.termsContainer} data-testid={"termsAndConditionsForm"}>
                        <BodyText className={classes.terms}>
                            I have read and accept the{" "}
                            <a
                                href="https://legal.avalara.com/#serviceterms"
                                target="_blank"
                                style={{ overflow: "e" }}
                                rel="noreferrer">
                                Avalara Service Terms and Conditions
                            </a>
                            .
                            <Switch
                                checked={areTermsOfUseAccepted}
                                onChange={(event: any, isChecked: boolean) => setAreTermsOfUseAccepted(isChecked)}
                            />
                        </BodyText>
                    </div>
                )}
            <div className={classes.buttonPlacement}>
                <Tooltip title={!areTermsOfUseAccepted ? "You'll need to accept the T&Cs before continuing." : ""}>
                    <span>
                        <Button
                            data-testid={"reconnectSubmitBtn"}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={
                                !areTermsOfUseAccepted ||
                                !invitationInfo.canSubscribe ||
                                !invitationInfo.hasPermissionToSubscribe ||
                                !invitationInfo.hasValidExternalSubscription ||
                                !invitationInfo.hasValidExternalPlan ||
                                !invitationInfo.hasTaxRates ||
                                isBusy
                            }
                            onClick={process}
                            style={{ margin: "10px" }}>
                            {`Start Subscription`}
                        </Button>
                    </span>
                </Tooltip>
                {!invitationInfo.hasPermissionToSubscribe && (
                    <>
                        <Alert severity="error" style={{ textAlign: "left" }} data-testid={"hasPermissionToSubscribe"}>
                            <Typography variant={"body1"} color="textSecondary">
                                Only a Manager user level or above can install DAVO Sales Tax.
                            </Typography>
                        </Alert>
                    </>
                )}
                {invitationInfo.hasPermissionToSubscribe && !invitationInfo.hasValidExternalSubscription && (
                    <>
                        <Alert
                            severity="error"
                            style={{ textAlign: "left" }}
                            data-testid={"hasValidExternalSubscription"}>
                            <Typography variant={"body1"} color="textSecondary">
                                Your Clover subscription is not active. Please contact Clover to resolve.
                            </Typography>
                        </Alert>
                    </>
                )}
                {invitationInfo.hasPermissionToSubscribe &&
                    invitationInfo.hasValidExternalSubscription &&
                    !invitationInfo.hasValidExternalPlan && (
                        <>
                            <Alert severity="error" style={{ textAlign: "left" }} data-testid={"hasValidExternalPlan"}>
                                <Typography variant={"body1"} color="textSecondary">
                                    Your current Clover plan does not support sales tax collection. Please contact
                                    Clover to upgrade your plan before subscribing to DAVO.
                                </Typography>
                            </Alert>
                        </>
                    )}
                {invitationInfo.hasPermissionToSubscribe &&
                    invitationInfo.hasValidExternalSubscription &&
                    invitationInfo.hasValidExternalPlan &&
                    !invitationInfo.hasTaxRates && (
                        <>
                            <Alert severity="error" style={{ textAlign: "left" }} data-testid={"hasTaxRates"}>
                                <Typography variant={"body1"} color="textSecondary">
                                    <a
                                        href="https://www.clover.com/setupapp/m/NHPQYVK9MTEX1/taxes/"
                                        target="_blank"
                                        rel="noreferrer">
                                        Set up your tax rates
                                    </a>{" "}
                                    on your POS before installing DAVO Sales Tax.
                                </Typography>
                            </Alert>
                        </>
                    )}
            </div>
        </BoardingContainer>
    );
}
