import { DateTime } from "luxon";
import { JSON_FIELD } from "./MetaType";

export interface IReferralSource {
    id: string;
    refSource: string;
    created: DateTime;
    description?: null | string;
    active: boolean;
}

export interface IReferral {
    id: string;
    refSource: string;
    refId?: null | string;
    refDealId?: null | string;
    created: DateTime;
    latestLeadSubmitted?: null | DateTime;
    leadSubmittedCount: number;
    latestLinkClicked?: null | DateTime;
    linkClickedCount: number;
    ip?: null | string;
}

export interface IReferralLocation {
    id: string;
    referralId: string;
    locationId: string;
    hasBank?: null | DateTime;
    hasAccount?: null | DateTime;
    hasNewAccount?: null | DateTime;
    created: DateTime;
    disconnected?: null | DateTime;
    firstBillingFunded?: null | DateTime;
    paid?: null | DateTime;
}

export interface IReferralLocationDetails extends IReferralLocation, IReferral {
    accountId?: null | string;
    accountName?: null | string;
    locationName?: null | string;
    userId?: null | string;
    userName?: null | string;
}

export const MetaRefTracking = {
    data: JSON_FIELD<IReferralLocation>(),
};
