import { ITaxProfileFormData } from "@davo/types";

export const getCredentialsStatus = (data: ITaxProfileFormData) => {
    // Always set credentials as working when a take give back tax profile is created or edited
    if (data.taxProfile?.filingMethod === "take-give-back" || data.filingMethod === "take-give-back") return "working";

    //When changing the TP’s frequency, whether it is group or not, it should mark the credentials as untested.
    if (data.oldFrequency && data.oldFrequency !== data.frequency) return "untested";

    // When initially creating new tax profiles or changing something other than frequency, group login credentials should be marked working
    if (data.credentialsType !== "SIMPLE") return "working";

    // For all other scenarios depending on whether reusing credentials is selected or not
    if (data.reuseExistingCredentials && data.taxProfile?.credentialsFailing)
        return data.taxProfile?.credentialsFailing;

    return "untested";
};
