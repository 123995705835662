import BlockIcon from "@mui/icons-material/Block";
import Alert from "@mui/material/Alert";
import React from "react";

export interface IFormError {
    message?: string;
}

export const FormError: React.FunctionComponent<IFormError> = ({ message }) =>
    message ? (
        <Alert
            sx={{ marginTop: "6px" }}
            data-testid={"formError"}
            icon={<BlockIcon />}
            severity={"error"}
            variant={"standard"}>
            {message}
        </Alert>
    ) : null;
