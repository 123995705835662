import { HiddenConfirmation, ReactTable, useLoginContext, useModalEditor } from "@davo/portal-common";
import { BankAccount } from "@davo/types";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControlLabel, FormGroup, IconButton, Stack, Switch, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { BankAccountInfoPane, BankAccountStatus } from "./components";
import { BankDetailsExpanded } from "./components/BankDetailsExpanded";
import { EditBankNicknameModalButton } from "./components/EditBankNicknameModalButton";
import { useAccountContext } from "./context";
import { SettingsBankingEmptyState } from "./SettingsBankingEmptyState";
import { formatBankLabel } from "./util";

export function Banking() {
    const loginContext = useLoginContext();
    const accountContext = useAccountContext();

    const [activeBankAccounts, setActiveBankAccounts] = useState<BankAccount[]>([]);
    const [unusedBankAccounts, setUnusedBankAccounts] = useState<BankAccount[]>([]);
    const [shouldRestrictMerchantEdit, setShouldRestrictMerchantEdit] = useState<boolean>(false);
    const [restrictMerchantEditMessage, setRestrictMerchantEditMessage] = useState<string>("");
    const [showUnused, setShowUnused] = useState<boolean>(false);
    const [selected, setSelected] = useState<string | undefined>(undefined);

    const [showBankAccountInfoPaneModal, bankAccountInfoPaneModalProps] = useModalEditor<string>(async () => {
        await accountContext.refresh();
    });

    const [showHiddenConfirmation, hiddenConfirmationProps] = useModalEditor<string>(async () => {
        await accountContext.refresh();
    });

    const refresh = useCallback(() => {
        if (
            !accountContext.locations ||
            (accountContext.locations && !accountContext.locations.filter((l) => l.active)) ||
            !accountContext.bankAccounts
        ) {
            return;
        }
        const active: BankAccount[] = [];
        const unused: BankAccount[] = [];
        accountContext.bankAccounts.forEach((acct) => {
            accountContext.locations?.some((loc) => loc.bankAccountId === acct.id && loc.active)
                ? active.push(acct)
                : unused.push(acct);
        });
        const unusedUnhidden = unused.filter((x) => !x.hidden);

        setActiveBankAccounts(active);
        setUnusedBankAccounts(unusedUnhidden);
    }, [accountContext.bankAccounts, accountContext.locations]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useEffect(() => {
        if (!accountContext.partner) {
            return;
        }
        if (accountContext.partner.restrictEditBankInfo) {
            setShouldRestrictMerchantEdit(true);
            setRestrictMerchantEditMessage("Our partner manages your banking information");
            if (loginContext.user && loginContext.user.type !== "Merchant") {
                setRestrictMerchantEditMessage("Merchant is restricted from editing this information");
                setShouldRestrictMerchantEdit(false);
            }
        }
    }, [accountContext.partner, loginContext.user]);

    if (
        !accountContext.account ||
        !accountContext.locations ||
        (accountContext.locations && !accountContext.locations.filter((l) => l.active)) ||
        !accountContext.bankAccounts
    ) {
        return null;
    }

    const renderName = (data: any) => {
        return (
            <div style={{ display: "inline-flex", marginTop: "5px", whiteSpace: "nowrap" }}>
                <Typography
                    title={formatBankLabel({ ba: data.row.original, withStarredPrefix: false })}
                    style={{ textOverflow: "ellipsis", overflow: "hidden", maxWidth: "336px" }}>
                    {formatBankLabel({ ba: data.row.original, withStarredPrefix: false })}
                </Typography>
                {accountContext.account && accountContext.account.id && (
                    <div style={{ marginTop: "-10px" }}>
                        <EditBankNicknameModalButton
                            accountId={accountContext.account.id}
                            bankAccountId={data.row.original.id}
                            oldNickname={data.row.original.nickname}
                            onCloseSuccess={accountContext.refresh}
                        />
                    </div>
                )}
            </div>
        );
    };

    const renderStatus = (data: any) => {
        return (
            accountContext.account && (
                <BankAccountStatus
                    accountId={accountContext.account.id}
                    bankAccount={data.cell.row.original}
                    onClick={() => showBankAccountInfoPaneModal(data.cell.row.original.id)}
                    showButton={true}
                />
            )
        );
    };

    const shouldToggle = (rowData: BankAccount) => {
        if (
            !accountContext.locations ||
            (accountContext.locations && !accountContext.locations.filter((l) => l.active))
        ) {
            return;
        }
        return accountContext.locations.some((loc) => loc.bankAccountId === rowData.id && loc.active);
    };

    const renderBankDetail = (rowData: any) => {
        if (
            !accountContext.locations ||
            (accountContext.locations && !accountContext.locations.filter((l) => l.active))
        ) {
            return null;
        }
        const found = accountContext.locations.filter(
            (loc) => loc.bankAccountId === rowData.row.original.id && loc.active
        );
        if (found.length > 0) {
            return (
                <BankDetailsExpanded
                    bankAccount={rowData.row.original}
                    associatedLocations={found}
                    restrictMerchantEditMessage={restrictMerchantEditMessage}
                    shouldRestrictMerchantEdit={shouldRestrictMerchantEdit}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <div data-testid={"settings-banking"}>
            <>
                {accountContext.bankAccounts.filter((x) => !x.hidden).length === 0 ? (
                    <SettingsBankingEmptyState
                        shouldRestrictMerchantEdit={shouldRestrictMerchantEdit}
                        restrictMerchantEditMessage={restrictMerchantEditMessage}
                    />
                ) : (
                    <>
                        <Stack justifyContent={"space-between"} spacing={2} direction={"row"}>
                            <Typography
                                variant={"h2"}
                                style={{ display: "inline-block", fontSize: "20px", marginBottom: "16px" }}>
                                Banking Details
                            </Typography>
                        </Stack>

                        <ReactTable
                            columns={[
                                {
                                    id: "expander", // Make sure expand columns have an ID
                                    Cell: (data: any) =>
                                        shouldToggle(data.row.original) ? (
                                            <span
                                                {...data.row.getToggleRowExpandedProps({
                                                    style: {
                                                        paddingLeft: "8px",
                                                    },
                                                })}
                                                className={"mui-expand-toggle-container"}>
                                                {data.row.isExpanded ? (
                                                    <span className={"expandLess"}>
                                                        <ExpandLessIcon
                                                            data-testid={`bank-expander-less-${data.row.original.id}`}
                                                        />
                                                    </span>
                                                ) : (
                                                    <span className={"expandMore"}>
                                                        <ExpandMoreIcon
                                                            data-testid={`bank-expander-more-${data.row.original.id}`}
                                                        />
                                                    </span>
                                                )}
                                            </span>
                                        ) : null,
                                    disableSortBy: true,
                                },
                                {
                                    accessor: "name",
                                    Cell: renderName,
                                },
                                {
                                    accessor: "routing",
                                    disableSortBy: true,
                                },
                                {
                                    accessor: "accountStarred",
                                    disableSortBy: true,
                                },
                                {
                                    accessor: "status",
                                    Cell: renderStatus,
                                    disableSortBy: true,
                                },
                            ]}
                            options={{
                                hideToolbar: true,
                            }}
                            renderRowSubComponent={renderBankDetail}
                            data={activeBankAccounts}
                        />

                        {unusedBankAccounts.length !== 0 && (
                            <FormGroup style={{ margin: "14px" }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            data-testid={"showUnusedBanksToggle"}
                                            checked={showUnused}
                                            onChange={() => setShowUnused(!showUnused)}
                                        />
                                    }
                                    label="Show unused bank accounts"
                                />
                            </FormGroup>
                        )}

                        {showUnused && unusedBankAccounts.length !== 0 && (
                            <>
                                <ReactTable
                                    data-testid={"unusedBankAccountsTable"}
                                    columns={[
                                        {
                                            accessor: "name",
                                            Cell: renderName,
                                        },
                                        {
                                            accessor: "routing",
                                            disableSortBy: true,
                                        },
                                        {
                                            accessor: "accountStarred",
                                            Cell: (data: any) => (
                                                <span className="fs-exclude">{data.row.original.accountStarred}</span>
                                            ),
                                            disableSortBy: true,
                                        },
                                        {
                                            id: "actions",
                                            Header: "",
                                            Cell: (data: any) => {
                                                return (
                                                    <Tooltip
                                                        title={`Delete ${formatBankLabel({
                                                            ba: data.row.original,
                                                            withStarredPrefix: false,
                                                        })}`}
                                                        placement={"top"}>
                                                        <IconButton
                                                            data-testid={"deleteBankBtn"}
                                                            size="small"
                                                            disabled={false}
                                                            onClick={() => {
                                                                setSelected(data.row.original.id);
                                                                showHiddenConfirmation(data.row.original.id);
                                                            }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                );
                                            },
                                            disableSortBy: true,
                                        },
                                    ]}
                                    options={{
                                        hideToolbar: true,
                                    }}
                                    data={unusedBankAccounts}
                                />
                            </>
                        )}
                    </>
                )}
            </>

            {bankAccountInfoPaneModalProps.isDialogOpen && (
                <BankAccountInfoPane accountId={accountContext.account.id} {...bankAccountInfoPaneModalProps} />
            )}
            {hiddenConfirmationProps.isDialogOpen && (
                <HiddenConfirmation
                    accountId={accountContext.account.id}
                    bankAccountId={selected}
                    label={"bank account"}
                    {...hiddenConfirmationProps}
                />
            )}
        </div>
    );
}
