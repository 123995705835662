import {
    formatPOSDisplayName,
    getAwaitingDisconnectLocationsWithRecentActivity,
    getDisconnectedLocationsWithRecentActivity,
    getLatestBillingSummary,
} from "@davo/portal-common";
import { IBillingSummary, LocationRecord } from "@davo/types";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { useAccountContext } from "./context";
import illustrationsData from "./resources/davo_illustrations_data.png";

export function MerchantBanner() {
    const accountContext = useAccountContext();
    const [msg, setMsg] = useState<React.JSX.Element | undefined>();
    const [showIntegrationsLink, setShowIntegrationsLink] = useState<boolean>(true);

    useAsyncEffect(async () => {
        if (!accountContext.account || !accountContext.locations) {
            return;
        }
        const activeLocations = accountContext.locations.filter((l) => l.active && !l.disconnected);

        const getPosNameForLocation = (locationId: string) => {
            const result = activeLocations.filter((l: LocationRecord) => l.id === locationId);
            const location = !isEmpty(result) ? result[0] : undefined;
            return location ? formatPOSDisplayName(location.posType) : "";
        };

        const [disconnectedLocationsWithActivity, awaitingDisconnectLocationsWithActivity, latestBillingSummary] =
            await Promise.all([
                getDisconnectedLocationsWithRecentActivity(accountContext.account.id),
                getAwaitingDisconnectLocationsWithRecentActivity(accountContext.account.id),
                getLatestBillingSummary(accountContext.account.id),
            ]);

        const hasDisconnectedLocationsWithActivity = !isEmpty(disconnectedLocationsWithActivity);
        const disconnectedLocationsPOSName = hasDisconnectedLocationsWithActivity
            ? getPosNameForLocation(disconnectedLocationsWithActivity[0])
            : "";
        const hasLocationAwaitingDisconnectWithActivity = !isEmpty(awaitingDisconnectLocationsWithActivity);
        const awaitingDisconnectPOSName = hasLocationAwaitingDisconnectWithActivity
            ? getPosNameForLocation(awaitingDisconnectLocationsWithActivity[0])
            : "";
        const hasFailedBilling = latestBillingSummary.some((s: IBillingSummary) => s.failed);
        const hasOnlyInactiveLocations = activeLocations.length === 0;
        const hasLocationInBeta = accountContext.locations.some((loc: LocationRecord) => loc.active && loc.beta);

        setShowIntegrationsLink(true);
        if (hasOnlyInactiveLocations) {
            setMsg(
                <>
                    Thank you for your business. When you require our service again, you can connect your POS{" "}
                    <a href="https://www.davosalestax.com/integrations">here</a>.
                </>
            );
        } else if (hasLocationInBeta) {
            setMsg(
                <>
                    Thank you for participating in our Beta Program! <br />
                    <p>
                        We are currently testing the integration and may ask you to send us a report with a couple of
                        days of transaction-level data for comparison. Once the integration goes live, we’ll turn
                        everything on and will manage your sales tax for you from that point on.
                    </p>
                </>
            );
        } else if (hasFailedBilling) {
            setMsg(
                <>
                    <span>There is a problem with your bank account. </span>
                    <span>
                        <Link to={`/${accountContext.account.id}/settings/banking`}>Please update it here</Link> or{" "}
                    </span>
                    <a href="mailto:support@davosalestax.com?subject=Billing+issue" target={"_blank"} rel="noreferrer">
                        contact support
                    </a>{" "}
                    for assistance.
                </>
            );
        } else if (hasDisconnectedLocationsWithActivity) {
            setMsg(
                <>
                    DAVO has been un-installed/deauthorized on one or more {disconnectedLocationsPOSName} devices.
                    Please <a href="https://www.davosalestax.com/integrations/">re-install</a>.
                </>
            );
        } else if (hasLocationAwaitingDisconnectWithActivity) {
            setMsg(
                <>
                    We are sorry to see you go. Please unsubscribe from our service within your{" "}
                    {formatPOSDisplayName(awaitingDisconnectPOSName)} system to no longer be billed.
                </>
            );
        } else {
            setMsg(undefined);
        }
    }, [accountContext.account, accountContext.locations]);

    if (!msg) {
        return null;
    }

    return (
        <div style={{ textAlign: "center" }} data-testid={"merchant-banner"}>
            {showIntegrationsLink && (
                <a href="https://www.davosalestax.com/integrations">
                    <img
                        src={illustrationsData}
                        alt="Davo Sales Tax integrations"
                        width="100px"
                        style={{ verticalAlign: "middle " }}
                    />
                </a>
            )}
            <span
                style={{
                    display: "inline-block",
                    fontSize: "16px",
                    marginLeft: "20px",
                    fontWeight: "bold",
                    verticalAlign: "middle",
                }}>
                {msg}
            </span>
        </div>
    );
}
