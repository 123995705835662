import { POSType, titleCase } from "@davo/types";
import { useTheme } from "@mui/material";
import React from "react";
import acadiaLogo from "./resources/acadia_logo.png";
import bookkeepLogo from "./resources/bookkeep_logo.svg";
import parBrinkLogo from "./resources/brink_logo.png";
import cakeLogo from "./resources/cake_logo.png";
import cloverLogo from "./resources/clover_logo.png";
import cloverPreAccountLogo from "./resources/clover_pre_acount_logo.png";
import davoLogo from "./resources/davo_logo.png";
import eposNowLogo from "./resources/epos_logo.svg";
import heartlandRestLogo from "./resources/heartland_rest.png";
import heartlandRetailLogo from "./resources/heartland_retail.png";
import lavuLogo from "./resources/lavu_logo.png";
import lightspeedLogo from "./resources/lightspeed_logo.png";
import mindbodyLogo from "./resources/mindbody_logo.svg";
import ovviLogo from "./resources/ovvi_logo.svg";
import payAnywhereLogo from "./resources/pa_logo.png";
import rasiDarkLogo from "./resources/partner/rasi_logo_dark.png";
import rasiLightLogo from "./resources/partner/rasi_logo_light.png";
import poyntLogo from "./resources/poynt_logo.png";
import quickbooksLogo from "./resources/qb_logo.png";
import restaurant365Logo from "./resources/r365-logo.svg";
import revelLogo from "./resources/revel_logo_trans.png";
import shift4Logo from "./resources/shift4_logo.png";
import spotOnLogo from "./resources/spoton_logo.png";
import squareLogo from "./resources/square_logo.png";
import tableNeedsLogo from "./resources/tableNeeds_logo.svg";
import toastLogo from "./resources/toast_logo_trans.png";

const _getPOSLogoImage = (posType: POSType, isDarkMode: boolean, isComboLogo: boolean) => {
    let logo;

    switch (posType) {
        case "square":
            logo = squareLogo;
            break;
        case "toast":
            logo = toastLogo;
            break;
        case "revel":
            logo = revelLogo;
            break;
        case "quickbooks":
            logo = quickbooksLogo;
            break;
        case "clover":
            logo = isComboLogo ? cloverPreAccountLogo : cloverLogo;
            break;
        case "poynt":
            logo = poyntLogo;
            break;
        case "par-brink":
            logo = parBrinkLogo;
            break;
        case "heartland-restaurant":
            logo = heartlandRestLogo;
            break;
        case "heartland-retail":
            logo = heartlandRetailLogo;
            break;
        case "lightspeed-e-series":
        case "lightspeed-k-series":
        case "lightspeed-l-series":
        case "lightspeed-r-series":
        case "lightspeed-u-series":
        case "lightspeed-x-series":
            logo = lightspeedLogo;
            break;
        case "ovvi":
            logo = ovviLogo;
            break;
        case "pay-anywhere":
            logo = payAnywhereLogo;
            break;
        case "mindbody":
            logo = mindbodyLogo;
            break;
        case "integrator":
            logo = davoLogo;
            break;
        case "spoton-retail":
        case "spoton-restaurant":
            logo = spotOnLogo;
            break;
        case "epos-now":
            logo = eposNowLogo;
            break;
        case "table-needs":
            logo = tableNeedsLogo;
            break;
        case "bookkeep":
            logo = bookkeepLogo;
            break;
        case "rasi":
            logo = isDarkMode ? rasiLightLogo : rasiDarkLogo;
            break;
        case "shift4":
            logo = shift4Logo;
            break;
        case "lavu":
            logo = lavuLogo;
            break;
        case "acadia":
            logo = acadiaLogo;
            break;
        case "restaurant365":
            logo = restaurant365Logo;
            break;
        case "cake":
            logo = cakeLogo;
            break;
        default:
            logo = undefined;
            break;
    }
    return logo;
};

export const POSLogo = ({
    posType,
    width,
    shouldFloatRight = false,
}: {
    posType: POSType;
    width?: number;
    shouldFloatRight?: boolean;
}) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";

    if (!posType) {
        return null;
    }

    const logo = _getPOSLogoImage(posType, isDarkMode, false);

    let styles: React.CSSProperties = {
        width: width ? `${width}px` : "auto",
        display: "block",
    };

    if (shouldFloatRight) {
        styles = { ...styles, float: "right" };
    }

    return (
        <span style={styles}>
            {logo === undefined ? (
                formatPOSDisplayName(posType)
            ) : (
                <img
                    style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                    }}
                    src={logo}
                    alt={posType}
                    title={posType}
                    data-testid={`${posType}Logo`}
                />
            )}
        </span>
    );
};

export const POSAccountLogo = ({
    posType,
    ...rest
}: {
    posType?: POSType;
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
    if (!posType) {
        return null;
    }
    const logo = _getPOSLogoImage(posType, false, true);
    return <img src={logo} {...rest} alt={posType} title={posType} />;
};

export const formatPOSDisplayName = (pos: string) => {
    if (pos === "quickbooks") {
        return "QuickBooks";
    } else {
        return titleCase(pos.replace("-", " "));
    }
};
