import { Colors } from "react-select";

// found this pattern here:
// https://github.com/JedWatson/react-select/issues/3692
export const getSelectTheme = (theme: any): Colors => {
    return {
        dangerLight: theme.palette.grey[200],
        danger: theme.palette.grey[200],
        neutral0: theme.palette.background.default,
        neutral5: theme.palette.background.default,
        neutral10: theme.palette.background.default,
        neutral20: theme.palette.grey.A200,
        neutral30: theme.palette.text.primary,
        neutral40: theme.palette.grey.A200,
        neutral50: theme.palette.grey.A200,
        neutral60: theme.palette.grey.A200,
        neutral70: theme.palette.grey.A200,
        neutral80: theme.palette.text.primary,
        neutral90: theme.palette.grey.primary,
        primary: theme.palette.text.primary,
        primary25: theme.palette.background.dark,
        primary50: theme.palette.background.paper,
        primary75: theme.palette.background.paper,
    };
};
