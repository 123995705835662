import { d30Toast, d30ToastError, PaperComponent, TextField, updateAccount } from "@davo/portal-common";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { useAccountContext } from "./context";

export interface IEditCompanyDetailsButtonProps {
    callback?: () => void;
}

export function EditCompanyDetailsButton({ callback }: IEditCompanyDetailsButtonProps) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const accountContext = useAccountContext();
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [companyName, setCompanyName] = useState<string>();

    useEffect(() => {
        setCompanyName(accountContext.account?.name);
    }, [accountContext.account]);

    const _shutdown = () => {
        setCompanyName(accountContext.account?.name);
        setIsShowing(false);
    };

    const _doCompanyUpdate = async () => {
        if (!accountContext.account || !companyName) {
            return;
        }

        updateAccount(accountContext.account.id, { ...accountContext.account, name: companyName })
            .then(() => {
                d30Toast("Changes saved");
                setIsShowing(false);
            })
            .catch(() => {
                d30ToastError("There was a problem saving the changes");
            })
            .finally(() => {
                callback && callback();
            });
    };

    if (!accountContext || !accountContext.account) {
        return null;
    }

    return (
        <>
            <Button
                data-testid={"editCompanyDetailsButton"}
                variant="outlined"
                style={{ padding: "2px" }}
                onClick={() => setIsShowing(true)}
                color="primary">
                Edit
            </Button>
            {isShowing && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    maxWidth={"lg"}
                    style={{ boxShadow: "none", padding: "10px" }}
                    onClose={(event, reason) => {
                        if (!["backdropClick"].includes(reason)) {
                            _shutdown();
                        }
                    }}>
                    <DialogTitle id="draggable-dialog-title">Edit Company Details</DialogTitle>
                    <DialogContent>
                        <TextField
                            data-testid={"companyName"}
                            label="Company Name"
                            style={{ width: "500px" }}
                            value={companyName}
                            onChange={setCompanyName}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" style={{ marginRight: "5px" }} onClick={_shutdown}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isEqual(accountContext.account?.name, companyName) || !companyName}
                            onClick={_doCompanyUpdate}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
