import { TaxProfileCredentials } from "./Account";
import { BOOLEAN, ResolveObject, STRING } from "./MetaType";

export const CRED_CHECK = "CRED_CHECK";
export const CRED_CHECK_DONE = "CRED_CHECK_DONE";
export const CRED_CHECK_ERROR = "CRED_CHECK_ERROR";

export type CredCheckQueueState = typeof CRED_CHECK | typeof CRED_CHECK_DONE | typeof CRED_CHECK_ERROR;

export const MetaCredCheckQueueEntry = {
    jurisdiction: STRING(),
    state: STRING<CredCheckQueueState>(),
    credentials: STRING<TaxProfileCredentials>(),
    isValid: BOOLEAN(),
    isPasswordChangeRequired: BOOLEAN(),
    errorMessage: STRING().optional(),
};

export type CredCheckQueueEntry = ResolveObject<typeof MetaCredCheckQueueEntry>;
