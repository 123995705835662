import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useState } from "react";

export interface IAddAdditionalStateType {
    onContinue: (isAddAnother: boolean) => void;
}

export function AddAdditionalState({ onContinue }: IAddAdditionalStateType) {
    const [addAdditionalState, setAddAdditionalState] = useState<string>("");

    const _handleClick = (event: any) => {
        if (event.target.value !== addAdditionalState) {
            setAddAdditionalState(event.target.value);
        }
    };

    return (
        <div style={{ display: "inline-block" }}>
            <FormControl>
                <Typography data-testid={"additionalStatePageTitle"} style={{ textAlign: "left" }}>
                    Are you required to file in an additional state?
                </Typography>
                <RadioGroup row aria-label="additionalState" value={addAdditionalState}>
                    <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" data-testid={"additionalStateYes"} onClick={_handleClick} />}
                        label="Yes"
                    />
                    <FormControlLabel
                        value="no"
                        control={<Radio color="primary" data-testid={"additionalStateNo"} onClick={_handleClick} />}
                        label="No"
                    />
                </RadioGroup>
            </FormControl>
            <div style={{ marginTop: "100px", marginRight: "10px", textAlign: "right" }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: "0px 0px 20px 5px" }}
                    disabled={!addAdditionalState}
                    onClick={() => onContinue(addAdditionalState === "yes")}>
                    Save and Continue
                </Button>
            </div>
        </div>
    );
}
