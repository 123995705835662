import { auth, BodyText, TextField, useLoginContext } from "@davo/portal-common";
import { isPhoneValid, validatePhone, validateRequired } from "@davo/types";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IMarketingInfo } from "../types/MarketingInfo";

export interface ICapturePhoneType {
    marketingInfo?: IMarketingInfo;
    onContinue: () => void;
}

export function CapturePhone({ marketingInfo, onContinue }: ICapturePhoneType) {
    const loginContext = useLoginContext();
    const [phone, setPhone] = useState<string>("");

    useEffect(() => {
        setPhone(loginContext.user?.phone || marketingInfo?.phone || "");
    }, [loginContext.user, marketingInfo?.phone]);

    return (
        <div style={{ textAlign: "left" }} data-testid={"capturePhone"}>
            <div>
                <h2>Enter Your Phone Number</h2>
                <BodyText>Enter a phone number where DAVO will be able to reach you.</BodyText>
            </div>
            <TextField
                className="fs-exclude"
                label="Phone"
                value={phone}
                validate={(v: string | undefined) => (v ? validatePhone(v) : validateRequired(v))}
                onChange={setPhone}
                data-testid={"phoneField"}
                inputProps={{
                    [`data-testid`]: "phoneInput",
                }}
                isRequired
            />

            <div style={{ textAlign: "right" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                        const user = await auth.updateCurrentUser({ phone });
                        loginContext.setUser(user);
                        onContinue();
                    }}
                    disabled={!isPhoneValid(phone)}>
                    Continue
                </Button>
            </div>
        </div>
    );
}
