import { ReactTable } from "@davo/portal-common";
import {
    AccountRecord,
    BusinessDay,
    initialCap,
    MerchantDailyDetail,
    moneyFromCents,
    toDateTime,
    toDisplayDateString,
} from "@davo/types";
import { Button, Tooltip } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import reverse from "lodash/reverse";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useAccountContext } from "./context";

const colors = {
    pending: "#FFAB2B",
    posted: "#FFAB2B",
    settling: "#6DD230",
    funded: "#6DD230",
    settled: "#6DD230",
    rejected: "#FE4D97",
    voided: "#FE4D97",
} as { [key: string]: string };

const statusTooltip = {
    pending: "Sent to ACH network",
    posted: "Sent to ACH network",
    settling: "In progress, often 3-4 business days in this status",
    funded: "Withdrawn from merchant account",
    settled: "Withdrawn from merchant account",
    rejected: "ACH is rejected",
    multiple: "Multiple status for multiple ACHs",
    voided: "ACH has been cancelled/stopped",
} as { [key: string]: string };

const renderStatus = (data: any) => {
    const status = data.value;
    if (!status) {
        return <div>-</div>;
    }

    const detail: MerchantDailyDetail = data.cell.row.original;
    const msg =
        detail.status && detail.status !== "--" ? (
            <div style={{ padding: "10px" }}>{statusTooltip[detail.status] ?? ""}</div>
        ) : (
            ""
        );

    return (
        <Tooltip title={msg} placement="top">
            <div
                data-testid={"status"}
                style={{
                    borderRadius: "4px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    color: colors[status.toLowerCase()],
                    padding: "7px",
                    textAlign: "right",
                }}>
                {initialCap(status)}
            </div>
        </Tooltip>
    );
};

const renderSetAsideTooltip = (data: any) => {
    const detail: MerchantDailyDetail = data.cell.row.original;
    let index = 0;
    const areAmountsDiffer = moneyFromCents(detail.salesTax) !== moneyFromCents(detail.totalSetAsideAmount);
    const msg = detail.setAsides ? (
        <div style={{ padding: "10px" }}>
            <div>Set Asides</div>
            <ul style={{ margin: 0, paddingLeft: "15px", paddingRight: "15px" }}>
                {orderBy(detail.setAsides, ["amount"]).map((s) => {
                    const manualNoteSuffix = s.isManual
                        ? `- ${s.manualNote ? s.manualNote : s.amount > 0 ? "Manual set aside" : "Manual return"}`
                        : "";
                    return (
                        <li key={index++} style={{ margin: 0, padding: 0 }}>
                            {`$${moneyFromCents(s.amount)}: ${initialCap(s.status)} ${manualNoteSuffix}`}
                        </li>
                    );
                })}
            </ul>
            {areAmountsDiffer && (
                <div>
                    Set aside and sales tax data on a given day differ due to timing between systems, paid vs closed
                    transactions, modifications in POS data, rejected attempts to collect funds, or an adjustment noted
                    above.
                </div>
            )}
        </div>
    ) : (
        ""
    );

    return (
        <Tooltip title={msg} placement="top">
            <div
                data-testid={"setAsideAmount"}
                style={{
                    cursor: "pointer",
                    borderRadius: "4px",
                    fontWeight: "bold",
                    padding: "7px",
                    textAlign: "right",
                }}>
                {formatMoney({ value: detail.totalSetAsideAmount })}
            </div>
        </Tooltip>
    );
};

function getDetailsForExport(details: MerchantDailyDetail[]) {
    const results: any = [];
    for (const d of details) {
        if (d.date > BusinessDay.today()) {
            continue;
        }
        const note = d.setAsides.find((f) => f.manualNote);
        results.push({
            Date: d.date,
            Location: d.locationName,
            "Daily Sales": moneyFromCents(d.sales),
            "Daily Tax": moneyFromCents(d.salesTax),
            "Set Aside": moneyFromCents(d.totalSetAsideAmount),
            Status: initialCap(d.status),
            Note: note?.manualNote ? initialCap(note.manualNote) : "",
        });
    }

    return orderBy(results, ["Date", "Location"]);
}

function formatMoney(data: any) {
    return <div style={{ textAlign: "right" }}>${moneyFromCents(data.value)}</div>;
}

function formatDate(data: any) {
    return (
        <div data-testid={"date"} style={{ textAlign: "right" }}>
            {toDisplayDateString(data.value)}
        </div>
    );
}

function getExportFileName(account: AccountRecord, period: string) {
    const name = account.name.replace(/\s/g, "-");
    return `${name}-funding-${period}.csv`;
}

export interface ITaxTableType {
    accountDailyDetails: MerchantDailyDetail[];
    locationDailyDetails: MerchantDailyDetail[];
    period: string;
    includeAllLocations: boolean;
}

const yesterday = DateTime.local().minus({ day: 1 });

export function TaxTable({ accountDailyDetails, locationDailyDetails, period, includeAllLocations }: ITaxTableType) {
    const accountContext = useAccountContext();

    const [detailsForDisplay, setDetailsForDisplay] = useState<MerchantDailyDetail[]>([]);
    const [detailsForExport, setDetailsForExport] = useState<MerchantDailyDetail[]>([]);

    useEffect(() => {
        setDetailsForDisplay(reverse((locationDailyDetails || []).filter((d) => toDateTime(d.date) <= yesterday)));
        setDetailsForExport(includeAllLocations ? accountDailyDetails : locationDailyDetails);
    }, [accountDailyDetails, locationDailyDetails, includeAllLocations]);

    if (!accountContext.account) {
        return null;
    }
    return (
        <>
            <div data-testid={"taxTable"} style={{ maxWidth: "100%" }}>
                <div style={{ maxWidth: "100%" }} id="davoTable">
                    <ReactTable
                        columns={[
                            { Header: "Date", accessor: "date", Cell: formatDate, rightAlign: true },
                            { Header: "Sales", accessor: "sales", Cell: formatMoney, rightAlign: true },
                            { Header: "Sales Tax", accessor: "salesTax", Cell: formatMoney, rightAlign: true },
                            {
                                Header: "Set Aside",
                                accessor: "setAsides",
                                Cell: renderSetAsideTooltip,
                                rightAlign: true,
                            },
                            { Header: "Status", accessor: "status", Cell: renderStatus, rightAlign: true },
                        ]}
                        data={detailsForDisplay}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 31],
                            hideToolbar: true,
                        }}
                    />
                </div>
            </div>

            {!isEmpty(locationDailyDetails) && (
                <Button variant="outlined" color="primary" size="small" style={{ margin: "10px", float: "left" }}>
                    <CSVLink
                        data-testid={"exportToCSV"}
                        filename={getExportFileName(accountContext.account, period)}
                        data={getDetailsForExport(detailsForExport)}
                        style={{ textDecoration: "unset", color: "unset" }}>
                        Export transactions
                    </CSVLink>
                </Button>
            )}
        </>
    );
}
