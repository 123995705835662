import { Typography } from "@mui/material";
import React from "react";

export function Header({ children }: { children: any }) {
    return <Typography style={{ fontSize: "20px", marginBottom: "15px" }}>{children}</Typography>;
}

export function BodyText({ children, className }: { children: any; className?: string }) {
    return (
        <Typography className={className} variant="body1" color="textSecondary">
            {children}
        </Typography>
    );
}
