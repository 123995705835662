import { DateTime } from "luxon";

export interface IEmailMessage {
    id: string;
    recipientEmail: string;
    bcc?: null | string;
    userId?: null | string;
    emailType: string;
    subject: string;
    postmarkMessageId?: null | string;
    created: DateTime;
    errorCode: number;
    responseMessage?: null | string;
    openedDate?: null | DateTime;
    bouncedDate?: null | DateTime;
    bouncedMessage?: null | string;
    deliveredDate?: null | DateTime;
}
