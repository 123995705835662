import { MultiSelect, setLocationTaxProfiles, TaxProfileAdd, useModalEditor } from "@davo/portal-common";
import { TaxProfile } from "@davo/types";
import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAccountContext, useLocationContext } from "../context";

const formatTaxProfile = (tp: TaxProfile) => (
    <Typography
        style={{ maxWidth: "500px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
        variant="body1">
        {tp.name}&nbsp;&nbsp;
        <Typography component={(props: any) => <span {...props} />} variant="body1" color="textSecondary">
            ({tp.state} - {tp.stateTaxId})
        </Typography>
    </Typography>
);

export function TaxProfileSelector({
    header,
    setTaxProfileIdsCallback,
}: {
    header?: any;
    setTaxProfileIdsCallback: (id: string[] | undefined) => void;
}) {
    const accountContext = useAccountContext();
    const locationContext = useLocationContext();
    const [nonHiddenTaxProfiles, setNonHiddenTaxProfiles] = useState<TaxProfile[]>();
    const [selectedTPs, setSelectedTPs] = useState<TaxProfile[]>();

    const [showAddTaxProfileModal, addTaxProfileModalProps] = useModalEditor<string[]>(
        async (newTaxProfileIds: string[] | undefined) => {
            if (
                !newTaxProfileIds ||
                !accountContext.account ||
                !locationContext.location ||
                !locationContext.location
            ) {
                return;
            }
            const tpSelected = [
                ...newTaxProfileIds,
                ...locationContext.location.taxProfiles.map((l: TaxProfile) => l.id),
            ];
            // When adding a new tax profile, automatically associated the tax ID to the current location
            await setLocationTaxProfiles(accountContext.account.id, locationContext.location.id, tpSelected);
            setTaxProfileIdsCallback(tpSelected);

            await accountContext.refresh();
            await locationContext.refresh();
        }
    );

    useEffect(() => {
        if (!accountContext.taxProfiles) {
            return;
        }
        setNonHiddenTaxProfiles(accountContext.taxProfiles.filter((x: TaxProfile) => !x.hidden));
    }, [accountContext.taxProfiles]);

    useEffect(() => {
        if (!locationContext.location) {
            return;
        }
        setSelectedTPs(
            accountContext.taxProfiles?.filter((x: TaxProfile) =>
                locationContext.location?.taxProfileIds.includes(x.id)
            )
        );
    }, [locationContext.location, accountContext.taxProfiles]);

    if (!nonHiddenTaxProfiles || !accountContext.account || !selectedTPs) {
        return null;
    }

    return (
        <div>
            {header}
            <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                <MultiSelect<TaxProfile>
                    style={{ textOverflow: "ellipsis" }}
                    options={nonHiddenTaxProfiles}
                    value={selectedTPs}
                    noneLabel="No Tax Profile"
                    label={formatTaxProfile}
                    onChange={(tps: TaxProfile[]) => {
                        setTaxProfileIdsCallback(tps.filter((t) => t && t.id).map((tp: TaxProfile) => tp.id));
                    }}
                />
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    style={{ marginRight: "5px", marginBottom: "5px" }}
                    onClick={() => showAddTaxProfileModal()}>
                    Add Tax Profile
                </Button>
            </div>
            {addTaxProfileModalProps.isDialogOpen && (
                <TaxProfileAdd accountId={accountContext.account.id} {...addTaxProfileModalProps} />
            )}
        </div>
    );
}
