import {
    IBillingEventDetail,
    IBillingInfo,
    IBillingInvoice,
    IBillingSalesTaxWithRate,
    LocationRecord,
    moneyFromCents,
    toDisplayDateString,
} from "@davo/types";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import isNil from "lodash/isNil";
import orderBy from "lodash/orderBy";
import React, { CSSProperties, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import useAsyncEffect from "use-async-effect";
import { LocationSelector } from "./LocationSelector";
import davoLogo from "./resources/davo_logo.png";
import { getLocations } from "./services";
import { useModalEditor } from "./useModal";

export const thStyle: CSSProperties = {
    display: "table-cell",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "rgb(224, 224, 224)",
    textAlign: "left",
    padding: "14px 40px 14px 16px",
    fontWeight: "normal",
};

export const rowStyle: CSSProperties = {
    display: "table-cell",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "rgb(224, 224, 224)",
    textAlign: "left",
    color: "rgb(152, 169, 188)",
    padding: "14px 40px 14px 16px",
};

export const amountRowStyle: CSSProperties = {
    ...rowStyle,
    textAlign: "right",
};

export interface IViewBillingInvoiceModalType {
    info: IBillingInfo;
    accountId?: string;
}

export function ViewBillingInvoiceModal({ info, accountId }: IViewBillingInvoiceModalType) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [locationNames, setLocationNames] = useState<Record<string, string>>({});
    const [invoice, setInvoice] = useState<IBillingInvoice | null>();
    const [locations, setLocations] = useState<LocationRecord[] | undefined>(undefined);
    const [events, setEvents] = useState<IBillingEventDetail[]>([]);
    const [salesTaxEvents, setSalesTaxEvents] = useState<IBillingSalesTaxWithRate[]>([]);
    const [currentLocation, setCurrentLocation] = useState<LocationRecord>();

    useEffect(() => {
        if (isDialogOpen) {
            setInvoice(info.invoice);
            setEvents(info.events);
            setSalesTaxEvents(info.salesTax);
        }
    }, [info, isDialogOpen]);

    useAsyncEffect(async () => {
        if (!isNil(accountId) && isDialogOpen) {
            const allLocations = (await getLocations(accountId)).filter((loc) => {
                return info.events
                    .map((e) => e.locationId)
                    .concat(info.salesTax.map((st) => st.locationId))
                    .includes(loc.id);
            });
            setLocations(allLocations);
            setLocationNames(
                allLocations
                    .map((location) => {
                        return { id: location.id, name: location.name };
                    })
                    .reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {})
            );
        }
    }, [accountId, isDialogOpen]);

    useEffect(() => {
        if (!isNil(currentLocation)) {
            const filteredEvents = info.events.filter((event) => event.locationId === currentLocation.id);
            setEvents(filteredEvents);
            const filteredSalesTax = info.salesTax.filter((event) => event.locationId === currentLocation.id);
            setSalesTaxEvents(filteredSalesTax);
        } else {
            setEvents(info.events);
            setSalesTaxEvents(info.salesTax);
        }
    }, [currentLocation]);

    const getExportFileName = () => {
        if (invoice) {
            return `billing_${accountId}_${invoice.date}.csv`;
        } else {
            return `billing_${accountId}_.csv`;
        }
    };

    function getDetailsForExport() {
        const results: any = [];
        for (const e of events) {
            results.push({
                Location: e.locationName,
                Description: e.description,
                Amount: renderAmount(e),
                Date: e.date,
            });
        }
        for (const s of salesTaxEvents) {
            results.push({
                Location: !isNil(s.locationId) ? locationNames[s.locationId] : "",
                Description: s.taxRate.description,
                Amount: moneyFromCents(s.amount),
                Date: invoice ? invoice.date : "",
            });
        }
        return orderBy(results, ["Date"], [`asc`]);
    }

    const renderAmount = (event: IBillingEventDetail) => {
        const isPaidExternally =
            event.description.includes("billed by Clover") ||
            event.description.includes("billed by Poynt") ||
            event.description.includes("billed by partner");
        return isPaidExternally ? "-" : moneyFromCents(event.amount);
    };

    const onSetLocation = (location: LocationRecord | undefined) => {
        setCurrentLocation(location);
    };

    const shutdown = () => {
        closeDialog();
    };

    return (
        <span>
            <Button
                data-testid="view-billing-invoice-modal-button"
                style={{ marginRight: "10px" }}
                variant="outlined"
                onClick={showDialog}
                size="small"
                color="primary">
                View
            </Button>
            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"lg"}
                    onClose={shutdown}>
                    <DialogTitle id="responsive-dialog-title">
                        <>
                            <Typography style={{ float: "right", cursor: "pointer" }}>
                                <CSVLink
                                    filename={getExportFileName()}
                                    data={getDetailsForExport()}
                                    style={{ textDecoration: "unset", color: "unset" }}>
                                    <CloudDownloadIcon color="primary" />
                                </CSVLink>
                            </Typography>
                            Billing Details {invoice ? `- ${toDisplayDateString(invoice.date)}` : ""}
                        </>
                    </DialogTitle>
                    <DialogContent data-testid="view-billing-invoice-modal-table">
                        {accountId && locations && locations.length > 1 && (
                            <LocationSelector
                                accountId={accountId}
                                locations={locations}
                                onLocationChange={onSetLocation}
                            />
                        )}
                        <div style={{ margin: "0 auto", textAlign: "center" }}>
                            <img src={davoLogo} alt="DAVO Logo" style={{ margin: "0 auto", width: "140px" }} />
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <td style={thStyle}>Location</td>
                                    <td style={thStyle}>Description</td>
                                    <td style={thStyle}>Amount</td>
                                    <td style={thStyle}>Date</td>
                                </tr>
                            </thead>

                            <tbody>
                                {events.map((event: IBillingEventDetail) => {
                                    return (
                                        <tr key={event.id}>
                                            <td style={rowStyle}>{event.locationName}</td>
                                            <td style={rowStyle}>{event.description}</td>
                                            <td style={amountRowStyle}>{renderAmount(event)}</td>
                                            <td style={rowStyle}>{toDisplayDateString(event.date)}</td>
                                        </tr>
                                    );
                                })}

                                {/* Sales tax row */}
                                {salesTaxEvents.map((salesTax: IBillingSalesTaxWithRate) => (
                                    <tr key={salesTax.id}>
                                        <td style={rowStyle}>
                                            {!isNil(salesTax.locationId) ? locationNames[salesTax.locationId] : ""}
                                        </td>
                                        <td style={rowStyle}>{salesTax.taxRate.description}</td>
                                        <td style={amountRowStyle}>{moneyFromCents(salesTax.amount)}</td>
                                        <td style={rowStyle}>{invoice ? toDisplayDateString(invoice.date) : ""}</td>
                                    </tr>
                                ))}

                                {/* Total row */}
                                <tr>
                                    <td style={rowStyle}>Total</td>
                                    <td style={rowStyle}>&nbsp;</td>
                                    <td style={amountRowStyle}>{moneyFromCents(+info.amount + +info.totalSalesTax)}</td>
                                    <td style={rowStyle}>{invoice ? toDisplayDateString(invoice.date) : ""}</td>
                                </tr>
                            </tbody>
                        </table>

                        <br />
                        <br />
                        <div>* Subscription charges are added to the system just before billing.</div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => shutdown()} color="primary" variant="contained">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </span>
    );
}
