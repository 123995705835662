import { BUSINESS_DAY, DATETIME, INTEGER, OBJECT, ResolveObject, STRING, UUID } from "./MetaType";

export type OutflowType = "write-off" | "returned-by-check" | "inter-location-transfer";

export const MetaOutflow = {
    id: STRING(),
    locationId: STRING(),
    type: STRING<OutflowType>(),
    amount: INTEGER(),
    periodDay: BUSINESS_DAY(),
    description: STRING(),
    created: DATETIME(),
    createdBy: STRING(),
    wellsNote: STRING().optional(),
    wellsNoteBy: STRING().optional(),
    wellsNoteDate: DATETIME().optional(),
    wellsTxn: UUID().optional(),
    checkNumber: STRING().optional(),
};

export type Outflow = ResolveObject<typeof MetaOutflow>;

export const MetaReturnedByCheck = {
    ...MetaOutflow,
    wellsNoteByName: STRING().optional(),
    createdByFirstName: STRING().optional(),
    checkNumber: STRING().optional(),
};

export type ReturnedByCheck = ResolveObject<typeof MetaReturnedByCheck>;

export const MetaOutflowWithFirstName = {
    type: STRING<OutflowType>(),
    amount: INTEGER(),
    description: STRING(),
    created: DATETIME(),
    firstName: STRING().optional(),
    checkNumber: STRING().optional(),
};

export type OutflowWithFirstName = ResolveObject<typeof MetaOutflowWithFirstName>;

export const MetaOutflowParams = {
    locationId: UUID(),
    amountInPennies: INTEGER(),
    periodDay: BUSINESS_DAY(),
    type: STRING<OutflowType>(),
    note: STRING().optional(),
    otherLocations: OBJECT({
        locationId: STRING(),
        nestedAmountInPennies: INTEGER(),
    })
        .array()
        .optional(),
    checkNumber: STRING().optional(),
};

export type OutflowParams = ResolveObject<typeof MetaOutflowParams>;
