import { DateTime } from "luxon";
import { BusinessDay } from "./BusinessDay";
import { BOOLEAN, BUSINESS_DAY, INTEGER, OBJECT, ResolveObject, STRING, UUID } from "./MetaType";

export const MetaSetAsideBalance = {
    locationId: UUID(),
    balance: INTEGER(),
};

export type SetAsideBalance = ResolveObject<typeof MetaSetAsideBalance>;

export interface IManualActivity {
    locationId: string;
    id: string;
    created: DateTime;
    sales: number;
    tax: number;
    periodDay: BusinessDay;
    note?: null | string;
    manualSetAsideId?: null | string;
    updatedBy: string;
}

export const MetaManualSetAsideParams = {
    isReturn: BOOLEAN(),
    locationId: UUID(),
    amountInPennies: INTEGER(),
    periodDay: BUSINESS_DAY().optional(),
    note: STRING().optional(),
    merchantNote: STRING().optional(),
    type: STRING<ManualSetAsideType>(),
    period: OBJECT({
        start: BUSINESS_DAY(),
        end: BUSINESS_DAY(),
    }).optional(),
};

export type ManualSetAsideParams = ResolveObject<typeof MetaManualSetAsideParams>;

export const MetaChangePeriodFundsParams = {
    locationId: UUID(),
    amountInPennies: INTEGER(),
    originDay: BUSINESS_DAY(),
    destinationDay: BUSINESS_DAY(),
    note: STRING().optional(),
};

export type ChangePeriodFundsParams = ResolveObject<typeof MetaChangePeriodFundsParams>;

export type ManualSetAsideType = "catchup" | "discount" | "reconcile" | "first-funding";

export const ManualSetAsideTypes = {
    catchup: "Catch-Up",
    discount: "Discount",
    reconcile: "Reconcile",
    "first-funding": "First-Funding",
} as { [key: string]: string };

export const ManualSetAsideTypeKeys = ["reconcile", "first-funding"] as ManualSetAsideType[];
export const ManualSetAsideReturnTypeKeys = ["discount", "reconcile"] as ManualSetAsideType[];
