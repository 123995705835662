import { downloadFilingAttachmentFile, getAttachmentHistorical } from "@davo/portal-common";
import {
    FilingAttachment,
    FilingHistoricalAttachment,
    FilingHistoricalWithAttachments,
    FilingWithAttachments,
} from "@davo/types";
import CloudDownloadTwoToneIcon from "@mui/icons-material/CloudDownload";
import { Link, Typography } from "@mui/material";
import React from "react";

export interface IFilingAttachmentsReadonly {
    accountId: string;
    filing: FilingWithAttachments;
}

export interface IFilingAttachmentsHistorical {
    accountId: string;
    filing: FilingHistoricalWithAttachments;
}

export function FilingAttachmentsReadonly({ accountId, filing }: IFilingAttachmentsReadonly) {
    if (filing.attachments.length === 0) {
        return null;
    }
    const downloadAttachments = async (attachment: FilingAttachment) => {
        await downloadFilingAttachmentFile(accountId, filing.taxProfileId, filing.id, attachment);
    };

    return (
        <div>
            {filing.attachments
                .filter((x) => !!x.filename)
                .map((attachment) => (
                    <div key={attachment.id} style={{ display: "inline-flex" }}>
                        <Link
                            data-testid={`filingAttachment-${attachment.id}`}
                            download
                            target="_blank"
                            onClick={async () => downloadAttachments(attachment)}>
                            <Typography style={{ marginRight: "4px", cursor: "pointer" }}>
                                <CloudDownloadTwoToneIcon color="primary" />
                            </Typography>
                        </Link>
                    </div>
                ))}
        </div>
    );
}

const downloadFileHistorical = async (
    accountId: string,
    taxProfileId: string,
    filingHistoricalId: string,
    attachment: FilingHistoricalAttachment
) => {
    const file = await getAttachmentHistorical(accountId, taxProfileId, filingHistoricalId, attachment.id);
    const filename = `attachment${
        attachment.filename ? attachment.filename.substr(0, attachment.filename.lastIndexOf(".")) : ""
    }`;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();
    URL.revokeObjectURL(a.href);
};

export function FilingHistoricalAttachments({ accountId, filing }: IFilingAttachmentsHistorical) {
    if (filing.attachments.length === 0) {
        return null;
    }
    return (
        <div>
            {filing.attachments
                .filter((x) => !!x.filename || !!x.response)
                .map((attachment) => (
                    <div key={attachment.id} style={{ display: "inline-block" }}>
                        <Link
                            download
                            target="_blank"
                            onClick={async () =>
                                downloadFileHistorical(accountId, filing.taxProfileId, filing.id, attachment)
                            }>
                            <Typography style={{ marginRight: "4px", cursor: "pointer" }}>
                                <CloudDownloadTwoToneIcon color="primary" />
                            </Typography>
                        </Link>
                    </div>
                ))}
        </div>
    );
}
