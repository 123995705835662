export type D30Environment = "dev" | "qa" | "sbx" | "stg" | "prd" | "rr";

export interface ICommonConfigType {
    d30Env: D30Environment;
    features: { [key: string]: boolean };
    credsCheck: {
        davoEnabled: boolean;
        skyScraperEnabled: boolean;
    };
}

export interface IPortalConfigType extends ICommonConfigType {
    demoVideo: string;
    revelAppId: string;
    revelInvitationURL: string;
    heartlandRestaurantURL: string;
    heartlandRetailRedirectURL: string;
    heartlandRetailBaseURL: string;
    heartlandRetailClientId: string;
    parBrinkURL: string;
    squareAppId: string;
    squareBaseURL: string;
    poyntBaseURL: string;
    poyntAppId: string;
    poyntCloudAppId: string;
    poyntRedirectURL: string;
    quickBooksConnectLink: string;
    cloverRedirectURL: string;
    cloverBaseURL: string;
    cloverV2BaseURL: string;
    googleOAuthClientId: string;
    spotOnRestaurantInvitationURL: string;
    spotOnRetailInvitationURL: string;
    avalaraIdentityEnabled?: null | boolean;
    shift4AuthUri: string;
    lightspeedESeriesAuthUri: string;
    lightspeedKSeriesAuthURL: string;
    lightspeedLSeriesBaseURL: string;
    lightspeedLSeriesChallenge: string;
    lightspeedLSeriesKey: string;
    lightspeedRSeriesClientId: string;
    lightspeedRSeriesUrl: string;
    lightspeedUSeriesInvitationURL: string;
    lightspeedXSeriesClientId: string;
    lightspeedXSeriesRedirectURL: string;
    lightspeedXSeriesUrl: string;
    fullStoryEnabled: boolean;
}

export interface IOpsConfigType extends ICommonConfigType {
    payorName: string;
    payorAddress: string;
    payorTown: string;
    payorStateAndZIP: string;
    bankName: string;
    filingSettings: {
        maxAdjustmentPercentage: number;
    };
    achOverrideRoutingNumbers: string[];
}

export type IPartnerConfigType = ICommonConfigType;
