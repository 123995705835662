import { LocationRecord } from "@davo/types";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { AddressEditForm } from "./AddressEditForm";
import { PaperComponent } from "./PaperComponent";
import { updateLocationAddress } from "./services";
import { d30Toast } from "./Toast";

export interface IAddressEditModalProps {
    title: string;
    accountId: string;
    location: LocationRecord;
    showCounty?: boolean;
    showCancel?: boolean;
    closeDialog: (target?: string) => void;
}

export function AddressEditModal({
    title,
    accountId,
    location,
    showCounty = false,
    showCancel = false,
    closeDialog,
}: IAddressEditModalProps) {
    const _updateAddress = (loc: LocationRecord): Promise<void> =>
        updateLocationAddress(accountId, loc.id, loc, false).then(() => {
            d30Toast("Successfully updated address!");
            closeDialog();
        });

    return (
        <Dialog
            data-testid={"addressEditModal"}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            fullScreen={false}
            open={true}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    closeDialog();
                }
            }}>
            <DialogTitle id="draggable-dialog-title">
                <span data-testid={"addressEditModalDialogTitle"} style={{ alignSelf: "center" }}>
                    {title}
                </span>
            </DialogTitle>
            <DialogContent>
                <AddressEditForm
                    location={location}
                    showCounty={showCounty}
                    showCancel={showCancel}
                    onSubmit={_updateAddress}
                    onCancel={() => closeDialog()}
                />
            </DialogContent>
        </Dialog>
    );
}
