import { fromJSON, MetaLoginAttempt, MetaUserRole, POSType, toJSON, toPartial, User, UserRole } from "@davo/types";
import { get, post, put } from "./api";

interface ICreateResponseType {
    token: string;
    user: User;
}

const create = async (
    fullname: string,
    username: string,
    password: string,
    phone?: string | null,
    merchantInvitationId?: string | null,
    userInvitationId?: string | null,
    partnerInvitationId?: string | null,
    allowRedirectOnLogout = false,
    posType?: POSType | null
) => {
    if (!merchantInvitationId && !userInvitationId && !partnerInvitationId && !posType) {
        throw new Error("Either merchant, user or partner invitation id is required to create a user");
    }

    const lwUsername = username.toLowerCase();
    return post<ICreateResponseType>(
        "authenticate/create",
        {
            fullname,
            username: lwUsername,
            password,
            phone,
            merchantInvitationId,
            userInvitationId,
            partnerInvitationId,
            posType,
        },
        true,
        undefined,
        undefined,
        undefined,
        allowRedirectOnLogout
    );
};

const createUserFromGoogle = async (
    googleJwtToken: string,
    app: string,
    allowRedirectOnLogout = false,
    posType?: POSType | null
) => {
    return post<ICreateResponseType>(
        "authenticate/create/google",
        { googleJwtToken, app, posType },
        true,
        undefined,
        undefined,
        undefined,
        allowRedirectOnLogout
    );
};

interface IAuthenticateResponse {
    user: User;
    firebaseToken?: string;
}

const authenticate = async (username: string, password: string, app: string, allowRedirectOnLogout = false) => {
    const lwUsername = username.toLowerCase();
    return post<IAuthenticateResponse>(
        "authenticate",
        { username: lwUsername, password, app },
        true,
        undefined,
        undefined,
        undefined,
        allowRedirectOnLogout
    );
};

const authenticateGoogle = async (googleJwtToken: string, app: string, allowRedirectOnLogout = false) => {
    return post<IAuthenticateResponse>(
        "authenticate/google",
        { googleJwtToken, app },
        true,
        undefined,
        undefined,
        undefined,
        allowRedirectOnLogout
    );
};

const resetPassword = async (email: string, app: string) => {
    return post("authenticate/forgot-password", { email, app }, false);
};

const setPassword = async (email: string, token: string, password: string) => {
    return post("authenticate/reset-password", { email, token, password }, false);
};

const updateCurrentUser = async (user: Partial<User>): Promise<UserRole> => {
    return put(`api/common/users/current`, toJSON(toPartial(MetaUserRole), user), true);
};

const updateCurrentUserPassword = async (oldPassword: string, newPassword: string): Promise<void> => {
    return put(`api/common/users/current/password`, { oldPassword, newPassword }, true);
};

// Fetch the current user. If we get back a 401 the server has already logged us out. Log out the frontend too.
// If we get back the user object we must be logged in and we can continue.
const whoAmI = async () => {
    return get<{ user: User | null; firebaseToken: string | undefined }>("authenticate/current-user", true);
};

const logout = async () => {
    try {
        await post("authenticate/logout", {}, true, {}, undefined);
    } catch (e: any) {
        // ignore these errors
    }
};

const logoutRedirect = (allowRedirect = true) => {
    // Redirect to / if we aren't already there
    if (allowRedirect && window.location.pathname !== "/") {
        let slash = "";
        if (!window.location.pathname.endsWith("/")) {
            slash = "/";
        }
        window.location.href = `${window.location.pathname}${slash}`;
    }
};

const getLastLogin = async () => {
    const result: any = await get("api/common/users/current/last-login", true);
    return result ? fromJSON(MetaLoginAttempt, result) : undefined;
};

const getAuthorizationError = (e: Error) => {
    return e.message.startsWith("Authorization Error:") ? e.message.substring(20) : e.message;
};

export const auth = {
    authenticate,
    authenticateGoogle,
    create,
    createUserFromGoogle,
    getLastLogin,
    logout,
    logoutRedirect,
    whoAmI,
    resetPassword,
    setPassword,
    updateCurrentUser,
    updateCurrentUserPassword,
    getAuthorizationError,
};
