import { FormError, TextField } from "@davo/portal-common";
import { validateNotNull } from "@davo/types";
import InfoIcon from "@mui/icons-material/Info";
import { Button, Theme, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { getMindbodyActivationLink, testMindbodyCreds } from "../services/boarding";
import { BoardingContainer, sharedButtonContainer } from "./BoardingContainer";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
    ...sharedButtonContainer,
}));

export function MindbodyBoarding() {
    const classes = useStyles();
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [siteId, setSiteId] = useState<string | undefined>(undefined);
    const [isUserAuthorizing, setIsUserAuthorizing] = useState<boolean>(false);
    const counter = React.useRef(0);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    useEffect(() => {
        if (isUserAuthorizing && siteId) {
            setMessage("Testing Mindbody connection.");
            const interval = setInterval(async () => {
                counter.current += 1;
                const credCheck = await testMindbodyCreds(siteId);
                if (credCheck && credCheck.success.valid) {
                    window.location.replace(`/invitation/create/mindbody?siteId=${siteId}`);
                } else if (counter.current >= 60) {
                    setMessage(
                        "Could not establish a connection with Mindbody.  Please try your siteId again or contact DAVO support to continue boarding."
                    );
                    clearInterval(interval);
                    counter.current = 0;
                    setIsUserAuthorizing(false);
                }
            }, 2000);
        }
    }, [isUserAuthorizing, siteId]);

    const doRedirect = async () => {
        if (isBusy) {
            return;
        }

        if (siteId) {
            setIsBusy(true);
            const result = await getMindbodyActivationLink(siteId);
            setIsBusy(false);
            if (result.ActivationLink) {
                window.open(result.ActivationLink);
                setIsUserAuthorizing(true);
            } else {
                setMessage("Invalid Mindbody site ID");
            }
        } else {
            setMessage("Please enter Mindbody site ID");
        }
    };

    return (
        <BoardingContainer posType={"mindbody"}>
            <p>
                Please enter your{" "}
                <a
                    href={"https://support.mindbodyonline.com/s/article/206398178-How-do-I-find-my-Client-ID"}
                    target={"_blank"}
                    rel="noopener noreferrer">
                    Mindbody Site ID
                </a>
            </p>
            <div style={{ display: "flex", width: "100%" }}>
                <TextField
                    label="Mindbody SiteId"
                    value={siteId ?? ""}
                    onChange={setSiteId}
                    onEnterPress={doRedirect}
                    isRequired={true}
                    validate={validateNotNull}
                    isDisabled={isBusy}
                    inputProps={{
                        [`data-testid`]: "siteIdInput",
                    }}
                    InputProps={{
                        endAdornment: (
                            <Tooltip
                                title="You can find your site ID by looking in the bottom right corner of your Mindbody site"
                                placement="left">
                                <InfoIcon fontSize={"medium"} color="primary" />
                            </Tooltip>
                        ),
                    }}
                />
            </div>
            <p>
                When you click connect, a new tab will open up asking you to sign in to Mindbody. After logging in,
                please return to this tab to continue boarding.
            </p>
            {message && <FormError message={message} />}
            <div className={classes.submitContainer}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={doRedirect}
                    data-testid={"submitBtn"}
                    disabled={!siteId?.trim() || isBusy}
                    startIcon={isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />}>
                    Connect with Mindbody
                </Button>
            </div>
        </BoardingContainer>
    );
}
