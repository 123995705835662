export const statusColors = {
    error: "#ED1C24",
    warning: "#FFAB2B",
    ok: "#6DD230",
    inactive: "#000000",
};

export type StatusLevel = keyof typeof statusColors;

export const darkStatusColors = {
    error: "#ED1C24",
    warning: "#FFAB2B",
    ok: "#6DD230",
    inactive: "#999999",
};
