import {
    accountHasAnyActivity,
    AddressVerificationContextProvider,
    getAccount,
    getAccounts,
    getBankAccounts,
    getLocationEnhanced,
    getLocationsAll,
    getLocationsEnhanced,
    getPartner,
    getTaxProfiles,
    INavMenuItem,
    LoginPage,
    Profile,
    ResetPassword,
    SetPassword,
    useLoginContext,
} from "@davo/portal-common";
import {
    AccountRecord,
    BankAccount,
    IPortalConfigType,
    Location,
    LocationRecord,
    OpsUserTypes,
    Partner,
    POSType,
    TaxProfile,
} from "@davo/types";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation, useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { PreAccount } from "./boarding/flow/PreAccount";
import { Reconnect } from "./boarding/flow/Reconnect";
import { HeartlandRestaurantBoarding } from "./boarding/HeartlandRestaurantBoarding";
import { HeartlandRetailBoarding } from "./boarding/HeartlandRetailBoarding";
import { LavuBoarding } from "./boarding/LavuBoarding";
import { LegacySubscriptionPage } from "./boarding/LegacySubscriptionPage";
import { LightspeedKSeriesBoarding } from "./boarding/LightspeedKSeriesBoarding";
import { LightspeedLSeriesBoarding } from "./boarding/LightspeedLSeriesBoarding";
import { LightspeedUSeriesBoarding } from "./boarding/LightspeedUSeriesBoarding";
import { LightspeedXSeriesBoarding } from "./boarding/LightspeedXSeriesBoarding";
import { LoadInvitation } from "./boarding/LoadInvitation";
import { MerchantInvitationLogin } from "./boarding/MerchantInvitationLogin";
import { MindbodyBoarding } from "./boarding/MindbodyBoarding";
import { ParBrinkBoarding } from "./boarding/ParBrinkBoarding";
import { QuickBooksBoarding } from "./boarding/QuickBooksBoarding";
import { RevelBoarding } from "./boarding/RevelBoarding";
import { SpotOnRestaurantBoarding } from "./boarding/SpotOnRestaurantBoarding";
import { SpotOnRetailBoarding } from "./boarding/SpotOnRetailBoarding";
import { AccountContext, AccountsContext, LocationContext, useConfigMerchantContext } from "./context";
import { Dashboard } from "./Dashboard";
import { Filings } from "./Filings";
import { FirstAccountChanger } from "./FirstAccountChanger";
import { History } from "./History";
import { InvitationRequired } from "./InvitationRequired";
import { MerchantDataTab, showMerchantDataTab } from "./MerchantDataTab";
import { MerchantDonut } from "./MerchantDonut";
import { NoticesTab } from "./NoticesTab";
import { QuickBooksDisconnect } from "./QuickBooksDisconnect";
import DashboardIcon from "./resources/dashboard.svg";
import DataIcon from "./resources/data.svg";
import FilingsIcon from "./resources/filings.svg";
import ViewTimeLine from "./resources/viewtimeline.svg";
import { getIncompleteBoarding, getInvitationByPOSMerchant, getLocationsByPOSMerchant } from "./services/boarding";
import { Settings } from "./Settings";
import { Banking } from "./SettingsBanking";
import { Billing } from "./SettingsBilling";
import { Company } from "./SettingsCompany";
import { SettingsCompanySetBankModal } from "./SettingsCompanySetBankModal";
import { SettingsCompanySetTaxProfilesModal } from "./SettingsCompanySetTaxProfiles";
import { SingleLocation } from "./SettingsSingleLocation";
import { Filing } from "./SettingsTaxProfile";
import { UserReferral } from "./SettingsUserReferral";
import { Users } from "./SettingsUsers";
import { UserInvitationLogin } from "./UserInvitationLogin";
import { determineLocationsNotFullyConnected } from "./util";

export function MerchantRouter() {
    const { merchantConfigInfo: configInfo } = useConfigMerchantContext();
    const loginContext = useLoginContext();

    const [accounts, setAccounts] = useState<AccountRecord[]>();
    const [account, setAccount] = useState<AccountRecord>();
    const [partner, setPartner] = useState<Partner>();
    const [accountLocations, setAccountLocations] = useState<LocationRecord[]>();
    const [accountBankAccounts, setAccountBankAccounts] = useState<BankAccount[]>();
    const [accountTaxProfiles, setAccountTaxProfiles] = useState<TaxProfile[]>();

    const [location, setLocation] = useState<Location>();
    const [locationsNotFullyConnected, setLocationsNotFullyConnected] = useState<Set<string>>();

    const [donutPrimaryRoutes, setDonutPrimaryRoutes] = useState<INavMenuItem[]>([]);
    const [shouldShowDetailPanes, setShouldShowDetailPane] = useState<boolean>(false);
    const [firstDonutPath, setFirstDonutPath] = useState<string>();

    const refreshAccounts = async () => {
        if (!loginContext.user) {
            return;
        }
        const accountsList = await getAccounts(loginContext.user.id);
        setAccounts(accountsList);
    };

    const getInvitationForBoardingRedirect = async () => {
        if (accounts && accounts.length === 1) {
            const lList = await getLocationsEnhanced(accounts[0].id);
            const activeLocations = lList.filter((l: LocationRecord) => l.active && !l.disconnected);

            if (!isEmpty(activeLocations) && activeLocations.length === 1) {
                const activeLocation = activeLocations[0];

                const locationsForPOSMerchant = await getLocationsByPOSMerchant(
                    activeLocation.posType,
                    activeLocation.posMerchant
                );
                if (locationsForPOSMerchant && locationsForPOSMerchant.length > 1) {
                    return;
                }

                if (
                    !activeLocation.bankAccount ||
                    isEmpty(activeLocation.taxProfileIds) ||
                    activeLocation.taxProfileIds.length === 0
                ) {
                    return getInvitationByPOSMerchant(activeLocation.posType, activeLocation.posMerchant);
                }
            }
        }
    };

    useAsyncEffect(async () => {
        if (!loginContext.user) {
            clearAccount();
            setAccounts(undefined);
        } else {
            await refreshAccounts();
        }
    }, [loginContext.user]);

    useAsyncEffect(async () => {
        if (accounts && loginContext.user && ![...OpsUserTypes, "Partner"].includes(loginContext.user.type)) {
            const isInInvitationEnrollment = window.location.pathname.startsWith("/invitation");
            if (accounts.length === 0) {
                const isInBoarding = window.location.pathname.startsWith("/boarding");
                if (!isInBoarding && !isInInvitationEnrollment) {
                    const openBoarding = await getIncompleteBoarding();
                    if (openBoarding) {
                        window.location.replace(`/boarding/${openBoarding.posType}`);
                    }
                }
            } else if (accounts.length === 1 && !isInInvitationEnrollment) {
                const invitation = await getInvitationForBoardingRedirect();
                if (invitation) {
                    window.location.replace(`/invitation/location/${invitation.id}`);
                }
            }
        }
    }, [accounts, loginContext.user]);

    const switchAccount = async (accountId: string | undefined) => {
        if (!accountId) {
            clearAccount();
            return;
        }
        await loadAccount(accountId);
    };

    const clearAccount = () => {
        setAccount(undefined);
        setPartner(undefined);
        setAccountLocations(undefined);
        setAccountBankAccounts(undefined);
        setAccountTaxProfiles(undefined);
        setLocation(undefined);
        setLocationsNotFullyConnected(undefined);
    };

    const refreshAccount = async () => {
        if (!account) {
            return;
        }
        await loadAccount(account.id);
    };

    const loadAccount = async (accountId: string) => {
        const accountPromise = getAccount(accountId);
        const locationsPromise = getLocationsAll(accountId);
        const bankAccountsPromise = getBankAccounts(accountId);
        const taxProfilesPromise = getTaxProfiles(accountId);
        const locationsNotFullyConnectedPromise = determineLocationsNotFullyConnected(accountId);
        const accountHasAnyActivityPromise = accountHasAnyActivity(accountId);
        const [
            accountResult,
            locationsResult,
            bankAccountsResult,
            taxProfilesResult,
            locationsNotFullyConnectedResult,
            hasActivity,
        ] = await Promise.all([
            accountPromise,
            locationsPromise,
            bankAccountsPromise,
            taxProfilesPromise,
            locationsNotFullyConnectedPromise,
            accountHasAnyActivityPromise,
        ]);
        setAccount(accountResult);
        setAccountLocations(locationsResult);
        setAccountBankAccounts(bankAccountsResult);
        setAccountTaxProfiles(taxProfilesResult);
        setLocationsNotFullyConnected(locationsNotFullyConnectedResult);

        // NOTE: if all locations are inactive, and we have no activity for this account (for any location)
        // hide the Dashboard and Settings endpoints and menu items
        const hasActiveLocations = locationsResult.some((l) => l.active);

        setShouldShowDetailPane(hasActiveLocations || hasActivity);
    };

    useAsyncEffect(async () => {
        if (!account?.partnerId) {
            setPartner(undefined);
            return;
        }
        setPartner(await getPartner(account.partnerId));
    }, [account]);

    const refreshLocation = async () => {
        if (!account || !location) {
            return;
        }
        const l: Location = await getLocationEnhanced(account.id, location.id);
        setLocation(l);
    };

    const switchLocation = async (locationId: string | undefined) => {
        if (!account) {
            return;
        }
        if (!locationId) {
            setLocation(undefined);
            return;
        }
        const l: Location = await getLocationEnhanced(account.id, locationId);
        setLocation(l);
    };

    useAsyncEffect(async () => {
        if (
            isNil(loginContext.user) ||
            isNil(account) ||
            isNil(accountLocations) ||
            isNil(accountTaxProfiles) ||
            isNil(locationsNotFullyConnected)
        ) {
            return;
        }

        const theRoutes: INavMenuItem[] = [];
        if (shouldShowDetailPanes) {
            theRoutes.push({
                name: "Dashboard",
                slug: "dashboard",
                destination: "dashboard",
                element: <Dashboard />,
                icon: DashboardIcon,
            });
        }
        theRoutes.push({
            name: "Filings",
            slug: "filings",
            destination: "filings",
            element: <Filings />,
            icon: FilingsIcon,
        });
        theRoutes.push({
            name: "History",
            slug: "history",
            destination: "history",
            element: <History />,
            icon: ViewTimeLine,
        });
        if (showMerchantDataTab(accountTaxProfiles, loginContext.permissions)) {
            theRoutes.push({
                name: "Additional Info",
                slug: "additional-info",
                destination: "additional-info",
                element: <MerchantDataTab />,
                icon: DataIcon,
            });
        }
        if (configInfo.features.notices && accountTaxProfiles.length > 0) {
            theRoutes.push({
                name: "Notices",
                slug: "notices",
                destination: "notices",
                element: <NoticesTab />,
                icon: DataIcon,
            });
        }

        setDonutPrimaryRoutes(theRoutes);

        if (shouldShowDetailPanes && locationsNotFullyConnected && locationsNotFullyConnected.size > 0) {
            if (accountLocations?.length === 1 && locationsNotFullyConnected.size === 1) {
                setFirstDonutPath(`settings/company/${[...locationsNotFullyConnected][0]}`);
            } else {
                setFirstDonutPath("settings/company");
            }
        } else {
            setFirstDonutPath(theRoutes[0].slug);
        }
    }, [
        loginContext.user,
        loginContext.permissions,
        account,
        accountLocations,
        accountTaxProfiles,
        locationsNotFullyConnected,
        configInfo.features.notices,
        shouldShowDetailPanes,
    ]);

    const profileProps = {
        showScreenMode: false,
    };

    return (
        <AccountsContext.Provider
            value={{
                accounts,
                refresh: refreshAccounts,
            }}>
            <AccountContext.Provider
                value={{
                    account,
                    switch: switchAccount,
                    refresh: refreshAccount,
                    partner,
                    locations: accountLocations,
                    bankAccounts: accountBankAccounts,
                    taxProfiles: accountTaxProfiles,
                    locationsNotFullyConnected,
                }}>
                <LocationContext.Provider
                    value={{
                        switch: switchLocation,
                        location,
                        refresh: refreshLocation,
                    }}>
                    <AddressVerificationContextProvider
                        location={location}
                        accountId={account?.id}
                        shouldAutoPrompt={true}
                        refreshLocation={refreshLocation}>
                        <Router>
                            <Routes>
                                <Route
                                    path="/boarding/v2clover"
                                    element={<CloverV2Boarding configInfo={configInfo} />}
                                />
                                <Route path="/boarding/clover" element={<CloverBoarding configInfo={configInfo} />} />
                                <Route path="/boarding/square" element={<SquareBoarding configInfo={configInfo} />} />
                                <Route path="/boarding/poynt" element={<PoyntBoarding configInfo={configInfo} />} />
                                <Route path="/invitation/create/:protocol" element={<CreateInvitation />} />
                                <Route path="/index.html" element={<Navigate to="/" />} />
                                <Route path="/pre-account/location/:code" element={<PreAccount />} />
                                <Route path="/reconnect/location/:code" element={<Reconnect />} />
                                <Route path="/invitation/location/:code/:ai" element={<MerchantInvitationLogin />} />
                                <Route path="/invitation/location/:code" element={<MerchantInvitationLogin />} />
                                <Route path="/invitation/user/:code" element={<UserInvitationLogin />} />
                                <Route path="/authenticate/resetPassword" element={<ResetPassword />} />
                                <Route path="/authenticate/setPassword" element={<SetPassword />} />
                                <Route path="/boarding/revel" element={<RevelBoarding />} />
                                <Route
                                    path="/boarding/heartland-restaurant"
                                    element={<HeartlandRestaurantBoarding />}
                                />
                                <Route path="/boarding/heartland-retail" element={<HeartlandRetailBoarding />} />
                                <Route path="/boarding/par-brink" element={<ParBrinkBoarding />} />
                                <Route path="/boarding/mindbody" element={<MindbodyBoarding />} />
                                <Route path="/boarding/spoton-restaurant" element={<SpotOnRestaurantBoarding />} />
                                <Route path="/boarding/spoton-retail" element={<SpotOnRetailBoarding />} />
                                <Route path="/boarding/quickbooks" element={<QuickBooksBoarding />} />
                                <Route path="/boarding/lavu" element={<LavuBoarding />} />
                                <Route path="/boarding/shift4" element={<Shift4Boarding configInfo={configInfo} />} />
                                <Route path="/disconnect/quickbooks" element={<QuickBooksDisconnect />} />
                                <Route
                                    path="/boarding/lightspeed-e-series"
                                    element={<LightspeedESeriesBoarding configInfo={configInfo} />}
                                />
                                <Route path="/boarding/lightspeed-k-series" element={<LightspeedKSeriesBoarding />} />
                                <Route path="/boarding/lightspeed-l-series" element={<LightspeedLSeriesBoarding />} />
                                <Route
                                    path="/boarding/lightspeed-r-series"
                                    element={<LightspeedRSeriesBoarding configInfo={configInfo} />}
                                />
                                <Route path="/boarding/lightspeed-u-series" element={<LightspeedUSeriesBoarding />} />
                                <Route path="/boarding/lightspeed-x-series" element={<LightspeedXSeriesBoarding />} />
                                <Route path="/legacy" element={<LegacySubscriptionPage />} />
                                {!loginContext.user && (
                                    <Route
                                        path="*"
                                        element={
                                            <LoginPage
                                                googleOAuthClientId={configInfo.googleOAuthClientId}
                                                isAvalaraIdentityEnabled={configInfo.avalaraIdentityEnabled}
                                                appType="merchantPortal"
                                            />
                                        }
                                    />
                                )}
                                {loginContext.user && (!account || !accounts || (accounts && accounts.length > 0)) && (
                                    <Route path="*" element={<FirstAccountChanger />} />
                                )}
                                {loginContext.user && (
                                    <Route
                                        path="/:accountId"
                                        element={
                                            <MerchantDonut
                                                routes={donutPrimaryRoutes}
                                                shouldShowDetailPanes={shouldShowDetailPanes}
                                            />
                                        }>
                                        {/* Redirect to first donut route if not provided */}
                                        {firstDonutPath && (
                                            <Route path="" element={<Navigate to={firstDonutPath} replace />} />
                                        )}

                                        <Route path="profile" element={<Profile {...profileProps} />} />

                                        <Route path="settings" element={<Settings />}>
                                            <Route path="company" element={<Company />} />
                                            <Route path="company/:locationId" element={<SingleLocation />}>
                                                <Route path="bank" element={<SettingsCompanySetBankModal />} />
                                                <Route path="tax" element={<SettingsCompanySetTaxProfilesModal />} />
                                            </Route>

                                            <Route path="banking/*" element={<Banking />} />
                                            <Route path="filing/*" element={<Filing />} />
                                            <Route path="users/*" element={<Users />} />
                                            {account && !account.partnerId && (
                                                <Route path="billing/*" element={<Billing />} />
                                            )}
                                            {loginContext.user?.referralCode && (
                                                <Route path="referral/*" element={<UserReferral />} />
                                            )}

                                            {/* The settings page defaults to the company pane */}
                                            <Route path="" element={<Navigate to="company" replace />} />
                                        </Route>

                                        {donutPrimaryRoutes.map((item) => (
                                            <Route key={item.slug} path={`${item.slug}/*`} element={item.element} />
                                        ))}

                                        {/* redirect any other path to the first donut route */}
                                        {firstDonutPath && (
                                            <Route path="*" element={<Navigate to={firstDonutPath} replace />} />
                                        )}
                                    </Route>
                                )}
                                {account && <Route path="/" element={<Navigate to={`/${account.id}`} />} />}
                                {!account && <Route path="*" element={<InvitationRequired />} />}
                            </Routes>
                        </Router>
                    </AddressVerificationContextProvider>
                </LocationContext.Provider>
            </AccountContext.Provider>
        </AccountsContext.Provider>
    );
}

function CloverBoarding({ configInfo }: { configInfo: IPortalConfigType }) {
    window.location.replace(configInfo.cloverV2BaseURL);
    return null;
}

function CloverV2Boarding({ configInfo }: { configInfo: IPortalConfigType }) {
    window.location.replace(`${configInfo.cloverV2BaseURL}`);
    return null;
}

function SquareBoarding({ configInfo }: { configInfo: IPortalConfigType }) {
    window.location.replace(
        `${configInfo.squareBaseURL}?client_id=${configInfo.squareAppId}&scope=MERCHANT_PROFILE_READ+PAYMENTS_READ+SETTLEMENTS_READ+BANK_ACCOUNTS_READ+EMPLOYEES_READ+ORDERS_READ+ITEMS_READ`
    );
    return null;
}

function PoyntBoarding({ configInfo }: { configInfo: IPortalConfigType }) {
    const encodedURI = encodeURI(`&redirect_uri=${configInfo.poyntRedirectURL}`);
    window.location.replace(`${configInfo.poyntBaseURL}?client_id=${configInfo.poyntCloudAppId}${encodedURI}`);
    return null;
}

function CreateInvitation() {
    const params = useParams();
    const location = useLocation();
    let paramsProtocol = params.protocol;

    if (!paramsProtocol) {
        return null;
    }
    if (paramsProtocol === "poynt" && location.search.length === 0) {
        // Poynt has one link in the app for oauth redirect and for the link to visit DAVO once the merchant is subbed
        // so we redirect to login here if there aren't any of the params we'd get from an oauth redirect
        return <Navigate to="/" />;
    }

    // Lightspeed doesn't allow use of "lightspeed" in oauth redirects, so we have to use non-POStype ls-* then reassign for all Lightspeed integrations
    if (paramsProtocol && paramsProtocol.startsWith("ls-")) {
        paramsProtocol = paramsProtocol.replace("ls-", "lightspeed-");
    }

    return <LoadInvitation posType={paramsProtocol as POSType} body={location.state} />;
}

function LightspeedRSeriesBoarding({ configInfo }: { configInfo: IPortalConfigType }) {
    window.location.replace(`${configInfo.lightspeedRSeriesUrl}`);
    return null;
}

function LightspeedESeriesBoarding({ configInfo }: { configInfo: IPortalConfigType }) {
    window.location.replace(`${configInfo.lightspeedESeriesAuthUri}`);
    return null;
}

function Shift4Boarding({ configInfo }: { configInfo: IPortalConfigType }) {
    window.location.replace(`${configInfo.shift4AuthUri}`);
    return null;
}
