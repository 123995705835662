import { BusinessDay, LocationRecord } from "@davo/types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { ConfirmationModal } from "./ConfirmationModal";
import { NumberField } from "./NumberField";
import { PaperComponent } from "./PaperComponent";
import Select from "./Select";
import { d30Toast, d30ToastError } from "./Toast";
import { addUseTax } from "./services";
import { useModalEditor } from "./useModal";
import { getLocationLabel, getMonths, monthFormat, monthYear, validateAmount } from "./util";

export function UseTaxModal({
    locations,
    accountId,
    refresh,
    includeAllMonths = false,
}: {
    locations?: LocationRecord[];
    accountId: string;
    refresh?: () => void;
    includeAllMonths?: boolean;
}) {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [amount, setAmount] = useState<number>();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [location, setLocation] = useState<LocationRecord>();
    const [month, setMonth] = useState<string>();
    const [monthOptions, setMonthOptions] = useState<string[]>();
    const addTax = () => {
        if (location && location.id && amount) {
            const day = month
                ? BusinessDay.fromDateTime(DateTime.fromFormat(month, monthFormat))
                : BusinessDay.fromDateTime(DateTime.fromJSDate(new Date()));
            const amtInPennies = Math.trunc(parseFloat(amount.toString().replace(/[^\d.]/g, "")) * 100);

            addUseTax(accountId, location.id, day, amtInPennies)
                .then(() => {
                    d30Toast(`Use Tax added.`);
                    setAmount(undefined);
                    setMonth(undefined);

                    refresh && refresh();
                })
                .catch(() => d30ToastError("Could not add use tax"));
        }
    };

    const [showConfirmationModal, confirmationModalProps] = useModalEditor(() => {
        addTax();
    });

    const show = () => {
        if (locations && locations.length === 1) {
            setLocation(locations[0]);
        } else {
            setLocation(undefined);
        }
        setIsBusy(false);
        setIsShowing(true);
    };

    useEffect(() => {
        if (!monthOptions && locations && locations.length > 0) {
            setMonthOptions(getMonths());
        }
    }, [locations, monthOptions]);

    return (
        <>
            {locations && locations.length > 0 && (
                <>
                    <Button
                        variant="outlined"
                        style={{ padding: "7px 9px", margin: "5px" }}
                        onClick={show}
                        size="small"
                        color="primary">
                        Add Use Tax
                    </Button>
                    {isShowing && (
                        <Dialog
                            PaperComponent={PaperComponent}
                            aria-labelledby="draggable-dialog-title"
                            open={true}
                            onClose={() => {
                                setIsShowing(false);
                            }}>
                            <DialogTitle id="draggable-dialog-title">Use Tax</DialogTitle>
                            <DialogContent>
                                <div style={{ width: "100%" }}>
                                    <Select<LocationRecord>
                                        noneLabel={`Select a location...`}
                                        value={location}
                                        onChange={setLocation}
                                        options={locations}
                                        label={(l) => getLocationLabel(l)}
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Select<string>
                                            value={month}
                                            title="Period"
                                            onChange={setMonth}
                                            style={{ width: "300px" }}
                                            options={monthOptions ?? []}
                                            label={monthYear}
                                            showFullWidth={false}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <NumberField
                                    validate={validateAmount}
                                    label={"Amount"}
                                    value={amount}
                                    onChange={setAmount}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={isBusy}
                                    onClick={() => {
                                        setIsShowing(false);
                                    }}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={isBusy || !location || !amount}
                                    onClick={() => {
                                        showConfirmationModal();
                                        setIsShowing(false);
                                    }}>
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                    {confirmationModalProps.isDialogOpen && (
                        <ConfirmationModal
                            title={"Are you sure?"}
                            message={
                                <div style={{ padding: "10px" }}>
                                    {includeAllMonths
                                        ? "You are adding data to the past, please review filed filings"
                                        : "Confirm your entry"}
                                </div>
                            }
                            continueLabel={includeAllMonths ? "OK" : "YES"}
                            showContinue={!!amount}
                            {...confirmationModalProps}
                            target={amount}
                        />
                    )}
                </>
            )}
        </>
    );
}
