import {
    AppType,
    isEmailValid,
    isPasswordValid,
    isPhoneValid,
    isProduction,
    POSType,
    validateEmail,
    validateNotNull,
    validatePassword,
    validatePhone,
} from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useConfigCommonContext, useLoginContext } from "./context";
import { FormError } from "./FormError";
import avalaraLogo from "./resources/avalara.svg";
import computerTaxLogo from "./resources/comp_tax.png";
import logo from "./resources/davo_logo.png";
import oktaLogo from "./resources/okta-logo.png";
import { auth } from "./services";
import { TextField } from "./TextField";
import { d30Toast, d30ToastError } from "./Toast";
import { apiUrl } from "./util";

const styles = makeStyles((theme: Theme) => ({
    avatar: {
        margin: theme.spacing(),
        backgroundColor: theme.palette.secondary.main,
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    container: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "spaceBetween",
        width: "300px",
    },
    avalaraButton: {
        borderWidth: "2px",
        boxShadow: "0 10px 15px 0 rgb(0 0 0 / 15%)",
        minWidth: "150px",
        cursor: "pointer",
        fontFamily: "source-sans-pro,Arial,sans-serif",
        fontSize: "18px",
        lineHeight: "27px",
        display: "inline-block",
        marginBottom: "0",
        textAlign: "center",
        verticalAlign: "middle",
        touchAction: "manipulation",
        whiteSpace: "normal",
        fontWeight: "600",
        borderRadius: "3px",
        minHeight: "40px",
        padding: "10px 20px",
        transition: "all .2s ease-in-out",
        backgroundColor: "#fff",
        color: "#1a1a1a",
    },
}));

export interface ILoginFormProps {
    showCreate?: boolean;
    useAlternateLogo?: boolean;
    shouldCreateOnly?: boolean;
    email?: string | null;
    doesExist?: boolean;
    name?: string;
    appName?: string;
    appType: AppType;
    googleOAuthClientId?: string;
    disableInternalLogin?: boolean;
    merchantInvitationId?: string | null;
    userInvitationId?: string | null;
    partnerInvitationId?: string | null;
    isAvalaraIdentityEnabled?: boolean | null;
    isOpsLoginEnabled?: boolean | null;
    posType?: POSType | null;
}

export function LoginForm({
    showCreate,
    useAlternateLogo,
    shouldCreateOnly,
    email,
    doesExist,
    name,
    appName = "Portal",
    appType,
    googleOAuthClientId,
    disableInternalLogin = false,
    merchantInvitationId,
    userInvitationId,
    partnerInvitationId,
    isAvalaraIdentityEnabled = false,
    isOpsLoginEnabled = false,
    posType,
}: ILoginFormProps) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = styles();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const { commonConfigInfo: configInfo } = useConfigCommonContext();
    const loginContext = useLoginContext();

    const [areCreating, setAreCreating] = useState<boolean>(shouldCreateOnly || showCreate || false);
    const [username, setUsername] = useState<string>(email || "");
    const [password, setPassword] = useState<string>("");
    const [fullname, setFullname] = useState<string>(name || "");
    // NOTE: invitation phone is purposefully passed in here as of 10/21/21; was too often the store's phone
    const [phoneNumber, setPhone] = useState<string>("");
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [showResetForm, setshowResetForm] = useState<boolean>(false);
    const pwRef = useRef<HTMLInputElement>(null);

    if (params.get("opsLogin") === "required") {
        isOpsLoginEnabled = true;
    }

    // check existing and switch to login automatically
    useEffect(() => {
        if (email && doesExist) {
            setAreCreating(false);
        }
    }, [email, doesExist]);

    const doReset = () => {
        setIsDisabled(true);
        auth.resetPassword(username, appType)
            .then(() => {
                setshowResetForm(false);
                d30Toast("If that email corresponds to an account, a password reset email was sent!");
            })
            .catch((e) => d30ToastError(e.message))
            .finally(() => setIsDisabled(false));
    };

    const authenticate = async () => {
        setMessage(undefined);
        setIsDisabled(true);

        if (areCreating && (merchantInvitationId || userInvitationId || partnerInvitationId || posType)) {
            try {
                const data = await auth.create(
                    fullname,
                    username,
                    password,
                    phoneNumber,
                    merchantInvitationId,
                    userInvitationId,
                    partnerInvitationId,
                    false,
                    posType
                );
                loginContext.setUser(data.user);
            } catch (e: any) {
                console.log(e); // eslint-disable-line no-console
                setMessage(e.message);
                setIsDisabled(false);
            }
        } else {
            try {
                const data = await auth.authenticate(username, password, appType);
                loginContext.setUser(data.user);
            } catch (e: any) {
                console.log(e); // eslint-disable-line no-console
                setMessage(auth.getAuthorizationError(e));
                setIsDisabled(false);
                return null;
            }
        }
    };

    const onFirstFieldEnterPressed = () => {
        if (pwRef.current) {
            pwRef.current.focus();
        }
    };

    const onSubmitKeyPress = async () => {
        if (
            isDisabled ||
            !username ||
            !isEmailValid(username) ||
            !password ||
            (areCreating && !fullname) ||
            (areCreating && !phoneNumber)
        ) {
            return;
        }
        await authenticate();
    };

    const onResetKeyPress = () => {
        if (!username || !isEmailValid(username)) {
            return;
        }
        doReset();
    };

    const validatePasswordOnCreate = (pw: string) => {
        if (areCreating) {
            return validatePassword(pw);
        }
        return undefined;
    };

    const handleGoogleLogin = async (response: CredentialResponse) => {
        setMessage(undefined);
        setIsDisabled(true);

        if (areCreating) {
            try {
                const data = await auth.createUserFromGoogle(response.credential!, appType, false, posType);
                loginContext.setUser(data.user);
            } catch (e: any) {
                console.log(e); // eslint-disable-line no-console
                setMessage(e.message ?? "Unable to create user with Google");
                setIsDisabled(false);
            }
        } else {
            try {
                const data = await auth.authenticateGoogle(response.credential!, appType);
                loginContext.setUser(data.user);
            } catch (e: any) {
                console.log(e); // eslint-disable-line no-console
                setMessage(auth.getAuthorizationError(e));
                setIsDisabled(false);
            }
        }
    };

    const handleGoogleLoginError = () => {
        setMessage("Unable to login with Google");
    };

    const handleAvalaraIdentityLogin = () => {
        if (areCreating && (merchantInvitationId || userInvitationId || partnerInvitationId)) {
            const queryParams = [];

            if (merchantInvitationId) {
                queryParams.push(`merchantInvitationId=${merchantInvitationId}`);
            }

            if (userInvitationId) {
                queryParams.push(`userInvitationId=${userInvitationId}`);
            }

            if (partnerInvitationId) {
                queryParams.push(`userInvitationId=${partnerInvitationId}`);
            }

            window.location.assign(`${apiUrl}/ai/login/create?${queryParams.join("&")}`);
        } else {
            window.location.assign(`${apiUrl}/ai/login/auth`);
        }
    };

    const handleOpsLogin = () => {
        const url = location.pathname.slice(1);
        window.location.assign(`${apiUrl}/opslogin/login?path=${url}&appType=${appType}`);
    };

    return (
        <div className={classes.container} data-testid={"loginForm"}>
            {!isProduction(configInfo.d30Env) && (
                <>
                    <br />
                    <span style={{ color: "red" }}>({configInfo.d30Env})</span>
                </>
            )}
            {!useAlternateLogo && <img src={logo} alt="" style={{ width: "150px" }} />}
            {useAlternateLogo && <img src={computerTaxLogo} alt="" style={{ width: "150px" }} />}
            <Typography variant="h5">
                <div
                    style={{
                        textAlign: "center",
                        marginTop: "20px",
                    }}>
                    {appName}
                </div>
            </Typography>
            {googleOAuthClientId && (
                <>
                    <div style={{ paddingTop: "30px", paddingBottom: "20px" }}>
                        <GoogleOAuthProvider clientId={googleOAuthClientId}>
                            <GoogleLogin
                                onSuccess={async (credentialResponse) => {
                                    await handleGoogleLogin(credentialResponse);
                                }}
                                onError={handleGoogleLoginError}
                            />
                        </GoogleOAuthProvider>
                    </div>

                    {(!disableInternalLogin || isAvalaraIdentityEnabled) && (
                        <Typography variant="subtitle2">OR</Typography>
                    )}
                </>
            )}
            {isAvalaraIdentityEnabled && (
                <>
                    <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.avalaraButton}
                            onClick={handleAvalaraIdentityLogin}>
                            <>
                                <div>Log in to</div>
                                <img width={"80px"} style={{ paddingLeft: "10px" }} src={avalaraLogo} />
                            </>
                        </Button>
                    </div>

                    {!disableInternalLogin && <Typography variant="subtitle2">OR</Typography>}
                </>
            )}
            {isOpsLoginEnabled && (
                <>
                    <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.avalaraButton}
                            onClick={handleOpsLogin}>
                            <>
                                <div>Log in to</div>
                                <img width={"80px"} style={{ paddingLeft: "10px" }} src={oktaLogo} />
                            </>
                        </Button>
                    </div>

                    {!disableInternalLogin && <Typography variant="subtitle2">OR</Typography>}
                </>
            )}
            {!disableInternalLogin && (
                <>
                    {areCreating && (
                        <TextField
                            label="Your Name"
                            className="fs-exclude"
                            value={fullname}
                            onChange={setFullname}
                            isAutoFocus
                            isRequired
                            validate={validateNotNull}
                        />
                    )}
                    {areCreating && (
                        <TextField
                            label="Your mobile phone"
                            className="fs-exclude"
                            value={phoneNumber}
                            isRequired
                            validate={(v) => (v ? validatePhone(v) : validateNotNull(null))}
                            onChange={setPhone}
                        />
                    )}
                    {areCreating && (
                        <span style={{ fontSize: "9px", color: "darkGray", lineHeight: "12px", marginBottom: "5px" }}>
                            By providing my mobile number, I agree to receive text messages from DAVO by Avalara
                            regarding my service.{" "}
                        </span>
                    )}
                    <TextField
                        name="email"
                        className="fs-exclude"
                        label="Email Address/Username"
                        value={username}
                        onChange={setUsername}
                        validate={validateEmail}
                        onEnterPress={onFirstFieldEnterPressed}
                    />
                    <TextField
                        name="password"
                        className="fs-exclude"
                        label="Password"
                        isPassword
                        value={password}
                        onChange={setPassword}
                        validate={validatePasswordOnCreate}
                        onEnterPress={onSubmitKeyPress}
                        inputRef={pwRef}
                    />
                </>
            )}
            <FormError message={message} />
            {params.get("errorState") && <Typography variant="subtitle2">{params.get("errorState")}&nbsp;</Typography>}
            {!disableInternalLogin && (
                <>
                    <Button
                        disabled={
                            isDisabled ||
                            !isEmailValid(username) ||
                            (areCreating && !fullname) ||
                            (areCreating && !isPhoneValid(phoneNumber)) ||
                            !isPasswordValid(password)
                        }
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={async (event) => {
                            event.preventDefault();
                            await authenticate();
                        }}>
                        {areCreating ? "Create account" : "Sign in"}
                    </Button>

                    {!areCreating && (
                        <Link style={{ marginTop: "20px", cursor: "pointer" }} onClick={() => setshowResetForm(true)}>
                            Reset password
                        </Link>
                    )}
                </>
            )}
            {!shouldCreateOnly && showCreate && (
                <Link
                    style={{ marginTop: "20px", cursor: "pointer" }}
                    onClick={() => {
                        setAreCreating(!areCreating);
                        setMessage(undefined);
                    }}>
                    {areCreating ? "Login to existing account" : "Create Account"}
                </Link>
            )}
            {showResetForm && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    onClose={() => setshowResetForm(false)}
                    style={{ boxShadow: "none", padding: "10px" }}>
                    <DialogTitle>Reset Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter your email address and we will send a reset link to your account.
                        </DialogContentText>
                        <TextField
                            className="fs-exclude"
                            label="Email Address"
                            value={username}
                            onChange={setUsername}
                            validate={validateEmail}
                            onEnterPress={onResetKeyPress}
                            isAutoFocus
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setshowResetForm(false)}
                            variant="outlined"
                            color="primary"
                            style={{ marginRight: "10px" }}>
                            Cancel
                        </Button>
                        <Button
                            disabled={!isEmailValid(username)}
                            onClick={doReset}
                            variant="contained"
                            color="primary">
                            Reset
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
