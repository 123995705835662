export const streetSuffixAbbreviations = new Map([
    ["ALLEE", "ALY"],
    ["ALLEY", "ALY"],
    ["ALLY", "ALY"],
    ["ANEX", "ANX"],
    ["ANNEX", "ANX"],
    ["ANNX", "ANX"],
    ["ARCADE", "ARC"],
    ["AV", "AVE"],
    ["AVEN", "AVE"],
    ["AVENU", "AVE"],
    ["AVENUE", "AVE"],
    ["AVN", "AVE"],
    ["AVNUE", "AVE"],
    ["BAYOO", "BYU"],
    ["BAYOU", "BYU"],
    ["BEACH", "BCH"],
    ["BEND", "BND"],
    ["BLUF", "BLF"],
    ["BLUFF", "BLF"],
    ["BLUFFS", "BLFS"],
    ["BOT", "BTM"],
    ["BOTTM", "BTM"],
    ["BOTTOM", "BTM"],
    ["BOUL", "BLVD"],
    ["BOULEVARD", "BLVD"],
    ["BOULV", "BLVD"],
    ["BRNCH", "BR"],
    ["BRANCH", "BR"],
    ["BRDGE", "BRG"],
    ["BRIDGE", "BRG"],
    ["BROOK", "BRK"],
    ["BROOKS", "BRKS"],
    ["BURG", "BG"],
    ["BURGS", "BGS"],
    ["BYPA", "BYP"],
    ["BYPAS", "BYP"],
    ["BYPASS", "BYP"],
    ["BYPS", "BYP"],
    ["CAMP", "CP"],
    ["CMP", "CP"],
    ["CANYN", "CYN"],
    ["CANYON", "CYN"],
    ["CNYN", "CYN"],
    ["CAPE", "CPE"],
    ["CAUSEWAY", "CSWY"],
    ["CAUSWA", "CSWY"],
    ["CEN", "CTR"],
    ["CENT", "CTR"],
    ["CENTER", "CTR"],
    ["CENTR", "CTR"],
    ["CENTRE", "CTR"],
    ["CNTER", "CTR"],
    ["CNTR", "CTR"],
    ["CENTERS", "CTRS"],
    ["CIRC", "CIR"],
    ["CIRCL", "CIR"],
    ["CIRCLE", "CIR"],
    ["CRCL", "CIR"],
    ["CRCLE", "CIR"],
    ["CIRCLES", "CIRS"],
    ["CLIFF", "CLF"],
    ["CLIFFS", "CLFS"],
    ["CLUB", "CLB"],
    ["COMMON", "CMN"],
    ["COMMONS", "CMNS"],
    ["CORNER", "COR"],
    ["CORNERS", "CORS"],
    ["COURSE", "CRSE"],
    ["COURT", "CT"],
    ["COURTS", "CTS"],
    ["COVE", "CV"],
    ["COVES", "CVS"],
    ["CREEK", "CRK"],
    ["CRESCENT", "CRES"],
    ["CRSENT", "CRES"],
    ["CRSNT", "CRES"],
    ["CREST", "CRST"],
    ["CROSSING", "XING"],
    ["CRSSNG", "XING"],
    ["CROSSROAD", "XRD"],
    ["CROSSROADS", "XRDS"],
    ["CURVE", "CURV"],
    ["DALE", "DL"],
    ["DAM", "DM"],
    ["DIV", "DV"],
    ["DIVIDE", "DV"],
    ["DVD", "DV"],
    ["DRIV", "DR"],
    ["DRIVE", "DR"],
    ["DRV", "DR"],
    ["DRIVES", "DRS"],
    ["ESTATE", "EST"],
    ["ESTATES", "ESTS"],
    ["EXP", "EXPY"],
    ["EXPR", "EXPY"],
    ["EXPRESS", "EXPY"],
    ["EXPRESSWAY", "EXPY"],
    ["EXPW", "EXPY"],
    ["EXTENSION", "EXT"],
    ["EXTN", "EXT"],
    ["EXTNSN", "EXT"],
    ["FALLS", "FLS"],
    ["FERRY", "FRY"],
    ["FRRY", "FRY"],
    ["FIELD", "FLD"],
    ["FIELDS", "FLDS"],
    ["FLAT", "FLT"],
    ["FLATS", "FLTS"],
    ["FORD", "FRD"],
    ["FORDS", "FRDS"],
    ["FOREST", "FRST"],
    ["FORESTS", "FRST"],
    ["FORG", "FRG"],
    ["FORGE", "FRG"],
    ["FORGES", "FRGS"],
    ["FORK", "FRK"],
    ["FORKS", "FRKS"],
    ["FORT", "FT"],
    ["FRT", "FT"],
    ["FREEWAY", "FWY"],
    ["FREEWY", "FWY"],
    ["FRWAY", "FWY"],
    ["FRWY", "FWY"],
    ["GARDEN", "GDN"],
    ["GARDN", "GDN"],
    ["GRDEN", "GDN"],
    ["GRDN", "GDN"],
    ["GARDENS", "GDNS"],
    ["GRDNS", "GDNS"],
    ["GATEWAY", "GTWY"],
    ["GATEWY", "GTWY"],
    ["GATWAY", "GTWY"],
    ["GTWAY", "GTWY"],
    ["GLEN", "GLN"],
    ["GLENS", "GLNS"],
    ["GREEN", "GRN"],
    ["GREENS", "GRNS"],
    ["GROV", "GRV"],
    ["GROVE", "GRV"],
    ["GROVES", "GRVS"],
    ["HARB", "HBR"],
    ["HARBOR", "HBR"],
    ["HARBR", "HBR"],
    ["HRBOR", "HBR"],
    ["HARBORS", "HBRS"],
    ["HAVEN", "HVN"],
    ["HT", "HTS"],
    ["HIGHWAY", "HWY"],
    ["HIGHWY", "HWY"],
    ["HIWAY", "HWY"],
    ["HIWY", "HWY"],
    ["HWAY", "HWY"],
    ["HILL", "HL"],
    ["HILLS", "HLS"],
    ["HLLW", "HOLW"],
    ["HOLLOW", "HOLW"],
    ["HOLLOWS", "HOLW"],
    ["HOLWS", "HOLW"],
    ["ISLAND", "IS"],
    ["ISLND", "IS"],
    ["ISLANDS", "ISS"],
    ["ISLNDS", "ISS"],
    ["ISLES", "ISLE"],
    ["JCTION", "JCT"],
    ["JCTN", "JCT"],
    ["JUNCTION", "JCT"],
    ["JUNCTN", "JCT"],
    ["JUNCTON", "JCT"],
    ["JCTNS", "JCTS"],
    ["JUNCTIONS", "JCTS"],
    ["KEY", "KY"],
    ["KEYS", "KYS"],
    ["KNOL", "KNL"],
    ["KNOLL", "KNL"],
    ["KNOLLS", "KNLS"],
    ["LAKE", "LK"],
    ["LAKES", "LKS"],
    ["LANDING", "LNDG"],
    ["LNDNG", "LNDG"],
    ["LANE", "LN"],
    ["LIGHT", "LGT"],
    ["LIGHTS", "LGTS"],
    ["LOAF", "LF"],
    ["LOCK", "LCK"],
    ["LOCKS", "LCKS"],
    ["LDGE", "LDG"],
    ["LODG", "LDG"],
    ["LODGE", "LDG"],
    ["LOOPS", "LOOP"],
    ["MANOR", "MNR"],
    ["MANORS", "MNRS"],
    ["MEADOW", "MDW"],
    ["MDW", "MDWS"],
    ["MEADOWS", "MDWS"],
    ["MEDOWS", "MDWS"],
    ["MILL", "ML"],
    ["MILLS", "MLS"],
    ["MISSN", "MSN"],
    ["MSSN", "MSN"],
    ["MOTORWAY", "MTWY"],
    ["MNT", "MT"],
    ["MOUNT", "MT"],
    ["MNTAIN", "MTN"],
    ["MNTN", "MTN"],
    ["MOUNTAIN", "MTN"],
    ["MOUNTIN", "MTN"],
    ["MTIN", "MTN"],
    ["MNTNS", "MTNS"],
    ["MOUNTAINS", "MTNS"],
    ["NECK", "NCK"],
    ["ORCHARD", "ORCH"],
    ["ORCHRD", "ORCH"],
    ["OVL", "OVAL"],
    ["OVERPASS", "OPAS"],
    ["PRK", "PARK"],
    ["PARKS", "PARK"],
    ["PARKWAY", "PKWY"],
    ["PARKWY", "PKWY"],
    ["PKWAY", "PKWY"],
    ["PKY", "PKWY"],
    ["PARKWAYS", "PKWY"],
    ["PKWYS", "PKWY"],
    ["PASSAGE", "PSGE"],
    ["PATHS", "PATH"],
    ["PIKES", "PIKE"],
    ["PINE", "PNE"],
    ["PINES", "PNES"],
    ["PLAIN", "PLN"],
    ["PLAINS", "PLNS"],
    ["PLAZA", "PLZ"],
    ["PLZA", "PLZ"],
    ["POINT", "PT"],
    ["POINTS", "PTS"],
    ["PORT", "PRT"],
    ["PORTS", "PRTS"],
    ["PRAIRIE", "PR"],
    ["PRR", "PR"],
    ["RAD", "RADL"],
    ["RADIAL", "RADL"],
    ["RADIEL", "RADL"],
    ["RANCH", "RNCH"],
    ["RANCHES", "RNCH"],
    ["RNCHS", "RNCH"],
    ["RAPID", "RPD"],
    ["RAPIDS", "RPDS"],
    ["REST", "RST"],
    ["RDGE", "RDG"],
    ["RIDGE", "RDG"],
    ["RIDGES", "RDGS"],
    ["RIVER", "RIV"],
    ["RVR", "RIV"],
    ["RIVR", "RIV"],
    ["ROAD", "RD"],
    ["ROADS", "RDS"],
    ["ROUTE", "RTE"],
    ["SHOAL", "SHL"],
    ["SHOALS", "SHLS"],
    ["SHOAR", "SHR"],
    ["SHORE", "SHR"],
    ["SHOARS", "SHRS"],
    ["SHORES", "SHRS"],
    ["SKYWAY", "SKWY"],
    ["SPNG", "SPG"],
    ["SPRING", "SPG"],
    ["SPRNG", "SPG"],
    ["SPNGS", "SPGS"],
    ["SPRINGS", "SPGS"],
    ["SPRNGS", "SPGS"],
    ["SPURS", "SPUR"],
    ["SQR", "SQ"],
    ["SQRE", "SQ"],
    ["SQU", "SQ"],
    ["SQUARE", "SQ"],
    ["SQRS", "SQS"],
    ["SQUARES", "SQS"],
    ["STATION", "STA"],
    ["STATN", "STA"],
    ["STN", "STA"],
    ["STRAV", "STRA"],
    ["STRAVEN", "STRA"],
    ["STRAVENUE", "STRA"],
    ["STRAVN", "STRA"],
    ["STRVN", "STRA"],
    ["STRVNUE", "STRA"],
    ["STREAM", "STRM"],
    ["STREME", "STRM"],
    ["STREET", "ST"],
    ["STRT", "ST"],
    ["STR", "ST"],
    ["STREETS", "STS"],
    ["SUMIT", "SMT"],
    ["SUMITT", "SMT"],
    ["SUMMIT", "SMT"],
    ["TERR", "TER"],
    ["TERRACE", "TER"],
    ["THROUGHWAY", "TRWY"],
    ["TRACE", "TRCE"],
    ["TRACES", "TRCE"],
    ["TRACK", "TRAK"],
    ["TRACKS", "TRAK"],
    ["TRK", "TRAK"],
    ["TRKS", "TRAK"],
    ["TRAFFICWAY", "TRFY"],
    ["TRAIL", "TRL"],
    ["TRAILS", "TRL"],
    ["TRLS", "TRL"],
    ["TRAILER", "TRLR"],
    ["TRLRS", "TRLR"],
    ["TUNEL", "TUNL"],
    ["TUNLS", "TUNL"],
    ["TUNNEL", "TUNL"],
    ["TUNNELS", "TUNL"],
    ["TUNNL", "TUNL"],
    ["TRNPK", "TPKE"],
    ["TURNPIKE", "TPKE"],
    ["TURNPK", "TPKE"],
    ["UNDERPASS", "UPAS"],
    ["UNION", "UN"],
    ["UNIONS", "UNS"],
    ["VALLEY", "VLY"],
    ["VALLY", "VLY"],
    ["VLLY", "VLY"],
    ["VALLEYS", "VLYS"],
    ["VDCT", "VIA"],
    ["VIADCT", "VIA"],
    ["VIADUCT", "VIA"],
    ["VIEW", "VW"],
    ["VIEWS", "VWS"],
    ["VILL", "VLG"],
    ["VILLAG", "VLG"],
    ["VILLAGE", "VLG"],
    ["VILLG", "VLG"],
    ["VILLIAGE", "VLG"],
    ["VILLAGES", "VLGS"],
    ["VILLE", "VL"],
    ["VIST", "VIS"],
    ["VISTA", "VIS"],
    ["VST", "VIS"],
    ["VSTA", "VIS"],
    ["WALKS", "WALK"],
    ["WY", "WAY"],
    ["WELL", "WL"],
    ["WELLS", "WLS"],
]);

export const secondaryUnitDesignators = new Map([
    ["APARTMENT", "APT"],
    ["BASEMENT", "BSMT"],
    ["BUILDING", "BLDG"],
    ["DEPARTMENT", "DEPT"],
    ["FLOOR", "FL"],
    ["FRONT", "FRNT"],
    ["HANGER", "HNGR"],
    ["LOBBY", "LBBY"],
    ["LOWER", "LOWR"],
    ["OFFICE", "OFC"],
    ["PENTHOUSE", "PH"],
    ["ROOM", "RM"],
    ["SPACE", "SPC"],
    ["SUITE", "STE"],
    ["TRAILER", "TRLR"],
    ["UPPER", "UPPR"],
]);

export const stateNames = new Map([
    ["ALASKA", "AK"],
    ["ALABAMA", "AL"],
    ["ARIZONA", "AZ"],
    ["ARKANSAS", "AR"],
    ["CALIFORNIA", "CA"],
    ["COLORADO", "CO"],
    ["CONNECTICUT", "CT"],
    ["DELAWARE", "DE"],
    ["DISTRICT OF COLUMBIA", "DC"],
    ["FLORIDA", "FL"],
    ["GEORGIA", "GA"],
    ["HAWAII", "HI"],
    ["IDAHO", "ID"],
    ["ILLINOIS", "IL"],
    ["INDIANA", "IN"],
    ["IOWA", "IA"],
    ["KANSAS", "KS"],
    ["KENTUCKY", "KY"],
    ["LOUISIANA", "LA"],
    ["MAINE", "ME"],
    ["MARYLAND", "MD"],
    ["MASSACHUSETTS", "MA"],
    ["MICHIGAN", "MI"],
    ["MINNESOTA", "MN"],
    ["MISSISSIPPI", "MS"],
    ["MISSOURI", "MO"],
    ["MONTANA", "MT"],
    ["NEBRASKA", "NE"],
    ["NEVADA", "NV"],
    ["NEW HAMPSHIRE", "NH"],
    ["NEW JERSEY", "NJ"],
    ["NEW MEXICO", "NM"],
    ["NEW YORK", "NY"],
    ["NORTH CAROLINA", "NC"],
    ["NORTH DAKOTA", "ND"],
    ["OHIO", "OH"],
    ["OKLAHOMA", "OK"],
    ["OREGON", "OR"],
    ["PENNSYLVANIA", "PA"],
    ["PUERTO RICO", "PR"],
    ["RHODE ISLAND", "RI"],
    ["SOUTH CAROLINA", "SC"],
    ["SOUTH DAKOTA", "SD"],
    ["TENNESSEE", "TN"],
    ["TEXAS", "TX"],
    ["UTAH", "UT"],
    ["VERMONT", "VT"],
    ["VIRGINIA", "VA"],
    ["WASHINGTON", "WA"],
    ["WEST VIRGINIA", "WV"],
    ["WISCONSIN", "WI"],
    ["WYOMING", "WY"],
]);
