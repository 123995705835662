import { DateTime } from "luxon";

export interface IMissingSetAside {
    locationName: string;
    locationId: string;
    isNew: boolean;
    outstandingAmount: number; // cents
    accountId: string;
    accountName: string;
    periodStart: DateTime;
    periodEnd: DateTime;
}
