import { FilingFrequency, ITaxProfileFormData } from "@davo/types";
import { Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import React, { FunctionComponent } from "react";
import { updateFilingForFrequencyChange, updateTaxProfile, updateTaxProfileCredentials } from "./services";
import { TaxProfileForm } from "./TaxProfileForm";
import { getCredentialsStatus } from "./util";

export interface ITaxProfileEdit {
    accountId: string;
    shouldLimitEdit?: boolean;
    target?: string[]; // taxProfileId passed generically from modal widget -- this is an array so we can pass more than one back, but we should not receive more than one
    closeDialog: (target?: string[]) => void;
}

export const TaxProfileEdit: FunctionComponent<ITaxProfileEdit> = ({
    accountId,
    shouldLimitEdit,
    target,
    closeDialog,
}) => {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const _shutdown = (result?: string[]) => {
        closeDialog(result);
    };
    const updateEditedTaxProfile = async (data: ITaxProfileFormData) => {
        const {
            name,
            legalName,
            state,
            stateTaxId,
            federalTaxId,
            frequency,
            reuseExistingCredentials, // eslint-disable-line @typescript-eslint/naming-convention,
            credentials,
            frequencyChangeAffectsCurrentPeriod, // eslint-disable-line @typescript-eslint/naming-convention
            oldFrequency,
            credentialsType,
            jurisdictionFilingTypeId,
            jurisdictionUIFlavorGroupId,
            filingMethod,
            additionalStateMetadata,
            additionalStateMetadata2,
            additionalStateMetadata3,
        } = data;
        const credentialsStatus = getCredentialsStatus(data);

        if (target) {
            if (target.length !== 1) {
                throw new Error("Cannot edit multiple targets!");
            }

            const taxProfileId = target[0];

            await updateTaxProfile(
                accountId,
                taxProfileId,
                {
                    name,
                    legalName,
                    state: state?.abbreviatedName,
                    stateTaxId,
                    federalTaxId,
                    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
                    frequency: frequency as FilingFrequency,
                    filingMethod,
                    credentialsFailing: credentialsStatus,
                    credentialsType,
                    jurisdictionFilingTypeId,
                    jurisdictionUIFlavorGroupId,
                    additionalStateMetadata,
                    additionalStateMetadata2,
                    additionalStateMetadata3,
                },
                frequencyChangeAffectsCurrentPeriod
            );

            if (!reuseExistingCredentials) {
                await updateTaxProfileCredentials(accountId, taxProfileId, credentials);
            }

            if (frequencyChangeAffectsCurrentPeriod && frequency && oldFrequency) {
                await updateFilingForFrequencyChange(false, accountId, taxProfileId, frequency, oldFrequency);
            }

            _shutdown([taxProfileId]);
        }
    };

    return (
        <Dialog
            fullScreen={makeFullScreen}
            open={true}
            aria-labelledby="responsive-dialog-title"
            onClose={(event, reason) => {
                if (!["backdropClick", "escapeKeyDown"].includes(reason)) {
                    _shutdown();
                }
            }}>
            <DialogTitle variant={"h5"} id="responsive-dialog-title">
                <strong>Automated Tax Filing</strong>
            </DialogTitle>
            <DialogContent data-testid={"dialogContent"}>
                <TaxProfileForm
                    accountId={accountId}
                    target={target ? target[0] : undefined} // awkward construction since we can send more than one back, but only edit one on the way in
                    onSubmit={updateEditedTaxProfile}
                    onCancel={() => _shutdown()}
                    showCancel={true}
                    shouldLimitEdit={shouldLimitEdit}
                />
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    );
};
