import { FormError, getPublicJurisdictions, Select, TextField } from "@davo/portal-common";
import {
    IJurisdiction,
    isEmailValid,
    isPhoneValid,
    IStorageMarketingInfo,
    validateEmail,
    validateNotNull,
    validatePhone,
    validateZip,
} from "@davo/types";
import InfoIcon from "@mui/icons-material/Info";
import { Button, Theme, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { useConfigMerchantContext } from "../context";
import { testUSeriesCredentials } from "../services/boarding";
import { BoardingContainer, sharedButtonContainer } from "./BoardingContainer";
import { LightspeedUPosInfo } from "./types/PosInfoTypes";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
    ...sharedButtonContainer,
}));

export function LightspeedUSeriesBoarding() {
    const classes = useStyles();
    const [username, setUsername] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [status, setStatus] = useState<"initial" | "complete">("initial");
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [bname, setBname] = useState<string | undefined>(undefined);
    const [address1, setAddress1] = useState<string | undefined>(undefined);
    const [phone, setPhone] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [city, setCity] = useState<string | undefined>(undefined);
    const [state, setState] = useState<string | undefined>(undefined);
    const [states, setStates] = useState<IJurisdiction[]>([]);
    const [zip, setZip] = useState<string | undefined>(undefined);
    const { merchantConfigInfo: configInfo } = useConfigMerchantContext();
    const navigate = useNavigate();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    useAsyncEffect(async () => {
        setStates(await getPublicJurisdictions());
        const storageMarketingInfo: IStorageMarketingInfo = JSON.parse(localStorage.getItem("marketingInfo") ?? "{}");
        setPhone(storageMarketingInfo.phone);
        setEmail(storageMarketingInfo.email);
    }, []);

    const doValidate = async () => {
        if (isBusy) {
            return;
        }

        if (username && password) {
            setIsBusy(true);
            const result = await testUSeriesCredentials(username, password);
            setIsBusy(false);
            if (result.success.valid === true) {
                const posData: LightspeedUPosInfo = {
                    username,
                    password,
                    title: bname,
                    phone,
                    email,
                    address1,
                    city,
                    state,
                    zip,
                };
                navigate(configInfo.lightspeedUSeriesInvitationURL, {
                    state: posData,
                });
            } else {
                setMessage("Invalid LS U-Series username or password.");
            }
        }
    };

    const doNext = () => {
        if (bname && address1 && city && state && zip && email && phone && isEmailValid(email) && isPhoneValid(phone)) {
            setMessage("");
            setStatus("complete");
        } else {
            setMessage("Please fill out all fields.");
        }
    };

    return (
        <BoardingContainer posType={"lightspeed-u-series"}>
            {status === "complete" && (
                <div data-testid={"completeStepContainer"}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <p>Please enter your Lightspeed U-Series API credentials:</p>
                        <Tooltip
                            title={
                                <div style={{ padding: "10px" }}>
                                    For information on how to find these details, check out the{" "}
                                    <a
                                        href="https://help.upserve.com/s/article/Set-Up-An-API-Connection-with-an-Integration-Partner"
                                        style={{ color: "#c5d5ff" }}>
                                        Lightspeed U-Series Knowledge Center
                                    </a>
                                </div>
                            }
                            placement="left">
                            <InfoIcon
                                fontSize={"medium"}
                                color="primary"
                                style={{ verticalAlign: "middle", margin: "13px 5px", paddingBottom: "20px" }}
                            />
                        </Tooltip>
                    </div>
                    <TextField
                        label="API Username"
                        value={username ?? ""}
                        onChange={setUsername}
                        isRequired
                        margin={"dense"}
                        validate={validateNotNull}
                        isDisabled={isBusy}
                        inputProps={{
                            [`data-testid`]: "apiUsernameInput",
                        }}
                    />
                    <TextField
                        label="API Password"
                        isPassword
                        value={password ?? ""}
                        onChange={setPassword}
                        onEnterPress={doValidate}
                        isRequired
                        margin={"dense"}
                        validate={validateNotNull}
                        isDisabled={isBusy}
                        inputProps={{
                            [`data-testid`]: "apiPasswordInput",
                        }}
                    />
                    {message && <FormError message={message} />}
                    <div className={classes.submitContainer}>
                        <Button
                            type="submit"
                            data-testid={"submitBtnComplete"}
                            disabled={!username || !password || isBusy}
                            variant="contained"
                            color="primary"
                            onClick={doValidate}
                            style={{ margin: "20px" }}
                            startIcon={
                                isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                            }>
                            Connect with Lightspeed U-Series
                        </Button>
                    </div>
                </div>
            )}
            {status === "initial" && (
                <div data-testid={"initialStepContainer"}>
                    <div style={{ paddingBottom: "20px" }}>Tell us a little about your business:</div>
                    <TextField
                        label="Business Name"
                        value={bname ?? ""}
                        onChange={setBname}
                        onEnterPress={doNext}
                        margin={"dense"}
                        inputProps={{
                            [`data-testid`]: "nameInput",
                        }}
                        isRequired
                        validate={validateNotNull}
                    />
                    <TextField
                        label="Phone"
                        validate={(v) => (v ? validatePhone(v) : validateNotNull(null))}
                        isRequired
                        value={phone ?? ""}
                        onChange={setPhone}
                        onEnterPress={doNext}
                        margin={"dense"}
                        inputProps={{
                            [`data-testid`]: "phoneInput",
                        }}
                    />
                    <TextField
                        label="Email"
                        validate={(v) => (v ? validateEmail(v) : validateNotNull(null))}
                        isRequired
                        value={email ?? ""}
                        onChange={setEmail}
                        onEnterPress={doNext}
                        margin={"dense"}
                        inputProps={{
                            [`data-testid`]: "emailInput",
                        }}
                    />
                    <TextField
                        label="Address"
                        value={address1 ?? ""}
                        onChange={setAddress1}
                        onEnterPress={doNext}
                        margin={"dense"}
                        isRequired
                        validate={validateNotNull}
                        inputProps={{
                            [`data-testid`]: "addressInput",
                        }}
                    />
                    <TextField
                        label="City"
                        value={city ?? ""}
                        onChange={setCity}
                        onEnterPress={doNext}
                        margin={"dense"}
                        isRequired
                        validate={validateNotNull}
                        inputProps={{
                            [`data-testid`]: "cityInput",
                        }}
                    />
                    <Select<IJurisdiction>
                        data-testid={"stateSelector"}
                        isRequired
                        validate={(v) => (v ? undefined : validateNotNull(null))}
                        title="State"
                        margin={"dense"}
                        options={states}
                        value={states.find((ba: IJurisdiction) => ba.abbreviatedName === state)}
                        label={(jurisdiction) => jurisdiction.fullName}
                        onChange={(val) => {
                            if (!val) {
                                return;
                            }
                            setState(val.abbreviatedName);
                        }}
                    />
                    <TextField
                        label="ZIP"
                        validate={(v) => (v ? validateZip(v) : validateNotNull(null))}
                        value={zip ?? ""}
                        onChange={setZip}
                        onEnterPress={doNext}
                        margin={"dense"}
                        isRequired
                        inputProps={{
                            [`data-testid`]: "zipInput",
                        }}
                    />
                    {message && <FormError message={message} />}
                    <div className={classes.submitContainer}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={doNext}
                            data-testid={"submitBtnInitial"}
                            disabled={!bname || !address1 || !city || !state || !zip || !email || !phone}
                            style={{ margin: "20px" }}>
                            Continue with Lightspeed U-Series
                        </Button>
                    </div>
                </div>
            )}
        </BoardingContainer>
    );
}
