import { NUMBER, ResolveObject, STRING } from "./MetaType";

export const MetaPartnerDashboardSubscriptionSummary = {
    totalSubs: NUMBER(),
    todaySubs: NUMBER(),
};

export type DashboardPartnerSubscriptionSummary = ResolveObject<typeof MetaPartnerDashboardSubscriptionSummary>;

export const MetaPartnerDashboardFilingsTotal = {
    filingDueDate: STRING<string>(),
    unfiled: NUMBER(),
    filed: NUMBER(),
};

export type DashboardPartnerFilingsTotal = ResolveObject<typeof MetaPartnerDashboardFilingsTotal>;

export const MetaPartnerDashboardUnFilingsByDueDate = {
    filingDueDate: STRING<string>(),
    unfiled: NUMBER(),
    jurisdiction: STRING<string>(),
};

export type DashboardPartnerUnFilingsByDueDate = ResolveObject<typeof MetaPartnerDashboardUnFilingsByDueDate>;
