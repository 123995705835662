import { BusinessDay } from "@davo/types";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";

export interface IBusinessDayPickerType {
    label: string;
    value: BusinessDay;
    onChange: (value: BusinessDay) => void;
    minDate?: BusinessDay;
    maxDate?: BusinessDay;
    isDisabled?: boolean;
    hasNoMargin?: boolean;
    isFullWidth?: boolean;
}

export function BusinessDayPicker({
    label,
    value,
    onChange,
    minDate,
    maxDate,
    isDisabled,
    hasNoMargin,
    isFullWidth,
}: IBusinessDayPickerType) {
    const [date, setDate] = useState<DateTime | undefined>(value ? value.toDateTime() : undefined);

    useEffect(() => {
        setDate(value.toDateTime());
    }, [value]);

    const getSX = () => {
        return isFullWidth ? { width: "100%" } : {};
    };

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                    label={label}
                    value={date?.toJSDate()}
                    closeOnSelect={true}
                    onChange={(newDate) => {
                        // Thu May 12 2022 00:00:00 GMT-0400 (Eastern Daylight Time)
                        if (newDate) {
                            // setDate will be called by the owner re-setting the value
                            onChange(BusinessDay.fromDateTime(DateTime.fromJSDate(new Date(String(newDate)))));
                        }
                    }}
                    minDate={minDate?.toDateTime().toJSDate()}
                    maxDate={maxDate?.toDateTime().toJSDate()}
                    disabled={isDisabled}
                    slotProps={{
                        textField: { style: { margin: hasNoMargin ? "0px" : "10px" } },
                        toolbar: { hidden: true },
                    }}
                    sx={getSX()}
                />
            </LocalizationProvider>
        </div>
    );
}
