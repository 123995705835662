import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";

interface IConfirmationModalType {
    message: any;
    closeDialog: (target?: any) => void;
    target?: any;
    title?: string;
    showContinue?: boolean;
    continueLabel?: string;
    showCancel?: boolean;
    cancelLabel?: string;
    zIndex?: number;
}

export const defaultModalContinueButtonText = "Continue";
export const defaultModalCancelButtonText = "Cancel";
export const defaultModalTitleText = "Confirmation";

export function ConfirmationModal({
    message,
    title,
    showContinue = true,
    continueLabel,
    showCancel = true,
    cancelLabel,
    closeDialog,
    target,
    zIndex,
}: IConfirmationModalType) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [canDisableContinue, setCanDisableContinue] = useState<boolean>(false);

    const doContinue = () => {
        setCanDisableContinue(true);
        closeDialog(target);
        setCanDisableContinue(false);
    };

    const doCancel = () => {
        // NOTE: this is still calling the handler function (see useModal.ts and useModalEditor) on cancel, but doesn't
        // provide the target parameter which you can use to determine if we're canceling or continuing from this modal.
        setCanDisableContinue(false);
        closeDialog();
    };

    if (!continueLabel) {
        continueLabel = defaultModalContinueButtonText;
    }

    if (!cancelLabel) {
        cancelLabel = defaultModalCancelButtonText;
    }

    if (!title) {
        title = defaultModalTitleText;
    }

    return (
        <Dialog
            fullScreen={makeFullScreen}
            open={true}
            aria-labelledby="responsive-dialog-title"
            style={{ boxShadow: "none", zIndex }}
            onClose={doCancel}>
            <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                {showCancel && (
                    <Button
                        data-testid={"confirmationModalCancelBtn"}
                        onClick={doCancel}
                        variant="outlined"
                        color="primary">
                        {cancelLabel}
                    </Button>
                )}

                {showContinue && (
                    <Button
                        data-testid={"confirmationModalConfirmBtn"}
                        onClick={doContinue}
                        variant="contained"
                        color="secondary"
                        autoFocus
                        disabled={canDisableContinue}>
                        {continueLabel}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
