import { User } from "@davo/types";
import React from "react";

export interface ILoginContext {
    user: User | undefined;
    setUser: React.Dispatch<User | undefined>;
    permissions: string[] | undefined;
    firebaseToken?: string;
    setFirebaseToken?: React.Dispatch<string | undefined>;
}

export const LoginContextDefaultValue = {} as ILoginContext;
export const LoginContext = React.createContext(LoginContextDefaultValue);
export const useLoginContext = () => React.useContext(LoginContext);
