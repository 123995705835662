import {
    AccountRecord,
    Filing,
    FilingDetails,
    FilingWithAttachmentHistoryAndLocations,
    FilingWithAttachments,
    fromJSON,
    IConnectionStatus,
    IFilingLocation,
    IFilingOptionsFilters,
    IFilingWithAttachmentsAndLocations,
    MerchantDailyDetail,
    MetaAccountRecord,
    TaxProfileRecord,
    toJSON,
    toPartial,
} from "@davo/types";
import isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";
import { get, post, put } from "./api";

export interface IModifiedFiling extends Omit<Filing, "periodStart" | "periodEnd"> {
    periodStart: string;
    periodEnd: string;
}

export interface ITaxAccountAndFilingType extends Omit<TaxProfileRecord, "created">, AccountRecord, FilingDetails {
    filing: IModifiedFiling;
}

export const getAccount = async (accountId: string): Promise<AccountRecord> => {
    return fromJSON(MetaAccountRecord, await get(`api/common/account/${accountId}/`, true));
};

export const updateAccount = async (accountId: string, accountUpdate: Partial<AccountRecord>) => {
    await put(`api/common/account/${accountId}`, toJSON(toPartial(MetaAccountRecord), accountUpdate), true);
};

export const accountHasAnyActivity = async (accountId: string) => {
    const result = await get(`api/common/account/${accountId}/has-activity`, true);
    return result.hasActivity;
};

export async function getMerchantDailyDetail(accountId: string, yearMonth: string): Promise<MerchantDailyDetail[]> {
    return get<MerchantDailyDetail[]>(`api/common/account/${accountId}/detail/month/${yearMonth}`, true);
}

export async function getStartDateForMerchantDailyDetail(accountId: string): Promise<DateTime> {
    return get<DateTime>(`api/common/account/${accountId}/first-activity`, true);
}

// Return list of Filings or FilingHistorical's with a status of 'filed'
export async function postGetFiledFilingsForAccount(
    account: AccountRecord,
    filters?: IFilingOptionsFilters,
    abortController?: AbortController
) {
    const result = await post<IFilingWithAttachmentsAndLocations>(
        `api/common/account/${account.id}/filed-filings`,
        {
            taxProfileId: filters?.taxProfile,
            state: filters?.state,
            periodEnd: filters?.periodEnd,
        },
        true,
        abortController
    );
    const addHistory = (
        isHistorical: boolean,
        filing: FilingWithAttachments,
        locations: (IFilingLocation & { name: string })[]
    ): FilingWithAttachmentHistoryAndLocations => {
        return { ...filing, historical: isHistorical, locations };
    };

    const filingsWithHistoricalAndLocations: FilingWithAttachmentHistoryAndLocations[] = [];

    if (!isEmpty(result.filings)) {
        for (const filing of result.filings) {
            filingsWithHistoricalAndLocations.push(addHistory(false, filing, filing.locations));
        }
    }
    if (!isEmpty(result.filingsHistorical)) {
        for (const filingHistorical of result.filingsHistorical) {
            filingsWithHistoricalAndLocations.push(addHistory(true, filingHistorical, []));
        }
    }
    return filingsWithHistoricalAndLocations;
}

export async function getOpenFilingsForAccount(accountId: string): Promise<ITaxAccountAndFilingType[]> {
    return get<ITaxAccountAndFilingType[]>(`api/common/account/${accountId}/open-filings`, true);
}

export async function getDisconnectedLocationsWithRecentActivity(accountId: string) {
    return get<string[]>(`api/common/account/${accountId}/disconnected-locations-with-activity`, true);
}

export async function getAwaitingDisconnectLocationsWithRecentActivity(accountId: string) {
    return get<string[]>(`api/common/account/${accountId}/awaiting-disconnect-locations-with-activity`, true);
}

export const getConnectionStatusForAccount = async (accountId: string) => {
    return get<IConnectionStatus>(`api/common/account/${accountId}/connection-status`, true);
};
