import { LocationRecord } from "./Account";
import { User } from "./User";

export interface IRevelAuthInfo {
    establishment_name: string;
    url: string;
    api_secret: string;
    api_key: string;
    day_start: string;
    establishment_id: number;
    timezone: string;
}

export interface IEposWebhook {
    AppId: number;
    ExternalConfig: string;
    CompanyGuid: string;
    CompanyName: string;
    LocationId: number;
    LocationName: string;
    Email: string;
    Region: string;
    Currency: string;
    Token: string;
}

export interface IShift4Webhook {
    event: {
        name: string;
        component: string;
        resource: string;
        action: "cancelled" | "deleted" | "created";
        version: string;
        subscriptionId: number;
        dispatchedAt: string;
    };
    payload: {
        locationId: number;
        guid: string;
    };
}

export interface IOnboardingResult {
    invitationId?: null | string;
    demo: boolean;
    email?: null | string;
    onboarded: IOnboardedType[];
    error?: null | string;
    isPreAccount: boolean;
    isReconnect: boolean;
    loginUser?: User;
}

export interface IOnboardedType {
    location: LocationRecord;
    isNew: boolean;
    isReconnect: boolean;
}

export interface IStorageMarketingInfo {
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
}
