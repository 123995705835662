import { FilingFrequency } from "./FilingFrequencyInfo";
import { BOOLEAN, BUSINESS_DAY, NUMBER, OBJECT, ResolveObject, STRING } from "./MetaType";

export const MetaLedgerReportLine = {
    account: STRING(),
    balance: NUMBER(),
};

export const MetaTaxPeriod = {
    due: BUSINESS_DAY(),
    start: BUSINESS_DAY(),
    end: BUSINESS_DAY(),
    prepayment: BOOLEAN(),
};

export type TaxPeriod = ResolveObject<typeof MetaTaxPeriod>;

export type LedgerReportLine = ResolveObject<typeof MetaLedgerReportLine>;

export type LedgerReport = LedgerReportLine[];

export const MetaPeriodReport = {
    period: OBJECT(MetaTaxPeriod),
    report: OBJECT(MetaLedgerReportLine).array(),
    frequency: STRING<FilingFrequency>(),
};

export type PeriodReport = ResolveObject<typeof MetaPeriodReport>;
