import { LocationRecord } from "@davo/types";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { AddressVerificationForm } from "./AddressVerificationForm";
import { useAddressVerificationContext } from "./context";
import { PaperComponent } from "./PaperComponent";
import { updateLocationAddress } from "./services";
import { d30Toast, d30ToastError } from "./Toast";

export interface IAddressVerificationModalProps {
    title: string;
    accountId: string;
    location: LocationRecord;
    showCancel?: boolean;
    closeDialog: (target?: string) => void;
}

export const AddressVerificationModal: FunctionComponent<IAddressVerificationModalProps> = ({
    title,
    accountId,
    location,
    showCancel = false,
    closeDialog,
}) => {
    const [isVerifyingAddress, setIsVerifyingAddress] = useState<{
        verifying: boolean;
        updateType: "existing" | "suggested" | undefined;
    }>({ verifying: false, updateType: undefined });
    const { launchEditAddressModal, avataxValidatedAddress } = useAddressVerificationContext();

    const _updateAddress = (updateType: "existing" | "suggested", loc: LocationRecord) => {
        setIsVerifyingAddress({ verifying: true, updateType });
        updateLocationAddress(accountId, loc.id, loc, true)
            .then(() => {
                d30Toast("Successfully updated address!");
                closeDialog();
            })
            .catch((e) => d30ToastError("There was a problem verifying the address.", e));
    };

    if (!avataxValidatedAddress) {
        return null;
    }

    return (
        <Dialog
            data-testid={"addressVerificationModal"}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            fullScreen={false}
            open={true}
            onClose={(event, reason) => {
                if (!["backdropClick", "escapeKeyDown"].includes(reason)) {
                    closeDialog();
                }
            }}>
            <DialogTitle id="draggable-dialog-title">
                <span data-testid={"addressVerificationModalDialogTitle"} style={{ alignSelf: "center" }}>
                    {title}
                </span>
            </DialogTitle>
            <DialogContent>
                <AddressVerificationForm
                    isVerifyingAddress={isVerifyingAddress}
                    location={location}
                    verifiedAddress={avataxValidatedAddress}
                    showCancel={showCancel}
                    onSubmitUseAddress={_updateAddress}
                    onSubmitEditAddress={launchEditAddressModal}
                    onCancel={() => closeDialog()}
                />
            </DialogContent>
        </Dialog>
    );
};
