import { BusinessDay, IManualActivity } from "@davo/types";
import { post } from "./api";

export const getAdditionalSalesManualActivityWithSetAsideStatus = async (
    locationIds: string[],
    periodStart?: BusinessDay,
    periodEnd?: BusinessDay
) => {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    return post<(IManualActivity & { manualSetAsideStatus: string | undefined })[]>(
        `api/common/account/additional-sales-manual-activity`,
        {
            locationIds,
            periodStart,
            periodEnd,
        },
        true
    );
};

export const addAdditionalSalesManualActivity = async (
    locationId: string,
    periodDay: BusinessDay,
    isChecked?: boolean,
    sales?: number,
    tax?: number,
    rate?: number,
    note?: string,
    taxName?: string
) => {
    await post(
        `api/common/account/additional-sales-manual-activity/${locationId}`,
        {
            periodDay: periodDay.toSQLDate(),
            checked: isChecked,
            sales,
            tax,
            rate,
            note,
            taxName,
        },
        true
    );
};
