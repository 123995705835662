import { RenderAddress, useAddressVerificationContext } from "@davo/portal-common";
import { Button } from "@mui/material";
import React from "react";
import { useLocationContext } from "../context";

export function LocationInfo() {
    const { location } = useLocationContext();
    const { launchEditAddressModal } = useAddressVerificationContext();

    if (!location) {
        return null;
    }

    return (
        <div data-testid={"locationInfo"}>
            <RenderAddress
                address1={location.address1}
                address2={location.address2}
                city={location.city}
                state={location.state}
                zip={location.zip}
            />
            <Button
                data-testid={"locationEditButton"}
                variant="outlined"
                color="primary"
                style={{ padding: "2px" }}
                onClick={() => launchEditAddressModal()}>
                Edit
            </Button>
        </div>
    );
}
