import { Header, POSLogo, ReactTable } from "@davo/portal-common";
import { LocationRecord } from "@davo/types";
import { Button } from "@mui/material";
import orderBy from "lodash/orderBy";
import uniq from "lodash/uniq";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MerchantConnectionStatus } from "./components";
import { ActionsRequiredMessage } from "./components/ActionsRequiredMessage";
import { useAccountContext } from "./context";
import { EditCompanyDetailsButton } from "./EditCompanyDetailsButton";

export function Company() {
    const navigate = useNavigate();
    const accountContext = useAccountContext();
    const [areActionsRequired, setAreActionsRequired] = useState<boolean>(false);

    // NOTE: extract /:accountId/settings/company and remove suffix
    const url = useLocation().pathname.split("/").slice(0, 4).join("/");

    useEffect(() => {
        setAreActionsRequired(false);
        if (accountContext.locationsNotFullyConnected && accountContext.locationsNotFullyConnected.size > 0) {
            setAreActionsRequired(true);
        }
    }, [accountContext.locationsNotFullyConnected]);

    if (
        !accountContext.account ||
        !accountContext.locations ||
        (accountContext.locations && !accountContext.locations.filter((l) => l.active))
    ) {
        return null;
    }

    const renderStatus = (data: any) => {
        return <MerchantConnectionStatus locationId={data.cell.row.original.id} />;
    };

    return (
        <>
            <Header>
                <span style={{ display: "inline", fontSize: "20px", marginBottom: "10px" }}>Company Details</span>
                <span style={{ margin: "0 14px" }}>|</span>
                {`${accountContext.account ? accountContext.account.name : ""}`}
                {uniq(accountContext.locations.map((i: LocationRecord) => i.posType)).length === 1 && (
                    <POSLogo
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                        posType={
                            uniq(
                                accountContext.locations.filter((l) => l.active).map((i: LocationRecord) => i.posType)
                            )[0]
                        }
                        width={125}
                        shouldFloatRight={true}
                    />
                )}
            </Header>

            <div style={{ paddingBottom: "15px" }}>
                <EditCompanyDetailsButton
                    callback={async () => {
                        await accountContext.refresh();
                    }}
                />
            </div>

            <ActionsRequiredMessage showBanner={areActionsRequired} />

            <div className="fs-exclude" style={{ paddingTop: "20px" }}>
                <Header>Locations</Header>
                <ReactTable
                    columns={[
                        { Header: "Name", accessor: "name" },
                        { Header: "Status", accessor: "id", id: "status", Cell: renderStatus },
                        { Header: "City", accessor: "city" },
                        { Header: "State", accessor: "state" },
                        {
                            Header: "",
                            id: "view",
                            accessor: "id",
                            Cell: (data: any) => (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => navigate(`${url}/${data.value}`)}>
                                    View
                                </Button>
                            ),
                            disableSortBy: true,
                        },
                    ]}
                    data={orderBy(
                        accountContext.locations.filter((x) => x.active),
                        "name"
                    )}
                    options={{
                        hidePagination: accountContext.locations.length <= 100,
                        pageSize: 100,
                    }}
                />
            </div>
        </>
    );
}
