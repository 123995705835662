import { BOOLEAN, BUSINESS_DAY, DATETIME, ResolveObject, STRING, UUID } from "./MetaType";

export const MetaAmendmentOpsPaneRecord = {
    filingId: UUID(),
    amendmentDate: DATETIME(),
    amendmentStatus: STRING(),
    amendmentReason: STRING().optional(),
    amendmentNote: STRING(),
    accountId: UUID(),
    accountName: STRING(),
    jurisdiction: STRING(),
    filingPeriodEnd: BUSINESS_DAY(),
    assignedUserId: UUID().optional(),
    davoError: BOOLEAN(),
    hasNotice: BOOLEAN(),
    amendmentAdditionalNote: STRING().optional(),
    amendmentAdditionalNoteBy: STRING().optional(),
    amendmentAdditionalNoteDate: DATETIME().optional(),
};
export type AmendmentOpsPaneRecord = ResolveObject<typeof MetaAmendmentOpsPaneRecord>;

export type AmendmentReason =
    | "frequencyChange"
    | "incompleteSalesData"
    | "overReported"
    | "underReported"
    | "filedInError";
export type AmendmentStatus = "required" | "completed";

export const AmendmentReasons = {
    frequencyChange: "Frequency Change",
    incompleteSalesData: "Incomplete Sales Data",
    overReported: "Over-reported",
    underReported: "Under-reported",
    filedInError: "Filed In Error (Other)",
} as { [key: string]: string };

export const AmendmentStatuses = {
    required: "Required",
    completed: "Completed",
} as { [key: string]: string };

export const AmendmentReasonKeys = [...(Object.keys(AmendmentReasons) as AmendmentReason[])];
export const AmendmentStatusKeys = [...(Object.keys(AmendmentStatuses) as AmendmentStatus[])];

export const isAmendmentReasonFiledInError = (value?: AmendmentReason | null): boolean => {
    return value === "filedInError";
};
