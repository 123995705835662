import { noop } from "@davo/types";
import { Icon, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface INavCommon {
    name: string;
    slug: string;
    icon?: string;
    badge?: string;
}

export interface INavItem extends INavCommon {
    destination?: string;
    onClick?: () => void;
}

export const NavItem = ({ name, slug, icon, badge, destination, onClick = noop }: INavItem) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isSelected = destination && location.pathname.includes(destination);

    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";

    const clickHandler = () => {
        if (destination) {
            navigate(destination);
        }
        onClick();
    };

    return (
        <ListItemButton
            key={`nav-item-li-${slug}`}
            style={{
                borderLeft: `2px solid ${isSelected ? "#4d7cfe" : isDarkMode ? "#FFFFFF" : "#778ca2"}`,
                paddingRight: "7px",
            }}
            onClick={clickHandler}>
            {icon && (
                <ListItemIcon>
                    <Icon style={{ display: "flex", justifyContent: "center" }}>
                        <img alt="icon" src={icon} style={{ display: "flex", alignContent: "center" }} />
                    </Icon>
                </ListItemIcon>
            )}
            <ListItemText
                primary={name}
                style={{ color: isSelected ? "#4d7cfe" : "" }}
                aria-current={isSelected ? "page" : undefined}
            />
            {badge && (
                <div
                    style={{
                        color: "#FFFFFF",
                        backgroundColor: "#FF0000",
                        borderRadius: "500px",
                        padding: "2px 10px",
                        marginLeft: "10px",
                        textAlign: "center",
                    }}>
                    {badge}
                </div>
            )}
        </ListItemButton>
    );
};
