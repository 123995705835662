import { d30Toast, Header, TextField, useLoginContext } from "@davo/portal-common";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import referAFriendImage from "./resources/davo_illustrations_refer_friend.png";

function getReferralUrl(code: string) {
    return `https://www.davosalestax.com/integrations/?refSource=friend&refId=${code}`;
}

function getReferralExpiration() {
    return "December 31, 2023";
}

export function UserReferral() {
    const loginContext = useLoginContext();

    if (!loginContext.user?.referralCode) {
        return <>This should not be showing...</>;
    }
    const link = getReferralUrl(loginContext.user.referralCode);

    return (
        <div>
            <Welcome />
            <br />
            <UserReferralDetails link={link} />
        </div>
    );
}

function UserReferralDetails({ link }: { link: string }) {
    return (
        <>
            {link && (
                <div style={{ textAlign: "center", width: "70%", margin: "auto" }}>
                    <CopyToClipboard text={link} onCopy={() => d30Toast("Copied")}>
                        <div>
                            <TextField label="" value={link} inputProps={{ readOnly: true }} />
                            <FileCopyTwoToneIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} />
                        </div>
                    </CopyToClipboard>
                </div>
            )}

            <br />
            <br />

            <h2 style={{ textAlign: "center" }}>
                <strong>How it works</strong>:
            </h2>
            <ul>
                <li>Copy URL above using the COPY button</li>
                <li>
                    Paste the URL in an email to the friend you are referring. Ask them to use the URL when they sign
                    up. This is the best way to make sure your referral is tracked.
                </li>
                <li>30 days after your friend signs up, DAVO will email you a $50 Amazon gift card</li>
                <li>Everyone wins!</li>
            </ul>
            <br />

            <div style={{ padding: "20px", backgroundColor: "#f4f4f4" }}>
                <h2 style={{ textAlign: "center", margin: "0px" }}>Referral Program Terms:</h2>
                <p>
                    Get rewarded for spreading the word about DAVO by Avalara! Refer a friend and receive a $50 Amazon
                    gift card for every location that signs up for DAVO by Avalara by {getReferralExpiration()},
                    completes the trial period, and makes its first payment for the service. Please review the terms
                    below before submitting a referral.
                </p>
                <h3>
                    <strong>Offer Timeline</strong>
                </h3>
                <p>
                    This offer is valid for referrals submitted between February 1, 2022 and {getReferralExpiration()}.
                </p>
                <h3>
                    <strong>Referral Requirements</strong>
                </h3>
                <p>
                    Subject to these Terms you will receive the $50 Amazon Gift Card for each “Qualified Lead Referral”.
                    A “Qualified Lead Referral” is a merchant that:
                </p>
                <ul>
                    <li>
                        is referred to DAVO by Avalara through your unique referral URL generated above and which
                        referral can be directly traced back to the unique URL. Verbal referrals are unable to be
                        verified and will not be eligible;
                    </li>
                    <li>
                        is not an existing lead or customer in the DAVO by Avalara system. DAVO by Avalara reserves the
                        right in its sole discretion to determine the original lead source. Exceptions may be made, at
                        DAVO by Avalara’s sole discretion, if the referral has not been contacted by DAVO by Avalara for
                        at least 6 months, and the lead otherwise meets the requirements of a Qualified Lead Referral.
                    </li>
                    <li>
                        signs up for the DAVO by Avalara service by {getReferralExpiration()}, completes the trial
                        period, and makes its first subscription payment for the DAVO by Avalara service.
                    </li>
                </ul>
                <h3>
                    <strong>Modifications</strong>
                </h3>
                <p>
                    DAVO reserves the right to modify these Terms or terminate the Program at any time, in its sole
                    discretion, without notice, however we will employ commercially reasonable efforts to post a notice
                    on the referral website.
                </p>
                <h3>
                    <strong>Questions?</strong>
                </h3>
                <p>
                    If you have any questions about the Refer a Friend offer, please{" "}
                    <a href="https://www.davosalestax.com/contact/">contact us</a>.
                </p>
            </div>
        </>
    );
}

function Welcome() {
    return (
        <>
            <Header>Refer a Friend to DAVO and get paid!</Header>

            <div style={{ float: "right" }}>
                <img src={referAFriendImage} style={{ width: "300px", padding: "10px" }} alt={"Referrals"} />
            </div>

            <h3>
                <span style={{ color: "#03bb9c" }}>Do you love DAVO?</span>
            </h3>
            <h4>
                <span style={{ color: "#1a2f5b" }}>
                    Want to help your friends and colleagues take sales tax off their plate?
                </span>
            </h4>
            <p>We know you love DAVO. Now you can make some money when you refer a friend to us.</p>
            <p>
                Copy and paste your URL to send it to a friend who could really benefit from never having to worry about
                sales tax again.
            </p>
            <p>
                If they sign up for DAVO, 30 days after we will send you a $50 Amazon gift card just for being so
                helpful!
            </p>
            <p>Thank you so much!</p>
            <p>&#8211; Team DAVO</p>

            <br style={{ clear: "both" }} />
        </>
    );
}
