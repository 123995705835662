import { Typography, useTheme } from "@mui/material";
import isNil from "lodash/isNil";
import React from "react";
import bookkeepLogo from "./resources/bookkeep_logo.svg";
import rasiDarkLogo from "./resources/partner/rasi_logo_dark.png";
import rasiLightLogo from "./resources/partner/rasi_logo_light.png";

export const PartnerLogo = ({
    partnerTag,
    partnerName,
    ...rest
}: { partnerTag?: string; partnerName?: string } & React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
>) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";

    if (!partnerTag || !partnerName) {
        return null;
    }

    let logo: any;
    switch (partnerTag) {
        case "rasi-partner":
            logo = isDarkMode ? rasiLightLogo : rasiDarkLogo;
            break;
        case "bookkeep-partner":
            logo = bookkeepLogo;
            break;
    }

    return !isNil(logo) ? (
        <img src={logo} {...rest} alt={partnerName} title={partnerName} />
    ) : (
        <Typography color="textPrimary">{partnerName}</Typography>
    );
};
