import { TaxProfileCredentials } from "./Account";

export type EFileResult = {
    isValid: boolean;
    isPasswordChangeRequired: boolean;
    error: boolean;
    errorMessage?: string;
};

export type AdditionalCredentialOptions = { [key: string]: string };

export type EfileCheckData = {
    jurisdictionFilingTypeId: string;
    profileLoginCreds: TaxProfileCredentials;
    additionalOptions?: AdditionalCredentialOptions | {};
    abortController: AbortController;
};

export type LoginCredentialValidationStatus =
    // verified credentials
    | "valid"
    // verified credentials are invalid
    | "invalid"
    // state site is requiring pw change
    | "isPasswordChangeRequired"
    // didn't get a response within timeout threshold (60s)
    | "timeout"
    // in progress
    | "validating"
    // couldn't access the state, let them through anyway
    | "error"
    // pristine, no attempt to validate has been made
    | undefined;
