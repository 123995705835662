import { useLoginContext } from "@davo/portal-common";
import { FullStory as FS, init as fsInit } from "@fullstory/browser";
import { useEffect, useState } from "react";
import { useAccountContext, useConfigMerchantContext } from "../context";

export function FullStory() {
    const { merchantConfigInfo: configInfo } = useConfigMerchantContext();
    const loginContext = useLoginContext();
    const accountContext = useAccountContext();

    const [, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!configInfo.fullStoryEnabled) {
            return;
        }
        setIsLoaded((isReallyLoaded) => {
            if (!isReallyLoaded && (!loginContext.user || loginContext.user.type === "Merchant")) {
                fsInit({ orgId: "1G7V8" });
            }
            return true;
        });

        if (loginContext.user && loginContext.user.type === "Merchant") {
            FS("setIdentity", { uid: loginContext.user.id });
        }
    }, [loginContext.user, configInfo]);

    useEffect(() => {
        if (
            !loginContext.user ||
            !accountContext.account ||
            !accountContext.locations ||
            (accountContext.locations && !accountContext.locations.filter((l) => l.active))
        ) {
            return;
        }
        if (configInfo.fullStoryEnabled && loginContext.user.type === "Merchant") {
            FS("setProperties", {
                type: "page",
                properties: {
                    accountId: accountContext.account.id,
                },
            });
            accountContext.locations
                .filter((l) => l.active)
                .forEach((location) => {
                    FS("setProperties", {
                        type: "page",
                        properties: {
                            locationId: location.id,
                        },
                    });
                });
        }
        return undefined;
    }, [configInfo, loginContext.user, accountContext.account, accountContext.locations]);

    return null;
}
