import { BusinessDay, LocationRecord, money, TaxProfileTaxRate, toPennies } from "@davo/types";
import InfoIcon from "@mui/icons-material/Info";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Tooltip,
    Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";
import React, { FunctionComponent, useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { PaperComponent } from "./PaperComponent";
import Select from "./Select";
import { addAdditionalSalesManualActivity, getTaxRatesForLocations } from "./services";
import { TextField } from "./TextField";
import { d30Toast, d30ToastError } from "./Toast";
import { getLocationLabel, getMonths, monthYear, renderRate } from "./util";

export interface IManualActivityModal {
    defaultLocation?: LocationRecord;
    locations: LocationRecord[];
    accountId: string;
    isDisabled: boolean;
    refresh: () => void;
    canAddNewTaxRate?: boolean;
    filingMonth?: BusinessDay;
    maxTaxCollectedAllowed?: number;
    useDefaultPeriod: boolean;
    isMerchantPay: boolean;
    buttonVariant?: "outlined" | "contained";
}

export const ManualActivityModal: FunctionComponent<IManualActivityModal> = ({
    defaultLocation,
    locations,
    accountId,
    isDisabled,
    refresh,
    canAddNewTaxRate,
    isMerchantPay,
    filingMonth,
    maxTaxCollectedAllowed,
    useDefaultPeriod,
    buttonVariant = "contained",
}) => {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [isConfirmShowing, setIsConfirmShowing] = useState<boolean>(false);
    const [location, setLocation] = useState<LocationRecord>();
    const [taxCollected, setTaxCollected] = useState<number>();
    const [note, setNote] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [period, setPeriod] = useState<string>();
    const [periodOptions, setPeriodOptions] = useState<string[]>();
    const [taxRate, setTaxRate] = useState<TaxProfileTaxRate>();
    const [shouldDoSetAside, setShouldDoSetAside] = useState<boolean>();
    const [taxRates, setTaxTaxRates] = useState<TaxProfileTaxRate[] | []>([]);
    const [salesAmount, setSalesAmount] = useState<number>();
    const [shouldShowTaxRateEntry, setShouldShowTaxRateEntry] = useState<boolean>(false);
    const [taxRateName, setTaxRateName] = useState<string>();
    const [newTaxRate, setNewTaxRate] = useState<string>();
    const [calculatedTaxRate, setCalculatedTaxRate] = useState<number>();
    const [canBeSaved, setCanBeSaved] = useState<boolean>(false);

    useEffect(() => {
        const getMonthsForFiling = getMonths(filingMonth);
        setPeriod(getMonthsForFiling[0]);
        setPeriodOptions(getMonthsForFiling);
    }, [filingMonth]);

    useEffect(() => {
        if (newTaxRate) {
            setCalculatedTaxRate(Math.round(parseFloat(newTaxRate) / 0.00001));
        }
    }, [newTaxRate]);

    useAsyncEffect(async () => {
        if (locations && accountId) {
            const locationIds = locations.map((x) => x.id);
            const rates = await getTaxRatesForLocations(accountId, locationIds);
            const filteredRates = rates.filter((tr) => !tr.taxName.toUpperCase().startsWith("UNKNOWN"));
            if (!filteredRates.find((tr) => tr.taxRate === 0)) {
                // @ts-ignore - we don't use any more than this from the DDL.
                filteredRates.push({ taxName: "Additional Tax", taxRate: 0 });
            }
            setTaxTaxRates(filteredRates);
        }
    }, [accountId]);

    useEffect(() => {
        setTaxRateName(undefined);
        setCalculatedTaxRate(undefined);
    }, [shouldShowTaxRateEntry]);

    const show = () => {
        setTaxCollected(undefined);
        setTaxRate(undefined);
        if (locations && locations.length === 1) {
            setLocation(locations[0]);
        } else {
            setLocation(defaultLocation);
        }
        setNote(undefined);
        setIsBusy(false);
        setIsShowing(true);
        setShouldDoSetAside(false);
        setShouldShowTaxRateEntry(false);
        setSalesAmount(undefined);
    };

    const validateTaxCollected = (val: string) => {
        const amountErrorMessage = validateAmount(val);
        if (amountErrorMessage) {
            return amountErrorMessage;
        }
        if (maxTaxCollectedAllowed && parseFloat(val) >= maxTaxCollectedAllowed) {
            return `For sales tax adds over $${money(
                maxTaxCollectedAllowed
            )} or adding new tax rates please email support@davosalestax.com for assistance`;
        }
        return undefined;
    };

    const validateAmountGreaterThanZero = (val: string) => {
        if (parseFloat(val) <= 0 || !/^\d*\.?\d*$/.test(val)) {
            return `Please enter a valid positive number`;
        }

        return undefined;
    };

    const validateAmount = (val: string) => {
        if (parseFloat(val) < 0 || !/^\d*\.?\d*$/.test(val)) {
            return `Please enter a valid positive number`;
        }

        return undefined;
    };

    useEffect(() => {
        setCanBeSaved(
            Boolean(!isBusy && period && salesAmount && (taxRate || newTaxRate) && taxCollected && location?.id)
        );
    }, [isBusy, period, salesAmount, taxRate, newTaxRate, taxCollected, location]);

    const doProcess = () => {
        if (!canBeSaved || !location) {
            return;
        }
        setIsBusy(true);
        const day = period
            ? BusinessDay.fromDateTime(DateTime.fromISO(period))
            : BusinessDay.fromDateTime(DateTime.fromJSDate(new Date()));

        // note that passing undefined for the rate means we use the calculated value:
        // record.tax === 0 || record.sales === 0 ? 0 : Math.round((record.tax / record.sales) * 1e7) -- ManualActivityService
        addAdditionalSalesManualActivity(
            location.id,
            day,
            shouldDoSetAside,
            toPennies(salesAmount),
            toPennies(taxCollected),
            shouldShowTaxRateEntry ? calculatedTaxRate : taxRate?.taxRate,
            note,
            (taxRateName ?? (!isNil(taxRate) && isNil(taxRate?.taxId))) ? taxRate?.taxName : undefined
        )
            .then(() => {
                d30Toast(`Additional sales and additional tax added.`);
                refresh();
            })
            .then(() => {
                setIsShowing(false);
                setIsConfirmShowing(false);
            })
            .catch((e) => {
                d30ToastError("Problem adding additional sales activity.", e);
                setIsBusy(false);
            });
    };

    if (!locations) {
        return null;
    }

    return (
        <div data-testid={"manualActivityModal"}>
            {locations && locations.length > 0 && (
                <>
                    <Button
                        data-testid={"launchAddAdditionalSalesBtn"}
                        variant={buttonVariant}
                        disabled={isDisabled}
                        onClick={show}
                        size="small"
                        style={{
                            padding: !canAddNewTaxRate ? "7px 9px" : "3px",
                            margin: !canAddNewTaxRate ? "5px" : "unset",
                        }}
                        color="primary">
                        Add Additional Sales
                    </Button>
                    {isShowing && (
                        <Dialog
                            PaperComponent={PaperComponent}
                            aria-labelledby="draggable-dialog-title"
                            open={true}
                            onClose={() => {
                                setIsShowing(false);
                            }}>
                            <DialogTitle id="draggable-dialog-title">Additional Sales...</DialogTitle>
                            <div style={{ padding: "20px" }}>
                                <Typography variant={"body1"}>
                                    Add tax collected and tax rate to a location and period
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Select<string>
                                        data-testid={"periodSelect"}
                                        value={period}
                                        title="Period"
                                        onChange={setPeriod}
                                        options={periodOptions ?? []}
                                        label={monthYear}
                                        isDisabled={useDefaultPeriod}
                                        showFullWidth={true}
                                    />
                                </LocalizationProvider>

                                {locations && locations.length > 1 && (
                                    <Select<LocationRecord>
                                        noneLabel={`Select a location...`}
                                        value={location}
                                        onChange={setLocation}
                                        options={locations}
                                        label={(l) => getLocationLabel(l)}
                                        showFullWidth={true}
                                    />
                                )}
                                <TextField
                                    label="Sales Amount"
                                    value={salesAmount}
                                    type="number"
                                    inputProps={{
                                        ["data-testid"]: "salesAmountInput",
                                        maxLength: 10,
                                        step: "1",
                                    }}
                                    validate={validateAmountGreaterThanZero}
                                    onChange={setSalesAmount}
                                />
                                <TextField
                                    label="Sales Tax Amount"
                                    value={taxCollected}
                                    type="number"
                                    inputProps={{
                                        ["data-testid"]: "salesTaxAmountInput",
                                        maxLength: 10,
                                        step: "1",
                                    }}
                                    validate={validateTaxCollected}
                                    onChange={setTaxCollected}
                                />
                                {taxRates.length > 0 && !shouldShowTaxRateEntry && (
                                    <div>
                                        <div style={{ textAlign: "right" }}>
                                            <Typography variant={"caption"}>
                                                If multiple rates apply to these sales, choose the combined rate.
                                            </Typography>
                                            <Tooltip
                                                title={`If you don’t see the tax rate for which this tax applies in the dropdown, email support@davosalestax.com`}>
                                                <div style={{ textAlign: "right", display: "inline-block" }}>
                                                    <InfoIcon
                                                        fontSize={"small"}
                                                        color="primary"
                                                        style={{ marginLeft: "5px" }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <Select<TaxProfileTaxRate>
                                            value={taxRate}
                                            data-testid={"taxRateSelect"}
                                            title="Tax Rate"
                                            onChange={setTaxRate}
                                            options={taxRates}
                                            label={(r) => {
                                                if (r) {
                                                    return `${r.taxName} - ${renderRate(r.taxRate)}`;
                                                } else {
                                                    return "Select a rate";
                                                }
                                            }}
                                            showFullWidth={true}
                                        />
                                    </div>
                                )}
                                {shouldShowTaxRateEntry && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}>
                                        <TextField
                                            style={{ marginRight: "10px" }}
                                            label="Tax Rate Name"
                                            value={taxRateName}
                                            onChange={setTaxRateName}
                                        />
                                        <TextField
                                            inputProps={{
                                                step: "0.01",
                                                lang: "en-US",
                                                maxLength: 5,
                                                inputMode: "numeric",
                                            }}
                                            validate={validateAmount}
                                            label="Tax Rate"
                                            value={newTaxRate ?? ""}
                                            onChange={setNewTaxRate}
                                            showFullWidth={false}
                                        />
                                    </div>
                                )}
                                {canAddNewTaxRate && (
                                    <div onClick={() => setShouldShowTaxRateEntry(!shouldShowTaxRateEntry)}>
                                        <Typography
                                            style={{
                                                color: "#78A0CD",
                                                fontSize: "10px",
                                                textDecoration: "underline",
                                                float: "right",
                                                cursor: "pointer",
                                            }}>
                                            {!shouldShowTaxRateEntry
                                                ? "Add new tax rate (ops only)"
                                                : "Use tax rate on file"}
                                        </Typography>
                                    </div>
                                )}
                                <TextField
                                    inputProps={{
                                        ["data-testid"]: "describeSalesInput",
                                    }}
                                    label="Describe Sales/Details: (ex. DoorDash Sales for May)"
                                    value={note}
                                    onChange={setNote}
                                />
                            </div>
                            <DialogActions>
                                <Button variant="outlined" color="primary" onClick={() => setIsShowing(false)}>
                                    Cancel
                                </Button>
                                {isMerchantPay && (
                                    <Button variant="contained" color="primary" onClick={doProcess}>
                                        Confirm
                                    </Button>
                                )}
                                {!isMerchantPay && (
                                    <Button
                                        data-testid={"continueAddAdditionalSaleBtn"}
                                        variant="contained"
                                        disabled={!canBeSaved}
                                        color="primary"
                                        onClick={() => {
                                            if (isNil(taxCollected)) {
                                                return;
                                            }

                                            setShouldDoSetAside(false);
                                            if (taxCollected > 0) {
                                                setIsConfirmShowing(true);
                                            } else {
                                                doProcess();
                                            }
                                        }}>
                                        Continue
                                    </Button>
                                )}
                            </DialogActions>
                        </Dialog>
                    )}

                    {isConfirmShowing && (
                        <Dialog
                            data-testid={"confirmManualActivityDialog"}
                            maxWidth={"md"}
                            open={true}
                            onClose={() => {
                                setIsConfirmShowing(false);
                            }}>
                            <DialogTitle>
                                Review the data you added and choose whether or not to trigger a one time movement of
                                money
                            </DialogTitle>
                            <DialogContent>
                                <FormControlLabel
                                    sx={{ alignItems: "flex-end" }}
                                    control={
                                        <Checkbox
                                            data-testid={"initiateSetAsideCb"}
                                            sx={{
                                                marginBottom: -1,
                                                color: "#78a0cd",
                                            }}
                                            checked={shouldDoSetAside}
                                            onChange={() => setShouldDoSetAside(!shouldDoSetAside)}
                                        />
                                    }
                                    labelPlacement={"start"}
                                    label={
                                        <Typography>
                                            Sales Data Added: ${salesAmount ?? 0}
                                            <br />
                                            Sales Tax Data Added: ${taxCollected ?? 0}
                                            <br />
                                            DAVO should initiate a one time set aside for $<b>
                                                {taxCollected ?? 0}
                                            </b>{" "}
                                            dollars of sales tax
                                        </Typography>
                                    }
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        setIsConfirmShowing(false);
                                    }}>
                                    Cancel
                                </Button>
                                <Button
                                    data-testid={"reviewAndConfirmManualActivityBtn"}
                                    variant="contained"
                                    color="primary"
                                    disabled={isBusy}
                                    onClick={doProcess}>
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </>
            )}
        </div>
    );
};
