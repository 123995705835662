import { BUSINESS_DAY, DATETIME, JSON_FIELD, NUMBER, OBJECT, ResolveObject, STRING } from "./MetaType";
import { INewLocationType } from "./POS";

export const MetaDavoApiInvitationPayload = {
    name: STRING(),
    email: STRING(),
    phone: STRING(),
};
export type DavoApiInvitationPayload = ResolveObject<typeof MetaDavoApiInvitationPayload>;

export const MetaDavoApiLocationPayload = {
    accountId: STRING().optional(),
    name: STRING(),
    externalLocationId: STRING(),
    bankAccountId: STRING().optional(),
    address1: STRING(),
    address2: STRING(),
    city: STRING(),
    postalCode: STRING(),
    state: STRING(),
    posType: STRING(),
    invitation: OBJECT(MetaDavoApiInvitationPayload).optional(),
};
export type DavoApiLocationPayload = ResolveObject<typeof MetaDavoApiLocationPayload>;

export const MetaOnboardEvent = {
    data: JSON_FIELD<INewLocationType>(),
};

export const MetaDavoApiTaxPayload = {
    identifier: STRING(),
    name: STRING(),
    rate: NUMBER(),
    sales: NUMBER(), // dollars
    tax: NUMBER(), // dollars
};
export type DavoApiTaxPayload = ResolveObject<typeof MetaDavoApiTaxPayload>;

export const MetaDavoApiItemPayload = {
    identifier: STRING(),
    timestamp: DATETIME(),
    activityDate: BUSINESS_DAY(),
    type: STRING(), // "refund" | "payment";
    sales: NUMBER(), // dollars
    taxes: OBJECT(MetaDavoApiTaxPayload).array(),
    tenderType: STRING().optional(),
};
export type DavoApiItemPayload = ResolveObject<typeof MetaDavoApiItemPayload>;

export const MetaDavoApiLocationTaxProfilePayload = {
    locationId: STRING().optional(),
    externalLocationId: STRING().optional(),
    taxProfileId: STRING(),
};
export type DavoApiLocationTaxProfilePayload = ResolveObject<typeof MetaDavoApiLocationTaxProfilePayload>;

export const MetaDavoApiActivityPayload = {
    locationId: STRING().optional(),
    externalLocationId: STRING(),
    identifier: STRING(),
    modified: DATETIME(),
    items: OBJECT(MetaDavoApiItemPayload).array(),
};
export type DavoApiActivityPayload = ResolveObject<typeof MetaDavoApiActivityPayload>;

export type DavoApiItemType = "refund" | "payment";

export const MetaDavoPOSTax = {
    identifier: STRING(),
    name: STRING(),
    rate: NUMBER(),
    sales: NUMBER(), // dollars
    tax: NUMBER(), // dollars
};
export type DavoPOSTax = ResolveObject<typeof MetaDavoPOSTax>;

export const MetaDavoPOSItem = {
    identifier: STRING(),
    timestamp: DATETIME(),
    activityDate: BUSINESS_DAY(),
    type: STRING(), // "refund" | "payment";
    sales: NUMBER(), // dollars
    taxes: OBJECT(MetaDavoPOSTax).array(),
    tenderType: STRING().optional(),
};
export type DavoPOSItem = ResolveObject<typeof MetaDavoPOSItem>;

export const MetaDavoPOSActivity = {
    identifier: STRING(),
    locationId: STRING(),
    items: OBJECT(MetaDavoPOSItem).array(),
};
export type DavoPOSActivity = ResolveObject<typeof MetaDavoPOSActivity>;
