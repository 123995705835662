import { Donut, getAccount, INavMenuItem, LoadingWithLogo, useLoginContext } from "@davo/portal-common";
import { INavSubMenu } from "@davo/portal-common/lib/DonutSubMenu";
import { AccountRecord, OpsUserTypes, User } from "@davo/types";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import { NavigateFunction, useMatch, useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { CompanySelector } from "./components";
import { useAccountContext, useAccountsContext, useLocationContext } from "./context";
import DetailsIcon from "./resources/dashboard.svg";
import FaceIcon from "./resources/face-24px.svg";
import BankIcon from "./resources/filings.svg";
import HandshakeIcon from "./resources/handshake.svg";
import SaveIcon from "./resources/saveicon.svg";
import SettingsIcon from "./resources/settings.svg";
import TaxIcon from "./resources/taxicon.svg";
import { Banking } from "./SettingsBanking";
import { Billing } from "./SettingsBilling";
import { Company } from "./SettingsCompany";
import { Filing } from "./SettingsTaxProfile";
import { UserReferral } from "./SettingsUserReferral";
import { Users } from "./SettingsUsers";

export const handleAccountChanges = async (
    accounts: AccountRecord[] | undefined,
    account: AccountRecord | undefined,
    urlAccountId: string | undefined,
    user: User | undefined,
    navigate: NavigateFunction,
    switchAccount: (accountId: string) => Promise<void>
) => {
    if (isNil(accounts)) {
        return;
    }

    if (account && urlAccountId && account.id === urlAccountId) {
        // We have the correct account setup - looks good
        return;
    }

    if (accounts.length > 0) {
        if (!urlAccountId) {
            // No accountId in the url. Let's use the first account from our list of accounts.
            navigate(`/${accounts[0].id}`, { replace: true });
        } else if (urlAccountId === "referral") {
            navigate(`/${accounts[0].id}/settings/referral`, { replace: true });
        } else {
            // merchant logging in
            const acct = accounts.find((a) => a.id === urlAccountId);
            if (acct) {
                if (account !== acct) {
                    await switchAccount(acct.id);
                }
            } else {
                // AccountId in url is not in account list
                const single = await getAccount(urlAccountId);
                if (single.name !== "<permission issue - try logging in again>" && single.id !== urlAccountId) {
                    // For account aliases the accountId in the url won't match the actual accountId for this account.
                    // Here we change the url which re-triggers this effect
                    navigate(`/${single.id}`, { replace: true });
                } else {
                    // user does not have access to this account
                    navigate(`/${accounts[0].id}`, { replace: true });
                }
            }
        }
    } else {
        // ops login or user has no accounts
        // We have the accountId in the url and we just need to find the account from our list
        // NOTE: that account aliases won't appear in this list

        // This is for the ops or partner type user
        if (user && [...OpsUserTypes, "Partner"].includes(user.type) && urlAccountId) {
            // We fall in here if we're an ops user or this is an aliased account
            // Explicitly set account for ops user. In this case accounts will be an empty list

            await switchAccount((await getAccount(urlAccountId)).id);
        }
    }
};

export const MerchantDonut = ({
    routes,
    shouldShowDetailPanes,
}: {
    routes: INavMenuItem[];
    shouldShowDetailPanes: boolean;
}) => {
    const navigate = useNavigate();

    const loginContext = useLoginContext();
    const accountsContext = useAccountsContext();
    const accountContext = useAccountContext();
    const locationContext = useLocationContext();

    const urlAccountId = useMatch("/:accountId/*")?.params?.accountId;
    const [donutSettingsRoutes, setDonutSettingsRoutes] = useState<INavMenuItem[]>();
    const [donutSubMenu, setDonutSubMenu] = useState<INavSubMenu[]>();

    useAsyncEffect(async () => {
        if (urlAccountId !== accountContext.account?.id) {
            await locationContext.switch(undefined);
            await handleAccountChanges(
                accountsContext.accounts,
                accountContext.account,
                urlAccountId,
                loginContext.user,
                navigate,
                accountContext.switch
            );
        }
    }, [loginContext.user, urlAccountId, accountsContext.accounts, accountContext.account]);

    useEffect(() => {
        if (isNil(accountContext.locationsNotFullyConnected)) {
            return;
        }
        const _settingsSubMenu: INavMenuItem[] = [];

        if (shouldShowDetailPanes) {
            _settingsSubMenu.push(
                ...[
                    {
                        name: "Company",
                        slug: "settings/company",
                        icon: DetailsIcon,
                        destination: "settings/company",
                        badge:
                            accountContext.locationsNotFullyConnected.size > 0
                                ? accountContext.locationsNotFullyConnected.size.toString()
                                : undefined,
                        element: <Company />,
                    },
                    {
                        name: "Banking",
                        slug: "settings/banking",
                        icon: BankIcon,
                        destination: "settings/banking",
                        element: <Banking />,
                    },
                    {
                        name: "Tax Profile",
                        slug: "settings/filing",
                        icon: TaxIcon,
                        destination: "settings/filing",
                        element: <Filing />,
                    },
                ]
            );
            if (accountContext.account && !accountContext.partner) {
                _settingsSubMenu.push({
                    name: "Billing",
                    slug: "settings/billing",
                    icon: SaveIcon,
                    destination: "settings/billing",
                    element: <Billing />,
                });
            }
            _settingsSubMenu.push({
                name: "Users",
                slug: "settings/users",
                icon: FaceIcon,
                destination: "settings/users",
                element: <Users />,
            });
            if (loginContext.user?.referralCode) {
                _settingsSubMenu.push({
                    name: "Referrals",
                    slug: "settings/referral",
                    icon: HandshakeIcon,
                    destination: "settings/referral",
                    element: <UserReferral />,
                });
            }
            setDonutSettingsRoutes(_settingsSubMenu);
        }
    }, [
        loginContext.user,
        accountContext.account,
        accountContext.partner,
        shouldShowDetailPanes,
        accountContext.locationsNotFullyConnected,
    ]);

    useEffect(() => {
        if (isNil(donutSettingsRoutes) || isNil(accountContext.locationsNotFullyConnected)) {
            return;
        }
        if (!shouldShowDetailPanes) {
            setDonutSubMenu([]);
            return;
        }
        const settings = {
            name: "Settings",
            slug: "settings",
            icon: SettingsIcon,
            items: donutSettingsRoutes,
            destination: "settings",
            badge:
                accountContext.locationsNotFullyConnected.size > 0
                    ? accountContext.locationsNotFullyConnected.size.toString()
                    : undefined,
        };
        setDonutSubMenu([settings]);
    }, [donutSettingsRoutes, accountContext.locationsNotFullyConnected, shouldShowDetailPanes]);

    if (
        isNil(donutSettingsRoutes) ||
        isNil(donutSubMenu) ||
        isNil(accountsContext.accounts) ||
        urlAccountId !== accountContext.account?.id
    ) {
        return <LoadingWithLogo />;
    } else {
        return <Donut header={<CompanySelector />} primary={routes} subMenus={donutSubMenu} />;
    }
};
