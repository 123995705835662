export const formatPosInfo = (pos: string, query: URLSearchParams, body?: any) => {
    let posInfo;
    const queryState = query.get("state");
    switch (pos) {
        case "clover":
            posInfo = {
                token: query.get("access_token"),
                merchantId: query.get("merchant_id"),
                clientId: query.get("client_id"),
                employeeId: query.get("employee_id"),
            };
            break;
        case "heartland-restaurant":
            posInfo = {
                key: body.key.trim(),
                email: body.email,
            };
            break;
        case "lavu":
            posInfo = {
                dataname: body.dataname.trim(),
                token: body.token.trim(),
                key: body.key.trim(),
            };
            break;
        case "lightspeed-k-series":
            if (queryState) {
                const stateDecode = JSON.parse(Buffer.from(queryState, "base64").toString("utf-8"));
                posInfo = {
                    email: stateDecode.email,
                    phone: stateDecode.phone,
                    address1: stateDecode.address1,
                    city: stateDecode.city,
                    state: stateDecode.state,
                    zip: stateDecode.zip,
                };
            }
            break;
        case "heartland-retail":
        case "lightspeed-l-series":
            if (queryState) {
                const peDecode = JSON.parse(Buffer.from(queryState, "base64").toString("utf-8"));
                posInfo = {
                    email: peDecode.email,
                    phone: peDecode.phone,
                };
            }
            break;
        case "restaurant365":
        case "lightspeed-u-series":
            posInfo = {
                username: body.username.trim(),
                password: body.password.trim(),
                title: body.title,
                email: body.email,
                phone: body.phone,
                address1: body.address1,
                city: body.city,
                state: body.state,
                zip: body.zip,
            };
            break;
        case "lightspeed-x-series":
            // eslint-disable-next-line no-case-declarations
            const penDecode = queryState ? JSON.parse(Buffer.from(queryState, "base64").toString("utf-8")) : undefined;
            posInfo = {
                email: penDecode ? penDecode.email : "",
                phone: penDecode ? penDecode.phone : "",
                title: penDecode ? penDecode.name : "",
                prefix: query.get("domain_prefix"),
            };
            break;
        case "mindbody":
            posInfo = {
                siteId: query.get("siteId"),
            };
            break;
        case "par-brink":
            posInfo = {
                locationUrl:
                    body.locationUrl.trim().slice(-1) === "/" ? body.locationUrl.trim() : `${body.locationUrl.trim()}/`,
                locationToken: body.locationToken.trim(),
                email: body.email,
            };
            break;
        case "poynt":
            posInfo = {
                status: query.get("status"), // whether the request has been authorized by the merchant or not
                businessId: query.get("businessId"),
                storeId: query.get("store_id"),
                planId: query.get("plan_id"),
            };
            break;
        case "quickbooks":
            posInfo = {
                realmId: query.get("realmId"),
            };
            break;
        case "revel":
            posInfo = {
                prefix: body.prefix.trim(),
                apiKey: body.apiKey.trim(),
                apiSecret: body.apiSecret.trim(),
            };
            break;
        case "shift4":
            posInfo = {
                linkToken: query.get("linkToken"),
            };
            break;
        case "spoton-restaurant":
        case "spoton-retail":
            posInfo = {
                title: body.title,
                address1: body.address1,
                city: body.city,
                email: body.email,
                spotOnLocId: body.spotOnLocId.trim(),
                phone: body.phone,
                state: body.state,
                zip: body.zip,
            };
            break;
        default:
            posInfo = {};
    }
    return posInfo;
};

export const checkErrors = (pos: string, query: URLSearchParams) => {
    let errorMessage: string | null | undefined = undefined;
    const paramError = query.get("error");
    switch (pos) {
        case "quickbooks":
        case "square":
            errorMessage = paramError;
            break;
        case "clover":
            if (!query.get("merchant_id")) {
                errorMessage = "Please try reconnecting through your Clover portal.";
            }
            break;
        case "toast":
            errorMessage = "Toast invitations process exclusively through webhook.";
    }
    return errorMessage ?? undefined;
};
