import {
    d30Toast,
    d30ToastError,
    getBankAccounts,
    getLocations,
    getTaxProfiles,
    unpauseBankAccount,
} from "@davo/portal-common";
import { BankAccount, LocationRecord, TaxProfile } from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";

export function BankAccountInfoPane({
    accountId,
    target,
    closeDialog,
}: {
    accountId: string;
    target?: string;
    closeDialog: (target?: string) => void;
}) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [locations, setLocations] = useState<LocationRecord[]>();
    const [bankAccounts, setBankAccounts] = useState<BankAccount[]>();

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [canReactivate, setCanReactivate] = useState<boolean>(false);

    useAsyncEffect(async () => {
        const locationsPromise = getLocations(accountId);
        const banksPromise = getBankAccounts(accountId);
        const tpsPromise = getTaxProfiles(accountId);
        const [newLocations, newBankAccounts, newTaxProfiles] = await Promise.all([
            locationsPromise,
            banksPromise,
            tpsPromise,
        ]);
        setLocations(newLocations);
        setBankAccounts(newBankAccounts);

        setCanReactivate(
            newTaxProfiles.find(
                (x: TaxProfile) => x.filingMethod === "file-only" || x.filingMethod === "merchant-pay"
            ) === undefined
        );
    }, [accountId]);

    if (!locations || !bankAccounts) {
        return null;
    }

    const shutdown = (result?: string) => {
        closeDialog(result);
    };

    const reactivateBankAccount = () => {
        // target = bankId passed generically from modal widget
        if (target) {
            setIsBusy(true);
            unpauseBankAccount(accountId, target)
                .then(() => shutdown(target))
                .then(() => d30Toast("Changes saved"))
                .catch((e) => d30ToastError(e.message))
                .finally(() => setIsBusy(false));
        }
    };

    return (
        <Dialog
            fullScreen={makeFullScreen}
            open={true}
            aria-labelledby="responsive-dialog-title"
            style={{ boxShadow: "none", padding: "10px" }}
            onClose={() => shutdown()}>
            <DialogTitle id="responsive-dialog-title">Bank Account Info</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to resume daily set asides?
                    <br />
                    <br />
                    Once the bank is unpaused, DAVO will initiate an ACH debit for the amount missed since the bank was
                    paused. In addition, daily set asides will resume.
                </DialogContentText>

                {!canReactivate && "Please contact customer service to unpause if this is in error"}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isBusy}
                    onClick={() => shutdown()}
                    variant="outlined"
                    color="primary"
                    style={{ width: "150px" }}>
                    {canReactivate ? "Cancel" : "Back"}
                </Button>
                {canReactivate && (
                    <Button
                        disabled={isBusy}
                        onClick={reactivateBankAccount}
                        variant="contained"
                        color="primary"
                        style={{ width: "150px" }}
                        autoFocus>
                        Continue
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
