import { ConnectionStatus } from "@davo/portal-common";
import React, { useEffect, useState } from "react";
import { useAccountContext } from "../context";

export interface IMerchantConnectionStatusProps {
    locationId?: string;
}

export function MerchantConnectionStatus({ locationId }: IMerchantConnectionStatusProps) {
    const accountContext = useAccountContext();
    const [settingsLink, setSettingsLink] = useState<string | undefined>();
    const [bankSettingsLink, setBankSettingsLink] = useState<string | undefined>();
    const [tpSettingsLink, setTpSettingsLink] = useState<string | undefined>();
    const [restrictedBankSettingsLink, setRestrictedBankSettingsLink] = useState<string | undefined>();

    useEffect(() => {
        if (
            !accountContext.account ||
            !accountContext.locations ||
            (accountContext.locations && !accountContext.locations.filter((l) => l.active)) ||
            !accountContext.bankAccounts ||
            !accountContext.taxProfiles
        ) {
            return;
        }
        setSettingsLink(
            locationId
                ? `/${accountContext.account.id}/settings/company/${locationId}`
                : `/${accountContext.account.id}/settings/company`
        );
        setRestrictedBankSettingsLink(
            locationId
                ? `/${accountContext.account.id}/settings/company/${locationId}`
                : `/${accountContext.account.id}/settings/company`
        );
        setBankSettingsLink(`/${accountContext.account.id}/settings/banking`);
        setTpSettingsLink(`/${accountContext.account.id}/settings/filing`);
    }, [
        accountContext.locations,
        accountContext.bankAccounts,
        accountContext.taxProfiles,
        accountContext.account,
        locationId,
    ]);

    if (!accountContext.account) {
        return;
    }

    return (
        <div data-testid={"merchantConnectionStatus"}>
            <ConnectionStatus
                locationId={locationId}
                accountId={accountContext.account.id}
                missingBankLink={settingsLink}
                pausedBankInUseLink={bankSettingsLink}
                missingTaxProfileLink={settingsLink}
                failingTaxProfileLink={tpSettingsLink}
                restrictedBankLink={restrictedBankSettingsLink}
            />
        </div>
    );
}
