import { IBillingSummary, IBillingWithBank } from "@davo/types";
import { get } from "./api";

export const getBillingsForAccount = async (accountId: string) => {
    return get<IBillingWithBank[]>(`api/common/account/${accountId}/billings`, true);
};

export const getBillingInfo = async (accountId: string, billingId: string) => {
    return get(`api/common/account/${accountId}/billings/${billingId}/info`, true, {});
};

export const getBillingHistory = async (accountId: string, billingId: string) => {
    return get(`api/common/account/${accountId}/billings/${billingId}/history`, true, {});
};

export async function getLatestBillingSummary(accountId: string) {
    return get<IBillingSummary[]>(`api/common/account/${accountId}/billing/latest-summary`, true);
}
