import {
    isPasswordValid,
    isPhoneValid,
    MetaScreenModes,
    OptionalString,
    validateNotNull,
    validatePassword,
    validatePhone,
} from "@davo/types";
import { Button, Grid, Paper, Typography } from "@mui/material";
import every from "lodash/every";
import trimEnd from "lodash/trimEnd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { useLoginContext } from "./context";
import Select from "./Select";
import { auth } from "./services";
import { TextField } from "./TextField";
import { d30Toast, d30ToastError } from "./Toast";

const ScreenMode = {
    light: "Always Light",
    dark: "Always Dark",
    os: "Same as Operating System",
} as { [key: string]: string };

function Header({ children }: { children: any }) {
    return <Typography style={{ fontSize: "20px", marginBottom: "15px" }}>{children}</Typography>;
}

export function Profile(profileProps: { showScreenMode: boolean }) {
    const loginContext = useLoginContext();
    const [firstName, setFirstName] = useState<OptionalString>();
    const [lastName, setLastName] = useState<OptionalString>();
    const [phone, setPhone] = useState<OptionalString>();
    const [screenMode, setScreenMode] = useState<string>();
    const [currentPassword, setCurrentPassword] = useState<string>();
    const [newPassword, setNewPassword] = useState<string>();

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const navigate = useNavigate();
    const [lastLogin, setLastLogin] = useState<string | undefined>(undefined);

    useAsyncEffect(async () => {
        reset();
        const lastLoginAttempt = await auth.getLastLogin();
        if (lastLoginAttempt) {
            const lastLoginTS = lastLoginAttempt.ts.toJSDate();
            setLastLogin(lastLoginTS.toLocaleString());
        }
    }, [loginContext.user]);

    if (!loginContext.user) {
        return;
    }

    const reset = () => {
        setFirstName(loginContext.user?.firstName);
        setLastName(loginContext.user?.lastName);
        setPhone(loginContext.user?.phone);
        setScreenMode(loginContext.user?.screenMode);
        setCurrentPassword(undefined);
        setNewPassword(undefined);
        setIsBusy(false);
    };

    const cancel = () => {
        navigate(1);
        const url = window.location.href;
        const newUrl = trimEnd(url, "/profile");
        navigate(newUrl);
    };

    const hasProfileChanges = () => {
        return (
            firstName !== loginContext.user?.firstName ||
            lastName !== loginContext.user?.lastName ||
            phone !== loginContext.user?.phone ||
            screenMode !== loginContext.user?.screenMode
        );
    };

    const hasPasswordChanges = () => {
        return !!currentPassword || !!newPassword;
    };

    const doUpdate = () => {
        setIsBusy(true);
        const updatesToProcess: Promise<any>[] = [];

        if (hasProfileChanges()) {
            const profileUpdates = auth.updateCurrentUser({ firstName, lastName, phone, screenMode }).then((user) => {
                loginContext.setUser(user);
            });
            updatesToProcess.push(profileUpdates);
        }
        if (hasPasswordChanges() && currentPassword && newPassword) {
            const pwdUpdates = auth.updateCurrentUserPassword(currentPassword, newPassword).then(() =>
                auth.logout().finally(() => {
                    loginContext.setUser(undefined);
                })
            );
            updatesToProcess.push(pwdUpdates);
        }

        Promise.all(updatesToProcess)
            .then(() => {
                d30Toast("Profile updated!");
            })
            .catch((e) => d30ToastError(e.message))
            .finally(() => setIsBusy(false));
    };

    const onEnterPressed = () => {
        if (isValid()) {
            doUpdate();
        }
    };

    const isValid = () => {
        return (
            (hasProfileChanges() || hasPasswordChanges()) &&
            every([phone, firstName, lastName]) &&
            isPhoneValid(phone) &&
            ((!currentPassword && !newPassword) || (currentPassword && newPassword && isPasswordValid(newPassword)))
        );
    };

    return (
        <Paper style={{ boxShadow: "none", padding: "2px" }}>
            <Grid container direction="row" style={{ padding: "30px" }}>
                <Grid item xs={12}>
                    <Header>Profile Information</Header>
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "15px", paddingBottom: "0px" }}>
                    <TextField
                        label="First Name"
                        value={firstName ?? ""}
                        onChange={setFirstName}
                        onEnterPress={onEnterPressed}
                        validate={validateNotNull}
                        isRequired={true}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "15px", paddingBottom: "0px" }}>
                    <TextField
                        label="Last Name"
                        value={lastName ?? ""}
                        onChange={setLastName}
                        onEnterPress={onEnterPressed}
                        validate={validateNotNull}
                        isRequired={true}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "15px", paddingBottom: "0px" }}>
                    <TextField
                        label="Phone"
                        value={phone ?? ""}
                        onChange={setPhone}
                        onEnterPress={onEnterPressed}
                        validate={validatePhone}
                        isRequired={true}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "15px", paddingBottom: "0px" }}>
                    &nbsp;
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "15px", paddingBottom: "0px" }}>
                    <TextField
                        isPassword
                        label="Current password"
                        value={currentPassword ?? ""}
                        onChange={setCurrentPassword}
                        onEnterPress={onEnterPressed}
                        isRequired={!!newPassword}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "15px", paddingBottom: "0px" }}>
                    <TextField
                        label="New password"
                        isPassword
                        value={newPassword ?? ""}
                        onChange={setNewPassword}
                        onEnterPress={onEnterPressed}
                        validate={validatePassword}
                        isRequired={!!currentPassword}
                    />
                </Grid>
                {profileProps.showScreenMode && (
                    <Grid item xs={12} md={6} style={{ padding: "15px", paddingBottom: "0px" }}>
                        <Select<string>
                            title="Screen Mode"
                            value={screenMode}
                            onChange={(value) => {
                                if (value) {
                                    setScreenMode(value);
                                }
                            }}
                            options={MetaScreenModes}
                            label={(mode) => ScreenMode[mode]}
                        />
                    </Grid>
                )}
                <Grid item xs={12} style={{ textAlign: "right", marginTop: "20px", whiteSpace: "nowrap" }}>
                    <Button variant="outlined" color="primary" style={{ marginRight: "15px" }} onClick={cancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" disabled={isBusy || !isValid()} onClick={doUpdate}>
                        Save Changes
                    </Button>
                </Grid>
            </Grid>
            {lastLogin ? (
                <div style={{ color: "rgb(173, 173, 173)", marginTop: "10px", fontSize: ".95em", float: "right" }}>
                    {loginContext.user.email}; Last login: {lastLogin}
                </div>
            ) : (
                ""
            )}
        </Paper>
    );
}
