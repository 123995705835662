import { money } from "@davo/types";
import { InputBaseProps } from "@mui/material/InputBase";
import React, { useState } from "react";
import { TextField } from "./TextField";

export interface INumberFieldProps {
    label: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    value?: number;
    helperText?: string;
    onChange?: (v: number | undefined) => void;
    validate?: (v: number | undefined) => string | undefined;
    onEnterPress?: () => void;
    autoFocus?: boolean;
    inputRef?: React.Ref<any>;
    isIntOnly?: boolean;
    inputProps?: InputBaseProps["inputProps"];
}

const noop = () => undefined;

const parse = (str: string) => +str.replace(/[$,]+/g, "");

export function NumberField({ value, onChange = noop, isIntOnly, validate = noop, ...rest }: INumberFieldProps) {
    const [stringValue, setStringValue] = useState<string>(!isIntOnly ? money(value) : (value ?? "").toString());

    const onStringChange = (str: string) => {
        setStringValue(str);
        const val = parse(str);
        if (str === "" || Number.isNaN(val)) {
            onChange(undefined);
        } else {
            onChange(val);
        }
    };

    const validateString = (str: string) => {
        const val = parse(str);
        if (Number.isNaN(val)) {
            return "Invalid number.";
        }
        return validate(val);
    };

    const resetFormatted = () => {
        if (!stringValue || stringValue.trim() === "") {
            setStringValue("");
        } else {
            setStringValue(!isIntOnly ? money(parse(stringValue)) : parse(stringValue).toString());
        }
    };

    return (
        <TextField
            value={stringValue}
            onChange={onStringChange}
            validate={validateString}
            onBlur={resetFormatted}
            {...rest}
        />
    );
}
