import { DateTime } from "luxon";
import { toSQLDate } from "./DateUtil";

const sqlDatePattern = /^\d{4}-\d{2}-\d{2}/;

export function integerDigits(n: number, digits: number) {
    return `${"0".repeat(digits)}${Math.round(n)}`.slice(-digits);
}

export class BusinessDay {
    public static today(timezone?: string) {
        if (timezone) {
            return BusinessDay.fromSQLDate(toSQLDate(DateTime.utc().setZone(timezone)));
        } else {
            return BusinessDay.fromSQLDate(toSQLDate(DateTime.utc()));
        }
    }

    public static yesterday(timezone?: string) {
        if (timezone) {
            return BusinessDay.fromSQLDate(toSQLDate(DateTime.utc().setZone(timezone).minus({ day: 1 })));
        } else {
            return BusinessDay.fromSQLDate(toSQLDate(DateTime.utc().minus({ day: 1 })));
        }
    }

    public static tomorrow(timezone?: string) {
        if (timezone) {
            return BusinessDay.fromSQLDate(toSQLDate(DateTime.utc().setZone(timezone).plus({ day: 1 })));
        } else {
            return BusinessDay.fromSQLDate(toSQLDate(DateTime.utc().plus({ day: 1 })));
        }
    }

    public static firstOfNextMonth(timezone?: string) {
        const nextMonth = timezone
            ? DateTime.utc().setZone(timezone).plus({ month: 1 })
            : DateTime.utc().plus({ month: 1 });

        return BusinessDay.fromSQLDate(toSQLDate(nextMonth.startOf("month")));
    }

    public static fromSQLDate(day: string) {
        if (day && day.match(sqlDatePattern)) {
            const [y, m, d] = day.split("-");
            return BusinessDay.from(+y, +m, +d);
        } else {
            throw new Error("Invalid SQL date format (YYYY-MM-DD): " + day);
        }
    }

    public static isSQLDate(day: string) {
        return !!(day && day.match(sqlDatePattern));
    }

    // closeoutOffset in format HH:MM:SS after midnight
    public static fromDateTime(
        dt: DateTime,
        { timezone, closeoutOffset }: { timezone?: string; closeoutOffset?: string } = {}
    ) {
        if (timezone) {
            dt = dt.setZone(timezone);
        }
        if (closeoutOffset) {
            const [hours, minutes, seconds] = closeoutOffset.split(":").map((x) => +x);
            dt = dt.minus({ hours, minutes, seconds });
        }
        return BusinessDay.fromSQLDate(toSQLDate(dt));
    }

    public static from(year: number, month: number, day: number) {
        return new BusinessDay(Math.floor(year), Math.floor(month), Math.floor(day));
    }

    private constructor(
        public readonly year: number,
        public readonly month: number,
        public readonly day: number
    ) {}

    public toSQLDate() {
        return `${integerDigits(this.year, 4)}-${integerDigits(this.month, 2)}-${integerDigits(this.day, 2)}`;
    }

    public offset(days: number) {
        return BusinessDay.fromSQLDate(toSQLDate(DateTime.fromSQL(toSQLDate(this)).plus({ days })));
    }

    public toDateTime(zone?: string) {
        return DateTime.fromSQL(this.toSQLDate(), { zone });
    }

    public toString() {
        return this.toSQLDate();
    }

    public toJSON() {
        return this.toString();
    }
}
