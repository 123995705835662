import {
    AccountRecord,
    BusinessDay,
    LocationRecord,
    LocationToTaxProfile,
    MetaLocationRecord,
    MetaTaxPeriod,
    MetaTaxProfile,
    MetaTaxProfileCredentials,
    TaxPeriod,
    TaxProfile,
    TaxProfileCredentials,
    TaxProfileFilingMethod,
    TaxProfileTaxRate,
    TaxProfileWithCredentials,
    toJSON,
    toPartial,
} from "@davo/types";
import omit from "lodash/omit";
import { DateTime } from "luxon";
import { get, getArray, post, put } from "./api";

export const getTaxProfiles = async (accountId: string): Promise<TaxProfile[]> => {
    return getArray(`api/common/account/${accountId}/tax-profiles`, true, MetaTaxProfile);
};

export const getTaxProfile = async (accountId: string, taxProfileId: string): Promise<TaxProfile> => {
    return get<any>(
        `api/common/account/${accountId}/tax-profiles/${taxProfileId}/`,
        toJSON(toPartial(MetaTaxProfile), taxProfileId),
        true
    );
};

export const getTaxProfileAndCredentials = async (accountId: string, taxProfileId: string) => {
    return post<TaxProfileWithCredentials>(
        `api/common/account/${accountId}/tax-profiles/${taxProfileId}/`,
        {
            includeCreds: true,
        },
        true
    );
};

export const getGroupLoginRequired = async (accountId: string, state: string) => {
    const result = await get<{ isRequired: boolean }>(
        `api/common/account/${accountId}/tax-profiles/group-login-required/${state}/`,
        true
    );
    return result.isRequired;
};

export const addTaxProfile = async (
    accountId: string,
    taxProfile: Omit<TaxProfileWithCredentials, "id" | "createdBy" | "updatedBy">,
    parentId?: string,
    shouldSendToMerchant?: boolean
) => {
    return post<TaxProfile>(
        `api/common/account/${accountId}/tax-profiles`,
        {
            ...toJSON(omit(MetaTaxProfile, ["id", "createdBy", "updatedBy"]), taxProfile),
            credentials: toJSON(MetaTaxProfileCredentials, taxProfile.credentials),
            parentId,
            shouldSendToMerchant,
        },
        true
    );
};

export const updateTaxProfile = async (
    accountId: string,
    taxProfileId: string,
    taxProfile: Partial<TaxProfile>,
    doesFrequencyChangeAffectCurrentPeriod: boolean
) => {
    return put<any>(
        `api/common/account/${accountId}/tax-profiles/${taxProfileId}`,
        { taxProfile: toJSON(toPartial(MetaTaxProfile), taxProfile), doesFrequencyChangeAffectCurrentPeriod },
        true
    );
};

export const updateTaxProfileFilingType = async (
    accountId: string,
    taxProfileId: string,
    jurisdictionFilingTypeId?: string | null
) => {
    await put(
        `api/common/account/${accountId}/tax-profiles/${taxProfileId}/filing-type`,
        { jurisdictionFilingTypeId },
        true
    );
};

export const updateTaxProfileCredentials = async (
    accountId: string,
    taxProfileId: string,
    credentials: TaxProfileCredentials
) => {
    return put<any>(
        `api/common/account/${accountId}/tax-profiles/${taxProfileId}/credentials`,
        toJSON(MetaTaxProfileCredentials, credentials),
        true
    );
};

export const updateFilingForFrequencyChange = async (
    isOnlyCurrentPeriod: boolean,
    accountId: string,
    taxProfileId: string,
    newFrequency: string,
    oldFrequency?: string
) => {
    await put(
        `api/common/account/${accountId}/tax-profiles/${taxProfileId}/frequency-change`,
        {
            onlyCurrentPeriod: isOnlyCurrentPeriod,
            newFrequency,
            oldFrequency,
        },
        true
    );
};

export const setTaxProfileLocations = (accountId: string, taxProfileId: string, locationIds: string[]) =>
    put<LocationRecord[]>(
        `api/common/account/${accountId}/tax-profiles/${taxProfileId}/locations`,
        { locationIds },
        true
    );

export const setLocationTaxProfiles = async (accountId: string, locationId: string, taxProfileIds: string[]) => {
    return put<Location>(
        `api/common/account/${accountId}/locations/${locationId}/tax-profiles`,
        {
            taxProfileIds,
        },
        true
    );
};

export const getLocationsForTaxProfile = async (accountId: string, taxProfileId: string): Promise<LocationRecord[]> => {
    return getArray(`api/common/account/${accountId}/tax-profiles/${taxProfileId}/locations`, true, MetaLocationRecord);
};

export const getFilingPeriodOptionsForTaxProfile = async (
    accountId: string,
    taxProfileId: string
): Promise<TaxPeriod[]> => {
    return getArray(`api/common/account/${accountId}/tax-profiles/${taxProfileId}/tax-periods`, true, MetaTaxPeriod);
};

export const getLocationToTaxProfiles = async (accountId: string, taxProfileId: string, locationIds: string[]) => {
    return post<LocationToTaxProfile[]>(
        `api/common/account/${accountId}/tax-profiles/${taxProfileId}/locations/associated`,
        { locationIds },
        true
    );
};

export const getTaxProfilesForLocation = async (accountId: string, locationId: string): Promise<TaxProfile[]> => {
    return getArray(`api/common/account/${accountId}/locations/${locationId}/tax-profiles`, true, MetaTaxProfile);
};

export const updateTaxProfileTaxRates = async (accountId: string, taxProfileTaxRates: TaxProfileTaxRate[]) => {
    return put(
        `api/common/account/${accountId}/tax-profiles/tax-rates`,
        {
            taxProfileTaxRates,
        },
        true
    );
};

export const getTaxProfileTaxRates = async (accountId: string, filingId: string) => {
    return get(`api/common/account/${accountId}/tax-profiles/tax-rates/${filingId}`, true);
};

export async function getTaxProfilesIdsWithFiledFilingsForAccount(account: AccountRecord) {
    return get<string[]>(`api/common/account/${account.id}/tax-profiles/with-filed-filings`, true);
}

export async function postGetFilingFilterOptions(account: AccountRecord, taxProfileIds: string[]) {
    return post<{ periodEnd: BusinessDay[]; filedDate: DateTime[]; state: string[] }>(
        `api/common/account/${account.id}/tax-profiles/filing-filter-options`,
        {
            taxProfileIds: taxProfileIds,
        },
        true
    );
}

export async function setTaxProfileFilingMethod(
    accountId: string,
    taxProfileId: string,
    newFilingMethod?: TaxProfileFilingMethod | null
) {
    await put(
        `api/common/account/${accountId}/tax-profiles/${taxProfileId}/filingMethod`,
        {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            filingMethod: newFilingMethod as TaxProfileFilingMethod,
        },
        true
    );
}

export async function markTaxProfileHidden(accountId: string, taxProfileId: string) {
    await put(`api/common/account/${accountId}/tax-profiles/${taxProfileId}/hide`, {}, true);
}
