import { toDateTime } from "@davo/types";

export const sortDate = (fieldName: string) => {
    return (x: any, y: any) => {
        const a = toDateTime(x.original[fieldName]);
        const b = toDateTime(y.original[fieldName]);
        if (a > b) {
            return 1;
        } else if (b > a) {
            return -1;
        } else {
            return 0;
        }
    };
};

export const sortAmount = (fieldName: string) => {
    return (x: any, y: any) => {
        const a = x.original[fieldName];
        const b = y.original[fieldName];
        if (a > b) {
            return 1;
        } else if (b > a) {
            return -1;
        } else {
            return 0;
        }
    };
};

export const sortBoolean = (fieldName: string) => {
    return (x: any) => {
        const a = x.original[fieldName];
        if (a) {
            return 1;
        } else {
            return 0;
        }
    };
};
