export type PenaltyReason =
    | "delinquentReturn"
    | "delinquentPayment"
    | "rateDiscrepancy"
    | "reportingPeriodDiscrepancy"
    | "salesDataMismatch"
    | "unpaid";
export type PenaltyStatus = "unworked" | "waitingOnState" | "successfullyWaived" | "completed";
export type PenaltyType = "penalty" | "interest" | "penaltyAndInterest";

export const PenaltyReasons = {
    delinquentReturn: "Delinquent Return",
    delinquentPayment: "Delinquent Payment",
    rateDiscrepancy: "Rate Discrepancy",
    reportingPeriodDiscrepancy: "Reporting Period Discrepancy",
    salesDataMismatch: "Sales Data Mismatch",
    unpaid: "Unpaid",
} as { [key: string]: string };

export const PenaltyStatuses = {
    unworked: "Unworked",
    waitingOnState: "Waiting on State",
    successfullyWaived: "Successfully Waived",
    completed: "Completed",
} as { [key: string]: string };

export const PenaltyTypes = {
    penalty: "Penalty",
    interest: "Interest",
    penaltyAndInterest: "Penalty & Interest",
} as { [key: string]: string };

export const PenaltyReasonKeys = [...(Object.keys(PenaltyReasons) as PenaltyReason[])];
export const PenaltyStatusKeys = [...(Object.keys(PenaltyStatuses) as PenaltyStatus[])];
export const PenaltyTypeKeys = [...(Object.keys(PenaltyTypes) as PenaltyType[])];

export const finalizedPenaltyStatuses = ["successfullyWaived", "completed"];
