import { AccountRecord, IUserAndPermissions, MetaAccountRecord } from "@davo/types";
import { del, get, getArray, post, put } from "./api";

export async function getUserPermissionsForAccount(accountId: string) {
    return get<IUserAndPermissions[]>(`api/common/users/account/${accountId}`, true);
}

export const getAccounts = async (userId: string): Promise<AccountRecord[]> => {
    return getArray(`api/common/users/${userId}/accounts`, true, MetaAccountRecord);
};

export const inviteUser = async (accountId: string, email: string, role: string) => {
    await post(`api/common/account/${accountId}/users/invite`, { email, role }, true);
};

export async function expireCurrentInvitation(accountId: string, invitationId: string) {
    await put(`api/common/account/${accountId}/users/invite/${invitationId}/expire`, {}, true);
}

export const removeUserInvite = async (accountId: string, inviteId: string) => {
    await del(`api/common/account/${accountId}/users/invite/${inviteId}`, true);
};

export const updateAccountPermissions = async (userId: string, accountId: string, newRole: string) => {
    await put(`api/common/account/${accountId}/users/${userId}`, { role: newRole }, true);
};

export const removeAccountPermissions = async (userId: string, accountId: string) => {
    await del(`api/common/account/${accountId}/users/${userId}`, true, {});
};

export const updateUserSummaryPreferences = async (userId: string, accountId: string, isNewSummaryOptIn: boolean) => {
    await put(
        `api/common/account/${accountId}/users/${userId}/email/preferences`,
        { summaryOptIn: isNewSummaryOptIn },
        true
    );
};

export const updateAccountEmailPreferences = async (userId: string, accountId: string, isNewEmailOptOut: boolean) => {
    await put(
        `api/common/account/${accountId}/users/${userId}/email/preferences`,
        { emailOptOut: isNewEmailOptOut },
        true
    );
};
