import {
    ACHFailedStatusTypes,
    ACHFundedStatusTypes,
    ACHNonTerminalStatusTypes,
    ACHStoppedVoidedTypes,
    ACHTransactionStatus,
    initialCap,
} from "@davo/types";
import { useTheme } from "@mui/material";
import React from "react";

type Level = "ok" | "warn" | "error" | "none";
const colors = {
    warn: "#FFAB2B",
    ok: "#6DD230",
    error: "#FE4D97",
    none: "gray",
};
const bgcolors = {
    warn: "#fff8ee",
    ok: "#f3fbee",
    error: "#fef0f6",
    none: "#eeeeee",
};

const darkColors = {
    warn: "#FFAB2B",
    ok: "#6DD230",
    error: "#FE4D97",
    none: "white",
};
const darkBgcolors = {
    warn: "#323232",
    ok: "#323232",
    error: "#323232",
    none: "#323232",
};

export const ACHStatusLozenge = (status: ACHTransactionStatus) => {
    let lvl: Level = "none";
    if (Object.keys(ACHFailedStatusTypes).includes(status) || Object.keys(ACHStoppedVoidedTypes).includes(status)) {
        lvl = "error";
    } else if (Object.keys(ACHNonTerminalStatusTypes).includes(status)) {
        lvl = "warn";
    } else if (Object.keys(ACHFundedStatusTypes).includes(status)) {
        lvl = "ok";
    }
    return (
        <div style={{ textAlign: "left" }}>
            <Lozenge level={lvl}>{initialCap(status)}</Lozenge>
        </div>
    );
};

export function Lozenge({ level, children }: { level: Level; children: any }) {
    const theme = useTheme();
    if (theme.palette.mode === "dark") {
        return (
            <div
                style={{
                    display: "inline-block",
                    color: darkColors[level],
                    backgroundColor: darkBgcolors[level],
                    borderRadius: "500px",
                    padding: "5px",
                }}>
                {children}
            </div>
        );
    } else {
        return (
            <div
                style={{
                    display: "inline-block",
                    color: colors[level],
                    backgroundColor: bgcolors[level],
                    borderRadius: "500px",
                    padding: "5px",
                }}>
                {children}
            </div>
        );
    }
}
