import isNil from "lodash/isNil";
import { useEffect, useState } from "react";

const GlobalData: any = {};

export function setGlobal(key: string, val: any) {
    if (isNil(val)) {
        delete GlobalData[key];
    } else {
        GlobalData[key] = val;
    }

    const event = new CustomEvent("global-set", { detail: { key: key, newValue: val } });
    document.dispatchEvent(event);
}

export function getGlobal<T>(key: string): T | undefined {
    const val: any = GlobalData[key];
    return val ? (val as T) : undefined;
}

export function useGlobalOptional<T>(key: string): [T | undefined, (val: T | undefined) => void] {
    const [getGlobalInternal, setGlobalInternal] = useState<T | undefined>(getGlobal<T>(key));

    useEffect(() => {
        const handler = (e: Event) => {
            const event = e as CustomEvent;
            const eventKey = event.detail.key;
            const newValue = event.detail.newValue;

            if (eventKey === key) {
                setGlobalInternal(newValue);
            }
        };

        document.addEventListener("global-set", handler);

        // NOTE: this is the tear down function and removes the listener we added above when this component is removed.
        return () => {
            document.removeEventListener("global-set", handler);
        };
    }, [key]);

    return [
        getGlobalInternal,
        (val: T | undefined) => {
            setGlobal(key, val);
        },
    ];
}
