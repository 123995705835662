import { noop } from "@davo/types";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, List, ListItemButton, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { INavCommon, INavItem, NavItem } from "./NavItem";

export interface INavSubMenu extends INavCommon {
    items: INavItem[];
    destination?: string;
    onClick?: () => void;
}

export function DonutSubMenu({ name, slug, icon, badge, items, destination, onClick = noop }: INavSubMenu) {
    const navigate = useNavigate();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <ListItemButton
                style={{
                    borderLeft: `2px solid ${isDarkMode ? "#FFFFFF" : "#778ca2"}`,
                    margin: 0,
                    padding: 0,
                }}>
                <NavItem
                    key={`donut-sub-menu-nav-${slug}`}
                    slug={slug}
                    name={name}
                    icon={icon}
                    badge={badge}
                    onClick={() => {
                        if (!isOpen && destination) {
                            navigate(destination);
                        }
                        handleClick();
                        onClick();
                    }}
                />
                {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="nav" disablePadding>
                    {items.map((item) => (
                        <NavItem
                            key={`nav-item-${item.slug}`}
                            slug={item.slug}
                            name={item.name}
                            icon={item.icon}
                            badge={item.badge}
                            destination={item.destination}
                            onClick={onClick}
                        />
                    ))}
                </List>
            </Collapse>
        </>
    );
}
