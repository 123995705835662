import { MultiSelect } from "@davo/portal-common";
import { LocationRecord } from "@davo/types";
import { Button, Tooltip, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import orderBy from "lodash/orderBy";
import React, { useEffect, useState } from "react";

export interface ISelectBoardingLocationsType {
    locations: LocationRecord[];
    onContinue: (selectedLocations: LocationRecord[]) => void;
}

export function SelectBoardingLocations({ locations, onContinue }: ISelectBoardingLocationsType) {
    const [available, setAvailable] = useState<LocationRecord[]>();
    const [selected, setSelected] = useState<LocationRecord[]>();
    const [boarded, setBoarded] = useState<LocationRecord[]>();

    useEffect(() => {
        const availableLocations = locations.filter((l) => !l.accountId && l.active);
        const boardedLocations = locations.filter((l) => l.accountId && l.active);

        if (availableLocations.length === 0) {
            onContinue([]);
            return;
        } else if (availableLocations.length === 1 && boardedLocations.length === 0) {
            // If there's a single location total, then skip the picker, select it and return
            onContinue([availableLocations[0]]);
            return;
        }
        setAvailable(orderBy(availableLocations, "name"));
        setBoarded(boardedLocations);
        setSelected(availableLocations);
    }, [locations, onContinue]);

    if (isNil(available) || isNil(selected) || isNil(boarded)) {
        return null;
    }

    const formatLocationListItem = (l: LocationRecord) => (
        <Typography
            style={{
                maxWidth: "290px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                color: "#000",
            }}
            variant="body1">
            <Tooltip title={l.name}>
                <span>{l.name}&nbsp;&nbsp;</span>
            </Tooltip>
        </Typography>
    );

    return (
        <div data-testid={"selectBoardingLocations"}>
            <div>Select one or more locations to enable DAVO:</div>
            <div style={{ overflowY: "auto", maxHeight: "800px", marginBottom: "20px", marginTop: "20px" }}>
                <MultiSelect<LocationRecord>
                    style={{ textOverflow: "ellipsis" }}
                    options={available}
                    value={selected}
                    label={formatLocationListItem}
                    onChange={setSelected}
                    showSelectAll={true}
                    selectAllStyle={{ color: "#000" }}
                />
            </div>

            {!isEmpty(boarded) && (
                <>
                    <p>You&apos;ve already enabled DAVO for the following location{boarded.length > 1 ? "s" : ""}:</p>
                    <div style={{ marginBottom: "20px", overflowY: "auto", maxHeight: "500px" }}>
                        <ul style={{ color: "gray" }}>
                            {boarded.map((l) => (
                                <li key={l.id} style={{ paddingBottom: "10px" }} data-testid={`boarded-${l.id}`}>
                                    <div
                                        style={{
                                            maxWidth: "270px",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                        }}>
                                        <Tooltip title={l.name}>
                                            <span>{l.name}</span>
                                        </Tooltip>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}

            <div style={{ textAlign: "center" }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        marginTop: "20px",
                        marginBottom: "10px",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        onContinue(selected);
                    }}
                    disabled={isEmpty(boarded) && isEmpty(selected)}>
                    Continue
                </Button>
            </div>
        </div>
    );
}
