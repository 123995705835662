import { createTheme } from "@mui/material";

const expandArrowOverrides = {
    [`.MuiAccordionSummary-root:not(.Mui-expanded) svg[data-testid="ExpandMoreIcon"]`]: {
        transform: "rotate(-0.25turn)",
    },
    [`.MuiAccordionSummary-root.Mui-expanded svg[data-testid="ExpandMoreIcon"]`]: {
        transform: "rotate(0.50turn)",
    },
};

export const lightTheme = createTheme({
    palette: {
        primary: { main: "#4D7CFE" },
        secondary: { main: "#e03c75" },
    },
    typography: {
        fontFamily: "Rubik, sans-serif",
    },
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    ...expandArrowOverrides,
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    color: "#778ca2",
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: "#778ca2",
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingTop: "15px",
                    paddingBottom: "15px",
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                title: {
                    fontSize: "25px",
                },
                subheader: {
                    fontSize: "15px",
                    color: "#98a9bc",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: "bold",
                },
                outlined: {
                    fontWeight: "bold",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    boxShadow: "none",
                    padding: "15px",
                },
            },
        },
    },
});

export const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: { main: "#78a0cd" },
        secondary: { main: "#e03c75" },
    },
    typography: {
        fontFamily: "Rubik, sans-serif",
    },
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingTop: "15px",
                    paddingBottom: "15px",
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                title: {
                    fontSize: "25px",
                },
                subheader: {
                    fontSize: "15px",
                    color: "#98a9bc",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: "bold",
                },
                outlined: {
                    fontWeight: "bold",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    boxShadow: "none",
                    padding: "15px",
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    ...expandArrowOverrides,
                },
            },
        },
    },
});

export const fourthTheme = createTheme({
    palette: {
        mode: "light",
        primary: { main: "#39ff14" },
        secondary: { main: "#001eff" },
    },
    typography: {
        fontFamily: '"Comic Sans MS", "Comic Sans", cursive',
        allVariants: {
            color: "#39ff14",
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FF10F0",
                    color: "#39ff14",
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingTop: "15px",
                    paddingBottom: "15px",
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                title: {
                    fontSize: "25px",
                },
                subheader: {
                    fontSize: "15px",
                    color: "#39ff14",
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    color: "#39ff14",
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: "#39ff14",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: "bold",
                },
                outlined: {
                    fontWeight: "bold",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    boxShadow: "none",
                    padding: "15px",
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    ...expandArrowOverrides,
                },
            },
        },
    },
});
