import {
    d30ToastError,
    getAdditionalSalesManualActivityWithSetAsideStatus,
    getUseTaxAmounts,
    Header,
    ManualActivityModal,
    ManualActivityTable,
    useLoginContext,
    useRefresh,
    UseTaxModal,
    UseTaxTable,
} from "@davo/portal-common";
import { LocationSelector } from "@davo/portal-common/lib/LocationSelector";
import { BusinessDay, IManualActivity, IUseTax, LocationRecord, TaxProfile, toSQLDate } from "@davo/types";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import InfoIcon from "@mui/icons-material/Info";
import { Alert, Button, Paper, Tooltip } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { LimitedTimeMerchantBanner } from "./LimitedTimeMerchantBanner";
import { MerchantBanner } from "./MerchantBanner";
import { useAccountContext } from "./context";
import { hasPermission } from "./util";

const showAdditionalSales = (isAdditionalSalesDataRequired: boolean): boolean => isAdditionalSalesDataRequired;

const showUseTax = (isUseDataRequired: boolean, userPermissions: string[] | undefined): boolean =>
    isUseDataRequired && hasPermission(userPermissions, "view_use_tax");

const findMerchantDataTabAccountRequirements = (taxProfiles: TaxProfile[]) => {
    let isAdditionalSalesDataRequired = false;
    let isUseDataRequired = false;

    taxProfiles.forEach((tp) => {
        if (tp.requireAdditionalSalesData) {
            isAdditionalSalesDataRequired = true;
        }
        if (tp.requireUseData) {
            isUseDataRequired = true;
        }
    });

    return { isAdditionalSalesDataRequired, isUseDataRequired };
};

export const showMerchantDataTab = (taxProfiles: TaxProfile[], userPermissions: string[] | undefined) => {
    const { isAdditionalSalesDataRequired, isUseDataRequired } = findMerchantDataTabAccountRequirements(taxProfiles);
    return showAdditionalSales(isAdditionalSalesDataRequired) || showUseTax(isUseDataRequired, userPermissions);
};

export function MerchantDataTab() {
    const loginContext = useLoginContext();
    const accountContext = useAccountContext();

    const [currentLocation, setCurrentLocation] = useState<LocationRecord>();
    const [useTaxes, setUseTaxes] = useState<IUseTax[]>();
    const [periodStart, setPeriodStart] = useState<BusinessDay>();
    const [periodEnd, setPeriodEnd] = useState<BusinessDay>();
    const [activeLocations, setActiveLocations] = useState<LocationRecord[]>([]);
    const [manualActivities, setManualActivities] = useState<
        (IManualActivity & { manualSetAsideStatus: string | undefined })[] | null
    >();
    const [showAdditionalSalesComponents, setShowAdditionalSalesComponents] = useState<boolean>(false);
    const [showUseTaxComponents, setShowUseTaxComponents] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const [refresh] = useRefresh(() => {
        searchByDate().catch((e) => {
            d30ToastError("Could not refresh data!", e);
        });
    });

    useAsyncEffect(async () => {
        if (
            !accountContext.locations ||
            (accountContext.locations && !accountContext.locations.filter((l) => l.active))
        ) {
            return;
        }
        setActiveLocations(accountContext.locations.filter((l) => l.active && !l.disconnected));

        const endDate = toSQLDate(DateTime.now());
        const endBusinessDay = BusinessDay.fromSQLDate(endDate);
        setPeriodEnd(endBusinessDay);

        const startBusinessDay = BusinessDay.fromSQLDate(
            DateTime.fromSQL(endDate).get("month") === 1
                ? toSQLDate(DateTime.now().minus({ year: 1 }).startOf("year"))
                : toSQLDate(DateTime.now().startOf("year"))
        );
        setPeriodStart(startBusinessDay);
        const locations = accountContext.locations.filter((l) => l.active).map((x: LocationRecord) => x.id);
        await getTaxAmounts(startBusinessDay, endBusinessDay, locations);
    }, [accountContext.locations]);

    useEffect(() => {
        if (!accountContext.taxProfiles) {
            return;
        }
        const { isAdditionalSalesDataRequired, isUseDataRequired } = findMerchantDataTabAccountRequirements(
            accountContext.taxProfiles
        );
        setShowAdditionalSalesComponents(showAdditionalSales(isAdditionalSalesDataRequired));
        setShowUseTaxComponents(showUseTax(isUseDataRequired, loginContext.permissions));
    }, [accountContext.taxProfiles]);

    useAsyncEffect(async () => {
        await searchByDate();
    }, [currentLocation]);

    const getTaxAmounts = async (startDay: BusinessDay, endDay: BusinessDay, locationIds: string[]) => {
        const use = getUseTaxAmounts(locationIds, startDay, endDay);
        const manual = getAdditionalSalesManualActivityWithSetAsideStatus(locationIds, startDay, endDay);
        const [useRes, manualRes] = await Promise.all([use, manual]);

        setUseTaxes(useRes);
        setManualActivities(manualRes);
    };

    const searchByDate = async () => {
        if (
            !accountContext.locations ||
            (accountContext.locations && !accountContext.locations.filter((l) => l.active))
        ) {
            return;
        }
        if (periodStart && periodEnd) {
            const locationIds = currentLocation
                ? [currentLocation.id]
                : accountContext.locations.filter((l) => l.active).map((x: LocationRecord) => x.id);
            await getTaxAmounts(periodStart, periodEnd, locationIds);
        }
    };

    useEffect(() => {
        if (!periodStart || !periodEnd || periodStart.toDateTime() > periodEnd.toDateTime()) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [periodStart, periodEnd]);

    const onSetLocation = (location: LocationRecord | undefined) => {
        setCurrentLocation(location);
    };

    if (
        !accountContext.account ||
        !accountContext.locations ||
        (accountContext.locations && !accountContext.locations.filter((l) => l.active)) ||
        !accountContext.taxProfiles
    ) {
        return null;
    }

    return (
        <div>
            <MerchantBanner />
            <LimitedTimeMerchantBanner />
            <LocationSelector
                accountId={accountContext.account.id}
                locations={accountContext.locations}
                onLocationChange={onSetLocation}
            />
            <Paper
                style={{
                    padding: "24px",
                    paddingBottom: "50px",
                    boxShadow: "4px 4px 5px -3px rgba(219,219,219,1)",
                    marginBottom: "50px",
                }}>
                <Header>
                    <span style={{ color: "rgb(3, 187, 156)", marginLeft: "15px" }}>Add More</span>
                </Header>
                <h4>
                    <div
                        style={{
                            color: "#1a2f5b",
                            width: "50%",
                            marginLeft: "15px",
                            display: "flex",
                            flexDirection: "row",
                        }}>
                        <span>For additional data that can&apos;t be captured in your POS, add it here.</span>
                        <Tooltip title="You’ll be asked to enter the sales tax amount, tax rate and total sales to which it applies.  The ability to add data locks after the 4th of the following month.">
                            <div style={{ textAlign: "right", marginTop: "2px" }}>
                                <InfoIcon fontSize={"small"} color="primary" />
                            </div>
                        </Tooltip>
                    </div>
                </h4>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "5px",
                    }}>
                    {showAdditionalSalesComponents && (
                        <div style={{ margin: "5px 10px" }}>
                            <ManualActivityModal
                                isDisabled={false}
                                canAddNewTaxRate={false}
                                accountId={accountContext.account.id}
                                defaultLocation={currentLocation}
                                locations={activeLocations}
                                isMerchantPay={accountContext.taxProfiles.some(
                                    (tp: TaxProfile) =>
                                        tp.filingMethod === "file-only" || tp.filingMethod === "merchant-pay"
                                )}
                                refresh={refresh}
                                maxTaxCollectedAllowed={10000}
                                useDefaultPeriod={false}
                            />
                        </div>
                    )}
                    {showUseTaxComponents && (
                        <div style={{ margin: "5px 10px" }}>
                            <UseTaxModal
                                refresh={refresh}
                                accountId={accountContext.account.id}
                                locations={activeLocations}
                            />
                        </div>
                    )}
                </div>
            </Paper>

            <Paper style={{ padding: "24px", paddingBottom: "0px", boxShadow: "none" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {periodStart && periodEnd && (
                                <div
                                    style={{
                                        textAlign: "left",
                                        alignSelf: "center",
                                        flexGrow: 10,
                                        color: "rgb(3, 187, 156)",
                                        fontWeight: 600,
                                    }}></div>
                            )}
                            <Tooltip
                                title={"search by periods to see a history of additional added sales tax info"}
                                placement="left-start">
                                <HelpTwoToneIcon fontSize="small" color="primary" style={{ alignSelf: "center" }} />
                            </Tooltip>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ marginLeft: "10px" }}>
                                        <MobileDatePicker
                                            views={["year", "month"]}
                                            label="Start Date"
                                            value={periodStart ? periodStart.toDateTime().toJSDate() : null}
                                            closeOnSelect={true}
                                            onChange={(x) => {
                                                setPeriodStart(
                                                    x
                                                        ? BusinessDay.fromSQLDate(toSQLDate(DateTime.fromJSDate(x)))
                                                        : undefined
                                                );
                                            }}
                                            format="MM-yyyy"
                                            minDate={DateTime.now().minus({ years: 4 }).toJSDate()}
                                            maxDate={DateTime.now().toJSDate()}
                                            slotProps={{
                                                textField: { style: { width: "100%", margin: "20px 0" } },
                                                toolbar: { hidden: true },
                                            }}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            marginLeft: "10px",
                                            flexDirection: "column",
                                            marginBottom: "unset",
                                        }}>
                                        <MobileDatePicker
                                            views={["year", "month"]}
                                            label="End Date"
                                            value={periodEnd ? periodEnd.toDateTime().toJSDate() : null}
                                            closeOnSelect={true}
                                            onChange={(x) => {
                                                setPeriodEnd(
                                                    x
                                                        ? BusinessDay.fromSQLDate(toSQLDate(DateTime.fromJSDate(x)))
                                                        : undefined
                                                );
                                            }}
                                            format="MM-yyyy"
                                            minDate={DateTime.now().minus({ years: 4 }).toJSDate()}
                                            maxDate={DateTime.now().plus({ years: 1 }).toJSDate()}
                                            slotProps={{
                                                textField: { style: { width: "100%", margin: "20px 0" } },
                                                toolbar: { hidden: true },
                                            }}
                                        />
                                    </div>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        style={{
                                            marginLeft: "10px",
                                            height: "55px",
                                            display: "flex",
                                            alignSelf: "center",
                                        }}
                                        color="primary"
                                        onClick={() => searchByDate()}
                                        disabled={hasError}>
                                        Search
                                    </Button>
                                </div>
                                <span
                                    style={{
                                        color: "#1a2f5b",
                                        fontSize: "12px",
                                        fontStyle: "italic",
                                        alignSelf: "center",
                                    }}></span>
                            </div>
                        </LocalizationProvider>
                    </div>
                    <span
                        style={{
                            fontSize: "14px",
                            fontWeight: "light",
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "11px",
                        }}>
                        {hasError && <Alert severity="error">Start date must be before end date!</Alert>}
                        {(!manualActivities || manualActivities.length === 0) && (
                            <Alert severity="info">No data available for these dates. Please search again.</Alert>
                        )}
                    </span>
                    {showAdditionalSalesComponents && (
                        <ManualActivityTable
                            additionalSalesManualActivity={manualActivities}
                            filterLocations={accountContext.locations.filter((l) => l.active)}
                        />
                    )}
                    {showUseTaxComponents && (
                        <UseTaxTable
                            useTax={useTaxes}
                            filterLocations={accountContext.locations.filter((l) => l.active)}
                        />
                    )}
                </div>
            </Paper>
        </div>
    );
}
