import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface IRouteModalProps {
    title?: string;
    body?: string;
    isDisabled?: boolean;
    action?: () => Promise<void> | void;
    actionLabel?: string;
    children?: any;
    onCancel?: () => Promise<void> | void;
    hideButtons?: boolean;
    closeDialog?: (target?: string) => void;
}

export function RouteModal({
    title,
    body,
    isDisabled,
    action,
    actionLabel = "Save",
    children,
    onCancel,
    hideButtons,
    closeDialog,
}: IRouteModalProps) {
    const navigate = useNavigate();
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const cancel = async () => {
        navigate(-1);
        if (onCancel) {
            await onCancel();
        }
        if (closeDialog) {
            closeDialog();
        }
    };

    const doAction = async () => {
        setIsBusy(true);
        try {
            if (action) {
                await action();
            }
            setIsBusy(false);
            navigate(-1);
            if (closeDialog) {
                closeDialog();
            }
        } catch (e: any) {
            setIsBusy(false);
        }
    };

    let buttons;
    if (!hideButtons) {
        buttons = (
            <div>
                <Button
                    disabled={isBusy}
                    onClick={cancel}
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "10px" }}>
                    Cancel
                </Button>
                <Button
                    startIcon={isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />}
                    disabled={!!isDisabled || isBusy}
                    onClick={doAction}
                    variant="contained"
                    color="primary"
                    autoFocus>
                    {actionLabel}
                </Button>
            </div>
        );
    }

    return (
        <Dialog
            data-testid={"routeModalContainer"}
            fullScreen={makeFullScreen}
            open={true}
            aria-labelledby="responsive-dialog-title"
            style={{ boxShadow: "none", padding: "10px" }}
            onClose={async () => {
                await cancel();
            }}>
            {title && <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>}
            <DialogContent>
                {body && <DialogContentText>{body}</DialogContentText>}
                {children}
            </DialogContent>
            <DialogActions>{buttons ? buttons : ""}</DialogActions>
        </Dialog>
    );
}
