import { IAvaTaxValidatedAddressWithAdditionalFields, LocationRecord, normalizeAddressWords } from "@davo/types";
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import isNil from "lodash/isNil";
import React from "react";
import { RenderAddress } from "./RenderAddress";

const useStyles = makeStyles(() => ({
    card: {
        border: "1px solid #808080",
        marginBottom: "15px",
        borderRadius: "4px",
        padding: "6px",
    },
}));

export interface IAddressVerificationFormProps {
    location: LocationRecord;
    verifiedAddress: IAvaTaxValidatedAddressWithAdditionalFields;
    showCancel: boolean;
    onSubmitUseAddress: (updateType: "existing" | "suggested", data: LocationRecord) => void;
    onSubmitEditAddress: (thing?: string | undefined) => void;
    onCancel?: () => void;
    isVerifyingAddress: {
        verifying: boolean;
        updateType: "existing" | "suggested" | undefined;
    };
}

export function AddressVerificationForm({
    location,
    verifiedAddress,
    showCancel,
    onSubmitUseAddress,
    onSubmitEditAddress,
    onCancel,
    isVerifyingAddress,
}: IAddressVerificationFormProps) {
    const classes = useStyles();

    if (!location || !verifiedAddress) {
        return null;
    }

    return (
        <div data-testid={"addressVerificationForm"}>
            <Card className={classes.card} data-testid={"currentAddress"}>
                <CardHeader
                    title={
                        <Typography
                            variant={"h4"}
                            style={{ fontSize: "16px", fontWeight: "bold", display: "inline-block" }}>
                            Current Address:
                        </Typography>
                    }
                />
                <CardContent>
                    <RenderAddress
                        name={location.name}
                        address1={location.address1}
                        address2={location.address2}
                        city={location.city}
                        state={location.state}
                        zip={location.zip}
                    />
                </CardContent>
                <div style={{ flexGrow: "1" }} />
                <CardActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        disabled={isVerifyingAddress.verifying}
                        onClick={() => onSubmitEditAddress(location.id)}
                        style={{ margin: "0 6px" }}>
                        {"Edit"}
                    </Button>
                    <Button
                        data-testid={"useExistingAddressBtn"}
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={
                            isVerifyingAddress.updateType === "existing" && (
                                <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                            )
                        }
                        disabled={isVerifyingAddress.verifying}
                        onClick={() =>
                            onSubmitUseAddress("existing", {
                                ...location,
                                state: location.state && normalizeAddressWords(location.state),
                                county:
                                    // We will just default to the AvaTax county if it exists.
                                    verifiedAddress && !isNil(verifiedAddress.county) && isNil(location.county)
                                        ? verifiedAddress.county
                                        : location.county,
                            })
                        }
                        style={{ margin: "0 6px" }}>
                        {"Select this address"}
                    </Button>
                </CardActions>
            </Card>

            {verifiedAddress.validationType && ["mismatch"].includes(verifiedAddress.validationType) && (
                <Card className={classes.card} data-testid={"suggestedAddress"}>
                    <CardHeader
                        title={
                            <Typography
                                variant={"h4"}
                                style={{ fontSize: "16px", fontWeight: "bold", display: "inline-block" }}>
                                Suggested Address:
                            </Typography>
                        }
                    />

                    <CardContent>
                        <RenderAddress
                            name={verifiedAddress.name}
                            address1={verifiedAddress.address1}
                            address2={verifiedAddress.address2}
                            city={verifiedAddress.city}
                            state={verifiedAddress.state}
                            zip={verifiedAddress.zip}
                        />
                    </CardContent>
                    <div style={{ flexGrow: "1" }} />
                    <CardActions>
                        <Button
                            data-testid={"useSuggestedAddressBtn"}
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={
                                isVerifyingAddress.updateType === "suggested" && (
                                    <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                                )
                            }
                            disabled={isVerifyingAddress.verifying}
                            onClick={() =>
                                onSubmitUseAddress("suggested", {
                                    ...location,
                                    name: verifiedAddress.name,
                                    address1: verifiedAddress.address1,
                                    address2: verifiedAddress.address2,
                                    city: verifiedAddress.city,
                                    state: verifiedAddress.state,
                                    county: verifiedAddress.county,
                                    zip: verifiedAddress.zip,
                                })
                            }
                            style={{ margin: "0 6px" }}>
                            {"Use suggested address"}
                        </Button>
                    </CardActions>
                </Card>
            )}

            {verifiedAddress.validationType && ["error", "unvalidated"].includes(verifiedAddress.validationType) && (
                <Typography data-testid={"noSuggestions"} style={{ fontSize: "18px", marginTop: "15px" }}>
                    No suggested addresses
                </Typography>
            )}

            {showCancel && (
                <Button
                    data-testid={"editAddressCancel"}
                    onClick={() => {
                        if (onCancel) {
                            onCancel();
                        }
                    }}
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "20px", float: "right" }}>
                    {"Cancel"}
                </Button>
            )}
        </div>
    );
}
