import { DateTime } from "luxon";

export interface IPubSubEvent {
    id: number;
    channel: string;
    event: any;
    timestamp: DateTime;
}

export interface IPubSubReliableConsumer {
    id: string;
    lastId: number;
}
