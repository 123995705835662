import {
    IJurisdictionFilingType,
    IJurisdictionUIField,
    IJurisdictionUIFieldDefinitionBase,
    LinkedTaxProfileType,
    LoginCredentialValidationStatus,
    TaxProfileCredentials,
} from "@davo/types";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Alert, AlertTitle, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { MouseEvent, useCallback, useState } from "react";
import { CredentialFlavorSelect } from "./CredentialFlavorSelect";
import { TextField } from "./TextField";
import { ValidateCredentialsStatus } from "./ValidateCredentialsStatus";
import { ValidateTaxCredentialsButton } from "./ValidateTaxCredentialsButton";
import { useConfigCommonContext } from "./context";

export interface ICredentialsEntry {
    hasFailingCredentials: boolean;
    failingCredentialsMessage: string;
    linked?: LinkedTaxProfileType;
    isGroupLoginRequired: boolean;
    filingType: IJurisdictionFilingType | null | undefined;
    isUsernameRequired: boolean;
    isPasswordRequired: boolean;
    isPinRequired: boolean;
    siteUrl: string;
    nameString: string;
    selectedFlavor: IJurisdictionUIField | undefined;
    handleCredentialFlavorChange: (flavor: IJurisdictionUIField) => void;
    usernameFieldDefinition: IJurisdictionUIFieldDefinitionBase | undefined;
    invalidCredentialFields: string[];
    credentials: TaxProfileCredentials;
    isBusy: boolean;

    setCredentials(v: TaxProfileCredentials): void;

    passwordFieldDefinition: IJurisdictionUIFieldDefinitionBase | undefined;
    pinFieldDefinition: IJurisdictionUIFieldDefinitionBase | undefined;
    credsValidationStatus: LoginCredentialValidationStatus;
    areAllCredentialFieldsValid: boolean;
    abortCredsCheck: () => void;
    checkCreds: () => void;
    areExistingCredentialsReused: boolean;
}

export const CredentialsEntry: React.FunctionComponent<ICredentialsEntry> = ({
    hasFailingCredentials,
    failingCredentialsMessage,
    linked,
    isGroupLoginRequired,
    filingType,
    isUsernameRequired,
    isPasswordRequired,
    isPinRequired,
    siteUrl,
    nameString,
    selectedFlavor,
    handleCredentialFlavorChange,
    usernameFieldDefinition,
    passwordFieldDefinition,
    pinFieldDefinition,
    invalidCredentialFields,
    credentials,
    isBusy,
    setCredentials,
    credsValidationStatus,
    areAllCredentialFieldsValid,
    abortCredsCheck,
    checkCreds,
    areExistingCredentialsReused,
}) => {
    const { commonConfigInfo: configInfo } = useConfigCommonContext();
    const [hidePassword, setHidePassword] = useState<boolean>(true);
    const handleClickHidePassword = () => setHidePassword((hide) => !hide);
    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => event.preventDefault();

    const credentialUpdateHandler = useCallback(
        (v: TaxProfileCredentials) => {
            setCredentials(v);
        },
        [setCredentials]
    );

    const _showCheckCredentials = () => {
        if (!filingType) {
            return false;
        }
        return (
            (configInfo.credsCheck.davoEnabled && filingType.davoCheckCreds) ||
            (configInfo.credsCheck.skyScraperEnabled && filingType.skyscraperCheckCreds)
        );
    };

    return (
        <div data-testid={"credentialsEntryFields"}>
            {!linked && (
                <div>
                    <Typography className={"da-tp-form-heading"} component="h3">
                        State Login
                    </Typography>

                    {filingType?.url && (isUsernameRequired || isPasswordRequired || isPinRequired) && (
                        <Typography gutterBottom={true}>
                            Enter your {filingType?.siteName ?? "site"} login details for the{" "}
                            {isGroupLoginRequired ? (
                                <Tooltip
                                    placement={"top"}
                                    title={
                                        "The information provided will be used to add your business to DAVO's group account with this site"
                                    }>
                                    <a href={siteUrl} target="_blank" rel="noreferrer">
                                        {nameString}
                                    </a>
                                </Tooltip>
                            ) : (
                                <a href={siteUrl} target="_blank" rel="noreferrer">
                                    {nameString}
                                </a>
                            )}{" "}
                            so DAVO can file for you.
                        </Typography>
                    )}
                </div>
            )}

            <CredentialFlavorSelect
                selectedFlavor={selectedFlavor}
                handleCredentialFlavorChange={handleCredentialFlavorChange}
                filingType={filingType}
            />

            {isUsernameRequired && usernameFieldDefinition && (
                <TextField
                    margin={"dense"}
                    data-testid={"usernameContainer"}
                    inputProps={{
                        [`data-testid`]: "usernameInput",
                    }}
                    InputProps={{
                        endAdornment: usernameFieldDefinition.toolTip && (
                            <Tooltip title={usernameFieldDefinition.toolTip}>
                                <InfoIcon fontSize={"small"} color="primary" />
                            </Tooltip>
                        ),
                    }}
                    validate={() =>
                        usernameFieldDefinition.label && invalidCredentialFields.includes(usernameFieldDefinition.label)
                            ? "Invalid"
                            : undefined
                    }
                    value={credentials.username}
                    isDisabled={isBusy || credsValidationStatus === "validating"}
                    className="fs-exclude"
                    onChange={(v) => credentialUpdateHandler({ ...credentials, username: v })}
                    label={`${usernameFieldDefinition.label} ${
                        usernameFieldDefinition.helpText ? ` (e.g. ${usernameFieldDefinition.helpText})` : ""
                    }`}
                    isRequired
                />
            )}

            {isPasswordRequired && passwordFieldDefinition && (
                <TextField
                    margin={"dense"}
                    data-testid={"passwordContainer"}
                    inputProps={{
                        [`data-testid`]: "passwordInput",
                    }}
                    InputProps={{
                        endAdornment: (
                            <>
                                {passwordFieldDefinition.toolTip && (
                                    <Tooltip title={passwordFieldDefinition.toolTip}>
                                        <InfoIcon fontSize={"small"} color="primary" />
                                    </Tooltip>
                                )}
                                <IconButton
                                    data-testid={"togglePwdVisibility"}
                                    aria-label="toggle password visibility"
                                    onClick={handleClickHidePassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end">
                                    {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </>
                        ),
                    }}
                    validate={() =>
                        passwordFieldDefinition.label && invalidCredentialFields.includes(passwordFieldDefinition.label)
                            ? "Invalid"
                            : undefined
                    }
                    value={credentials.password}
                    className="fs-exclude"
                    isDisabled={isBusy || credsValidationStatus === "validating"}
                    isPassword={hidePassword}
                    onChange={(v) => credentialUpdateHandler({ ...credentials, password: v })}
                    label={`${passwordFieldDefinition.label} ${
                        passwordFieldDefinition.helpText ? ` (e.g. ${passwordFieldDefinition.helpText})` : ""
                    }`}
                    isRequired
                />
            )}

            {isPinRequired && pinFieldDefinition && (
                <TextField
                    margin={"dense"}
                    data-testid={"pinContainer"}
                    inputProps={{
                        [`data-testid`]: "pinInput",
                    }}
                    InputProps={{
                        endAdornment: pinFieldDefinition.toolTip && (
                            <Tooltip title={pinFieldDefinition.toolTip}>
                                <InfoIcon fontSize={"small"} color="primary" />
                            </Tooltip>
                        ),
                    }}
                    validate={() =>
                        pinFieldDefinition.label && invalidCredentialFields.includes(pinFieldDefinition.label)
                            ? "Invalid"
                            : undefined
                    }
                    className="fs-exclude"
                    value={credentials.pin}
                    onChange={(v) => credentialUpdateHandler({ ...credentials, pin: v })}
                    label={`${pinFieldDefinition.label} ${
                        pinFieldDefinition.helpText ? ` (e.g. ${pinFieldDefinition.helpText})` : ""
                    }`}
                    isRequired
                    isDisabled={isBusy || credsValidationStatus === "validating"}
                />
            )}

            {filingType?.twoFactorRequired && (
                <Alert
                    data-testid={"twoFactorRequiredMsg"}
                    style={{ margin: "8px 0" }}
                    icon={<PhonelinkLockIcon />}
                    variant={"filled"}
                    color={"info"}>
                    If your state account requires 2-factor authentication, you may need to provide DAVO support with
                    the code for first-time setup
                </Alert>
            )}

            {filingType && _showCheckCredentials() && (
                <Stack justifyContent={"space-between"} direction="column" spacing={1}>
                    <ValidateTaxCredentialsButton
                        areExistingCredentialsReused={areExistingCredentialsReused}
                        credsValidationStatus={credsValidationStatus}
                        jurisdictionFilingTypeId={filingType.id}
                        areAllCredentialFieldsValid={areAllCredentialFieldsValid}
                        validateCredentials={checkCreds}
                    />

                    {credsValidationStatus && (
                        <ValidateCredentialsStatus
                            credsValidationStatus={credsValidationStatus}
                            abortCredsCheck={abortCredsCheck}
                        />
                    )}
                </Stack>
            )}

            {hasFailingCredentials && credsValidationStatus === undefined && (
                <Alert icon={<ErrorIcon />} variant={"outlined"} severity={"error"} style={{ margin: "6px 0" }}>
                    <AlertTitle>Invalid credentials</AlertTitle>
                    {failingCredentialsMessage && (
                        <em data-testid={"failingCredentialsMessage"}>{failingCredentialsMessage}</em>
                    )}
                </Alert>
            )}
        </div>
    );
};
