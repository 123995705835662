import { LoginForm } from "@davo/portal-common";
import { IMerchantInvitationInfo, POSType } from "@davo/types";
import React from "react";
import { useConfigMerchantContext } from "../../context";
import { IMarketingInfo } from "../types/MarketingInfo";

export interface IBoardingLoginType {
    invitationInfo?: IMerchantInvitationInfo;
    marketingInfo?: IMarketingInfo;
    posType?: POSType;
}

export function BoardingLogin({ invitationInfo, marketingInfo, posType }: IBoardingLoginType) {
    const { merchantConfigInfo: configInfo } = useConfigMerchantContext();
    const getMarketingName = () => {
        if (marketingInfo && (marketingInfo.firstName || marketingInfo.lastName)) {
            return `${marketingInfo.firstName} ${marketingInfo.lastName}`.trim();
        }
        return "";
    };

    return (
        <div data-testid={"boardingLogin"}>
            {invitationInfo?.hasAccount && invitationInfo?.firstName && (
                <>
                    <div className="fs-exclude" style={{ marginBottom: "20px" }}>
                        Hello {invitationInfo.firstName}.
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        To add one or more locations to your existing user account, please log in below.
                    </div>
                </>
            )}

            <LoginForm
                shouldCreateOnly={false}
                showCreate={true}
                disableInternalLogin={false}
                useAlternateLogo={true}
                merchantInvitationId={invitationInfo?.invitationId}
                email={invitationInfo?.email}
                doesExist={invitationInfo?.hasAccount}
                googleOAuthClientId={configInfo.googleOAuthClientId}
                isAvalaraIdentityEnabled={false}
                name={getMarketingName()}
                appType="merchantPortal"
                posType={posType}
            />
        </div>
    );
}
