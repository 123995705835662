import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    useTheme,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import PropTypes from "prop-types";
import React from "react";
import {
    Column,
    ColumnExtended,
    ColumnInstance,
    Hooks,
    Row,
    TableOptions,
    defaultColumn,
    useExpanded,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from "react-table";
import { v4 as uuid } from "uuid";
import ReactTableToolbar from "./ReactTableToolbar";

// NOTE: here we're doing something called Interface Merging where we're effectively overwriting types of the same name from the real 'react-table' definition
declare module "react-table" {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    export interface TableOptions<D>
        extends UseExpandedOptions<D>,
            UseFiltersOptions<D>,
            UseGlobalFiltersOptions<D>,
            UseGroupByOptions<D>,
            UsePaginationOptions<D>,
            UseResizeColumnsOptions<D>,
            UseRowSelectOptions<D>,
            UseRowStateOptions<D>,
            UseSortByOptions<D>,
            // note that having Record here allows you to add anything to the options, this matches the spirit of the
            // underlying js library, but might be cleaner if it's replaced by a more specific type that matches your
            // feature set, this is a safe default.
            Record<string, any> {}

    // eslint-disable-next-line @typescript-eslint/naming-convention
    export interface Hooks<D>
        extends UseExpandedHooks<D>,
            UseGroupByHooks<D>,
            UseRowSelectHooks<D>,
            UseSortByHooks<D>,
            UseTableHooks<D> {}

    // eslint-disable-next-line @typescript-eslint/naming-convention
    export interface TableInstance<D>
        extends UseColumnOrderInstanceProps<D>,
            UseExpandedInstanceProps<D>,
            UseFiltersInstanceProps<D>,
            UseGlobalFiltersInstanceProps<D>,
            UseGroupByInstanceProps<D>,
            UsePaginationInstanceProps<D>,
            UseRowSelectInstanceProps<D>,
            UseRowStateInstanceProps<D>,
            UseSortByInstanceProps<D> {}

    // eslint-disable-next-line @typescript-eslint/naming-convention
    export interface TableState<D>
        extends UseColumnOrderState<D>,
            UseExpandedState<D>,
            UseFiltersState<D>,
            UseGlobalFiltersState<D>,
            UseGroupByState<D>,
            UsePaginationState<D>,
            UseResizeColumnsState<D>,
            UseRowSelectState<D>,
            UseRowStateState<D>,
            UseSortByState<D> {}

    // eslint-disable-next-line @typescript-eslint/naming-convention
    export interface ColumnInterface<D>
        extends UseFiltersColumnOptions<D>,
            UseGlobalFiltersColumnOptions<D>,
            UseGroupByColumnOptions<D>,
            UseResizeColumnsColumnOptions<D>,
            UseSortByColumnOptions<D> {}

    // eslint-disable-next-line @typescript-eslint/naming-convention
    export interface ColumnInstance<D>
        extends UseFiltersColumnProps<D>,
            UseGroupByColumnProps<D>,
            UseResizeColumnsColumnProps<D>,
            UseSortByColumnProps<D>,
            UseTableColumnProps<D>,
            UseSortByColumnOptions<D> {
        rightAlign?: boolean;
        inlineColumnHeaderStyles?: React.CSSProperties;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    export interface Cell<D> extends UseGroupByCellProps<D>, UseRowStateCellProps<D> {}

    // eslint-disable-next-line @typescript-eslint/naming-convention
    export interface Row<D>
        extends UseExpandedRowProps<D>,
            UseGroupByRowProps<D>,
            UseRowSelectRowProps<D>,
            UseRowStateRowProps<D> {}

    // @ts-ignore: ts believe this type is duplicated
    export type ColumnExtended<D extends object = {}> =
        | ColumnGroup<D>
        | ColumnWithLooseAccessor<D>
        | ColumnWithStrictAccessor<D>
        | { rightAlign?: boolean }
        | { inlineColumnHeaderStyles?: React.CSSProperties };
}

export function ReactTable({
    columns,
    data,
    refreshData,
    skipPageReset,
    title,
    options,
    renderRowSubComponent,
}: {
    columns: ColumnExtended[];
    data: any;
    refreshData?: any;
    skipPageReset?: boolean;
    title?: string;
    options?: {
        pageSize?: number;
        pageSizeOptions?: number[];
        showMultiSelection?: boolean;
        hiddenColumns?: string[];
        hideToolbar?: boolean;
        hidePagination?: boolean;
        hideHeader?: boolean;
        headerRowStyle?: any;
    };
    renderRowSubComponent?: (row: any) => any;
}) {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";

    // the any is to allow for the "All" option
    const pageSizeOptions: any[] = options?.pageSizeOptions
        ? options?.pageSizeOptions
        : [5, 50, 100, 500, { label: "All", value: data.length }];
    // this is an any to allow the option "All"
    let _pageSize: any = options?.pageSize;
    if (options?.pageSize && pageSizeOptions.indexOf(options?.pageSize) < 0) {
        pageSizeOptions.push(options?.pageSize);
    }

    pageSizeOptions.sort((a, b) => {
        if (Number.isInteger(a) && Number.isInteger(b)) {
            return a - b;
        }
        if (Object.keys(a).indexOf("label") > -1 && Number.isInteger(b)) {
            return 1;
        }
        if (Object.keys(b).indexOf("label") > -1 && Number.isInteger(a)) {
            return -1;
        }
        if (Object.keys(a).indexOf("label") > -1 && Object.keys(b).indexOf("label") > -1) {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
        }
        return 0;
    });

    if (!_pageSize) {
        _pageSize = pageSizeOptions[0];
    }

    const tableOptions: TableOptions<object> = {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        columns: columns as Column[],
        data,
        defaultColumn,
        autoResetPage: !Boolean(skipPageReset).valueOf(),
        initialState: {
            pageSize: _pageSize,
            hiddenColumns: options?.hiddenColumns ?? [],
        },
    };

    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        visibleColumns,
        state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    } = useTable(tableOptions, useGlobalFilter, useSortBy, useExpanded, usePagination, useRowSelect, (hooks: Hooks) => {
        if (options?.showMultiSelection) {
            hooks.allColumns.push((columnsArg: ColumnInstance[]) => [
                {
                    id: "selection",
                    // Note this has the same problem as the other table lib.  The solution should
                    // be server side pagination.  The client should only download data for the current page.
                    // In that case, getToggleAllRowsSelectedProps works fine.
                    Header: ({ getToggleAllRowsSelectedProps }: { getToggleAllRowsSelectedProps: any }) => {
                        return (
                            <div>
                                <Checkbox {...getToggleAllRowsSelectedProps()} />
                            </div>
                        );
                    },
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }: { row: Row }) => {
                        return (
                            <div>
                                <Checkbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        );
                    },
                },
                ...columnsArg,
            ]);
        }
    });

    const handleChangePage = (event: any, newPage: any) => {
        gotoPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setPageSize(Number(event.target.value));
    };

    const headerColumnStyles = (column: ColumnInstance): React.CSSProperties => {
        return {
            ...column.inlineColumnHeaderStyles,
            ...(column.rightAlign ? { textAlign: "right" } : {}),
            color: isDarkMode ? "#fff" : "#252631",
            verticalAlign: "top",
        };
    };

    return (
        <TableContainer>
            {options?.hideToolbar ? (
                <div />
            ) : (
                <ReactTableToolbar
                    numSelected={Object.keys(selectedRowIds).length}
                    title={title}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    setGlobalFilter={setGlobalFilter}
                    globalFilter={globalFilter}
                    refresh={refreshData}
                />
            )}
            <Table {...getTableProps()} size={"small"}>
                {!options?.hideHeader && (
                    <TableHead>
                        {headerGroups.map((headerGroup: any) => (
                            <TableRow
                                {...headerGroup.getHeaderGroupProps()}
                                style={options?.headerRowStyle}
                                key={uuid()}>
                                {headerGroup.headers.map((column: ColumnInstance) => (
                                    <TableCell
                                        {...(column.id === "selection"
                                            ? column.getHeaderProps()
                                            : column.getHeaderProps(column.getSortByToggleProps()))}
                                        key={uuid()}
                                        style={headerColumnStyles(column)}>
                                        {column.render("Header")}
                                        {column.id !== "selection" && !column.disableSortBy ? (
                                            <TableSortLabel
                                                active={column.isSorted}
                                                // react-table has a unsorted state which is not treated here
                                                direction={column.isSortedDesc ? "desc" : "asc"}
                                            />
                                        ) : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                )}
                <TableBody>
                    {page.map((row: any) => {
                        prepareRow(row);
                        return (
                            <React.Fragment key={uuid()}>
                                <TableRow {...row.getRowProps()} key={uuid()}>
                                    {row.cells.map((cell: any) => {
                                        return (
                                            <TableCell {...cell.getCellProps()} key={uuid()}>
                                                {cell.render("Cell")}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                {row.isExpanded && renderRowSubComponent ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                                    </tr>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </TableBody>

                <TableFooter>
                    {!options?.hidePagination && (
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={pageSizeOptions}
                                count={data.length}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                SelectProps={{
                                    inputProps: { "aria-label": "rows per page" },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    )}
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

ReactTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
};

export default ReactTable;
