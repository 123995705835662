import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import React from "react";

export interface IBypassDetailsToggle {
    shouldBypassDetails: boolean;
    setShouldBypassDetails: (isChecked: boolean) => void;
}

export const BypassDetailsToggle: React.FunctionComponent<IBypassDetailsToggle> = ({
    shouldBypassDetails,
    setShouldBypassDetails,
}) => {
    return (
        <FormGroup data-testid={"bypassDetailsContainer"}>
            <FormControlLabel
                control={
                    <Switch
                        data-testid={"bypassDetailsToggle"}
                        checked={shouldBypassDetails}
                        onChange={(event, isChecked) => setShouldBypassDetails(isChecked)}
                    />
                }
                label="Send this Tax Profile to the merchant to enter the detail"
            />
        </FormGroup>
    );
};
