import { useLoginContext } from "@davo/portal-common";
import { isProduction } from "@davo/types";
import { useEffect } from "react";
import { useConfigMerchantContext } from "../context";

export function HubSpot() {
    const loginContext = useLoginContext();
    const { merchantConfigInfo: configInfo } = useConfigMerchantContext();

    useEffect(() => {
        if (isProduction(configInfo.d30Env) && (!loginContext.user || loginContext.user?.type === "Merchant")) {
            // This adds the hubspot chat widget only in Production for merchant users
            const script = document.createElement("script");
            script.src = "//js-na1.hs-scripts.com/1846071.js";
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }
        return undefined;
    }, [configInfo, loginContext.user]);
    return null;
}
