import {
    addTaxProfile,
    BodyText,
    d30ToastError,
    getJurisdictionAdditionalTaxProfiles,
    getJurisdictionByStateCode,
    Header,
    setLocationTaxProfiles,
    TaxProfileForm,
    useModalEditor,
} from "@davo/portal-common";
import {
    FilingFrequency,
    IJurisdictionUIAdditionalTaxProfile,
    ITaxProfileFormData,
    TaxProfileWithCredentials,
} from "@davo/types";
import assert from "assert";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteModal } from "./RouteModal";
import { TaxProfileSelector } from "./components";
import { useAccountContext, useLocationContext } from "./context";

export function SettingsCompanySetTaxProfilesModal() {
    const navigate = useNavigate();
    const accountContext = useAccountContext();
    const locationContext = useLocationContext();

    const [taxProfileIdsSelected, setTaxProfileIdsSelected] = useState<string[]>();
    const [selectTaxProfileModalProps] = useModalEditor<string>();
    const [addEditTaxProfileModalProps] = useModalEditor<string>();
    const [parentErrorMessage, setParentErrorMessage] = useState<string>("");
    const [status, setStatus] = useState<"initial" | "linked">("initial");
    const [jurisdictionUIAdditionalTaxProfile, setJurisdictionUIAdditionalTaxProfile] = useState<
        IJurisdictionUIAdditionalTaxProfile | undefined
    >(undefined);
    const [parentTaxProfileId, setParentTaxProfileId] = useState<string | undefined>(undefined);

    if (!accountContext.account || !locationContext.location || !locationContext.location) {
        return null;
    }

    const headerTax = (
        <div>
            <Header>Tax Profile(s) for {locationContext.location.name}</Header>
            <BodyText>
                Select the sales tax profile(s) for this location. DAVO will file sales tax according to the selected
                settings.
            </BodyText>
        </div>
    );

    const onsSubmitSelected = async () => {
        if (!accountContext.account || !locationContext.location) {
            return;
        }
        if (!taxProfileIdsSelected || taxProfileIdsSelected.length === 0) {
            const msg = "Please select at least one tax profile";
            d30ToastError(msg);
            throw new Error(msg);
        }
        await setLocationTaxProfiles(accountContext.account.id, locationContext.location.id, taxProfileIdsSelected);
        await accountContext.refresh();
        await locationContext.refresh();
    };

    const onSubmitNew = async (
        data: ITaxProfileFormData,
        newTaxProfileData: Omit<TaxProfileWithCredentials, "id" | "createdBy" | "updatedBy">
    ) => {
        if (!accountContext.account || !locationContext.location) {
            return;
        }

        setStatus("linked");
        const newProfile = await addTaxProfile(accountContext.account.id, newTaxProfileData);
        await setLocationTaxProfiles(accountContext.account.id, locationContext.location.id, [newProfile.id]);
        await accountContext.refresh();
        await locationContext.refresh();

        const jurisdiction = await getJurisdictionByStateCode(newTaxProfileData.state);
        const linked = await getJurisdictionAdditionalTaxProfiles(jurisdiction.id);

        if (newProfile.state && !isEmpty(linked)) {
            setParentTaxProfileId(newProfile.id);
            // Right now we only process the first one we get back
            setJurisdictionUIAdditionalTaxProfile(linked[0]);
        } else {
            setStatus("initial");
            navigate("..");
        }
    };

    const onSubmitLinked = async (data: ITaxProfileFormData) => {
        if (!accountContext.account || !locationContext.location || !parentTaxProfileId) {
            return;
        }
        const {
            name,
            legalName,
            state,
            stateTaxId,
            federalTaxId,
            frequency,
            credentials,
            jurisdictionFilingTypeId,
            jurisdictionUIFlavorGroupId,
            additionalStateMetadata,
            additionalStateMetadata2,
            additionalStateMetadata3,
        } = data;
        assert(state, "state is required");
        // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
        const ff = frequency as FilingFrequency;
        try {
            const newTaxProfile = await addTaxProfile(accountContext.account.id, {
                name,
                legalName,
                state: state.abbreviatedName,
                stateTaxId,
                federalTaxId,
                frequency: ff,
                credentialsFailing: "untested",
                credentials,
                hidden: false,
                manualFiling: false,
                requireAdditionalSalesData: false,
                requireUseData: false,
                earlyFilingRequired: false,
                manualReconcileRequired: false,
                ratesVerified: undefined,
                ratesVerifiedBy: undefined,
                typeVerified: undefined,
                typeVerifiedBy: undefined,
                taxRateType: "overlapping",
                credentialsType: "SIMPLE",
                jurisdictionFilingTypeId,
                jurisdictionUIFlavorGroupId,
                showExpandedFields: false,
                additionalStateMetadata,
                additionalStateMetadata2,
                additionalStateMetadata3,
            });
            await setLocationTaxProfiles(accountContext.account.id, locationContext.location.id, [
                newTaxProfile.id,
                parentTaxProfileId,
            ]);
            await accountContext.refresh();
            await locationContext.refresh();

            setStatus("initial");
            navigate("..");
        } catch (e: any) {
            setParentErrorMessage(e.message);
        }
    };

    // If the user has no tax profiles for this location then skip right to the add dialog rather than showing the selector
    if (accountContext.taxProfiles && accountContext.taxProfiles.length > 0 && status !== "linked") {
        return (
            <RouteModal {...selectTaxProfileModalProps} action={onsSubmitSelected}>
                <TaxProfileSelector setTaxProfileIdsCallback={setTaxProfileIdsSelected} header={headerTax} />
            </RouteModal>
        );
    } else {
        const headerContent = <Header>State tax account for {locationContext.location.name}</Header>;

        if (!accountContext.account || !locationContext.location || !locationContext.location) {
            return null;
        }

        return (
            <RouteModal {...addEditTaxProfileModalProps} hideButtons={true}>
                {status === "initial" && (
                    <TaxProfileForm
                        accountId={accountContext.account.id}
                        headerContent={headerContent}
                        onSubmit={onSubmitNew}
                        onCancel={() => {
                            setStatus("initial");
                            navigate("..");
                        }}
                        showCancel={true}
                        parentErrorMessage={parentErrorMessage}
                    />
                )}
                {status === "linked" && jurisdictionUIAdditionalTaxProfile && parentTaxProfileId && (
                    <TaxProfileForm
                        accountId={accountContext.account.id}
                        showCancel={true}
                        onCancel={() => {
                            setStatus("initial");
                            navigate("..");
                        }}
                        onSubmit={onSubmitLinked}
                        parentErrorMessage={parentErrorMessage}
                        linked={{
                            parentTaxProfileId: parentTaxProfileId,
                            jurisdictionUIAdditionalTaxProfile: jurisdictionUIAdditionalTaxProfile,
                        }}
                    />
                )}
            </RouteModal>
        );
    }
}
