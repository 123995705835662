import { UserScreenMode } from "@davo/types";
import { useTheme } from "@mui/material";
import React from "react";
import { Loading } from "./Loading";
import davoLogo from "./resources/davo_logo.png";
import davoLogoWhite from "./resources/davo_logo_white.png";

export function LoadingWithLogo() {
    const theme = useTheme();
    return (
        <div style={{ margin: "0 auto", width: "200px", marginTop: "8em" }}>
            {theme.palette.mode === ("dark" as UserScreenMode) && (
                <img src={davoLogoWhite} style={{ width: "200px" }} alt="DAVO - loading" />
            )}
            {theme.palette.mode !== ("dark" as UserScreenMode) && (
                <img src={davoLogo} style={{ width: "200px" }} alt="DAVO - loading" />
            )}
            <div style={{ textAlign: "center", marginTop: "40px" }}>
                <Loading />
            </div>
        </div>
    );
}
