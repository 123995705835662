export enum BillingEventTypes {
    DISCOUNT = "DISCOUNT",
    MANUAL = "MANUAL",
    NSF = "NSF",
    REFUND = "REFUND",
    ROLL_FORWARD = "ROLL_FORWARD",
    SUBSCRIPTION = "SUBSCRIPTION",
    EXTRA_FILINGS = "EXTRA_FILINGS",
}

export type BillingEventType = keyof typeof BillingEventTypes;
export const BillingEventTypeKeys = [...(Object.keys(BillingEventTypes) as BillingEventType[])];

export const BillingEventIncreaseTypes: BillingEventType[] = ["NSF", "SUBSCRIPTION", "MANUAL", "EXTRA_FILINGS"];
export const BillingEventDecreaseTypes: BillingEventType[] = ["DISCOUNT", "REFUND", "ROLL_FORWARD"];
// billing events handled externally can be zero in our system
export const BillingEventAllowedToBeZeroTypes: BillingEventType[] = ["SUBSCRIPTION", "DISCOUNT", "REFUND"];

export const BillingEventDisallowedConsolidatedTypes: BillingEventType[] = ["NSF"];
export const BillingEventAllowedConsolidatedTypes: BillingEventType[] = BillingEventTypeKeys.filter(
    (obj: BillingEventType) => !BillingEventDisallowedConsolidatedTypes.includes(obj)
);
