import {
    getLocationsEnhanced,
    HiddenConfirmation,
    ReactTable,
    TaxProfileAdd,
    TaxProfileEdit,
    useModalEditor,
} from "@davo/portal-common";
import { FrequencyLabels, Location, TaxProfile } from "@davo/types";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkIcon from "@mui/icons-material/Link";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
    Button,
    FormControlLabel,
    FormGroup,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { TaxProfileStatus } from "./components";
import { useAccountContext } from "./context";
import { MultiLocationSelector } from "./MultiLocationSelector";
import { SettingsTaxProfileEmptyState } from "./SettingsTaxProfileEmptyState";

export function Filing() {
    const navigate = useNavigate();
    const accountContext = useAccountContext();
    const [locations, setLocations] = useState<Location[]>();

    const [nonHiddenTaxProfiles, setNonHiddenTaxProfiles] = useState<TaxProfile[]>();
    const [activeTaxProfiles, setActiveTaxProfiles] = useState<TaxProfile[]>([]);
    const [unusedTaxProfiles, setUnusedTaxProfiles] = useState<TaxProfile[]>([]);

    const [showUnused, setShowUnused] = useState<boolean>(false);
    const [selected, setSelected] = useState<string | undefined>(undefined);
    const [taxProfilesToAssociate, setTaxProfilesToAssociate] = useState<TaxProfile[]>([]);

    const refresh = async () => {
        if (!accountContext.taxProfiles || !accountContext.account) {
            return;
        }
        const lList = await getLocationsEnhanced(accountContext.account.id);
        setLocations(lList);
        setNonHiddenTaxProfiles(accountContext.taxProfiles.filter((t) => !t.hidden));
        const active: TaxProfile[] = [];
        let unused: TaxProfile[] = [];

        accountContext.taxProfiles.forEach((tp) => {
            lList.some((loc) => loc.active && loc.taxProfileIds.includes(tp.id)) ? active.push(tp) : unused.push(tp);
        });

        unused = unused.filter((x: TaxProfile) => !x.hidden);
        setActiveTaxProfiles(active);
        setUnusedTaxProfiles(unused);
    };

    useAsyncEffect(async () => {
        await refresh();
    }, [accountContext.taxProfiles, accountContext.account]);

    const [showTaxProfileEdit, taxProfileEditProps] = useModalEditor<string[]>(async () => {
        await accountContext.refresh();
    });

    const [
        showAddNewTaxProfileModal,
        { isDialogOpen: isAddNewTaxProfileModalOpen, closeDialog: closeAddNewTaxProfileModal },
    ] = useModalEditor(() => {
        showAssociateLocToTpModal(taxProfilesToAssociate);
    });

    const [
        showAssociateLocToTpModal,
        { isDialogOpen: isAssociateLocToTpModalOpen, closeDialog: closeAssociateLocToTpModal },
    ] = useModalEditor();

    const [showHiddenConfirmation, hiddenConfirmationProps] = useModalEditor<string>(async () => {
        await accountContext.refresh();
    });

    if (!locations || !accountContext.account) {
        return null;
    }

    const renderFrequency = (data: any) => {
        return <>{FrequencyLabels[data.value]}</>;
    };

    const renderStatus = (data: any) => {
        return <TaxProfileStatus taxProfile={data.cell.row.original} />;
    };

    const renderEditButton = (data: any) => (
        <Button variant="outlined" size="small" onClick={() => showTaxProfileEdit([data.value])}>
            Edit
        </Button>
    );

    const renderActionButtons = (data: any) => (
        <div style={{ whiteSpace: "nowrap" }}>
            <Tooltip title="Manage Location Associations">
                <IconButton
                    style={{ marginRight: "18px" }}
                    size="small"
                    onClick={() => {
                        const tps = accountContext.taxProfiles?.filter((p) => p.id === data.value);
                        setTaxProfilesToAssociate(tps ?? []);
                        showAssociateLocToTpModal();
                    }}>
                    <LinkIcon stroke={"currentColor"} strokeWidth={0.3} />
                </IconButton>
            </Tooltip>
            {renderEditButton(data)}
        </div>
    );

    const renderLink = (name: string, locId: string) => {
        const LinkButton = (
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Button
                    style={{ marginRight: "12px" }}
                    variant={"text"}
                    color={"primary"}
                    size="small"
                    onClick={() => navigate(`/${accountContext.account?.id}/settings/company/${locId}`)}>
                    Manage
                </Button>
            </div>
        );

        return <Tooltip title={`Manage ${name}`}>{LinkButton}</Tooltip>;
    };

    const getDetailPaneData = (rowData: any) => {
        const found = locations.filter(
            (loc: Location) => loc.taxProfileIds.includes(rowData.row.original.id) && loc.active
        );
        if (found.length > 0) {
            return (
                <div style={{ padding: "32px" }}>
                    <Typography style={{ display: "inline-block", fontSize: "20px" }}>
                        <Tooltip title={`Locations associated with ${rowData.row.original.name}`} placement={"top"}>
                            <span>Locations associated with this tax profile</span>
                        </Tooltip>
                    </Typography>
                    <List sx={{ width: "100%", bgcolor: "background.paper" }} dense className={"da-row-expand-content"}>
                        {found.map((loc) => {
                            return (
                                <ListItem
                                    key={loc.id}
                                    secondaryAction={renderLink(loc.name, loc.id)}
                                    sx={{
                                        boxShadow: 1,
                                        mb: "4px",
                                    }}>
                                    <ListItemAvatar>
                                        <LocationOnIcon />
                                    </ListItemAvatar>
                                    <ListItemText primary={loc.name} secondary={loc.address1} />
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
            );
        } else {
            return null;
        }
    };

    const shouldToggle = (rowData: TaxProfile) => {
        return locations.some((loc: Location) => loc.taxProfileIds.includes(rowData.id) && loc.active);
    };

    if (!nonHiddenTaxProfiles) {
        return null;
    }

    return (
        <div>
            {nonHiddenTaxProfiles.length === 0 ? (
                <SettingsTaxProfileEmptyState />
            ) : (
                <>
                    <Stack
                        style={{ marginBottom: "16px" }}
                        justifyContent={"space-between"}
                        direction="row"
                        spacing={2}>
                        <Typography variant={"h2"} style={{ display: "inline-block", fontSize: "20px" }}>
                            Tax Profile Details
                        </Typography>

                        <div>
                            <Button
                                size={"small"}
                                variant={"contained"}
                                onClick={() => {
                                    setTaxProfilesToAssociate([]);
                                    showAddNewTaxProfileModal();
                                }}>
                                Add Tax Profile
                            </Button>
                        </div>
                    </Stack>
                    <ReactTable
                        columns={[
                            {
                                id: "expander", // Make sure expand columns have an ID
                                Cell: (data: any) => {
                                    if (shouldToggle(data.row.original)) {
                                        return (
                                            <span
                                                {...data.row.getToggleRowExpandedProps({
                                                    style: {
                                                        paddingLeft: "8px",
                                                    },
                                                })}
                                                className={"mui-expand-toggle-container"}>
                                                {data.row.isExpanded ? (
                                                    <span className={"expandLess"}>
                                                        <ExpandLessIcon />
                                                    </span>
                                                ) : (
                                                    <span className={"expandMore"}>
                                                        <ExpandMoreIcon />
                                                    </span>
                                                )}
                                            </span>
                                        );
                                    } else {
                                        return null;
                                    }
                                },
                                disableSortBy: true,
                            },
                            {
                                Header: (
                                    <Tooltip title={"Tax Profile Nickname"} placement={"top"}>
                                        <span>{"Nickname"}</span>
                                    </Tooltip>
                                ),
                                accessor: "name",
                            },
                            {
                                Header: (
                                    <Tooltip title={"The filing frequency"} placement={"top"}>
                                        <span>{"Frequency"}</span>
                                    </Tooltip>
                                ),
                                accessor: "frequency",
                                Cell: renderFrequency,
                            },
                            {
                                Header: <span>{"Status"}</span>,
                                accessor: "credentialsFailing",
                                Cell: renderStatus,
                            },
                            {
                                Header: "",
                                accessor: "id" as const,
                                Cell: renderActionButtons,
                                disableSortBy: true,
                            },
                        ]}
                        options={{
                            hideToolbar: true,
                            pageSize: 10,
                        }}
                        renderRowSubComponent={getDetailPaneData}
                        data={activeTaxProfiles}
                    />

                    <Stack style={{ margin: "16px 0" }} justifyContent={"space-between"} direction="row" spacing={2}>
                        {unusedTaxProfiles.length !== 0 && (
                            <FormGroup style={{ margin: "14px" }}>
                                <FormControlLabel
                                    control={
                                        <Switch checked={showUnused} onChange={() => setShowUnused(!showUnused)} />
                                    }
                                    label="Show unassociated tax profiles"
                                />
                            </FormGroup>
                        )}
                    </Stack>

                    {showUnused && unusedTaxProfiles.length !== 0 && (
                        <ReactTable
                            title="Unassociated Tax Profiles"
                            columns={[
                                {
                                    Header: (
                                        <Tooltip title={"Tax Profile Nickname"} placement={"top"}>
                                            <span>{"Nickname"}</span>
                                        </Tooltip>
                                    ),
                                    accessor: "name",
                                },
                                {
                                    Header: (
                                        <Tooltip title={"The filing frequency"} placement={"top"}>
                                            <span>{"Frequency"}</span>
                                        </Tooltip>
                                    ),
                                    accessor: "frequency",
                                    Cell: renderFrequency,
                                },
                                {
                                    Header: <span>{"Status"}</span>,
                                    accessor: "credentialsFailing",
                                    Cell: renderStatus,
                                },
                                {
                                    id: "actions",
                                    Header: "",
                                    Cell: (data: any) => (
                                        <Tooltip title="Delete">
                                            <IconButton
                                                size="small"
                                                disabled={false}
                                                onClick={() => {
                                                    setSelected(data.row.original.id);
                                                    showHiddenConfirmation(data.row.original.id);
                                                }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ),
                                    disableSortBy: true,
                                },
                                {
                                    Header: "",
                                    accessor: "id" as const,
                                    Cell: (data) => {
                                        return <>{renderActionButtons(data)}</>;
                                    },
                                    disableSortBy: true,
                                },
                            ]}
                            options={{
                                hideToolbar: true,
                            }}
                            renderRowSubComponent={getDetailPaneData}
                            data={unusedTaxProfiles}
                        />
                    )}
                </>
            )}

            {isAddNewTaxProfileModalOpen && (
                <TaxProfileAdd
                    accountId={accountContext.account.id}
                    shouldLimitEdit={false}
                    target={undefined}
                    closeDialog={closeAddNewTaxProfileModal}
                    setTpsToAssociate={setTaxProfilesToAssociate}
                />
            )}
            {accountContext.account.id &&
                taxProfilesToAssociate &&
                !isEmpty(taxProfilesToAssociate) &&
                isAssociateLocToTpModalOpen && (
                    <MultiLocationSelector
                        locations={locations}
                        accountId={accountContext.account.id}
                        taxProfilesToAssociate={taxProfilesToAssociate}
                        handleClose={closeAssociateLocToTpModal}
                    />
                )}
            {taxProfileEditProps.isDialogOpen && (
                <TaxProfileEdit accountId={accountContext.account.id} {...taxProfileEditProps} />
            )}
            {hiddenConfirmationProps.isDialogOpen && (
                <HiddenConfirmation
                    accountId={accountContext.account.id}
                    taxProfileId={selected}
                    label={"tax profile"}
                    {...hiddenConfirmationProps}
                />
            )}
        </div>
    );
}
