import { DateTime } from "luxon";
import { BusinessDay } from "./BusinessDay";

export enum BillingInvoiceTypes {
    SIMPLE = "SIMPLE",
    LOCATION_CENTRIC = "LOCATION_CENTRIC",
    PARTNER_PARTIAL = "PARTNER_PARTIAL",
    PARTNER_FULL = "PARTNER_FULL",
    PARTNER_ACCOUNT_PARTIAL = "PARTNER_ACCOUNT_PARTIAL",
    PARTNER_ACCOUNT_FULL = "PARTNER_ACCOUNT_FULL",
    ACCOUNT_PARTIAL = "ACCOUNT_PARTIAL",
    ACCOUNT_FULL = "ACCOUNT_FULL",
}

export type BillingInvoiceType = keyof typeof BillingInvoiceTypes;

export const billingInvoiceTypes = [...(Object.keys(BillingInvoiceTypes) as BillingInvoiceType[])];
export const partnerBillingInvoiceTypes = [
    "PARTNER_PARTIAL",
    "PARTNER_FULL",
    "PARTNER_ACCOUNT_PARTIAL",
    "PARTNER_ACCOUNT_FULL",
];
export const accountBillingInvoiceTypes = ["ACCOUNT_PARTIAL", "ACCOUNT_FULL"];

export interface IBillingInvoice {
    id: string;
    date: BusinessDay;
    bankAccountId?: string | null;
    partnerBankAccountId?: string | null;
    created: DateTime;
    type: BillingInvoiceType;
}
