import React from "react";
import { ConfirmationModal } from "./ConfirmationModal";
import { d30ToastError } from "./Toast";
import { markBankAccountHidden, markTaxProfileHidden } from "./services";

export interface IHiddenConfirmationProps {
    accountId: string;
    bankAccountId?: string;
    taxProfileId?: string;
    label?: string;
    target?: string;
    closeDialog: (target?: string) => void;
}

export function HiddenConfirmation({
    accountId,
    bankAccountId,
    taxProfileId,
    label,
    target,
    closeDialog,
}: IHiddenConfirmationProps) {
    const doMarkHidden = async (theTarget: string | undefined) => {
        if (!theTarget) {
            // Cancel
            shutdown();
            return;
        }

        if (bankAccountId) {
            try {
                await markBankAccountHidden(accountId, bankAccountId);
            } catch (e: any) {
                d30ToastError(e.message);
                throw e;
            }
            shutdown(bankAccountId);
        } else if (taxProfileId) {
            try {
                await markTaxProfileHidden(accountId, taxProfileId);
            } catch (e: any) {
                d30ToastError(e.message);
                throw e;
            }
            shutdown(taxProfileId);
        } else {
            shutdown();
        }
    };

    const shutdown = (result?: string) => {
        closeDialog(result);
    };

    const message = (
        <div data-testid={"hiddenConfirmationMessage"}>
            Are you sure you want to delete this {label ?? "item"}?
            <br />
            Warning: This action cannot be undone!
        </div>
    );

    return (
        <ConfirmationModal
            message={message}
            title="Confirm delete?"
            continueLabel="Delete"
            closeDialog={doMarkHidden}
            target={target}
        />
    );
}
