import { Address } from "./Account";

export interface IAvaTaxAddress {
    address: {
        line1: string; // the info passed to the verifier, could be bad data
        city: string;
        region: string;
        postalCode: string;
    };
    validatedAddresses: IAvaTaxAddressValidatedAddress[];
    coordinates: {
        latitude: number;
        longitude: number;
    };
    messages?: null | IAvaTaxAddressMessage[];
    resolutionQuality: string;
    taxAuthorities: IAvaTaxAddressTaxAuthority[];
}

export interface IAvaTaxAddressValidatedAddress {
    addressType: string;
    line1: string;
    line2: string;
    line3: string;
    city: string;
    county: string;
    region: string; // state shortcode like "ME"
    country: string;
    postalCode: string;
    latitude: number;
    longitude: number;
}

export interface IAvaTaxValidatedAddressWithAdditionalFields extends Address {
    name: string;
    validationType?: string;
}

export interface IAvaTaxAddressTaxAuthority {
    avalaraId: string;
    jurisdictionName: string;
    jurisdictionType: string;
    signatureCode: string;
}

export interface IAvaTaxAddressMessage {
    details: string;
    refersTo: string;
    severity: string;
    source: string;
    summary: string;
}

export type PartialAddress = {
    address1?: string | null | undefined;
    address2?: string | null | undefined;
    city?: string | null | undefined;
    state?: string | null | undefined;
    zip?: string | null | undefined;
    [p: string]: unknown;
};

export type AddressDiff = {
    forwardDiff: string[];
    s1Words: string;
    s1Tokens: string[];
    s2Words: string;
    reverseDiff: string[];
    s2Tokens: string[];
};
