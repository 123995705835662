import { Paper } from "@mui/material";
import React from "react";

import { Outlet } from "react-router-dom";
import { LimitedTimeMerchantBanner } from "./LimitedTimeMerchantBanner";
import { MerchantBanner } from "./MerchantBanner";

export function Settings() {
    return (
        <div>
            <MerchantBanner />
            <LimitedTimeMerchantBanner />
            <Paper style={{ boxShadow: "none", padding: "30px 30px 44px", minHeight: "80vh" }}>
                <Outlet />
            </Paper>
        </div>
    );
}
