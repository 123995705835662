import { IBillingWithBank } from "@davo/types";
import { Typography } from "@mui/material";
import React from "react";
import billing from "./resources/davo_illustrations_working.png";

export const SettingsBillingEmptyState: React.FunctionComponent<{
    externalBilling: string | boolean;
    billings?: IBillingWithBank[];
}> = ({ externalBilling, billings }) => {
    return (
        <div style={{ textAlign: "center" }}>
            <img src={billing} style={{ maxWidth: "100%" }} alt={"Billing"} />
            <Typography style={{ fontSize: "20px", margin: "10px", maxWidth: "100%" }}>
                Thank you for using our service.
            </Typography>
            {billings && billings.length === 0 && !externalBilling && (
                <Typography
                    style={{
                        fontSize: "14px",
                        maxWidth: "100%",
                        color: "#252631",
                        margin: "auto",
                        marginBottom: "30px",
                    }}>
                    We are excited to have you using our updated portal. Any future billings will appear here.
                </Typography>
            )}
            {externalBilling && (
                <Typography
                    style={{
                        fontSize: "14px",
                        maxWidth: "100%",
                        color: "#252631",
                        margin: "auto",
                        marginBottom: "30px",
                    }}>
                    Your POS provider takes care of billing for one or more of your devices. Log in to your POS provider
                    to see billing information. Any other billings (if any) will appear below.
                </Typography>
            )}
        </div>
    );
};
