import { DATETIME, ResolveObject, STRING, UUID } from "./MetaType";

export const MetaTermsAndConditions = {
    id: UUID(),
    locationId: UUID(),
    accepted: DATETIME(),
    acceptedVersion: STRING(),
    acceptedBy: UUID().optional(),
    partnerId: UUID().optional(),
};

export type TermsAndConditions = ResolveObject<typeof MetaTermsAndConditions>;
