import { DateTime } from "luxon";
import { LocationRecord } from "./Account";
import { POSType } from "./POS";
import { PermissionRole } from "./User";

export interface IMerchantInvitationInfo {
    status: "available" | "redeemed";
    name?: null | string;
    type?: null | POSType;
    email?: null | string;
    hasAccount?: boolean;
    phone?: null | string;
    locations: LocationRecord[];
    firstName?: null | string;
    lastName?: null | string;
    invitationId: string;
    canSubscribe: boolean;
    hasPermissionToSubscribe: boolean;
    hasValidExternalSubscription: boolean;
    hasValidExternalPlan: boolean;
    hasTaxRates: boolean;
    hasTaxProfiles: boolean;
    hasBankAccount: boolean;
    partnerId?: null | string;
    shouldRestrictBoardingFlowToPartner: boolean;
    posMerchant: string;
}

export interface IUserInvitationInfo {
    status: "available" | "expired" | "redeemed";
    name: string;
    role: PermissionRole;
    id: string;
    addedBy: string;
}

export interface IPartnerInvitationInfo {
    status: "available" | "expired" | "redeemed";
    name: string;
    id: string;
    addedBy: string;
    expires: DateTime<true>;
    originalEmail?: null | string;
}
