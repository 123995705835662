import { IPortalConfigType } from "@davo/types";
import React from "react";

export interface IConfigMerchantContext {
    merchantConfigInfo: IPortalConfigType;
}

export const ConfigMerchantContextDefaultValue = {} as IConfigMerchantContext;
export const ConfigMerchantContext = React.createContext(ConfigMerchantContextDefaultValue);
export const useConfigMerchantContext = () => React.useContext(ConfigMerchantContext);
