import {
    FilingFrequency,
    IJurisdictionUIAdditionalTaxProfile,
    ITaxProfileFormData,
    TaxProfile,
    TaxProfileWithCredentials,
} from "@davo/types";
import { Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import assert from "assert";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import {
    addTaxProfile,
    getJurisdictionAdditionalTaxProfiles,
    getJurisdictionByStateCode,
    updateFilingForFrequencyChange,
} from "./services";
import { TaxProfileForm } from "./TaxProfileForm";
import { d30Toast } from "./Toast";
import { getCredentialsStatus } from "./util";

export interface ITaxProfileAdd {
    accountId: string;
    shouldLimitEdit?: boolean;
    target?: string[]; // taxProfileId passed generically from modal widget -- this is an array so we can pass more than one back, but we should not receive more than one
    closeDialog: (target?: string[]) => void;
    setTpsToAssociate?: (tps: TaxProfile[]) => void;
}

export function TaxProfileAdd({ accountId, shouldLimitEdit, target, closeDialog, setTpsToAssociate }: ITaxProfileAdd) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [status, setStatus] = useState<"initial" | "linked">("initial");
    const [parentTaxProfile, setParentTaxProfile] = useState<TaxProfile | undefined>();
    const [jurisdictionUIAdditionalTaxProfile, setJurisdictionUIAdditionalTaxProfile] = useState<
        IJurisdictionUIAdditionalTaxProfile | undefined
    >(undefined);
    const _shutdown = (result?: string[]) => {
        closeDialog(result);
        setStatus("initial");
    };

    const _addNewTaxProfileWorkflow = async (data: ITaxProfileFormData) => {
        const {
            name,
            legalName,
            state,
            stateTaxId,
            federalTaxId,
            frequency,
            credentials,
            credentialsType,
            jurisdictionFilingTypeId,
            jurisdictionUIFlavorGroupId,
            filingMethod,
            additionalStateMetadata,
            additionalStateMetadata2,
            additionalStateMetadata3,
        } = data;
        const credentialsStatus = getCredentialsStatus(data);
        const taxProfileFormData: Omit<TaxProfileWithCredentials, "id" | "createdBy" | "updatedBy"> = {
            name,
            legalName,
            state: state ? state.abbreviatedName : "",
            stateTaxId,
            federalTaxId,
            // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
            frequency: frequency as FilingFrequency,
            credentialsFailing: credentialsStatus,
            credentials,
            hidden: false,
            manualFiling: false,
            requireAdditionalSalesData: false,
            requireUseData: false,
            earlyFilingRequired: false,
            ratesVerified: undefined,
            ratesVerifiedBy: undefined,
            typeVerified: undefined,
            typeVerifiedBy: undefined,
            manualReconcileRequired: false,
            taxRateType: "overlapping",
            jurisdictionUIFlavorGroupId,
            jurisdictionFilingTypeId,
            filingMethod,
            credentialsType,
            showExpandedFields: false,
            additionalStateMetadata,
            additionalStateMetadata2,
            additionalStateMetadata3,
        };

        const newTp = await addTaxProfile(accountId, taxProfileFormData, undefined, data.sentToMerchant);

        if (newTp) {
            setParentTaxProfile(newTp);
            // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
            await updateFilingForFrequencyChange(false, accountId, newTp.id, frequency as FilingFrequency); // not passing an old frequency since this is new

            if (state) {
                const jurisdiction = await getJurisdictionByStateCode(state.abbreviatedName);
                const linked = await getJurisdictionAdditionalTaxProfiles(jurisdiction.id);
                if (
                    !isEmpty(linked) &&
                    (isNil(jurisdictionFilingTypeId) ||
                        (!isNil(jurisdictionFilingTypeId) &&
                            jurisdictionFilingTypeId !== linked[0].jurisdictionFilingTypeId))
                ) {
                    setStatus("linked");
                    // Right now we only process the first one we get back
                    setJurisdictionUIAdditionalTaxProfile(linked[0]);
                } else {
                    if (setTpsToAssociate) {
                        setTpsToAssociate([newTp]);
                    }
                    _shutdown([newTp.id]);
                }
            } else {
                if (setTpsToAssociate) {
                    setTpsToAssociate([newTp]);
                }
                _shutdown([newTp.id]);
            }
        }
    };

    const createEditTaxProfile = async (data: ITaxProfileFormData) => {
        assert(data.frequency, "FilingFrequency required");
        await _addNewTaxProfileWorkflow(data).then(() => {
            d30Toast("Changes saved");
        });
    };

    const createEditLinkedTaxProfile = async (data: ITaxProfileFormData) => {
        const {
            name,
            legalName,
            state,
            stateTaxId,
            federalTaxId,
            frequency,
            credentials,
            jurisdictionFilingTypeId,
            jurisdictionUIFlavorGroupId,
            additionalStateMetadata,
            additionalStateMetadata2,
            additionalStateMetadata3,
        } = data;
        assert(state, "state is required");
        assert(parentTaxProfile?.id, "parentTaxProfileId is required to add a linked tax profile");
        // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
        const ff = frequency as FilingFrequency;
        const linkedTaxProfile = await addTaxProfile(
            accountId,
            {
                name,
                legalName,
                state: state?.abbreviatedName,
                stateTaxId,
                federalTaxId,
                frequency: ff,
                credentialsFailing: "untested",
                credentials,
                hidden: false,
                manualFiling: false,
                requireAdditionalSalesData: false,
                requireUseData: false,
                earlyFilingRequired: false,
                manualReconcileRequired: false,
                taxRateType: "overlapping",
                credentialsType: "SIMPLE",
                jurisdictionFilingTypeId,
                jurisdictionUIFlavorGroupId,
                showExpandedFields: false,
                additionalStateMetadata,
                additionalStateMetadata2,
                additionalStateMetadata3,
            },
            undefined,
            data.sentToMerchant
        );
        await updateFilingForFrequencyChange(false, accountId, linkedTaxProfile.id, ff); // not passing an old frequency since this is new
        if (setTpsToAssociate) {
            setTpsToAssociate([parentTaxProfile, linkedTaxProfile]);
        }
        _shutdown([parentTaxProfile.id, linkedTaxProfile.id]);

        d30Toast("Changes saved");
    };

    return (
        <Dialog
            data-testid={"addTpDialog"}
            fullScreen={makeFullScreen}
            open={true}
            aria-labelledby="responsive-dialog-title"
            onClose={(event, reason) => {
                if (!["backdropClick", "escapeKeyDown"].includes(reason)) {
                    _shutdown();
                }
            }}>
            <DialogTitle variant={"h5"} id="responsive-dialog-title">
                <strong>Add a Tax Return</strong>
            </DialogTitle>
            <DialogContent>
                {status === "initial" && (
                    <TaxProfileForm
                        accountId={accountId}
                        target={target ? target[0] : undefined} // awkward construction since we can send more than one back, but only edit one on the way in
                        onSubmit={createEditTaxProfile}
                        onCancel={() => _shutdown()}
                        showCancel={true}
                        shouldLimitEdit={shouldLimitEdit}
                    />
                )}
                {status === "linked" && parentTaxProfile && jurisdictionUIAdditionalTaxProfile && (
                    <TaxProfileForm
                        accountId={accountId}
                        showCancel={true}
                        onCancel={() => {
                            if (setTpsToAssociate) {
                                setTpsToAssociate([parentTaxProfile]);
                            }
                            _shutdown([parentTaxProfile.id]);
                        }}
                        onSubmit={createEditLinkedTaxProfile}
                        linked={{
                            parentTaxProfileId: parentTaxProfile.id,
                            jurisdictionUIAdditionalTaxProfile: jurisdictionUIAdditionalTaxProfile,
                        }}
                    />
                )}
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    );
}
