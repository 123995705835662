import HelpOutline from "@mui/icons-material/HelpOutline";
import { Button, Stack, Tooltip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAccountContext } from "../context";

export interface IBankSummaryActions {
    name: string;
    locId: string;
    shouldRestrictMerchantEdit: boolean;
    restrictMerchantEditMessage: string;
}

export const BankSummaryActions: React.FunctionComponent<IBankSummaryActions> = ({
    name,
    locId,
    shouldRestrictMerchantEdit,
    restrictMerchantEditMessage,
}) => {
    const navigate = useNavigate();
    const accountContext = useAccountContext();
    return (
        <Stack justifyContent={"end"} direction="row" spacing={2}>
            <Tooltip title={`Manage ${name}`} placement="top">
                <span>
                    <Button
                        data-testid={`manage-loc-${locId}-btn`}
                        disabled={shouldRestrictMerchantEdit}
                        variant={"text"}
                        color="primary"
                        size="small"
                        onClick={() => navigate(`/${accountContext.account?.id}/settings/company/${locId}`)}>
                        Manage
                    </Button>
                </span>
            </Tooltip>

            {restrictMerchantEditMessage ? (
                <Tooltip title={restrictMerchantEditMessage} placement="top">
                    <HelpOutline fontSize="small" color="primary" style={{ alignSelf: "center" }} />
                </Tooltip>
            ) : null}
        </Stack>
    );
};
