import { BOOLEAN, NUMBER, ResolveObject, STRING } from "./MetaType";

export const MetaFilingPeriod = {
    frequency: STRING(),
    state: STRING(),
    dueDayOfMonth: NUMBER(),
    prepaymentOneDayOfMonth: NUMBER().optional(),
    prepaymentTwoDayOfMonth: NUMBER().optional(),
    prepaymentThreeDayOfMonth: NUMBER().optional(),
    startMonth: NUMBER(),
    isMonthSix: BOOLEAN(),
    firstDayOfCutoffPeriod: NUMBER(),
    isFiscalMonthNotThreeSixNineTwelve: BOOLEAN(),
    prepayment: BOOLEAN(),
    start: STRING(),
    end: STRING(),
    lastDayOfCutoffPeriod: NUMBER(),
    isMonthTwoFiveEightEleven: BOOLEAN(),
    isMonthProductOfThree: BOOLEAN(),
    isFiscalMonthNotTwelve: BOOLEAN(),
    legacy: BOOLEAN(),
};

export type FilingPeriod = ResolveObject<typeof MetaFilingPeriod>;

export const FrequencyLabels: { [key: string]: string } = {
    quarter_mpp: "Quarterly (Monthly prepay)",
    quarter: "Quarterly",
    month: "Monthly",
    semimonthly: "Semi-Monthly",
    semiannual: "Semi-Annually",
    annual: "Annually",
    fiscal_annual: "Annually (Fiscal year)",
    nypromptax: "NY Prompt Tax",
    month_mpp: "Monthly (with prepayment)",
    annual_mpp: "Annually (with prepayment)",
    fiscal_annual_mpp: "Annually (Fiscal year with prepayment)",
    month_expected_halves: "Monthly (with expected payments)",
    month_weekly_prepayment: "Monthly (with weekly prepayments)",
    quarter_smpp: "Quarterly (with semi-monthly prepayments)",
};

export interface IFilingOptionsFilters {
    state: string | undefined;
    periodEnd: string | undefined;
    taxProfile: string | undefined;
}
