import { DateTime } from "luxon";

export interface IWellsFargoTransaction {
    id: string;
    postingDate: DateTime; // date only; central timezone
    valueDate: DateTime; // date only; central timezone
    transactionDatetime: DateTime; //  Date time; UTC
    debitCreditIndicator: string;
    transactionType: string;
    baiTypeCode: string; // Bank Administration Institute (BAI)
    transactionDescription?: null | string;
    transactionAmount: number; // pennies
    zeroDayFloat: number; // pennies
    oneDayFloat: number; // pennies
    twoDayFloat: number; // pennies
    checkNumber?: null | string;
    bankReference?: null | string;
    transactionStatus: string;
    created: DateTime;
    state?: null | string;
    updated?: null | DateTime;
    updatedBy?: null | string;
    stateMappingId?: null | string;
    reversal?: null | string;
    note?: null | string;
    noteBy?: null | string;
    noteDate?: null | DateTime;
    isWsud: boolean;
}

export interface IWellsFargoTransactionIntraDay {
    id: string;
    postingDate: DateTime; // date only; central timezone
    valueDate: DateTime; // date only; central timezone
    transactionDatetime: DateTime; //  Date time; UTC
    debitCreditIndicator: string;
    transactionType: string;
    baiTypeCode: string; // Bank Administration Institute (BAI)
    transactionDescription?: null | string;
    transactionAmount: number; // pennies
    zeroDayFloat: number; // pennies
    oneDayFloat: number; // pennies
    twoDayFloat: number; // pennies
    checkNumber?: null | string;
    bankReference?: null | string;
    transactionStatus: string;
    created: DateTime;
    updated?: null | DateTime;
    updatedBy?: null | string;
    reversed?: null | DateTime;
    note?: null | string;
    noteBy?: null | string;
    noteDate?: null | DateTime;
    companyId?: null | string;
    recipientId?: null | string;
}

export interface IWellsFargoTransactionMapping {
    id: string;
    txnId: string;
    matchedFiling?: null | string;
    matchedPenalty?: null | string;
    matchedOutflow?: null | string;
    amount: number;
    created: DateTime;
    createdBy?: null | string;
}

export interface IWellsFargoStateMapping {
    id: string;
    state: string; // 2 character state code
    descriptionPattern: string;
    created: DateTime;
    createdBy?: null | string;
    updated?: null | DateTime;
    updatedBy?: null | string;
}

export interface IWellsFargoTransactionMappingInfo extends IWellsFargoTransactionMapping {
    createdByName?: null | string;
    matchedOutflowAccountId?: null | string;
}

export interface IWellsFargoTransactionInfo extends IWellsFargoTransaction {
    matchedEntities: IWellsFargoTransactionMappingInfo[];
    amountMatched?: null | number;
    noteByName?: null | string;
}

export interface IWellsFargoTransactionIntraDayInfo extends IWellsFargoTransactionIntraDay {
    noteByName?: null | string;
    isDuplicate: boolean;
}
