import isNil from "lodash/isNil";
import { DateTime } from "luxon";
import numeral from "numeral";
import { secondaryUnitDesignators, stateNames, streetSuffixAbbreviations } from "./AddressAbbreviations";
import { AddressDiff, PartialAddress } from "./AvaTaxAddress";
import { BusinessDay } from "./BusinessDay";
import { D30Environment } from "./Config";

export type OptionalString = string | undefined | null;

export function toDollars(value?: number | null) {
    if (typeof value === "undefined") {
        return undefined;
    }
    return isNil(value) ? 0 : value / 100;
}

export function toPennies(value?: number | null) {
    if (typeof value === "undefined") {
        return undefined;
    }
    return Math.round(isNil(value) ? 0 : value * 100);
}

export function percent(value?: number | null, precision?: number | null) {
    if (typeof value === "undefined") {
        return "";
    }
    const decimalPrecision = !isNil(precision) ? precision : 2;
    return numeral(value).format("%0." + "0".repeat(decimalPrecision));
}

export const delay = (ms: number) => new Promise<void>((resolve) => setTimeout(resolve, ms));

export function money(value?: number | null) {
    if (typeof value === "undefined") {
        return "";
    }
    return numeral(value).format("0,0.00");
}

export function moneyFromCents(value?: number | null) {
    const valDollars = value ? value / 100 : value;
    return money(valDollars);
}

export function date(value: DateTime) {
    if (!value) {
        return "";
    }
    return value.toFormat("MMMM dd, yyyy");
}

export function datetime(value: DateTime) {
    if (!value) {
        return "";
    }
    return value.toFormat("MMMM dd, yyyy HH:mm a");
}

export function initialCap(value: string) {
    return value.substring(0, 1).toUpperCase() + value.substring(1).toLowerCase();
}

export function titleCase(value: string) {
    return value.replace(/\w\S*/g, (word) => word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase());
}

export function toMap<T>(things: T[], keyfn: (t: T) => string) {
    return things.reduce(
        (p, t) => {
            p[String(keyfn(t))] = t;
            return p;
        },
        {} as { [key: string]: T | undefined }
    );
}

export const noop = () => {
    /* */
};

export const upToLast = (value: string | undefined, separator: string) => {
    if (value) {
        const idx = value.lastIndexOf(separator);
        if (idx >= 0) {
            return value.substring(0, idx);
        }
    }
    return value;
};

export const isDefined = <T>(x: T): x is NonNullable<T> => !!x;

export const toDateTime = (dt: DateTime | string | BusinessDay) => {
    if (typeof dt === "string") {
        dt = DateTime.fromSQL(dt);
    } else if (dt instanceof BusinessDay) {
        dt = dt.toDateTime();
    }
    return dt;
};

export const toDisplayDateString = (dt: DateTime | string | BusinessDay, makeLocal = true) => {
    dt = toDateTime(dt);

    if (makeLocal) {
        // within a browser this is local to the user, on the server...it's server local aka: UTC
        return dt.toLocal().toFormat("MMM dd, yyyy");
    }
    return dt.toFormat("MMM dd, yyyy");
};

export const toDisplayDayMonthString = (dt: DateTime | string | BusinessDay, makeLocal = true) => {
    dt = toDateTime(dt);

    if (makeLocal) {
        // within a browser this is local to the user, on the server...it's server local aka: UTC
        return dt.toLocal().toFormat("MMM dd");
    }
    return dt.toFormat("MMM dd");
};

export const toDisplayDateTimeString = (
    dt: DateTime | string,
    opts?: { makeLocal?: boolean; showSeconds?: boolean }
) => {
    const format = opts?.showSeconds ? "FF" : "ff";
    const makeLocal = opts?.makeLocal ?? true;

    if (typeof dt === "string") {
        dt = DateTime.fromSQL(dt);
    }

    if (makeLocal) {
        // within a browser this is local to the user, on the server...it's server local aka: UTC
        return dt.toLocal().toFormat(format);
    }
    return dt.toFormat(format);
};

export const isLastDayOfMonth = (dt: DateTime | string | BusinessDay) => {
    const currentDate = toDateTime(dt);
    const endOfMonth = currentDate.endOf("month");
    return currentDate.day === endOfMonth.day;
};

export const truncateString = (str: OptionalString, len: number) => {
    if (!str) {
        return "";
    }

    let truncated = str.substring(0, Math.min(len, str.length));
    if (truncated.length < str.length) {
        truncated += "...";
    }

    return truncated;
};

export const isProduction = (environmentCode: D30Environment | undefined | null) => {
    return environmentCode && environmentCode === "prd";
};

export const copyValues = <T extends object>(origin: T, overrides?: Partial<T>) => {
    const baseValue = { ...origin }; // NO SIDE EFFECTS!!
    if (overrides) {
        Object.keys(overrides).forEach(function (key) {
            // @ts-ignore - implicit any type issue with following lines
            baseValue[key] = overrides[key];
        });
    }
    return baseValue;
};

/**
 * Sets number to certain precision after rounding
 * This function effectively rounds, then truncates to a certain precision
 * @param numArg number to be converted
 * @param precision how many digits after the decimal to convert to
 */
export const toActuallyFixedRounded = (numArg: number, precision = 2) => {
    if (precision < 1) {
        throw new Error("Precision must be > 0");
    }
    const factor = +"1".padEnd(precision + 1, "0");
    let rounded = 0;
    let num = numArg * factor;
    if (num >= 0) {
        rounded = Math.round(num);
    } else {
        rounded = -Math.round(num * -1);
    }
    num = rounded / factor;
    return num;
};

/**
 * Sets number to certain precision after flooring
 * This function effectively truncates to a certain precision
 * @param numArg number to be converted
 * @param precision how many digits after the decimal to convert to
 */
export const toActuallyFixedFloor = (numArg: number, precision = 2) => {
    if (precision < 1) {
        throw new Error("Precision must be > 0");
    }
    const factor = +"1".padEnd(precision + 1, "0");
    let floored = 0;
    let num = numArg * factor;
    if (num >= 0) {
        floored = Math.floor(num);
    } else {
        floored = -Math.floor(num * -1);
    }
    num = floored / factor;
    return num;
};

export const toActuallyFixedFloorString = (numArg: number, precision = 2) => {
    const floored = toActuallyFixedFloor(numArg, precision);
    return numeral(floored).format("0." + "0".repeat(precision));
};

const tokenizeAddressChars = (addressPart: string) => {
    return addressPart
        .toUpperCase()
        .split("")
        .filter((el) => el !== " ")
        .filter((el) => el !== ".");
};

export const normalizeAddressWords = (wordString: string): string => {
    return wordString
        .toUpperCase()
        .split(".")
        .map((words) =>
            words
                .split(" ")
                .map((word) => (streetSuffixAbbreviations.has(word) ? streetSuffixAbbreviations.get(word) : word))
                .join(" ")
        )
        .map((words) =>
            words
                .split(" ")
                .map((word) => (secondaryUnitDesignators.has(word) ? secondaryUnitDesignators.get(word) : word))
                .join(" ")
        )
        .map((words) => (stateNames.has(words) ? stateNames.get(words) : words))
        .join(".");
};

const diffString = (s1: string, s2: string): AddressDiff => {
    const s1Words = normalizeAddressWords(s1);
    const s2Words = normalizeAddressWords(s2);

    const s1Tokens = tokenizeAddressChars(s1Words);
    const s2Tokens = tokenizeAddressChars(s2Words);

    return {
        forwardDiff: s1Tokens.filter((x) => !s2Tokens.includes(x)),
        reverseDiff: s2Tokens.filter((x) => !s1Tokens.includes(x)),
        s1Words,
        s1Tokens,
        s2Words,
        s2Tokens,
    };
};

export const isEmptyAddress = (address: PartialAddress) => Object.values(address).every((v) => !v);

/**
 * @param existingAddress The address the user entered
 * @param avataxAddress The address to compare from AvaTax
 * @param shouldLog console logging to explain in what ways addresses were/weren't alike
 */
export const areAddressesSame = (existingAddress: PartialAddress, avataxAddress: PartialAddress, shouldLog = false) => {
    let areSame = true;

    if (isEmptyAddress(existingAddress)) {
        return false;
    }

    for (const [key, val] of Object.entries(existingAddress)) {
        const isExactMatch = val === avataxAddress[key];
        const areBothFalsy = !val && !avataxAddress[key];

        const source = typeof val === "string" ? val.toUpperCase() : "";
        const target = typeof avataxAddress[key] === "string" ? avataxAddress[key].toUpperCase() : "";

        const doesAvataxOfferMissingValue = !source && !!target;
        const { forwardDiff, reverseDiff, s1Words, s1Tokens, s2Words, s2Tokens } = diffString(source, target);
        const relevantDiffsForField = key === "zip" ? forwardDiff : forwardDiff.concat(reverseDiff);
        const hasNoPerceivableDiff = relevantDiffsForField.length === 0;

        if (doesAvataxOfferMissingValue) {
            return false;
        }

        if (areBothFalsy) {
            continue;
        }

        areSame = isExactMatch || hasNoPerceivableDiff;

        if (!areSame) {
            if (shouldLog) {
                // eslint-disable-next-line no-console
                console.table({
                    [`${key}`]: {
                        isExactMatch,
                        areBothFalsy,
                        hasNoPerceivableDiff,
                        relevantDiffsForField,
                        source: JSON.stringify(source),
                        target: JSON.stringify(target),
                        forwardDiff: forwardDiff,
                        reverseDiff: reverseDiff,
                    },
                });
                // eslint-disable-next-line no-console
                console.table({
                    s1: {
                        words: s1Words,
                        tokens: JSON.stringify(s1Tokens),
                    },
                    s2: {
                        words: s2Words,
                        tokens: JSON.stringify(s2Tokens),
                    },
                });
            }

            break;
        }
    }
    return areSame;
};
