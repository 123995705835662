import { IConnectionStatus, StatusLevel, toDisplayDateString } from "@davo/types";
import React, { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { StatusDot } from "./StatusDot";
import { getConnectionStatusForAccount, getConnectionStatusForLocation } from "./services";

export const defaultConnectionStatus: IConnectionStatus = {
    singleLocationIsInactive: false,
    hasMissingBankInfo: false,
    arePausedBankAccountsInUse: false,
    areBankAccountsRestricted: false,
    hasMissingTaxInfo: false,
    hasFailingTaxInfo: false,
    hasUntestedTaxCredentials: false,
    pausedWithUnpauseDate: undefined,
};

export interface IConnectionStatusProps {
    accountId?: string;
    locationId?: string;
    connectionStatusProvided?: IConnectionStatus;
    missingBankLink?: string;
    pausedBankInUseLink?: string;
    missingTaxProfileLink?: string;
    failingTaxProfileLink?: string;
    hideMessage?: boolean;
    restrictedBankLink?: string;
}

export function ConnectionStatus({
    accountId,
    locationId,
    connectionStatusProvided,
    missingBankLink,
    pausedBankInUseLink,
    missingTaxProfileLink,
    failingTaxProfileLink,
    hideMessage,
    restrictedBankLink,
}: IConnectionStatusProps) {
    const [status, setStatus] = useState<IConnectionStatus | undefined>(connectionStatusProvided);
    const [level, setLevel] = useState<StatusLevel>();
    const [message, setMessage] = useState<string>();
    const [destination, setDestination] = useState<string | undefined>(undefined);
    const [tooltip, setTooltip] = useState<string | undefined>(undefined);

    useAsyncEffect(async () => {
        if (!accountId || connectionStatusProvided) {
            return;
        }

        let st: IConnectionStatus;
        if (locationId) {
            st = await getConnectionStatusForLocation(accountId, locationId);
        } else {
            st = await getConnectionStatusForAccount(accountId);
        }
        setStatus(st);
    }, [accountId, locationId, connectionStatusProvided]);

    useEffect(() => {
        if (!accountId) {
            setLevel("error");
            setMessage("No account");
            return;
        }
        if (!status) {
            return;
        }

        if (locationId && status.singleLocationIsInactive) {
            // For a single location also check if it's inactive. Don't include this when we're considering all locations.
            setLevel("error");
            setMessage("Location inactive");
        } else if (status.hasMissingBankInfo) {
            setLevel("error");
            setMessage("Missing bank info");
            setDestination(missingBankLink);
        } else if (status.areBankAccountsRestricted) {
            setLevel("error");
            setMessage("New bank account required");
            setDestination(restrictedBankLink);
        } else if (status.arePausedBankAccountsInUse) {
            setLevel("error");
            setMessage("Paused bank account");
            setDestination(pausedBankInUseLink);
        } else if (status.hasMissingTaxInfo) {
            setLevel("error");
            setMessage("Missing tax profile info");
            setDestination(missingTaxProfileLink);
        } else if (status.hasFailingTaxInfo) {
            setLevel("error");
            setMessage("Failing tax credentials");
            setDestination(failingTaxProfileLink);
        } else if (status.pausedWithUnpauseDate) {
            setLevel("ok");
            setMessage(`Set-asides start ${toDisplayDateString(status.pausedWithUnpauseDate)}`);
        } else if (status.hasUntestedTaxCredentials) {
            setLevel("warning");
            setMessage("Credentials to be verified");
        } else {
            setLevel("ok");
            setMessage("Everything looks good!");
        }
        if (status.pausedWithUnpauseDate) {
            setTooltip(
                () => `Your daily set-asides are scheduled to resume pulling funds on ${toDisplayDateString(
                    status.pausedWithUnpauseDate!
                )} for
                    ${
                        status.pausedWithUnpauseDate
                            ? toDisplayDateString(status.pausedWithUnpauseDate.minus({ days: 1 }))
                            : ""
                    } sales tax amounts.`
            );
        } else if (status.hasUntestedTaxCredentials) {
            setTooltip(
                "The DAVO team will verify the tax credential " +
                    "information you provided and will contact you via email " +
                    "if any further action is required on your part."
            );
        }
    }, [status, failingTaxProfileLink, missingBankLink, missingTaxProfileLink, pausedBankInUseLink]);

    useEffect(() => {
        if (hideMessage && message) {
            // If the message isn't displayed, use the message as the tooltip
            setTooltip(message);
        }
    }, [hideMessage, message]);

    if (!level || !message) {
        return null;
    }

    return (
        <StatusDot
            toolTip={tooltip}
            level={level}
            message={hideMessage ? undefined : message}
            destination={destination}
        />
    );
}
