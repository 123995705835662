import {
    areAddressesSame,
    IAvaTaxValidatedAddressWithAdditionalFields,
    isEmptyAddress,
    LocationRecord,
    normalizeAddressWords,
} from "@davo/types";
import * as Sentry from "@sentry/browser";
import pick from "lodash/pick";
import React, { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from "react";
import { AddressEditModal } from "../AddressEditModal";
import { AddressVerificationModal } from "../AddressVerificationModal";
import { updateLocationAddress, verifyLocationAddress } from "../services";
import { d30Toast, d30ToastError } from "../Toast";
import { useModalEditor } from "../useModal";

export interface IAddressVerificationContext {
    avataxValidatedAddress: IAvaTaxValidatedAddressWithAdditionalFields | undefined;
    launchVerifyAddressModal: (thing?: string | undefined) => void;
    launchEditAddressModal: (thing?: string | undefined) => void;
}

export const AddressVerificationContextDefaultValue: IAddressVerificationContext = {
    avataxValidatedAddress: undefined,
    launchVerifyAddressModal: () => undefined,
    launchEditAddressModal: () => undefined,
};

export const AddressVerificationContext = createContext(AddressVerificationContextDefaultValue);
export const useAddressVerificationContext = () => useContext(AddressVerificationContext);

export interface IAddressVerificationContextProviderProps {
    accountId: string | undefined;
    location: LocationRecord | undefined;
    refreshLocation: () => Promise<void>;
    shouldAutoPrompt: boolean;
    showCancelVerify?: boolean;
    showCancelEdit?: boolean;
    showCounty?: boolean;
}

export const AddressVerificationContextProvider: FunctionComponent<
    PropsWithChildren<IAddressVerificationContextProviderProps>
> = ({
    children,
    location,
    accountId,
    refreshLocation,
    shouldAutoPrompt,
    showCancelVerify = false,
    showCancelEdit = true,
    showCounty = false,
}) => {
    const [avataxValidatedAddress, setAvataxValidatedAddress] = useState<
        IAvaTaxValidatedAddressWithAdditionalFields | undefined
    >();

    const [showVerifyAddressModal, verifyAddressModalProps] = useModalEditor<string>(() => refreshLocation());

    const [showEditAddressModal, editAddressModalProps] = useModalEditor<string>(() => refreshLocation());

    useEffect(() => {
        if (!location) {
            setAvataxValidatedAddress(undefined);
            return;
        }

        if (location.validatedAddress) {
            return;
        }

        const locationAddress = pick(location, ["address1", "address2", "city", "state", "zip"]);
        const hasEmptyLocationAddress = isEmptyAddress(locationAddress);

        if (hasEmptyLocationAddress && shouldAutoPrompt) {
            showEditAddressModal();
        }

        !hasEmptyLocationAddress &&
            verifyLocationAddress(location)
                .then((avataxAddressWithFields) => {
                    setAvataxValidatedAddress(avataxAddressWithFields);
                    const avataxAddress = pick(avataxAddressWithFields, [
                        "address1",
                        "address2",
                        "city",
                        "state",
                        "zip",
                    ]);

                    const areSame = areAddressesSame(locationAddress, avataxAddress);

                    if (
                        (avataxAddressWithFields.validationType === "validated" ||
                            avataxAddressWithFields.validationType === "mismatch") &&
                        areSame &&
                        shouldAutoPrompt &&
                        accountId
                    ) {
                        updateLocationAddress(
                            accountId,
                            location.id,
                            {
                                ...location,
                                state: location.state && normalizeAddressWords(location.state),
                            },
                            true
                        )
                            .then(
                                () =>
                                    process.env.NODE_ENV === "development" &&
                                    d30Toast(`Auto-verified address for ${location.name}`)
                            )
                            .then(() => refreshLocation())
                            .catch((e) => d30ToastError("There was a problem auto-verifying the address.", e));
                        return;
                    }

                    if (areSame) {
                        return;
                    }

                    if (shouldAutoPrompt && avataxAddressWithFields.validationType !== "error") {
                        showVerifyAddressModal();
                    }
                })
                .catch((e) => {
                    // eating, we don't want validation to impact experience
                    console.error(`Problem verifying location address; ${e.message}`); // eslint-disable-line no-console
                    Sentry.captureException(e);
                });
    }, [location, shouldAutoPrompt, accountId, refreshLocation]);

    return (
        <AddressVerificationContext.Provider
            value={{
                avataxValidatedAddress,
                launchVerifyAddressModal: showVerifyAddressModal,
                launchEditAddressModal: showEditAddressModal,
            }}>
            {children}

            {accountId && location && !location?.validatedAddress && verifyAddressModalProps.isDialogOpen && (
                <AddressVerificationModal
                    title={"Confirm address"}
                    location={location}
                    accountId={accountId}
                    showCancel={showCancelVerify}
                    {...verifyAddressModalProps}
                />
            )}

            {accountId && location && editAddressModalProps.isDialogOpen && (
                <AddressEditModal
                    title={"Edit Address"}
                    accountId={accountId}
                    location={location}
                    showCancel={showCancelEdit}
                    showCounty={showCounty}
                    {...editAddressModalProps}
                />
            )}
        </AddressVerificationContext.Provider>
    );
};
