import {
    EfileCheckData,
    EFileResult,
    IJurisdiction,
    IJurisdictionFilingType,
    IJurisdictionRateType,
    IJurisdictionUIAdditionalTaxProfile,
} from "@davo/types";
import { get, post } from "./api";

export async function getPublicJurisdictions() {
    return get<IJurisdiction[]>(`api/public/jurisdictions`, false);
}

export async function getJurisdictions() {
    return get<IJurisdiction[]>(`api/common/jurisdictions`, true);
}

export async function getJurisdictionByStateCode(stateCode: string) {
    return get<IJurisdiction>(`api/common/jurisdictions/state-code/${stateCode}/`, true);
}

export async function getJurisdictionFilingTypes(jurisdictionId: string) {
    return get<IJurisdictionFilingType[]>(`api/common/jurisdictions/${jurisdictionId}/filingtypes/`, true);
}

export async function getJurisdictionFilingType(jurisdictionFilingTypeId: string) {
    return get<IJurisdictionFilingType>(`api/common/jurisdictions/filingtypes/${jurisdictionFilingTypeId}/`, true);
}

export async function getJurisdictionDefaultFilingType(stateCode: string) {
    return get<IJurisdictionFilingType>(`api/common/jurisdictions/filingtypes/default/${stateCode}`, true);
}

export async function getJurisdictionRateTypes(jurisdictionId: string) {
    return get<IJurisdictionRateType[]>(`api/common/jurisdictions/${jurisdictionId}/ratetypes/`, true);
}

export async function getJurisdictionRateType(jurisdictionRateTypeId: string) {
    return get<IJurisdictionRateType>(`api/common/jurisdictions/ratetypes/${jurisdictionRateTypeId}/`, true);
}

export async function getJurisdictionAdditionalTaxProfiles(jurisdictionId: string) {
    return get<IJurisdictionUIAdditionalTaxProfile[]>(`api/common/jurisdictions/${jurisdictionId}/tax-profiles/`, true);
}

export async function getCheckEFileCredentials({
    jurisdictionFilingTypeId,
    profileLoginCreds,
    additionalOptions,
    abortController,
}: EfileCheckData) {
    return post<EFileResult>(
        `api/common/jurisdictions/check-efile-credentials`,
        { jurisdictionFilingTypeId, profileLoginCreds, additionalOptions },
        true,
        {},
        abortController
    );
}
