import { DateTime } from "luxon";
import { ACHTransactionStatus } from "./ACH";
import { IBillingEventDetail } from "./BillingEvent";
import { IBillingInvoice } from "./BillingInvoice";
import { IBillingSalesTaxWithRate } from "./BillingSalesTax";
import { OptionalString } from "./Utils";

// NOTE: deprecated. Use BillingType instead.
export type BillingStatus = "billed" | "retried" | "refunded";

export type BillingType = "bill" | "retry" | "refund";

export interface IBilling {
    id: string;
    achTransactionId: OptionalString;
    created: DateTime;
    billingInvoiceId?: null | string;
    status: BillingStatus;
    amount: number; // pennies
    recorded: boolean; // recorded in the internal pos
    type: BillingType;
    retried: boolean;
    refunded: boolean;
    refundReason?: null | string;
    relatedBilling?: null | string;
}

export interface IBillingInfo {
    achId?: string;
    achStatus?: ACHTransactionStatus;
    achStatusNotes?: string;
    billingType: BillingType;
    isRefunded: boolean;
    refundReason?: null | string;
    bank?: string;
    amount: number;
    salesTax: IBillingSalesTaxWithRate[];
    totalSalesTax: number;
    period: string;
    canRefund: boolean;
    pull?: boolean;
    id: string;
    billingInvoiceId?: null | string;
    invoice?: null | IBillingInvoice;
    events: IBillingEventDetail[];
    created: DateTime;
    canRebill: boolean;
    accountStarred?: null | string;
    locationId?: null | string;
    locationName?: null | string;
}

export function getBillingStatus(info: IBillingInfo, achStatus: string) {
    let s = achStatus;

    if (info.isRefunded) {
        s = "refunded";
    }
    return s;
}

export interface IBillingSummary {
    amount: number;
    accountBankAccountId: string;
    status: string;
    statusNotes: string;
    created: DateTime;
    failed: boolean;
    accountStarred: string;
}

export interface IBillingWithBank extends IBilling {
    accountStarred: string;
}

export enum PartnerBillingTypes {
    ACCOUNT_PARTIAL = "ACCOUNT_PARTIAL",
    ACCOUNT_FULL = "ACCOUNT_FULL",
    PARTNER_PARTIAL = "PARTNER_PARTIAL",
    PARTNER_FULL = "PARTNER_FULL",
}

export type PartnerBillingType = keyof typeof PartnerBillingTypes;

export enum AccountBillingTypes {
    LOCATION_BASED = "LOCATION_BASED",
    ACCOUNT_FULL = "ACCOUNT_FULL",
    ACCOUNT_PARTIAL = "ACCOUNT_PARTIAL",
}

export type AccountBillingType = keyof typeof AccountBillingTypes;
