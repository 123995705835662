import { FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface ITermsAndConditionsFormType {
    hasAcceptedTermsOfUse?: boolean;
    onChangeAcceptedTermsOfUse?: (val: boolean) => void;
}

export function TermsAndConditionsForm({
    hasAcceptedTermsOfUse = false,
    onChangeAcceptedTermsOfUse,
}: ITermsAndConditionsFormType) {
    const [areTermsOfUseAccepted, setAreTermsOfUseAccepted] = useState<boolean>(false);

    useEffect(() => {
        changeAcceptedTermsOfUse(hasAcceptedTermsOfUse);
    }, [hasAcceptedTermsOfUse]);

    const changeAcceptedTermsOfUse = (isChecked: boolean) => {
        setAreTermsOfUseAccepted(isChecked);
        if (onChangeAcceptedTermsOfUse) {
            onChangeAcceptedTermsOfUse(isChecked);
        }
    };

    return (
        <FormGroup data-testid={"termsAndConditionsForm"}>
            <FormControlLabel
                label={
                    <Typography style={{ display: "inline-block" }} variant="body1" color="textSecondary">
                        I have read and accept the{" "}
                        <a
                            href="https://legal.avalara.com/#serviceterms"
                            target="_blank"
                            style={{ overflow: "e" }}
                            rel="noreferrer">
                            Avalara Service Terms and Conditions
                        </a>
                        .
                    </Typography>
                }
                control={
                    <Switch
                        checked={areTermsOfUseAccepted}
                        onChange={(event: any, isChecked: boolean) => changeAcceptedTermsOfUse(isChecked)}
                    />
                }
            />
        </FormGroup>
    );
}
