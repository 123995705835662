import { d30Toast, d30ToastError, MultiSelect, setTaxProfileLocations } from "@davo/portal-common";
import { Location, LocationRecord, TaxProfile } from "@davo/types";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { useAccountContext } from "./context";

export interface IMultiLocationSelector {
    handleClose: () => void;
    taxProfilesToAssociate: TaxProfile[];
    accountId: string;
    locations: Location[];
}

export const MultiLocationSelector: React.FunctionComponent<IMultiLocationSelector> = ({
    handleClose,
    taxProfilesToAssociate,
    accountId,
    locations,
}) => {
    const titleId = "associate-loc-to-tp-dialog-title";
    const accountContext = useAccountContext();
    const [selectedLocations, setSelectedLocations] = useState<LocationRecord[]>([]);
    const [initiallySelectedCount, setInitiallySelectedCount] = useState<number>(0);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    useEffect(() => {
        const locationsUsingTaxProfile = locations.filter((l) =>
            l.taxProfileIds.includes(taxProfilesToAssociate[0].id)
        );
        const matchedLocationIds = locationsUsingTaxProfile.map((l) => l.id);
        const initiallySelectedLocations = accountContext.locations?.filter((l) => matchedLocationIds.includes(l.id));
        setInitiallySelectedCount(initiallySelectedLocations?.length ?? 0);
        setSelectedLocations(initiallySelectedLocations ?? []);
    }, [accountContext, taxProfilesToAssociate, locations]);

    const _getHeader = () => {
        return taxProfilesToAssociate.map((tp) => `${tp.name}`).join(", ");
    };

    const _handleSubmit = () => {
        if (!isEmpty(taxProfilesToAssociate)) {
            setIsBusy(true);
            Promise.all(
                taxProfilesToAssociate.map((taxProfile) =>
                    setTaxProfileLocations(
                        accountId,
                        taxProfile.id,
                        selectedLocations.map((l) => l.id)
                    )
                )
            )
                .then(() => d30Toast("Success"))
                .then(() => accountContext.refresh())
                .catch((e) => {
                    d30ToastError("Problem associating tax profile with location.", e);
                })
                .finally(() => handleClose());
        }
    };

    if (!taxProfilesToAssociate || isEmpty(taxProfilesToAssociate)) {
        return null;
    }

    return (
        <Dialog
            data-testid={"tpToLocationsDialog"}
            open={true}
            aria-labelledby={titleId}
            onClose={() => !isBusy && handleClose()}>
            <DialogTitle id={titleId}>
                <Stack justifyContent={"space-between"} direction="row">
                    <span style={{ alignSelf: "center" }}>Associate locations with tax profile(s): {_getHeader()}</span>
                    <IconButton disabled={isBusy} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <MultiSelect
                    isDisabled={isBusy}
                    style={{ textOverflow: "ellipsis" }}
                    options={
                        accountContext.locations && accountContext.locations.filter((l) => l.active)
                            ? accountContext.locations.filter((l) => l.active)
                            : []
                    }
                    value={selectedLocations}
                    noneLabel="No Locations"
                    label={(loc) => {
                        return <span data-testid={"locationName"}>{loc.name}</span>;
                    }}
                    onChange={(locs) => {
                        setSelectedLocations(locs);
                    }}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button disabled={isBusy} variant={"outlined"} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        data-testid={"confirmAssociationsBtn"}
                        style={{ marginLeft: "6px" }}
                        color={selectedLocations.length === 0 && initiallySelectedCount !== 0 ? "warning" : "primary"}
                        disabled={isBusy || (initiallySelectedCount === 0 && selectedLocations.length === 0)}
                        startIcon={
                            isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                        }
                        variant={"contained"}
                        onClick={_handleSubmit}>
                        {`${
                            selectedLocations.length === 0 && initiallySelectedCount !== 0 ? "Remove" : "Confirm"
                        } Associations`}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};
