import {
    EfileCheckData,
    FilingFrequency,
    FrequencyLabels,
    getTaxProfileFailureReasonDescription,
    IJurisdiction,
    IJurisdictionFilingType,
    IJurisdictionUIAdditionalTaxProfile,
    IJurisdictionUIField,
    IJurisdictionUIFieldDefinitionBase,
    ITaxProfileFormData,
    LoginCredentialValidationStatus,
    OptionalString,
    PLACEHOLDER_PREFIX,
    PLACEHOLDER_VALUES,
    TaxPeriod,
    TaxProfile,
    TaxProfileCredentials,
    TaxProfileFilingMethod,
    TaxProfileWithCredentials,
} from "@davo/types";
import BlockIcon from "@mui/icons-material/Block";
import InfoIcon from "@mui/icons-material/Info";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import {
    Alert,
    AlertTitle,
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { BypassDetailsToggle } from "./BypassDetailsToggle";
import { useConfigCommonContext, useLoginContext } from "./context";
import { CredentialsEntry } from "./CredentialsEntry";
import { Select } from "./Select";
import {
    _trim,
    calculateFilingPeriod,
    getCheckEFileCredentials,
    getFilingFrequencyByJurisdiction,
    getGroupLoginRequired,
    getJurisdictionByStateCode,
    getJurisdictionFilingType,
    getJurisdictionFilingTypes,
    getJurisdictions,
    getTaxProfile,
    getTaxProfileAndCredentials,
    getTaxProfiles,
} from "./services";
import { TakeGiveBackToggle } from "./TakeGiveBackToggle";
import "./TaxProfileForm.css";
import { TextField } from "./TextField";
import { d30ToastError } from "./Toast";
import { getCredentialsStatus, hasPermission } from "./util";

export type LinkedTaxProfileType = {
    parentTaxProfileId: string;
    jurisdictionUIAdditionalTaxProfile: IJurisdictionUIAdditionalTaxProfile;
};

export interface ITaxProfileFormType {
    accountId?: string;
    target?: string; // taxProfileId passed generically from modal widget
    onSubmit: (
        data: ITaxProfileFormData,
        newTaxProfileData: Omit<TaxProfileWithCredentials, "id" | "createdBy" | "updatedBy">
    ) => Promise<void>;
    saveLabel?: string;
    showCancel?: boolean;
    onCancel?: (data: ITaxProfileFormData) => void;
    headerContent?: any;
    shouldLimitEdit?: boolean;
    value?: ITaxProfileFormData;
    parentErrorMessage?: string;
    linked?: LinkedTaxProfileType;
}

export const TaxProfileForm: React.FunctionComponent<ITaxProfileFormType> = ({
    accountId,
    target,
    onSubmit,
    saveLabel,
    showCancel,
    onCancel,
    headerContent,
    shouldLimitEdit,
    value,
    parentErrorMessage,
    linked,
}) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";
    const { commonConfigInfo: configInfo } = useConfigCommonContext();
    const loginContext = useLoginContext();

    const [states, setStates] = useState<IJurisdiction[]>([]);
    const [filingTypesForState, setFilingTypesForState] = useState<IJurisdictionFilingType[]>([]);
    const [name, setName] = useState<string>("");
    const [legalName, setLegalName] = useState<string>("");
    const [jurisdiction, setJurisdiction] = useState<IJurisdiction | undefined | null>();
    const [filingType, setFilingType] = useState<IJurisdictionFilingType | undefined | null>();
    const [legalNameFieldDefinition, setLegalNameFieldDefinition] = useState<IJurisdictionUIFieldDefinitionBase>();
    const [stateTaxIdFieldDefinition, setStateTaxIdFieldDefinition] = useState<IJurisdictionUIFieldDefinitionBase>();
    const [feinFieldDefinition, setFeinFieldDefinition] = useState<IJurisdictionUIFieldDefinitionBase>();
    const [usernameFieldDefinition, setUsernameFieldDefinition] = useState<IJurisdictionUIFieldDefinitionBase>();
    const [passwordFieldDefinition, setPasswordFieldDefinition] = useState<IJurisdictionUIFieldDefinitionBase>();
    const [pinFieldDefinition, setPinFieldDefinition] = useState<IJurisdictionUIFieldDefinitionBase>();
    const [additionalStateMetadataFieldDefinition, setAdditionalStateMetadataFieldDefinition] =
        useState<IJurisdictionUIFieldDefinitionBase>();
    const [additionalStateMetadata2FieldDefinition, setAdditionalStateMetadata2FieldDefinition] =
        useState<IJurisdictionUIFieldDefinitionBase>();
    const [additionalStateMetadata3FieldDefinition, setAdditionalStateMetadata3FieldDefinition] =
        useState<IJurisdictionUIFieldDefinitionBase>();
    const [isUsernameRequired, setIsUsernameRequired] = useState<boolean>(false);
    const [isPasswordRequired, setIsPasswordRequired] = useState<boolean>(false);
    const [isPinRequired, setIsPinRequired] = useState<boolean>(false);
    const [selectedFlavor, setSelectedFlavor] = useState<IJurisdictionUIField>();
    const [frequency, setFrequency] = useState<OptionalString>(undefined);
    const [stateTaxId, setStateTaxId] = useState<string>("");
    const [federalTaxId, setFederalTaxId] = useState<string>("");
    const [additionalStateMetadata, setAdditionalStateMetadata] = useState<string>("");
    const [additionalStateMetadata2, setAdditionalStateMetadata2] = useState<string>("");
    const [additionalStateMetadata3, setAdditionalStateMetadata3] = useState<string>("");
    const [jurisdictionFilingTypeId, setJurisdictionFilingTypeId] = useState<string | null>();
    const [credentials, setCredentials] = useState<TaxProfileCredentials>({});
    const [previouslySavedCredentials, setPreviouslySavedCredentials] = useState<TaxProfileCredentials>();
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [selectedFrequencyList, setSelectedFrequencyList] = useState<string[]>();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [taxProfile, setTaxProfile] = useState<TaxProfile | undefined>(undefined);
    const [allTaxProfiles, setAllTaxProfiles] = useState<TaxProfile[]>([]);
    const [isNicknameInUse, setIsNicknameInUse] = useState<boolean>(false);
    const [areExistingCredentialsReused, setAreExistingCredentialsReused] = useState<boolean>(false);
    const [showFrequencyConfirm, setShowFrequencyConfirm] = useState<boolean>(false);
    const [linkedTaxProfileQuestionAnswer, setLinkedTaxProfileQuestionAnswer] = useState<string>("");
    const [showLinkedTaxProfileDetails, setShowLinkedTaxProfileDetails] = useState<boolean>(false);
    const [doesFrequencyChangeAffectsCurrentPeriod, setDoesFrequencyChangeAffectsCurrentPeriod] =
        useState<boolean>(false);
    const [oldFrequency, setOldFrequency] = useState<OptionalString>(undefined);
    const [hasFailingCredentials, setHasFailingCredentials] = useState<boolean>(false);
    const [failingCredentialsMessage, setFailingCredentialsMessage] = useState<string>("");
    const [isGroupLoginUsed, setIsGroupLoginUsed] = useState<boolean>(false);
    const [isGroupLoginRequired, setIsGroupLoginRequired] = useState<boolean>(true);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isTakeGiveBack, setIsTakeGiveBack] = useState<boolean>(false);
    const [frequencyData, setFrequencyData] = useState<TaxPeriod | undefined>(undefined);
    const [invalidStandardFields, setInvalidStandardFields] = useState<string[]>([]);
    const [areAllStandardFieldsValid, setAreAllStandardFieldsValid] = useState<boolean>(false);
    const [invalidCredentialFields, setInvalidCredentialFields] = useState<string[]>([]);
    const [areAllCredentialFieldsValid, setAreAllCredentialFieldsValid] = useState<boolean>(false);
    const [credsValidationStatus, setCredsValidationStatus] = useState<LoginCredentialValidationStatus>();
    const [shouldBypassDetails, setShouldBypassDetails] = useState<boolean>(false);
    const [isDraftForMerchant, setIsDraftForMerchant] = useState<boolean>(false);
    const [parentTaxProfile, setParentTaxProfile] = useState<TaxProfile>();
    const tpNameFieldLabel = "Return Nickname";
    const filingFreqFieldLabel = "Filing Frequency";
    const filingTypeFieldLabel = "Filing Type";
    const stateTaxIdInputRef = useRef<HTMLInputElement>();
    const controllerRef = useRef<AbortController>();
    const nicknameInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        getJurisdictions()
            .then((result) => setStates(result))
            .catch((e) => {
                d30ToastError("Problem getting jurisdictions", e);
            });
    }, []);

    useEffect(() => {
        if (parentErrorMessage) {
            setMessage(parentErrorMessage);
        } else {
            setMessage(undefined);
        }
    }, [parentErrorMessage]);

    useEffect(() => {
        if (accountId) {
            getTaxProfiles(accountId)
                .then((existingTaxProfiles) => setAllTaxProfiles(existingTaxProfiles))
                .catch((e) => d30ToastError("Problem getting tax profiles for account.", e));
        }
    }, [accountId]);

    useEffect(() => {
        if (accountId) {
            const tp = allTaxProfiles?.find((t) => t.id === target);

            if (tp && target) {
                getTaxProfileAndCredentials(accountId, tp.id)
                    .then((tpWithCreds) => {
                        setCredentials(tpWithCreds.credentials);
                        setPreviouslySavedCredentials(tpWithCreds.credentials);
                        setTaxProfile(tp);
                        setIsDraftForMerchant(tp.name?.startsWith(PLACEHOLDER_PREFIX));
                    })
                    .catch((e) => d30ToastError("Problem getting tax profile and credentials", e));
            }
        }
    }, [accountId, target, allTaxProfiles]);

    const _setLinkedTpDefaultValues = useCallback(() => {
        if (linked && parentTaxProfile) {
            if (linked.jurisdictionUIAdditionalTaxProfile.copyLegalName) {
                setLegalName(parentTaxProfile.legalName);
            }
            if (linked.jurisdictionUIAdditionalTaxProfile.copyStateTaxId) {
                parentTaxProfile.stateTaxId && setStateTaxId(parentTaxProfile.stateTaxId);
            }
            if (linked.jurisdictionUIAdditionalTaxProfile.copyFederalTaxId) {
                parentTaxProfile.federalTaxId && setFederalTaxId(parentTaxProfile.federalTaxId);
            }
            if (linked.jurisdictionUIAdditionalTaxProfile.copyFrequency) {
                setFrequency(parentTaxProfile.frequency);
            }
            if (linked.jurisdictionUIAdditionalTaxProfile.copyAdditionalStateMetadata) {
                parentTaxProfile.additionalStateMetadata &&
                    setAdditionalStateMetadata(parentTaxProfile.additionalStateMetadata);
            }
            if (linked.jurisdictionUIAdditionalTaxProfile.copyAdditionalStateMetadata2) {
                parentTaxProfile.additionalStateMetadata2 &&
                    setAdditionalStateMetadata2(parentTaxProfile.additionalStateMetadata2);
            }
            if (linked.jurisdictionUIAdditionalTaxProfile.copyAdditionalStateMetadata3) {
                parentTaxProfile.additionalStateMetadata3 &&
                    setAdditionalStateMetadata3(parentTaxProfile.additionalStateMetadata3);
            }
        }
    }, [linked, parentTaxProfile]);

    useEffect(() => {
        if (!linked || !accountId) {
            return;
        }
        getTaxProfile(accountId, linked.parentTaxProfileId)
            .then((parentTp) => setParentTaxProfile(parentTp))
            .catch((e) => d30ToastError("Problem getting tax profile.", e));
    }, [accountId, linked]);

    useAsyncEffect(async () => {
        if (!linked || !accountId || !parentTaxProfile) {
            return;
        }

        const newJurisdiction = await getJurisdictionByStateCode(parentTaxProfile.state);
        const linkedFilingType = await getJurisdictionFilingType(
            linked.jurisdictionUIAdditionalTaxProfile.jurisdictionFilingTypeId
        );

        setJurisdiction(newJurisdiction);
        setFilingType(linkedFilingType);
        setJurisdictionFilingTypeId(linked.jurisdictionUIAdditionalTaxProfile.jurisdictionFilingTypeId);

        _setLinkedTpDefaultValues();
    }, [accountId, linked, _setLinkedTpDefaultValues, parentTaxProfile]);

    // This use effect sets all the basic form data that is part of the tax profile object
    useAsyncEffect(async () => {
        if (taxProfile) {
            if (isDraftForMerchant) {
                const withoutPrefix = taxProfile.name.replace(`${PLACEHOLDER_PREFIX}: `, "");
                setName(withoutPrefix);
            }

            if (!isDraftForMerchant) {
                setName(taxProfile.name);
                setLegalName(taxProfile.legalName);
                setFrequency(taxProfile.frequency);
                setStateTaxId(taxProfile.stateTaxId ?? "");
                setFederalTaxId(taxProfile.federalTaxId ?? "");
                setAdditionalStateMetadata(taxProfile.additionalStateMetadata ?? "");
                setAdditionalStateMetadata2(taxProfile.additionalStateMetadata2 ?? "");
                setAdditionalStateMetadata3(taxProfile.additionalStateMetadata3 ?? "");
            }

            setMessage(undefined);
            setDoesFrequencyChangeAffectsCurrentPeriod(false);
            setIsEditing(true);
            setIsTakeGiveBack(taxProfile.filingMethod === "take-give-back");
            setJurisdiction(await getJurisdictionByStateCode(taxProfile.state));
            setJurisdictionFilingTypeId(taxProfile.jurisdictionFilingTypeId);
            if (taxProfile.jurisdictionFilingTypeId) {
                setFilingType(await getJurisdictionFilingType(taxProfile.jurisdictionFilingTypeId));
            }
        }
    }, [taxProfile, isDraftForMerchant]);

    const _clearFormValues = useCallback(() => {
        setCredsValidationStatus(undefined);
        setIsTakeGiveBack(false);
        setCredentials({});
        setName("");
        setStateTaxId("");
        setFrequency(undefined);
        setLegalName("");
        setFederalTaxId("");
        setAdditionalStateMetadata("");
        setAdditionalStateMetadata2("");
        setAdditionalStateMetadata3("");
    }, []);

    const _setPlaceholderValues = useCallback(() => {
        setIsTakeGiveBack(false);
        setCredentials({});
        setName(`${PLACEHOLDER_PREFIX}: ${filingType?.name}`);
        setStateTaxId(PLACEHOLDER_VALUES.STATE_TAX_ID);
        setFrequency(PLACEHOLDER_VALUES.FILING_FREQUENCY);
        setLegalName(PLACEHOLDER_VALUES.LEGAL_NAME);
        setFederalTaxId(PLACEHOLDER_VALUES.FEIN);
        setAdditionalStateMetadata(PLACEHOLDER_VALUES.ADDITIONAL_METADATA_1);
        setAdditionalStateMetadata2(PLACEHOLDER_VALUES.ADDITIONAL_METADATA_2);
        setAdditionalStateMetadata3(PLACEHOLDER_VALUES.ADDITIONAL_METADATA_3);
    }, [filingType]);

    useEffect(() => {
        if (isEditing || !filingType) {
            return;
        }

        if (!linked && shouldBypassDetails) {
            _setPlaceholderValues();
        } else if (!linked && !shouldBypassDetails) {
            _clearFormValues();
        } else if (linked && !shouldBypassDetails) {
            _setLinkedTpDefaultValues();
            setName(`${filingType.name}`);
            setFrequency(undefined);
        } else if (linked && shouldBypassDetails) {
            _setLinkedTpDefaultValues();
            setName(`${PLACEHOLDER_PREFIX}: ${filingType.name}`);
            setFrequency(PLACEHOLDER_VALUES.FILING_FREQUENCY);
        }
    }, [
        _clearFormValues,
        _setLinkedTpDefaultValues,
        shouldBypassDetails,
        linked,
        _setPlaceholderValues,
        isEditing,
        filingType,
    ]);

    useEffect(() => {
        if (!isEditing) {
            setIsDraftForMerchant(name.startsWith(PLACEHOLDER_PREFIX));
        }
    }, [name, isEditing]);

    useLayoutEffect(() => {
        if (filingType) {
            let fieldDefinition = filingType.fields[0];
            if (taxProfile && !isEmpty(filingType.fields) && !isNil(taxProfile.jurisdictionUIFlavorGroupId)) {
                const match = filingType.fields.find(
                    (field: IJurisdictionUIField) =>
                        field.jurisdictionFlavorGroupId === taxProfile.jurisdictionUIFlavorGroupId
                );
                fieldDefinition = match ? match : fieldDefinition;
            }
            handleCredentialFlavorChange(fieldDefinition);
        }
    }, [filingType, taxProfile]);

    // This use effect gets the group Login Required info, and sets it.
    useAsyncEffect(async () => {
        setIsGroupLoginUsed(
            taxProfile
                ? taxProfile.credentialsType === "INTERNAL_GROUP" || taxProfile.credentialsType === "PARTNER_GROUP"
                : false
        );
        if (jurisdiction && accountId) {
            const isGroupLoginRequiredForState = await getGroupLoginRequired(accountId, jurisdiction.abbreviatedName);
            setIsGroupLoginRequired(isGroupLoginRequiredForState);
        } else {
            setIsGroupLoginRequired(false);
        }
    }, [jurisdiction, taxProfile, accountId]);

    useEffect(() => {
        setAreExistingCredentialsReused(isEqual(previouslySavedCredentials, credentials));
    }, [previouslySavedCredentials, credentials]);

    // This use effect sets the failing credentials message
    useEffect(() => {
        if (taxProfile) {
            const hasFailingCreds = taxProfile.credentialsFailing === "failing";
            setHasFailingCredentials(hasFailingCreds);
            setAreExistingCredentialsReused(
                !hasFailingCreds && (isUsernameRequired || isPasswordRequired || isPinRequired)
            );
            setFailingCredentialsMessage(
                taxProfile.failingReason ? getTaxProfileFailureReasonDescription(taxProfile.failingReason, false) : ""
            );
        }
    }, [taxProfile, isUsernameRequired, isPasswordRequired, isPinRequired]);

    useEffect(() => {
        if (frequency) {
            const sub = frequency.startsWith("Ops-only: ") ? frequency.substring(10) : frequency;
            // NOTE: we don't have to confirm frequency so long as we're editing and the frequency hasn't been changed
            if (taxProfile !== undefined) {
                const hasFrequencyChanged = taxProfile.frequency !== sub;
                if (hasFrequencyChanged) {
                    setOldFrequency(taxProfile.frequency);
                }
                setShowFrequencyConfirm(hasFrequencyChanged);
            }
        }
    }, [frequency, taxProfile]);

    useEffect(() => {
        if (value) {
            setIsEditing(true);
            setName(value.name);
            setLegalName(value.legalName);
            setJurisdiction(value.state);
            setFrequency(value.frequency);
            setStateTaxId(value.stateTaxId);
            setFederalTaxId(value.federalTaxId);
            setCredentials(value.credentials);
            setTaxProfile(undefined);
            setAreExistingCredentialsReused(false);
            setDoesFrequencyChangeAffectsCurrentPeriod(value.frequencyChangeAffectsCurrentPeriod);
            setOldFrequency(value.oldFrequency);
            setIsGroupLoginUsed(value.credentialsType !== "SIMPLE");
            setAdditionalStateMetadata(value.additionalStateMetadata ?? "");
            setAdditionalStateMetadata2(value.additionalStateMetadata2 ?? "");
            setAdditionalStateMetadata3(value.additionalStateMetadata3 ?? "");
        }
    }, [value]);

    const handleCredentialFlavorChange = (flavor: IJurisdictionUIField) => {
        const usernameField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "USERNAME"
        );
        const passwordField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "PASSWORD"
        );
        const pinField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "PIN"
        );

        const legalNameField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "LEGAL_NAME"
        );
        const stateTaxIdField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "STATE_TAX_ID"
        );
        const feinField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "FEIN"
        );

        const additionalStateMetadataField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "ADDITIONAL_STATE_METADATA"
        );
        const additionalStateMetadataTwoField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) =>
                def.jurisdictionFieldType.tag === "ADDITIONAL_STATE_METADATA_TWO"
        );
        const additionalStateMetadataThreeField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) =>
                def.jurisdictionFieldType.tag === "ADDITIONAL_STATE_METADATA_THREE"
        );

        setSelectedFlavor(flavor);

        setIsUsernameRequired(!!usernameField?.isVisible);
        setIsPasswordRequired(!!passwordField?.isVisible);
        setIsPinRequired(!!pinField?.isVisible);

        setUsernameFieldDefinition(usernameField);
        setPasswordFieldDefinition(passwordField);
        setPinFieldDefinition(pinField);

        setLegalNameFieldDefinition(legalNameField);
        setStateTaxIdFieldDefinition(stateTaxIdField);
        setFeinFieldDefinition(feinField);

        setAdditionalStateMetadataFieldDefinition(additionalStateMetadataField);
        setAdditionalStateMetadata2FieldDefinition(additionalStateMetadataTwoField);
        setAdditionalStateMetadata3FieldDefinition(additionalStateMetadataThreeField);
    };

    useAsyncEffect(async () => {
        if (jurisdiction) {
            // NOTE: frequencies are for the state, regardless of subtype
            const frequencies = await getFilingFrequencyByJurisdiction(jurisdiction.abbreviatedName);

            if (frequencies) {
                const frFiltered: string[] = [];
                let count = 0;
                if (loginContext.user && ["Admin", "Superuser"].includes(loginContext.user.type)) {
                    for (const z of Object.keys(frequencies)) {
                        if (frequencies[z]?.legacy) {
                            frFiltered.push("Ops-only: " + z);
                        } else {
                            frFiltered.push(z);
                        }
                        count++;
                    }
                } else {
                    for (const z of Object.keys(frequencies)) {
                        if (!frequencies[z]?.legacy) {
                            frFiltered.push(z);
                            count++;
                        }
                    }
                    if (frequency && !frFiltered.includes(frequency)) {
                        frFiltered.push(frequency);
                    }
                }
                if (count > 0) {
                    if (frequency && !frFiltered.includes(frequency) && frFiltered.includes("Ops-only: " + frequency)) {
                        setFrequency("Ops-only: " + frequency);
                    }
                    setMessage(undefined);
                    setSelectedFrequencyList(frFiltered);
                } else {
                    setMessage(
                        "The selected state may not have statewide sales tax, for help setting up a local rate, contact support"
                    );
                    setSelectedFrequencyList(undefined);
                }
            } else {
                // no frequencies found, reset list
                setMessage(
                    "The selected state may not have statewide sales tax, for help setting up a local rate, contact support"
                );
                setSelectedFrequencyList(undefined);
            }

            // NOTE: we can reuse credentials so long as we're editing and the state hasn't been changed
            const hasStateChanged = taxProfile !== undefined && taxProfile.state !== jurisdiction.abbreviatedName;
            if (hasStateChanged) {
                setAreExistingCredentialsReused(false);
            }
        }
    }, [jurisdiction, loginContext.user, taxProfile]);

    useAsyncEffect(async () => {
        if (jurisdiction && !linked) {
            const filingTypes = (await getJurisdictionFilingTypes(jurisdiction.id)).filter((ft) => ft.active);
            setFilingTypesForState(filingTypes);
        }
    }, [jurisdiction]);

    useLayoutEffect(() => {
        if (!taxProfile?.jurisdictionFilingTypeId && filingTypesForState.length) {
            const defaultFilingType = filingTypesForState.find((juris) => juris.isDefault);
            setFilingType(defaultFilingType);
            setJurisdictionFilingTypeId(defaultFilingType?.id);
        }
    }, [filingTypesForState, taxProfile?.jurisdictionFilingTypeId]);

    useEffect(() => {
        if (jurisdiction && taxProfile && taxProfile.frequency) {
            calculateFilingPeriod(jurisdiction.abbreviatedName, taxProfile.frequency)
                .then((data) => {
                    setFrequencyData(data);
                })
                .catch((e) => d30ToastError("Problem calculating filing period.", e));
        }
    }, [jurisdiction, taxProfile]);

    const _isFilingTypeRequired = useCallback(() => {
        return (
            hasPermission(loginContext.permissions, "base_access") &&
            filingTypesForState &&
            filingTypesForState.length > 1
        );
    }, [loginContext.permissions, filingTypesForState]);

    const validateStateTaxIdField = useCallback(
        (stateTaxIdValue: string) => {
            const regex = stateTaxIdFieldDefinition?.validationRegex
                ? new RegExp(stateTaxIdFieldDefinition.validationRegex, "i")
                : false;
            const isValid = regex ? regex.test(stateTaxIdValue) : true;
            return !!stateTaxIdValue && isValid;
        },
        [stateTaxIdFieldDefinition]
    );

    const validateFeinField = useCallback(
        (fienValue: string) => {
            const regex = feinFieldDefinition?.validationRegex
                ? new RegExp(feinFieldDefinition.validationRegex, "i")
                : false;
            const isValid = regex ? regex.test(fienValue) : true;
            return !!fienValue && isValid;
        },
        [feinFieldDefinition]
    );

    const validateAdditionalStateMetadataField = useCallback(
        (additionalValue: string) => {
            const regex = additionalStateMetadataFieldDefinition?.validationRegex
                ? new RegExp(additionalStateMetadataFieldDefinition.validationRegex, "i")
                : false;
            const isValid = regex ? regex.test(additionalValue) : true;
            return !!additionalValue && isValid;
        },
        [additionalStateMetadataFieldDefinition]
    );

    const validateAdditionalStateMetadata2Field = useCallback(
        (additionalValue: string) => {
            const regex = additionalStateMetadata2FieldDefinition?.validationRegex
                ? new RegExp(additionalStateMetadata2FieldDefinition.validationRegex, "i")
                : false;
            const isValid = regex ? regex.test(additionalValue) : true;
            return !!additionalValue && isValid;
        },
        [additionalStateMetadata2FieldDefinition]
    );

    const validateAdditionalStateMetadata3Field = useCallback(
        (additionalValue: string) => {
            const regex = additionalStateMetadata3FieldDefinition?.validationRegex
                ? new RegExp(additionalStateMetadata3FieldDefinition.validationRegex, "i")
                : false;
            const isValid = regex ? regex.test(additionalValue) : true;
            return !!additionalValue && isValid;
        },
        [additionalStateMetadata3FieldDefinition]
    );

    const validateUsernameField = useCallback(
        (usernameValue: string) => {
            const regex = usernameFieldDefinition?.validationRegex
                ? new RegExp(usernameFieldDefinition.validationRegex, "i")
                : false;
            const isValid = regex ? regex.test(usernameValue) : true;
            return !!usernameValue && isValid;
        },
        [usernameFieldDefinition]
    );

    const validatePasswordField = useCallback(
        (pwdValue: string) => {
            const regex = passwordFieldDefinition?.validationRegex
                ? new RegExp(passwordFieldDefinition.validationRegex, "i")
                : false;
            const isValid = regex ? regex.test(pwdValue) : true;
            return !!pwdValue && isValid;
        },
        [passwordFieldDefinition]
    );

    const validatePinField = useCallback(
        (pinValue: string) => {
            const regex =
                pinFieldDefinition && pinFieldDefinition.validationRegex
                    ? new RegExp(pinFieldDefinition.validationRegex, "i")
                    : false;
            const isValid = regex ? regex.test(pinValue) : true;
            return !!pinValue && isValid;
        },
        [pinFieldDefinition]
    );

    const getLegalNameLabel = useCallback((): string => {
        return legalNameFieldDefinition?.label ?? "";
    }, [legalNameFieldDefinition]);

    const getTaxIdLabel = useCallback((): string => {
        return stateTaxIdFieldDefinition?.label ?? "";
    }, [stateTaxIdFieldDefinition]);

    useLayoutEffect(() => {
        const failedFields: string[] = [];

        const isTpNameValid = !!_trim(name) && !_trim(name).startsWith(PLACEHOLDER_PREFIX);
        !isTpNameValid && filingType && failedFields.push(tpNameFieldLabel);

        const isLegalNameValid =
            (linked && !linked.jurisdictionUIAdditionalTaxProfile.showLegalName) ||
            !!_trim(legalName) ||
            !legalNameFieldDefinition?.isVisible;
        !isLegalNameValid && filingType && !isTakeGiveBack && failedFields.push(getLegalNameLabel());

        const isFrequencyValid = (linked && !linked.jurisdictionUIAdditionalTaxProfile.showFrequency) || !!frequency;
        !isFrequencyValid && filingType && failedFields.push(filingFreqFieldLabel);

        const isStateTaxIdValid =
            !stateTaxIdFieldDefinition?.isVisible ||
            (linked && !linked.jurisdictionUIAdditionalTaxProfile.showLegalName) ||
            validateStateTaxIdField(_trim(stateTaxId));
        !isStateTaxIdValid && !isTakeGiveBack && failedFields.push(getTaxIdLabel());

        const isFeinValid =
            !feinFieldDefinition?.isVisible ||
            (linked && !linked.jurisdictionUIAdditionalTaxProfile.showFederalTaxId) ||
            validateFeinField(_trim(federalTaxId));
        !isFeinValid && !isTakeGiveBack && failedFields.push(feinFieldDefinition?.label);

        const isFilingTypeValid = !_isFilingTypeRequired() || !!jurisdictionFilingTypeId;
        !isFilingTypeValid && !isTakeGiveBack && failedFields.push(filingTypeFieldLabel);

        const isAdditionalStateMetadataValid =
            !additionalStateMetadataFieldDefinition?.isVisible ||
            (linked && !linked.jurisdictionUIAdditionalTaxProfile.showAdditionalStateMetadata) ||
            validateAdditionalStateMetadataField(_trim(additionalStateMetadata ?? ""));
        !isAdditionalStateMetadataValid &&
            !isTakeGiveBack &&
            failedFields.push(additionalStateMetadataFieldDefinition?.label);

        const isAdditionalStateMetadata2Valid =
            !additionalStateMetadata2FieldDefinition?.isVisible ||
            (linked && !linked.jurisdictionUIAdditionalTaxProfile.showAdditionalStateMetadata2) ||
            validateAdditionalStateMetadata2Field(_trim(additionalStateMetadata2 ?? ""));
        !isAdditionalStateMetadata2Valid &&
            !isTakeGiveBack &&
            failedFields.push(additionalStateMetadata2FieldDefinition?.label);

        const isAdditionalStateMetadata3Valid =
            !additionalStateMetadata3FieldDefinition?.isVisible ||
            (linked && !linked.jurisdictionUIAdditionalTaxProfile.showAdditionalStateMetadata3) ||
            validateAdditionalStateMetadata3Field(_trim(additionalStateMetadata3 ?? ""));
        !isAdditionalStateMetadata3Valid &&
            !isTakeGiveBack &&
            failedFields.push(additionalStateMetadata3FieldDefinition?.label);

        const areFieldsValid = isTakeGiveBack
            ? isTpNameValid && isFrequencyValid
            : shouldBypassDetails
              ? isFilingTypeValid
              : (isTpNameValid &&
                    isLegalNameValid &&
                    isFrequencyValid &&
                    isStateTaxIdValid &&
                    isFeinValid &&
                    isAdditionalStateMetadataValid &&
                    isAdditionalStateMetadata2Valid &&
                    isAdditionalStateMetadata3Valid &&
                    isFilingTypeValid) ||
                linkedTaxProfileQuestionAnswer === "no";

        setAreAllStandardFieldsValid(areFieldsValid);
        setInvalidStandardFields(areFieldsValid ? [] : failedFields.filter(Boolean));
    }, [
        shouldBypassDetails,
        linked,
        legalNameFieldDefinition,
        feinFieldDefinition,
        jurisdictionFilingTypeId,
        stateTaxIdFieldDefinition,
        getTaxIdLabel,
        additionalStateMetadataFieldDefinition,
        additionalStateMetadata2FieldDefinition,
        additionalStateMetadata3FieldDefinition,
        _isFilingTypeRequired,
        isTakeGiveBack,
        name,
        legalName,
        frequency,
        stateTaxId,
        federalTaxId,
        additionalStateMetadata,
        additionalStateMetadata2,
        additionalStateMetadata3,
        filingType,
        getLegalNameLabel,
        linkedTaxProfileQuestionAnswer,
        validateAdditionalStateMetadataField,
        validateAdditionalStateMetadata2Field,
        validateAdditionalStateMetadata3Field,
        validateFeinField,
        validateStateTaxIdField,
    ]);

    useLayoutEffect(() => {
        const failedCredentials: string[] = [];

        const isUsernameValid = !isUsernameRequired || validateUsernameField(_trim(credentials?.username ?? ""));
        !isUsernameValid &&
            usernameFieldDefinition &&
            usernameFieldDefinition.label &&
            !isTakeGiveBack &&
            failedCredentials.push(usernameFieldDefinition.label);

        const isPasswordValid = !isPasswordRequired || validatePasswordField(_trim(credentials?.password ?? ""));
        !isPasswordValid &&
            passwordFieldDefinition &&
            passwordFieldDefinition.label &&
            !isTakeGiveBack &&
            failedCredentials.push(passwordFieldDefinition.label);

        const isPinValid = !isPinRequired || validatePinField(_trim(credentials?.pin ?? ""));
        !isPinValid &&
            pinFieldDefinition &&
            pinFieldDefinition.label &&
            !isTakeGiveBack &&
            failedCredentials.push(pinFieldDefinition.label);

        const areCredentialsValid =
            isTakeGiveBack || isGroupLoginUsed || shouldBypassDetails || (linked && !showLinkedTaxProfileDetails)
                ? true
                : isUsernameValid && isPasswordValid && isPinValid;

        setAreAllCredentialFieldsValid(areCredentialsValid);
        setInvalidCredentialFields(areCredentialsValid ? [] : failedCredentials);
    }, [
        shouldBypassDetails,
        linked,
        credentials,
        isGroupLoginUsed,
        isPasswordRequired,
        isPinRequired,
        isTakeGiveBack,
        isUsernameRequired,
        pinFieldDefinition,
        usernameFieldDefinition,
        passwordFieldDefinition,
        showLinkedTaxProfileDetails,
        validatePasswordField,
        validatePinField,
        validateUsernameField,
    ]);

    const _doSubmit = () => {
        if (isBusy) return;
        setMessage(undefined);
        setIsBusy(true);

        if (linked && !showLinkedTaxProfileDetails) {
            // When the user selects No to creation of an additional tax profile, we skip creating a new tax profile
            _doCancel();
        } else {
            const data = _getData();
            if (data.nameInUse) {
                setMessage("Please choose a tax profile nickname that is not already registered");
                setIsBusy(false);
                nicknameInputRef.current?.focus();
                return;
            }
            const newTaxProfileData: Omit<TaxProfileWithCredentials, "id" | "createdBy" | "updatedBy"> = {
                name: data.name,
                legalName: data.legalName,
                state: data.state ? data.state.abbreviatedName : "",
                stateTaxId: data.stateTaxId,
                federalTaxId: data.federalTaxId,
                frequency: data.frequency as FilingFrequency,
                credentialsFailing: getCredentialsStatus(data),
                credentials: data.credentials,
                hidden: false,
                manualFiling: false,
                requireAdditionalSalesData: false,
                requireUseData: false,
                earlyFilingRequired: false,
                manualReconcileRequired: false,
                ratesVerified: undefined,
                ratesVerifiedBy: undefined,
                typeVerified: undefined,
                typeVerifiedBy: undefined,
                taxRateType: "overlapping",
                jurisdictionFilingTypeId: data.jurisdictionFilingTypeId,
                jurisdictionUIFlavorGroupId: data.jurisdictionUIFlavorGroupId,
                filingMethod: data.filingMethod,
                credentialsType: data.credentialsType ?? "SIMPLE",
                showExpandedFields: false,
                additionalStateMetadata: data.additionalStateMetadata,
                additionalStateMetadata2: data.additionalStateMetadata2,
                additionalStateMetadata3: data.additionalStateMetadata3,
            };
            onSubmit(data, newTaxProfileData).catch((e: any) => {
                setIsBusy(false);
                d30ToastError("Problem adding tax profile", e);
                setMessage("Problem adding tax profile");
            });
        }
    };

    const _changeState = (newState?: IJurisdiction) => {
        if (!newState || newState === jurisdiction) {
            return;
        }
        setJurisdiction(newState);
        setIsGroupLoginUsed(false);
        setShouldBypassDetails(false);
    };

    const _doCancel = () => {
        abortCredsCheck();

        if (onCancel) {
            onCancel(_getData());
        }
    };

    useEffect(() => {
        const isInUse =
            !!allTaxProfiles.length &&
            !!allTaxProfiles.find((profile) => profile.name.toLowerCase() === name.toLowerCase());
        const isSameName = name === taxProfile?.name;
        setIsNicknameInUse(!isSameName && isInUse);
    }, [allTaxProfiles, name, taxProfile]);

    const _getFilingMethodForData = (): TaxProfileFilingMethod | undefined | null => {
        if (isTakeGiveBack) {
            // if the slider is checked it is always take-give-back
            return "take-give-back" as TaxProfileFilingMethod;
        } else {
            if (taxProfile && taxProfile.filingMethod === "take-give-back") {
                // if the type was previously take-give-back and the slider is unchecked set it back to no type
                return undefined;
            } else if (taxProfile) {
                // otherwise set the type back to whatever it was previously
                return taxProfile.filingMethod;
            } else {
                // we don't have anything to fall back to, and the slider is unchecked, so no type
                return undefined;
            }
        }
    };

    const _getJurisdictionFilingType = () => {
        if (!jurisdictionFilingTypeId) {
            return filingType?.id;
        }
        return jurisdictionFilingTypeId;
    };

    const _getJurisdictionFlavorGroup = () => {
        return selectedFlavor ? selectedFlavor.jurisdictionFlavorGroupId : undefined;
    };

    const _getData = (): ITaxProfileFormData => {
        const freq = frequency?.startsWith("Ops-only: ")
            ? (frequency.substring(10) as FilingFrequency)
            : (frequency as FilingFrequency);
        return {
            name,
            nameInUse: isNicknameInUse,
            legalName: isTakeGiveBack ? "take-give-back" : legalName,
            state: jurisdiction,
            frequency: frequency ? freq : undefined,
            stateTaxId: isTakeGiveBack ? "" : stateTaxId,
            federalTaxId: isTakeGiveBack ? "" : federalTaxId,
            credentials: isTakeGiveBack ? {} : credentials,
            taxProfile,
            reuseExistingCredentials: areExistingCredentialsReused,
            frequencyChangeAffectsCurrentPeriod: doesFrequencyChangeAffectsCurrentPeriod,
            oldFrequency,
            credentialsType: isGroupLoginUsed ? (taxProfile?.credentialsType ?? "INTERNAL_GROUP") : "SIMPLE",
            jurisdictionFilingTypeId: _getJurisdictionFilingType(),
            jurisdictionUIFlavorGroupId: _getJurisdictionFlavorGroup(),
            filingMethod: _getFilingMethodForData(),
            showExpandedFields: false,
            additionalStateMetadata: !isTakeGiveBack ? additionalStateMetadata : undefined,
            additionalStateMetadata2: !isTakeGiveBack ? additionalStateMetadata2 : undefined,
            additionalStateMetadata3: !isTakeGiveBack ? additionalStateMetadata3 : undefined,
            sentToMerchant: shouldBypassDetails,
        };
    };

    const _handleAdditionalTaxProfileAnswerChange = (event: any) => {
        if (event.target.value !== linkedTaxProfileQuestionAnswer) {
            setLinkedTaxProfileQuestionAnswer(event.target.value);
            setShowLinkedTaxProfileDetails(event.target.value === "yes");
        }
    };

    const _renderHeaderContent = () => {
        return linked?.jurisdictionUIAdditionalTaxProfile.pageTitle ? (
            <div style={{ display: "inline-block" }}>
                <FormControl>
                    <Typography data-testid={"additionalTaxProfilePageTitle"} style={{ textAlign: "left" }}>
                        {linked.jurisdictionUIAdditionalTaxProfile.pageTitle}
                    </Typography>
                    <RadioGroup row value={linkedTaxProfileQuestionAnswer}>
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio
                                    aria-label="Add additional tax profile"
                                    color="primary"
                                    data-testid={"additionalTaxProfileYes"}
                                    onClick={_handleAdditionalTaxProfileAnswerChange}
                                />
                            }
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio
                                    aria-label="Don't add additional tax profile"
                                    color="primary"
                                    data-testid={"additionalTaxProfileNo"}
                                    onClick={_handleAdditionalTaxProfileAnswerChange}
                                />
                            }
                            label="No"
                        />
                    </RadioGroup>
                </FormControl>
            </div>
        ) : (
            <>{headerContent}</>
        );
    };

    const _renderPageDescription = () => {
        return (
            !linked &&
            !isEditing && (
                <Typography>
                    We will automatically file your returns and transfer the sales tax you collect directly to tax
                    authorities.
                </Typography>
            )
        );
    };

    const _renderState = () => {
        return (
            !linked && (
                <>
                    {isEditing ? (
                        <TextField
                            data-testid={"selectedStateContainer"}
                            InputProps={{
                                readOnly: true,
                            }}
                            inputProps={{
                                [`data-testid`]: "selectedStateInput",
                            }}
                            label={"State:"}
                            value={jurisdiction?.fullName ?? ""}
                        />
                    ) : (
                        <Select<IJurisdiction>
                            data-testid={"stateDropdown"}
                            inputProps={{
                                id: "stateDropdownInput",
                                ["data-testid"]: "stateDropdownInput",
                            }}
                            title="State"
                            noneLabel={`Select a state...`}
                            isDisabled={isBusy}
                            options={states}
                            value={jurisdiction}
                            onChange={(newState) => {
                                abortCredsCheck();
                                _changeState(newState);
                            }}
                            label={(s) => s?.fullName}
                        />
                    )}
                </>
            )
        );
    };

    const _renderBypassDetailsToggle = () => {
        return (
            (!linked || linkedTaxProfileQuestionAnswer === "yes") &&
            !isEditing &&
            hasPermission(loginContext.permissions, "base_access") && (
                <BypassDetailsToggle
                    shouldBypassDetails={shouldBypassDetails}
                    setShouldBypassDetails={setShouldBypassDetails}
                />
            )
        );
    };

    const _renderTakeGiveBackToggle = () => {
        return (
            !shouldLimitEdit &&
            !linked &&
            hasPermission(loginContext.permissions, "base_access") && (
                <TakeGiveBackToggle isTakeGiveBack={isTakeGiveBack} setIsTakeGiveBack={setIsTakeGiveBack} />
            )
        );
    };

    const _renderFilingType = () => {
        return (
            !isTakeGiveBack &&
            _isFilingTypeRequired() &&
            !linked && (
                <Select<IJurisdictionFilingType>
                    noneLabel={`Select a filing type...`}
                    title={filingTypeFieldLabel}
                    validate={() => (invalidStandardFields.includes(filingTypeFieldLabel) ? "Invalid" : undefined)}
                    data-testid={"filingType"}
                    isDisabled={isBusy}
                    options={filingTypesForState}
                    value={filingType}
                    onChange={(x) => {
                        abortCredsCheck();
                        setCredsValidationStatus(undefined);
                        setFilingType(x);
                        setJurisdictionFilingTypeId(x?.id);
                    }}
                    label={(s) => s?.name}
                    isRequired
                />
            )
        );
    };

    const _renderLegalName = () => {
        return (
            !isTakeGiveBack &&
            legalNameFieldDefinition?.isVisible &&
            (!linked || (linked.jurisdictionUIAdditionalTaxProfile.showLegalName && showLinkedTaxProfileDetails)) && (
                <div>
                    <TextField
                        data-testid={"legalNameContainer"}
                        inputProps={{
                            [`data-testid`]: "legalNameInput",
                        }}
                        InputProps={{
                            endAdornment: legalNameFieldDefinition.toolTip && (
                                <Tooltip title={legalNameFieldDefinition.toolTip}>
                                    <InfoIcon fontSize={"small"} color="primary" />
                                </Tooltip>
                            ),
                        }}
                        validate={() => (invalidStandardFields.includes(getLegalNameLabel()) ? "Invalid" : undefined)}
                        className="fs-exclude"
                        label={`${getLegalNameLabel()} ${
                            legalNameFieldDefinition.helpText ? ` (e.g. ${legalNameFieldDefinition.helpText})` : ""
                        }`}
                        isDisabled={isBusy}
                        value={legalName}
                        onChange={setLegalName}
                        isRequired
                    />
                </div>
            )
        );
    };

    const _renderStateTaxId = () => {
        return (
            !isTakeGiveBack &&
            stateTaxIdFieldDefinition?.isVisible &&
            (!linked || (linked.jurisdictionUIAdditionalTaxProfile.showStateTaxId && showLinkedTaxProfileDetails)) && (
                <div style={{ marginBottom: "8px" }}>
                    <TextField
                        inputRef={stateTaxIdInputRef}
                        data-testid={"stateTaxIdContainer"}
                        InputProps={{
                            endAdornment: stateTaxIdFieldDefinition.toolTip && (
                                <Tooltip title={stateTaxIdFieldDefinition.toolTip}>
                                    <InfoIcon fontSize={"small"} color="primary" />
                                </Tooltip>
                            ),
                        }}
                        inputProps={{
                            [`data-testid`]: "stateTaxIdInput",
                        }}
                        validate={() => (invalidStandardFields.includes(getTaxIdLabel()) ? "Invalid" : undefined)}
                        className="fs-exclude"
                        label={`${getTaxIdLabel()} ${
                            stateTaxIdFieldDefinition.helpText ? ` (e.g. ${stateTaxIdFieldDefinition.helpText})` : ""
                        }`}
                        isDisabled={isBusy}
                        value={stateTaxId}
                        onChange={setStateTaxId}
                        isRequired
                    />
                    <a
                        href="https://www.davosalestax.com/understanding-your-business-state-tax-id/"
                        target="_blank"
                        rel="noreferrer">
                        Questions about your {getTaxIdLabel()}?
                    </a>
                </div>
            )
        );
    };

    const _renderFederalTaxId = () => {
        return (
            !isTakeGiveBack &&
            feinFieldDefinition?.isVisible &&
            (!linked ||
                (linked.jurisdictionUIAdditionalTaxProfile.showFederalTaxId && showLinkedTaxProfileDetails)) && (
                <div>
                    <TextField
                        data-testid={"feinContainer"}
                        inputProps={{
                            [`data-testid`]: "feinInput",
                        }}
                        InputProps={{
                            endAdornment: feinFieldDefinition.toolTip && (
                                <Tooltip title={feinFieldDefinition.toolTip}>
                                    <InfoIcon fontSize={"small"} color="primary" />
                                </Tooltip>
                            ),
                        }}
                        validate={() =>
                            invalidStandardFields.includes(feinFieldDefinition.label) ? "Invalid" : undefined
                        }
                        className="fs-exclude feinContainer"
                        label={`${feinFieldDefinition.label} ${
                            feinFieldDefinition.helpText ? ` (e.g. ${feinFieldDefinition.helpText})` : ""
                        }`}
                        isDisabled={isBusy}
                        value={federalTaxId}
                        onChange={setFederalTaxId}
                        isRequired
                    />
                </div>
            )
        );
    };

    const _renderAdditionalMetadata = () => {
        return (
            !isTakeGiveBack &&
            additionalStateMetadataFieldDefinition?.isVisible &&
            (!linked ||
                (linked.jurisdictionUIAdditionalTaxProfile.showAdditionalStateMetadata &&
                    showLinkedTaxProfileDetails)) && (
                <div>
                    <TextField
                        data-testid={"additionalStateMetadata"}
                        validate={() =>
                            invalidStandardFields.includes(additionalStateMetadataFieldDefinition.label)
                                ? "Invalid"
                                : undefined
                        }
                        InputProps={{
                            endAdornment: additionalStateMetadataFieldDefinition.toolTip && (
                                <Tooltip title={additionalStateMetadataFieldDefinition.toolTip}>
                                    <InfoIcon fontSize={"small"} color="primary" />
                                </Tooltip>
                            ),
                        }}
                        className="fs-exclude"
                        label={`${additionalStateMetadataFieldDefinition.label} ${
                            additionalStateMetadataFieldDefinition.helpText
                                ? ` (e.g. ${additionalStateMetadataFieldDefinition.helpText})`
                                : ""
                        }`}
                        isDisabled={isBusy}
                        value={additionalStateMetadata}
                        onChange={setAdditionalStateMetadata}
                        isRequired
                    />
                </div>
            )
        );
    };

    const _renderAdditionalMetadata2 = () => {
        return (
            !isTakeGiveBack &&
            additionalStateMetadata2FieldDefinition?.isVisible &&
            (!linked ||
                (linked.jurisdictionUIAdditionalTaxProfile.showAdditionalStateMetadata2 &&
                    showLinkedTaxProfileDetails)) && (
                <div>
                    <TextField
                        data-testid={"additionalStateMetadata2"}
                        validate={() =>
                            invalidStandardFields.includes(additionalStateMetadata2FieldDefinition.label)
                                ? "Invalid"
                                : undefined
                        }
                        InputProps={{
                            endAdornment: additionalStateMetadata2FieldDefinition.toolTip && (
                                <Tooltip title={additionalStateMetadata2FieldDefinition.toolTip}>
                                    <InfoIcon fontSize={"small"} color="primary" />
                                </Tooltip>
                            ),
                        }}
                        className="fs-exclude"
                        label={`${additionalStateMetadata2FieldDefinition.label} ${
                            additionalStateMetadata2FieldDefinition.helpText
                                ? ` (e.g. ${additionalStateMetadata2FieldDefinition.helpText})`
                                : ""
                        }`}
                        isDisabled={isBusy}
                        value={additionalStateMetadata2}
                        onChange={setAdditionalStateMetadata2}
                        isRequired
                    />
                </div>
            )
        );
    };

    const _renderAdditionalMetadata3 = () => {
        return (
            !isTakeGiveBack &&
            additionalStateMetadata3FieldDefinition?.isVisible &&
            (!linked ||
                (linked.jurisdictionUIAdditionalTaxProfile.showAdditionalStateMetadata3 &&
                    showLinkedTaxProfileDetails)) && (
                <div>
                    <TextField
                        data-testid={"additionalStateMetadata3"}
                        validate={() =>
                            invalidStandardFields.includes(additionalStateMetadata3FieldDefinition.label)
                                ? "Invalid"
                                : undefined
                        }
                        InputProps={{
                            endAdornment: additionalStateMetadata3FieldDefinition.toolTip && (
                                <Tooltip title={additionalStateMetadata3FieldDefinition.toolTip}>
                                    <InfoIcon fontSize={"small"} color="primary" />
                                </Tooltip>
                            ),
                        }}
                        className="fs-exclude"
                        label={`${additionalStateMetadata3FieldDefinition.label} ${
                            additionalStateMetadata3FieldDefinition.helpText
                                ? ` (e.g. ${additionalStateMetadata3FieldDefinition.helpText})`
                                : ""
                        }`}
                        isDisabled={isBusy}
                        value={additionalStateMetadata3}
                        onChange={setAdditionalStateMetadata3}
                        isRequired
                    />
                </div>
            )
        );
    };

    const abortCredsCheck = () =>
        controllerRef && controllerRef.current && controllerRef.current.abort("Expectedly bailing on creds check.");

    const checkCreds = () => {
        if (filingType?.id) {
            setCredsValidationStatus("validating");
            controllerRef.current = new AbortController();

            const postData: EfileCheckData = {
                jurisdictionFilingTypeId: filingType.id,
                profileLoginCreds: {
                    username: _trim(credentials.username),
                    password: _trim(credentials.password),
                },
                abortController: controllerRef.current,
            };

            if (filingType?.davoCheckCreds) {
                postData.profileLoginCreds.pin = _trim(credentials.pin);
            } else {
                if (_trim(credentials.pin)) {
                    // Pin Provided in Additional Options for Skyscrapper integration
                    postData.additionalOptions = {
                        [`${pinFieldDefinition?.jurisdictionFieldType.id}`]: _trim(credentials.pin),
                    };
                }
            }

            getCheckEFileCredentials(postData)
                .then((response) => {
                    if (response.errorMessage?.includes("Did not receive a response after")) {
                        setCredsValidationStatus("timeout");
                    } else if (response.error) {
                        setCredsValidationStatus("error");
                    } else if (response.isPasswordChangeRequired) {
                        setCredsValidationStatus("isPasswordChangeRequired");
                    } else if (response.isValid) {
                        setCredsValidationStatus("valid");
                    } else {
                        setCredsValidationStatus("invalid");
                    }
                })
                .catch((e) => {
                    d30ToastError("There was a problem checking credentials.", e);
                    if (e.name !== "AbortError" && !e.message.includes("aborted")) {
                        setCredsValidationStatus("error");
                    } else {
                        setCredsValidationStatus(undefined);
                    }
                });

            stateTaxIdInputRef.current && stateTaxIdInputRef.current.focus();
        }
    };

    const _renderCredentials = () => {
        return (
            !isTakeGiveBack &&
            !isGroupLoginUsed &&
            (isUsernameRequired || isPasswordRequired || isPinRequired) &&
            (!linked || showLinkedTaxProfileDetails) && (
                <CredentialsEntry
                    areExistingCredentialsReused={areExistingCredentialsReused}
                    abortCredsCheck={abortCredsCheck}
                    checkCreds={checkCreds}
                    areAllCredentialFieldsValid={areAllCredentialFieldsValid}
                    credsValidationStatus={credsValidationStatus}
                    credentials={credentials}
                    setCredentials={setCredentials}
                    handleCredentialFlavorChange={handleCredentialFlavorChange}
                    filingType={filingType}
                    passwordFieldDefinition={passwordFieldDefinition}
                    pinFieldDefinition={pinFieldDefinition}
                    usernameFieldDefinition={usernameFieldDefinition}
                    isBusy={isBusy}
                    invalidCredentialFields={invalidCredentialFields}
                    selectedFlavor={selectedFlavor}
                    siteUrl={filingType?.url ?? "site"}
                    hasFailingCredentials={hasFailingCredentials}
                    nameString={filingType?.name ? `${filingType?.name} site` : "State site"}
                    failingCredentialsMessage={failingCredentialsMessage}
                    isGroupLoginRequired={isGroupLoginRequired}
                    isUsernameRequired={isUsernameRequired}
                    isPasswordRequired={isPasswordRequired}
                    isPinRequired={isPinRequired}
                />
            )
        );
    };

    const _renderFrequency = () => {
        return (
            !shouldLimitEdit &&
            !!selectedFrequencyList &&
            (!linked || (linked?.jurisdictionUIAdditionalTaxProfile.showFrequency && showLinkedTaxProfileDetails)) && (
                <Select<string>
                    validate={() => (invalidStandardFields.includes(filingFreqFieldLabel) ? "Invalid" : undefined)}
                    data-testid={"filingFrequency"}
                    inputProps={{
                        [`data-testid`]: "filingFrequencyInput",
                    }}
                    title={filingFreqFieldLabel}
                    noneLabel={`Select a filing frequency...`}
                    isDisabled={isBusy}
                    options={selectedFrequencyList.sort()}
                    value={frequency || ""}
                    onChange={setFrequency}
                    label={(f) => {
                        if (f.startsWith("Ops-only: ")) {
                            return "Ops-only: " + FrequencyLabels[f.substring(10)].toString();
                        } else {
                            return FrequencyLabels[f];
                        }
                    }}
                />
            )
        );
    };

    const _renderFrequencyChange = () => {
        return (
            !shouldLimitEdit &&
            showFrequencyConfirm &&
            frequency &&
            (!linked || showLinkedTaxProfileDetails) && (
                <div style={{ marginTop: "10px", marginBottom: "10px" }} data-testid={"frequencyChangeConfirmation"}>
                    <FormControl>
                        <RadioGroup row aria-label="frequencyChangeRadioGroup" value={linkedTaxProfileQuestionAnswer}>
                            <FormControlLabel
                                value="no"
                                control={
                                    <Radio
                                        checked={!doesFrequencyChangeAffectsCurrentPeriod}
                                        color="primary"
                                        data-testid={"shouldImpactNextPeriodOnly"}
                                        onClick={() => {
                                            setDoesFrequencyChangeAffectsCurrentPeriod(false);
                                        }}
                                    />
                                }
                                label="Only the next filing will be updated to match the new frequency."
                            />
                            <FormControlLabel
                                value="yes"
                                control={
                                    <Radio
                                        checked={doesFrequencyChangeAffectsCurrentPeriod}
                                        color="primary"
                                        data-testid={"shouldImpactCurrentAndNextPeriod"}
                                        onClick={() => {
                                            setDoesFrequencyChangeAffectsCurrentPeriod(true);
                                        }}
                                    />
                                }
                                label="Both the current and next filings should be updated to match the new frequency."
                            />
                        </RadioGroup>
                    </FormControl>

                    <br />
                    {frequencyData && (
                        <div>
                            {`Current period started on ${frequencyData.start}`}
                            <br />
                            {`Next period starts after ${frequencyData.end}`}
                        </div>
                    )}
                </div>
            )
        );
    };

    const _renderTaxProfileName = () => {
        return (
            !linked &&
            filingType && (
                <TextField
                    validate={() =>
                        isNicknameInUse
                            ? "Nickname in use"
                            : invalidStandardFields.includes(tpNameFieldLabel)
                              ? "Invalid"
                              : undefined
                    }
                    data-testid={"tpNameContainer"}
                    inputProps={{
                        [`data-testid`]: "tpNameInput",
                    }}
                    inputRef={nicknameInputRef}
                    label={`${tpNameFieldLabel} (for your purposes)`}
                    isDisabled={isBusy}
                    value={name}
                    onChange={setName}
                    isRequired
                />
            )
        );
    };

    const _renderAlerts = () => {
        return (
            (!!invalidStandardFields.length || !!invalidCredentialFields.length) &&
            (!linked || showLinkedTaxProfileDetails) && (
                <Alert
                    data-testid={"tpValidationAlert"}
                    icon={<BlockIcon />}
                    color={"error"}
                    variant={isDarkMode ? "outlined" : "standard"}
                    style={{ marginBottom: "6px" }}>
                    <AlertTitle>Invalid Fields:</AlertTitle>
                    <span>{invalidStandardFields.concat(invalidCredentialFields).join(", ")}</span>
                </Alert>
            )
        );
    };

    const CancelButton = () => (
        <Button
            data-testid={"cancelTpFormBtn"}
            disabled={isBusy}
            onClick={_doCancel}
            variant="outlined"
            color="primary"
            style={{ marginRight: "10px" }}>
            Cancel
        </Button>
    );

    const _renderAdditionalInfoHeading = () => {
        return (
            (!linked || showLinkedTaxProfileDetails) && (
                <Typography className={"da-tp-form-heading"} component="h3">
                    Additional Filing Information
                </Typography>
            )
        );
    };

    const _renderCompanyInformationHeading = () => {
        return (
            (!linked || showLinkedTaxProfileDetails) && (
                <Typography className={"da-tp-form-heading"} component="h3">
                    Company Information
                </Typography>
            )
        );
    };

    const _renderFrequencyChangePreventionNotice = () => {
        return (
            shouldLimitEdit && (
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <span>NOTE: Filing frequency cannot be changed from the filing screen</span>
                </div>
            )
        );
    };

    const _renderUsingGroupLoginNotice = () => {
        return (
            isGroupLoginUsed &&
            !isTakeGiveBack &&
            (!linked || showLinkedTaxProfileDetails) && (
                <div style={{ padding: "10px 0", textAlign: "left" }}>
                    DAVO has established secure connectivity to the tax agency on your behalf using the information you
                    provided.
                </div>
            )
        );
    };

    const _renderNeedSupportNotice = () => {
        return (
            <Typography data-testid={"needSupportMsg"} className={"needSupportMsg"} gutterBottom={true}>
                <LightbulbOutlinedIcon />
                Need more support? Give us a call at <a href="tel:+18886598432">888.659.8432</a>
            </Typography>
        );
    };

    const _renderMessageNotice = () => {
        return (
            message && (
                <Typography data-testid={"messagesNoticeContainer"} style={{ color: "red" }}>
                    {message}
                </Typography>
            )
        );
    };

    return (
        <div
            data-testid={"taxProfileForm"}
            className={[isDarkMode ? "isDarkMode" : "", "taxProfileForm"].filter(Boolean).join(" ")}>
            {_renderHeaderContent()}
            {_renderPageDescription()}

            {!linked && (
                <>
                    <Typography
                        className={["da-tp-form-heading", isEditing && "first-of-type"].filter(Boolean).join(" ")}
                        component={"h3"}>
                        State Tax Return
                    </Typography>

                    <Typography variant="body1">
                        Refer to your state business registration to find this information.
                    </Typography>
                </>
            )}

            {_renderState()}

            {jurisdiction && _renderBypassDetailsToggle()}
            {filingType && !shouldBypassDetails && _renderTakeGiveBackToggle()}
            {jurisdiction && _renderFilingType()}
            {filingType && !shouldBypassDetails && _renderTaxProfileName()}
            {filingType && !shouldBypassDetails && _renderCredentials()}
            {filingType && !shouldBypassDetails && _renderAdditionalInfoHeading()}
            {filingType && !shouldBypassDetails && _renderStateTaxId()}
            {filingType && !shouldBypassDetails && _renderAdditionalMetadata()}
            {filingType && !shouldBypassDetails && _renderAdditionalMetadata2()}
            {filingType && !shouldBypassDetails && _renderAdditionalMetadata3()}
            {filingType && !shouldBypassDetails && _renderFrequency()}
            {filingType && !shouldBypassDetails && _renderFrequencyChange()}
            {filingType && !shouldBypassDetails && _renderCompanyInformationHeading()}
            {filingType && !shouldBypassDetails && _renderLegalName()}
            {filingType && !shouldBypassDetails && _renderFederalTaxId()}
            {filingType && !shouldBypassDetails && _renderFrequencyChangePreventionNotice()}
            {filingType && !shouldBypassDetails && _renderUsingGroupLoginNotice()}
            {filingType && !shouldBypassDetails && _renderNeedSupportNotice()}
            {filingType && _renderMessageNotice()}
            {filingType && _renderAlerts()}

            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}>
                {showCancel && <CancelButton />}
                {jurisdiction && (
                    <Button
                        data-testid={"saveBtn"}
                        disabled={
                            isBusy ||
                            !jurisdiction ||
                            (!shouldBypassDetails &&
                                (!areAllStandardFieldsValid ||
                                    !areAllCredentialFieldsValid ||
                                    credsValidationStatus === "validating" ||
                                    credsValidationStatus === "invalid" ||
                                    (((configInfo.credsCheck.skyScraperEnabled && filingType?.skyscraperCheckCreds) ||
                                        (configInfo.credsCheck.davoEnabled && filingType?.davoCheckCreds)) &&
                                        !areExistingCredentialsReused &&
                                        !isGroupLoginUsed &&
                                        !isTakeGiveBack &&
                                        credsValidationStatus === undefined)))
                        }
                        startIcon={
                            isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                        }
                        onClick={_doSubmit}
                        variant={"contained"}
                        color="primary"
                        autoFocus>
                        {saveLabel ? saveLabel : "Save"}
                    </Button>
                )}
            </div>
        </div>
    );
};
