import { qbReconnectBtn, useLoginContext } from "@davo/portal-common";
import React from "react";
import { useConfigMerchantContext } from "../context";
import { BoardingContainer } from "./BoardingContainer";
import { BoardingLogin } from "./flow/BoardingLogin";

export function QuickBooksBoarding() {
    const { merchantConfigInfo: configInfo } = useConfigMerchantContext();
    const loginContext = useLoginContext();

    return (
        <BoardingContainer posType={"quickbooks"}>
            {!loginContext.user && <BoardingLogin posType={"quickbooks"} />}
            {loginContext.user && (
                <div style={{ textAlign: "center" }}>
                    <p>DAVO automates every step of the sales tax process — from collecting to filing to paying.</p>
                    <p>Click below to connect your QuickBooks account and put your sales tax on autopilot.</p>
                    {configInfo && (
                        <a
                            href={configInfo.quickBooksConnectLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-testid={"quickBooksConnectLink"}>
                            <img src={qbReconnectBtn} alt="QuickBooks Connect button" style={{ margin: "20px 0" }} />
                        </a>
                    )}
                </div>
            )}
        </BoardingContainer>
    );
}
