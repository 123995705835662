import React from "react";
import { useLoginContext } from "./context";

const first = (name?: string | null) => (name ? name.substring(0, 1) : "").toUpperCase();

export const UserAvatar = ({ size, onClick }: { size: number; onClick?: (e: any) => void }) => {
    const loginContext = useLoginContext();
    return (
        <div
            style={{
                width: `${size}px`,
                height: `${size}px`,
                borderRadius: "50%",
                background: "#1A2F5B",
                color: "#FFFFFF",
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                fontSize: `${size * 0.5}px`,
                fontWeight: "bold",
            }}
            onClick={onClick}>
            {first(loginContext.user?.firstName) + first(loginContext.user?.lastName) ||
                first(loginContext.user?.email)}
        </div>
    );
};
