import { POSLogo } from "@davo/portal-common";
import { POSType } from "@davo/types";
import { Grid, Paper, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { FunctionComponent, PropsWithChildren } from "react";
import davoLogo from "../resources/davo_logo.png";

export const sharedButtonContainer = {
    submitContainer: {
        display: "flex",
        justifyContent: "center",
        margin: "10px",
    },
};

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: "20px",
        height: "100%",
    },
    control: {
        padding: theme.spacing(2),
    },
    panel: {
        verticalAlign: "middle",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    item: {
        maxWidth: "500px",
    },
}));

export interface IBoardingContainer {
    posType: POSType | undefined | null;
    plusMerchantName?: string | null;
    tagLine?: string;
}

export const BoardingContainer: FunctionComponent<PropsWithChildren<IBoardingContainer>> = ({
    posType,
    plusMerchantName,
    tagLine = "You are on your way to never worrying about sales tax again!",
    children,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.panel} data-testid={"boardingContainer"}>
            <Grid
                container
                item
                xs={12}
                sm={12}
                md={10}
                lg={10}
                xl={8}
                spacing={0}
                direction="row"
                justifyContent="center"
                style={{ padding: "10px" }}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.item}>
                    <Paper className={classes.paper}>
                        <div className={classes.panel}>
                            <img style={{ width: "200px" }} alt="DAVO Logo" src={davoLogo} />
                            <Typography style={{ fontSize: 40, marginTop: "-10px" }}>+</Typography>
                            {posType && <POSLogo posType={posType} width={200} />}
                            {plusMerchantName && (
                                <>
                                    <Typography style={{ fontSize: 40, marginTop: "-10px", marginBottom: "-10px" }}>
                                        +
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontSize: 30,
                                            fontWeight: "bold",
                                            margin: "10px",
                                            textAlign: "center",
                                        }}>
                                        {plusMerchantName}
                                    </Typography>
                                </>
                            )}
                            {tagLine && (
                                <div>
                                    <Typography
                                        style={{ marginTop: "30px", marginBottom: "20px", textAlign: "center" }}>
                                        {tagLine}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.item}>
                    <Paper className={classes.paper}>
                        <div className={classes.panel}>{children}</div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};
