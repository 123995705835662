import { FormError, getPublicJurisdictions, Select, TextField } from "@davo/portal-common";
import {
    IJurisdiction,
    isEmailValid,
    isPhoneValid,
    IStorageMarketingInfo,
    validateEmail,
    validateNotNull,
    validatePhone,
    validateZip,
} from "@davo/types";
import { Button, Theme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { useConfigMerchantContext } from "../context";
import { testSpotOnRetailCredentials } from "../services/boarding";
import { BoardingContainer, sharedButtonContainer } from "./BoardingContainer";
import { SpotOnRetailPosInfo } from "./types/PosInfoTypes";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
    ...sharedButtonContainer,
}));

export function SpotOnRetailBoarding() {
    const classes = useStyles();
    const [spotOnLocId, setSpotOnLocId] = useState<string | undefined>(undefined);
    const [status, setStatus] = useState<"initial" | "complete">("initial");
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [bname, setBname] = useState<string | undefined>(undefined);
    const [address1, setAddress1] = useState<string | undefined>(undefined);
    const [phone, setPhone] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [city, setCity] = useState<string | undefined>(undefined);
    const [state, setState] = useState<IJurisdiction>();
    const [selectedStateOption, setSelectedStateOption] = useState<string>();
    const [states, setStates] = useState<IJurisdiction[]>([]);
    const [zip, setZip] = useState<string | undefined>(undefined);
    const { merchantConfigInfo: configInfo } = useConfigMerchantContext();
    const navigate = useNavigate();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    useAsyncEffect(async () => {
        setStates(await getPublicJurisdictions());
        const storageMarketingInfo: IStorageMarketingInfo = JSON.parse(localStorage.getItem("marketingInfo") ?? "{}");
        setPhone(storageMarketingInfo.phone);
        setEmail(storageMarketingInfo.email);
    }, []);

    useEffect(() => {
        const selected = states.find((ba: IJurisdiction) => ba.abbreviatedName === selectedStateOption);
        setState(selected);
    }, [states, state, selectedStateOption]);

    const doValidate = async () => {
        if (isBusy) {
            return;
        }

        if (spotOnLocId) {
            setIsBusy(true);
            const result = await testSpotOnRetailCredentials(spotOnLocId);
            setIsBusy(false);
            if (result.success.valid === true) {
                setStatus("complete");
            } else {
                setMessage(
                    "We do not yet have access to this SpotOn location ID.  Please email SpotOn to request activation for DAVO."
                );
            }
        }
    };

    const doNext = () => {
        if (
            bname &&
            spotOnLocId &&
            address1 &&
            city &&
            state &&
            zip &&
            email &&
            phone &&
            isEmailValid(email) &&
            isPhoneValid(phone)
        ) {
            const posData: SpotOnRetailPosInfo = {
                address1,
                city,
                email,
                spotOnLocId,
                phone,
                state: state.abbreviatedName,
                title: bname,
                zip,
            };
            navigate(configInfo.spotOnRetailInvitationURL, {
                state: posData,
            });
        } else {
            setMessage("Please fill out required fields.");
        }
    };

    return (
        <BoardingContainer posType={"spoton-retail"}>
            {status === "complete" && (
                <div>
                    <div style={{ paddingBottom: "10px" }}>Tell us a little about your business:</div>
                    <div>
                        <TextField
                            label="Business Name"
                            value={bname ?? ""}
                            onChange={setBname}
                            onEnterPress={doNext}
                            margin="dense"
                            isRequired
                            validate={validateNotNull}
                        />
                        <TextField
                            label="Phone"
                            validate={validatePhone}
                            value={phone ?? ""}
                            onChange={setPhone}
                            onEnterPress={doNext}
                            margin="dense"
                            isRequired
                        />
                        <TextField
                            label="Email"
                            validate={validateEmail}
                            value={email ?? ""}
                            onChange={setEmail}
                            onEnterPress={doNext}
                            margin="dense"
                            isRequired
                        />
                        <TextField
                            label="Address"
                            value={address1 ?? ""}
                            onChange={setAddress1}
                            onEnterPress={doNext}
                            margin="dense"
                            isRequired
                            validate={validateNotNull}
                        />
                        <TextField
                            label="City"
                            value={city ?? ""}
                            onChange={setCity}
                            onEnterPress={doNext}
                            margin="dense"
                            isRequired
                            validate={validateNotNull}
                        />
                        <Select<IJurisdiction>
                            title="State"
                            options={states}
                            value={state}
                            label={(jurisdiction) => jurisdiction.fullName}
                            onChange={(val) => val && setSelectedStateOption(val.abbreviatedName)}
                            isRequired
                            validate={(v) => (v ? undefined : validateNotNull(null))}
                        />
                        <TextField
                            label="ZIP"
                            isRequired
                            validate={(v) => (v ? validateZip(v) : validateNotNull(null))}
                            value={zip ?? ""}
                            onChange={setZip}
                            onEnterPress={doNext}
                            margin="dense"
                        />
                        {message && <FormError message={message} />}
                        <div className={classes.submitContainer}>
                            <Button type="submit" variant="contained" color="primary" onClick={doNext}>
                                Connect with SpotOn Retail
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {status === "initial" && (
                <div data-testid={"initialStep"}>
                    <div style={{ paddingBottom: "10px" }}>
                        Enter the Location ID provided to you via email. If you have not received a Location ID, email{" "}
                        <a href="mailto:retailteam@spoton.com">retailteam@spoton.com</a>.
                    </div>
                    <TextField
                        label="Location Id"
                        inputProps={{
                            [`data-testid`]: "locationIdInput",
                        }}
                        value={spotOnLocId ?? ""}
                        onChange={setSpotOnLocId}
                        onEnterPress={doValidate}
                        isDisabled={isBusy}
                        isRequired
                        validate={validateNotNull}
                    />
                    {message && <FormError message={message} />}
                    <div className={classes.submitContainer}>
                        <Button
                            data-testid={"submitBtn"}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={doValidate}
                            style={{ margin: "20px" }}
                            disabled={!spotOnLocId || !spotOnLocId.trim() || isBusy}
                            startIcon={
                                isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                            }>
                            Continue with SpotOn Retail
                        </Button>
                    </div>
                </div>
            )}
        </BoardingContainer>
    );
}
