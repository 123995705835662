import {
    Checkbox,
    FormControl,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Typography,
} from "@mui/material";
import range from "lodash/range";
import React, { ReactElement, useEffect, useState } from "react";

export interface IMultiSelectProps<T> {
    value: T[];
    options: T[];
    onChange: (value: T[]) => void;
    label?: (value: T) => string | ReactElement;
    noneLabel?: string;
    style?: React.CSSProperties;
    isDisabled?: boolean;
    title?: string;
    showFullWidth?: boolean;
    showSelectAll?: boolean;
    selectAllStyle?: React.CSSProperties;
}

export function MultiSelect<T extends string | number | object>({
    value,
    options,
    onChange,
    label,
    noneLabel = "No items to display",
    style,
    isDisabled,
    title,
    showFullWidth = true,
    showSelectAll,
    selectAllStyle,
}: IMultiSelectProps<T>) {
    const [checked, setChecked] = useState<number[]>([]);
    const [shouldSelectAll, setShouldSelectAll] = useState<boolean>(false);

    useEffect(() => {
        const selectedIndices = value.map((v) => options.indexOf(v));
        setChecked(selectedIndices);
        setShouldSelectAll(selectedIndices.length === options.length);
    }, [options, value]);

    const handleToggle = (arg: number) => () => {
        const currentIndex = checked.indexOf(arg);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(arg);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        setShouldSelectAll(options.length === newChecked.length);

        const newValue: T[] = newChecked.map((i) => options[+i]);
        onChange(newValue);
    };

    const toggleSelectAll = () => {
        const isSelectAll = !shouldSelectAll;
        setShouldSelectAll(isSelectAll);
        setChecked(isSelectAll ? range(options.length) : []);
        onChange(isSelectAll ? options : []);
    };

    const format = (t: any) => (label ? label(t) : (t.label ?? t.name ?? String(t)));
    const getLabelId = (key: string) => {
        return `checkbox-label-${key}`;
    };

    return (
        <FormControl variant="outlined" fullWidth={showFullWidth} disabled={isDisabled} style={style}>
            <List subheader={<ListSubheader>{title}</ListSubheader>}>
                {showSelectAll && (
                    <ListItemButton
                        disabled={isDisabled}
                        role={"listitem"}
                        key="select-all"
                        dense
                        onClick={toggleSelectAll}
                        style={{ borderBottom: "1px solid", marginBottom: "10px" }}>
                        <ListItemIcon>
                            <Checkbox
                                inputProps={{ "aria-labelledby": "select-all" }}
                                edge="start"
                                checked={shouldSelectAll}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText
                            id="select-all"
                            primary={
                                <Typography variant="body1" style={selectAllStyle}>
                                    {"Select all"}
                                </Typography>
                            }
                        />
                    </ListItemButton>
                )}

                {options.map((t: any, i) => (
                    <ListItemButton disabled={isDisabled} role={"listitem"} key={i} dense onClick={handleToggle(i)}>
                        <ListItemIcon>
                            <Checkbox
                                inputProps={{ "aria-labelledby": getLabelId(i.toString()) }}
                                edge="start"
                                checked={checked.indexOf(i) !== -1}
                                tabIndex={-1}
                            />
                        </ListItemIcon>
                        <ListItemText id={getLabelId(i.toString())} primary={format(t)} />
                    </ListItemButton>
                ))}
            </List>
            {options.length === 0 && <Typography>{noneLabel}</Typography>}
        </FormControl>
    );
}

export default MultiSelect;
