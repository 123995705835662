import {
    addTaxProfile,
    getJurisdictionAdditionalTaxProfiles,
    getJurisdictionByStateCode,
    Header,
    setLocationTaxProfiles,
    TaxProfileForm,
} from "@davo/portal-common";
import {
    FilingFrequency,
    IJurisdictionUIAdditionalTaxProfile,
    ITaxProfileFormData,
    TaxProfileWithCredentials,
} from "@davo/types";
import assert from "assert";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import { useAccountContext } from "../../context";

export interface ITaxProfileSetupNewType {
    onCreate: () => void;
    onCancel: () => void;
    locationId: string;
}

export function TaxProfileSetupNew({ onCreate, onCancel, locationId }: ITaxProfileSetupNewType) {
    const accountContext = useAccountContext();

    const [status, setStatus] = useState<"initial" | "linked">("initial");
    const [jurisdictionUIAdditionalTaxProfile, setJurisdictionUIAdditionalTaxProfile] = useState<
        IJurisdictionUIAdditionalTaxProfile | undefined
    >(undefined);
    const [parentTaxProfileId, setParentTaxProfileId] = useState<string | undefined>(undefined);

    const onSubmitInitial = async (
        data: ITaxProfileFormData,
        newTaxProfileData: Omit<TaxProfileWithCredentials, "id" | "createdBy" | "updatedBy">
    ) => {
        if (!accountContext.account || isNil(accountContext.taxProfiles)) {
            return;
        }
        const newTaxProfile = await addTaxProfile(accountContext.account.id, newTaxProfileData);
        const associatedTaxProfileIds: string[] = accountContext.taxProfiles?.map((tp) => tp.id);
        await setLocationTaxProfiles(accountContext.account.id, locationId, [
            ...associatedTaxProfileIds,
            newTaxProfile.id,
        ]);
        await accountContext.refresh();

        const jurisdiction = await getJurisdictionByStateCode(newTaxProfileData.state);
        const linked = await getJurisdictionAdditionalTaxProfiles(jurisdiction.id);

        if (newTaxProfileData.state && !isEmpty(linked)) {
            setParentTaxProfileId(newTaxProfile.id);
            setStatus("linked");
            // Right now we only process the first one we get back
            setJurisdictionUIAdditionalTaxProfile(linked[0]);
        } else {
            onCreate();
        }
    };

    const onSubmitLinked = async (data: ITaxProfileFormData) => {
        if (!accountContext.account) {
            return;
        }
        const {
            name,
            legalName,
            state,
            stateTaxId,
            federalTaxId,
            frequency,
            credentials,
            jurisdictionFilingTypeId,
            jurisdictionUIFlavorGroupId,
            additionalStateMetadata,
            additionalStateMetadata2,
            additionalStateMetadata3,
        } = data;
        assert(state, "state is required");
        assert(parentTaxProfileId, "parentTaxProfileId is required to add a linked tax profile");
        // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
        const ff = frequency as FilingFrequency;
        const newTaxProfile = await addTaxProfile(accountContext.account.id, {
            name,
            legalName,
            state: state.abbreviatedName,
            stateTaxId,
            federalTaxId,
            frequency: ff,
            credentialsFailing: "untested",
            credentials,
            hidden: false,
            manualFiling: false,
            requireAdditionalSalesData: false,
            requireUseData: false,
            ratesVerified: undefined,
            ratesVerifiedBy: undefined,
            earlyFilingRequired: false,
            manualReconcileRequired: false,
            taxRateType: "overlapping",
            credentialsType: "SIMPLE",
            jurisdictionFilingTypeId,
            jurisdictionUIFlavorGroupId,
            showExpandedFields: false,
            additionalStateMetadata,
            additionalStateMetadata2,
            additionalStateMetadata3,
        });

        const associatedTaxProfileIds: string[] = (accountContext.taxProfiles ?? []).map((tp) => tp.id);
        await setLocationTaxProfiles(accountContext.account.id, locationId, [
            ...associatedTaxProfileIds,
            newTaxProfile.id,
        ]);
        await accountContext.refresh();

        onCreate();
    };

    if (!accountContext.account) {
        return null;
    }

    const headerContent = <Header>Connect Your State Tax Account</Header>;

    return (
        <>
            {status === "initial" && (
                <TaxProfileForm
                    accountId={accountContext.account.id}
                    saveLabel={"Save and Continue"}
                    onSubmit={onSubmitInitial}
                    // as we loop and add this list grows
                    showCancel={accountContext.taxProfiles && accountContext.taxProfiles.length >= 1}
                    onCancel={() => {
                        setStatus("initial");
                        onCreate();
                    }}
                    headerContent={headerContent}
                />
            )}
            {status === "linked" && jurisdictionUIAdditionalTaxProfile && parentTaxProfileId && (
                <TaxProfileForm
                    accountId={accountContext.account.id}
                    showCancel={true}
                    onCancel={() => {
                        setStatus("initial");
                        onCancel();
                    }}
                    saveLabel={"Save and Continue"}
                    onSubmit={onSubmitLinked}
                    linked={{
                        parentTaxProfileId: parentTaxProfileId,
                        jurisdictionUIAdditionalTaxProfile: jurisdictionUIAdditionalTaxProfile,
                    }}
                />
            )}
        </>
    );
}
