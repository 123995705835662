import { ICommonConfigType } from "@davo/types";
import React from "react";

export interface IConfigCommonContext {
    commonConfigInfo: ICommonConfigType;
}

export const ConfigCommonContextDefaultValue = {} as IConfigCommonContext;
export const ConfigCommonContext = React.createContext(ConfigCommonContextDefaultValue);
export const useConfigCommonContext = () => React.useContext(ConfigCommonContext);
