import { AccountRecord } from "@davo/types";
import React from "react";

export interface IAccountsContext {
    accounts: AccountRecord[] | undefined;

    refresh: () => Promise<void>;
}

export const AccountsContextDefaultValue = {} as IAccountsContext;
export const AccountsContext = React.createContext(AccountsContextDefaultValue);
export const useAccountsContext = () => React.useContext(AccountsContext);
