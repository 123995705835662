import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./LinkButton.css";

/**
 * This class creates a react router <Link> that is styled like a material UI <Button>
 */
export function LinkButton({
    url,
    label,
    labelMaxChars,
    target,
    align,
    style,
    rel,
}: {
    url: string;
    label: string;
    labelMaxChars?: number;
    target?: string;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    style?: any;
    rel?: string;
}) {
    return (
        <Typography color={"primary"} align={align ? align : "center"}>
            <Link className="linkbutton" to={url} target={target} style={style} rel={rel}>
                {labelMaxChars && label.length > labelMaxChars ? `${label.substring(0, labelMaxChars)}...` : label}
            </Link>
        </Typography>
    );
}
