import React, { useEffect, useState } from "react";

export const ValidateCredentialsProgress: React.FunctionComponent = () => {
    const [value, setValue] = useState<number>(60);

    useEffect(() => {
        const ticker = setInterval(() => {
            setValue((prevValue) => {
                return prevValue <= 0 ? 0 : prevValue - 1;
            });
        }, 1000);

        return () => {
            clearInterval(ticker);
        };
    }, []);

    return <span data-testid={"validateCredentialsProgress"}>{`${value}s`}</span>;
};
