import { DateTime } from "luxon";

export interface IGroupLoginUnencrypted {
    id: string;
    originCode: string;
    created: DateTime;
    updated: DateTime;
    capturedAsOf?: null | DateTime;
    partnerId?: null | string;
}

export interface IGroupLoginEncrypted {
    password?: string;
    pin?: string;
    username?: string;
}

export interface IGroupLogin extends IGroupLoginUnencrypted, IGroupLoginEncrypted {}

export enum GroupLoginEncrypted {
    password = "password",
    pin = "pin",
    username = "username",
}
