import { AccountRecord, BankAccount, LocationRecord, Partner, TaxProfile } from "@davo/types";
import React from "react";

export interface IAccountContext {
    account: AccountRecord | undefined;
    partner: Partner | undefined;
    locations: LocationRecord[] | undefined;
    locationsNotFullyConnected?: Set<string>;
    bankAccounts: BankAccount[] | undefined;
    taxProfiles: TaxProfile[] | undefined;

    switch: (accountId: string | undefined) => Promise<void>;
    refresh: () => Promise<void>;
}

export const AccountContextDefaultValue = {} as IAccountContext;
export const AccountContext = React.createContext(AccountContextDefaultValue);
export const useAccountContext = () => React.useContext(AccountContext);
