import {
    BusinessDay,
    Filing,
    FilingHistoricalWithAttachments,
    FilingWithAttachmentHistoryAndLocations,
    IFilingOptionsFilters,
    moneyFromCents,
    TaxProfile,
    toDateTime,
    toDisplayDateString,
} from "@davo/types";
import { Button, FormControlLabel, Paper, Tooltip, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { CellProps } from "react-table";

import {
    getTaxProfilesIdsWithFiledFilingsForAccount,
    postGetFiledFilingsForAccount,
    postGetFilingFilterOptions,
    ReactTable,
    Select,
} from "@davo/portal-common";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import { CSVLink } from "react-csv";
import useAsyncEffect from "use-async-effect";
import { FilingAttachmentsReadonly, FilingHistoricalAttachments } from "./components";
import { useAccountContext } from "./context";
import { LimitedTimeMerchantBanner } from "./LimitedTimeMerchantBanner";
import { MerchantBanner } from "./MerchantBanner";
import workingImage from "./resources/davo_illustrations_working.png";

export function Filings() {
    const accountContext = useAccountContext();
    const [taxProfilesWithFilings, setTaxProfilesWithFilings] = useState<TaxProfile[] | undefined>();
    const [tableData, setTableData] = useState<FilingWithAttachmentHistoryAndLocations[] | undefined>();
    const [stateOptions, setStateOptions] = useState<string[]>();
    const [periodEndOptions, setPeriodEndOptions] = useState<string[]>();
    const [filters, setFilters] = useState<IFilingOptionsFilters>();
    const abortControllerRef = useRef<AbortController>();
    const dateFormat = (data: any) => (data.value ? toDisplayDateString(data.value) : "");
    const periodEndFormat = (data: any) => (data.value ? toDisplayDateString(data.value) : "");
    const nameFormat = (taxProfileId: string) => {
        const tp = find(taxProfilesWithFilings, { id: taxProfileId });
        if (!tp) {
            return "";
        }

        let label = `${tp.legalName}-${tp.name}`;
        label = label.length > 40 ? `${label.substring(0, 40)}...` : label;
        return label;
    };

    const legalNameFormat = (taxProfileId: string) => {
        const tp = find(taxProfilesWithFilings, { id: taxProfileId });
        if (!tp) {
            return "";
        }

        return `${tp.legalName}`;
    };

    const taxProfileNameFormat = (taxProfileId: string) => {
        const tp = find(taxProfilesWithFilings, { id: taxProfileId });
        if (!tp) {
            return "";
        }

        return tp.name;
    };

    const taxDueFormat = (data: any) => <div style={{ textAlign: "right" }}>${moneyFromCents(data.value ?? 0)}</div>;

    const remittedFormat = (data: any) => {
        type RowDataType = CellProps<Filing>;
        const typedRowData: RowDataType = data;

        const davoRemitted = +(typedRowData.value ?? 0);
        const remitted2 = +(typedRowData.row.original?.remittedPart2 ?? 0);
        const remitted3 = +(typedRowData.row.original?.remittedPart3 ?? 0);
        const remitted4 = +(typedRowData.row.original?.remittedPart4 ?? 0);
        const remitted5 = +(typedRowData.row.original?.remittedPart5 ?? 0);
        const remittedDirect = +(typedRowData.row.original?.remittedDirect ?? 0);

        const totalRemittedValue = davoRemitted + remitted2 + remitted3 + remitted4 + remitted5 + remittedDirect;

        return <div style={{ textAlign: "right" }}>${moneyFromCents(totalRemittedValue)}</div>;
    };

    const discountFormat = (data: any) => <div style={{ textAlign: "right" }}>${moneyFromCents(data.value ?? 0)}</div>;
    const attachmentFormat = (data: any) => {
        if (!data || !accountContext.account) {
            return <div></div>;
        }

        if (!data.cell.row.original.historical) {
            return <FilingAttachmentsReadonly accountId={accountContext.account.id} filing={data.cell.row.original} />;
        } else {
            const hist = [];
            for (const a of data.cell.row.original.attachments) {
                hist.push({ ...a, filingHistoricalId: a.filingId });
            }
            const historic: FilingHistoricalWithAttachments = { ...data.cell.row.original, attachments: hist };
            return <FilingHistoricalAttachments accountId={accountContext.account.id} filing={historic} />;
        }
    };

    function getExportData(data: FilingWithAttachmentHistoryAndLocations[]) {
        const results: any[] = [];

        for (const d of data) {
            for (const location of orderBy(d.locations, "name")) {
                const remitted =
                    +(d.remitted ?? 0) +
                    +(d.remittedPart2 ?? 0) +
                    +(d.remittedPart3 ?? 0) +
                    +(d.remittedPart4 ?? 0) +
                    +(d.remittedPart5 ?? 0);

                results.push({
                    "Legal Name": legalNameFormat(d.taxProfileId),
                    Jurisdiction: d.jurisdiction,
                    "Tax Profile Name": taxProfileNameFormat(d.taxProfileId),
                    "Tax Due": moneyFromCents(d.taxDue ?? 0),
                    Remitted: moneyFromCents(remitted),
                    "Merchant Direct Remit": moneyFromCents(d.remittedDirect ?? 0),
                    Discount: moneyFromCents(d.discount ?? 0),
                    "Period Start": toDateTime(d.periodStart).toSQLDate(),
                    "Period End": toDateTime(d.periodEnd).toSQLDate(),
                    "Filed Date": d.filedDate ? toDateTime(d.filedDate).toSQLDate() : "-",
                    "Merchant Note": d.merchantNote,
                    "Location Name": location.name,
                    "Tax Due Location": moneyFromCents(location.taxDue ?? 0),
                    "Remitted Location": moneyFromCents(location.remitted ?? 0),
                    "Merchant Direct Remitted Location": moneyFromCents(location.directRemittance ?? 0),
                    "Discount Location": moneyFromCents(location.discount ?? 0),
                });
            }
        }
        return results;
    }

    function getExportName(accountName: string) {
        const name = accountName.replace(/\s/g, "-");
        return `${name}-filings.csv`;
    }

    useAsyncEffect(async () => {
        if (!accountContext.account || !accountContext.taxProfiles) {
            return;
        }
        const tpsWithFiledFilings = await getTaxProfilesIdsWithFiledFilingsForAccount(accountContext.account).then(
            (tpIds) =>
                accountContext.taxProfiles
                    ?.filter((tp) => tpIds.indexOf(tp.id) !== -1)
                    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)) ?? []
        );
        setTaxProfilesWithFilings(tpsWithFiledFilings);

        if (!isEmpty(tpsWithFiledFilings)) {
            const filterOptions = await postGetFilingFilterOptions(
                accountContext.account,
                tpsWithFiledFilings.map((tp) => tp.id)
            );

            const sortedOptions = (options: BusinessDay[]) => {
                return options.sort((x: BusinessDay, y: BusinessDay) => {
                    if (x > y) {
                        return -1;
                    } else if (x < y) {
                        return 1;
                    }
                    return 0;
                });
            };
            const sortedPeriodEndOptions = [
                ...new Set(sortedOptions(filterOptions.periodEnd).map((pe) => pe.toString())),
            ];
            setPeriodEndOptions(sortedPeriodEndOptions);
            setStateOptions(filterOptions.state);
            setFilters({
                state: undefined,
                taxProfile: undefined,
                periodEnd: sortedPeriodEndOptions[0],
            });
        }
    }, [accountContext.account, accountContext.taxProfiles]);

    useAsyncEffect(async () => {
        if (!filters || !accountContext.account) {
            return;
        }
        abortControllerRef.current?.abort(`Abort request in favor of new search filter`);
        abortControllerRef.current = new AbortController();
        const filteredFilings = await postGetFiledFilingsForAccount(
            accountContext.account,
            {
                state: filters.state,
                periodEnd: filters.periodEnd ? filters.periodEnd : undefined,
                taxProfile: filters.taxProfile,
            },
            abortControllerRef.current
        ).catch((e) => {
            if (e.name === "AbortError") {
                console.debug(e.message); // eslint-disable-line no-console
                return [];
            }
        });
        setTableData(filteredFilings);
    }, [filters]);

    if (!accountContext.account || !accountContext.taxProfiles) {
        return null;
    }

    return (
        <div>
            <MerchantBanner />
            <LimitedTimeMerchantBanner />
            <div style={{ marginBottom: "20px" }}>
                {filters && periodEndOptions && stateOptions && (
                    <div data-testid={"filterSelectors"} style={{ alignItems: "center", display: "inline-flex" }}>
                        <Typography style={{ marginRight: "20px" }} variant="body1">
                            Filters:
                        </Typography>
                        <FormControlLabel
                            control={
                                <Select<string>
                                    label={(pe) => pe.substring(0, 7)}
                                    data-testid={"periodEndSelector"}
                                    title="Period"
                                    style={{}}
                                    noneLabel={"None"}
                                    options={periodEndOptions}
                                    value={filters.periodEnd}
                                    onChange={(value) => {
                                        setFilters({ ...filters, periodEnd: value ?? undefined });
                                    }}
                                />
                            }
                            label={""}
                        />
                        <FormControlLabel
                            control={
                                <Select<string>
                                    data-testid={"stateSelector"}
                                    title="State"
                                    style={{}}
                                    noneLabel={"None"}
                                    options={stateOptions}
                                    value={filters.state}
                                    onChange={(value) => {
                                        setFilters({ ...filters, state: value ?? undefined });
                                    }}
                                />
                            }
                            label={""}
                        />
                        <FormControlLabel
                            control={
                                <Select<string>
                                    data-testid={"taxProfileSelector"}
                                    title="Tax Profile"
                                    style={{}}
                                    noneLabel={"None"}
                                    label={(value) => {
                                        return taxProfileNameFormat(value);
                                    }}
                                    options={taxProfilesWithFilings ? taxProfilesWithFilings.map((tp) => tp.id) : []}
                                    value={filters.taxProfile}
                                    onChange={(value) => {
                                        setFilters({ ...filters, taxProfile: value ?? undefined });
                                    }}
                                />
                            }
                            label={""}
                        />
                    </div>
                )}
            </div>
            {filters && isEmpty(tableData) && (
                <div>
                    <Typography style={{ marginBottom: "20px" }} variant="body1" data-testid={"no-results"}>
                        No Filings Matching Search Criteria
                    </Typography>
                </div>
            )}
            <Paper style={{ padding: "24px", paddingBottom: "0px", boxShadow: "none" }}>
                {(!tableData || isEmpty(tableData)) && (
                    <div style={{ textAlign: "center" }}>
                        <img src={workingImage} style={{ maxWidth: "100%" }} alt={"Thank you for your service"} />
                        <Typography
                            style={{ fontSize: "20px", margin: "10px", maxWidth: "100%" }}
                            data-testid={"thanks"}>
                            Thank you for using our service.
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "14px",
                                maxWidth: "100%",
                                color: "#252631",
                                margin: "auto",
                                marginBottom: "30px",
                            }}>
                            We are excited to have you using our updated portal. This is where you&apos;ll find the tax
                            filings we perform on your behalf.
                        </Typography>
                    </div>
                )}

                {tableData && !isEmpty(tableData) && (
                    <div style={{ maxWidth: "100%" }}>
                        <div style={{ maxWidth: "100%" }} id="davoTable">
                            <ReactTable
                                columns={[
                                    {
                                        Header: "Filed Date",
                                        accessor: "filedDate" as const,
                                        Cell: (data: any) => (
                                            <span style={{ minWidth: "80px" }}>{dateFormat(data)}</span>
                                        ),
                                    },
                                    {
                                        Header: "For Period End",
                                        accessor: "periodEnd" as const,
                                        Cell: (data: any) => (
                                            <span style={{ minWidth: "132px" }}>{periodEndFormat(data)}</span>
                                        ),
                                    },
                                    { Header: "Jurisdiction", accessor: "jurisdiction" as const },
                                    {
                                        Header: "Tax Profile Name",
                                        accessor: "id" as const,
                                        Cell: (data: any) => nameFormat(data.cell.row.original.taxProfileId),
                                    },
                                    { Header: "Tax Due", accessor: "taxDue" as const, Cell: taxDueFormat },
                                    {
                                        Header: () => (
                                            <Tooltip
                                                placement={"top"}
                                                title={
                                                    "This includes all scheduled payments, use the export button to see a detailed breakdown."
                                                }>
                                                <div>Amount Remitted</div>
                                            </Tooltip>
                                        ),
                                        accessor: "remitted" as const,
                                        Cell: (data: any) => (
                                            <span style={{ minWidth: "155px" }}>{remittedFormat(data)}</span>
                                        ),
                                    },
                                    {
                                        Header: () => (
                                            <Tooltip
                                                placement={"top"}
                                                title={"In jurisdictions where timely filing discounts are available."}>
                                                <div>Timely File Discount</div>
                                            </Tooltip>
                                        ),
                                        accessor: "discount" as const,
                                        Cell: (data: any) => (
                                            <span style={{ minWidth: "165px" }}>{discountFormat(data)}</span>
                                        ),
                                    },
                                    { Header: "Attachments", accessor: "attachments" as const, Cell: attachmentFormat },
                                ]}
                                data={tableData}
                                options={{
                                    pageSize: 10,
                                    hideToolbar: true,
                                }}
                            />
                        </div>
                        <Button
                            data-testid={"export-to-csv-table-data"}
                            variant="outlined"
                            color="primary"
                            size={"small"}
                            style={{ margin: "10px" }}>
                            <CSVLink
                                filename={getExportName(accountContext.account.name)}
                                data={getExportData(tableData)}
                                style={{ textDecoration: "unset", color: "unset" }}
                                className="btn btn-primary">
                                Export Filings
                            </CSVLink>
                        </Button>
                    </div>
                )}
            </Paper>
        </div>
    );
}
