import { d30Toast, d30ToastError, TextField, updateBankAccountNickname, useModalEditor } from "@davo/portal-common";
import EditIcon from "@mui/icons-material/Edit";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useState } from "react";

export function EditBankNicknameModalButton({
    accountId,
    bankAccountId,
    oldNickname,
    onCloseSuccess,
}: {
    accountId: string;
    bankAccountId: string;
    oldNickname: string | undefined;
    onCloseSuccess: () => void;
}) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showDialog, { isDialogOpen, closeDialog }] = useModalEditor();
    const [nickname, setNickname] = useState<string | undefined>(oldNickname);

    const doSave = async () => {
        try {
            await updateBankAccountNickname(accountId, bankAccountId, nickname ? nickname.trim() : "");
            d30Toast("Nickname saved");
            closeDialog();
            onCloseSuccess();
        } catch (e: any) {
            d30ToastError(e.message);
            closeDialog();
        }
    };

    return (
        <>
            <IconButton aria-label={`Edit ${oldNickname ?? "bank"}`} onClick={showDialog}>
                <EditIcon color="primary" />
            </IconButton>
            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    style={{ boxShadow: "none", padding: "10px" }}
                    onClose={() => closeDialog()}>
                    <DialogTitle id="responsive-dialog-title">Edit Your Bank Nickname</DialogTitle>
                    <DialogContent>
                        <TextField
                            label={"Bank Nickname"}
                            value={nickname}
                            onChange={setNickname}
                            onEnterPress={doSave}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="secondary" onClick={() => closeDialog()}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={doSave}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
