import { LoginCredentialValidationStatus } from "@davo/types";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import { Alert, AlertProps, AlertTitle, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ValidateCredentialsProgress } from "./ValidateCredentialsProgress";

export interface IValidateCredentialsStatus {
    credsValidationStatus: LoginCredentialValidationStatus;
    abortCredsCheck: () => void;
}

export const ValidateCredentialsStatus: React.FunctionComponent<IValidateCredentialsStatus> = ({
    credsValidationStatus,
    abortCredsCheck,
}) => {
    const [alertProps, setAlertProps] = useState<AlertProps>();

    useEffect(() => {
        if (credsValidationStatus === "validating") {
            setAlertProps({
                icon: <ValidateCredentialsProgress />,
                action: (
                    <Tooltip title={"Cancel validation"} placement={"top"}>
                        <IconButton aria-label="Cancel validation" onClick={abortCredsCheck} size="small">
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                ),
                variant: "outlined",
                severity: "warning",
                children: (
                    <>
                        <AlertTitle>We&apos;re testing your state login</AlertTitle>
                        <span>You can continue filling out the rest of your information.</span>
                    </>
                ),
            });
        } else if (credsValidationStatus === "timeout") {
            setAlertProps({
                icon: <SyncDisabledIcon />,
                variant: "outlined",
                severity: "info",
                children: (
                    <>
                        <AlertTitle>We weren&apos;t able to connect to the state</AlertTitle>
                        <span>
                            Feel free to complete and save the rest of your information, and try testing your login
                            later.
                        </span>
                    </>
                ),
            });
        } else if (credsValidationStatus === "valid") {
            setAlertProps({
                icon: <CheckCircleIcon />,
                variant: "outlined",
                severity: "success",
                children: <AlertTitle>Your state login has been validated</AlertTitle>,
            });
        } else if (credsValidationStatus === "invalid") {
            setAlertProps({
                icon: <ErrorIcon />,
                variant: "outlined",
                severity: "error",
                children: (
                    <>
                        <AlertTitle>The login details you provided didn&apos;t work</AlertTitle>
                        <span>Update your details and try testing again.</span>
                    </>
                ),
            });
        } else if (credsValidationStatus === "isPasswordChangeRequired") {
            setAlertProps({
                icon: <ErrorIcon />,
                variant: "outlined",
                severity: "error",
                children: (
                    <>
                        <AlertTitle>The state is requiring you change your password</AlertTitle>
                        <span>Please take that action before continuing</span>
                    </>
                ),
            });
        } else if (credsValidationStatus === "error") {
            setAlertProps({
                icon: <SyncDisabledIcon />,
                variant: "outlined",
                severity: "info",
                children: (
                    <>
                        <AlertTitle>Call to validation service failed</AlertTitle>
                        <span>You can proceed submitting the form and try validating credentials again later.</span>
                    </>
                ),
            });
        }
    }, [abortCredsCheck, credsValidationStatus]);

    return credsValidationStatus && <Alert data-testid={"validateCredentialsStatus"} {...alertProps} />;
};
