import { DateTime } from "luxon";

export interface IEncryptedStoreHistoryEntry {
    tag: string;
    id: string;
    encrypted: string;
    updated?: DateTime | null;
    updatedBy: string;
    historyId: string;
    historyTimestamp: DateTime;
}
