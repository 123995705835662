import { FormError, TextField } from "@davo/portal-common";
import { isEmailValid, IStorageMarketingInfo, validateEmail, validateNotNull } from "@davo/types";
import { Button, Theme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useConfigMerchantContext } from "../context";
import { testHeartlandRestaurantCredentials } from "../services/boarding";
import { BoardingContainer, sharedButtonContainer } from "./BoardingContainer";
import { HeartlandRestaurantPosInfo } from "./types/PosInfoTypes";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
    ...sharedButtonContainer,
}));

export function HeartlandRestaurantBoarding() {
    const classes = useStyles();
    const [key, setKey] = useState<string>("");
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const { merchantConfigInfo: configInfo } = useConfigMerchantContext();
    const navigate = useNavigate();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    useEffect(() => {
        const storageMarketingInfo: IStorageMarketingInfo = JSON.parse(localStorage.getItem("marketingInfo") ?? "{}");
        setEmail(storageMarketingInfo.email);
    }, []);

    const doCall = async () => {
        if (isBusy) {
            return;
        }

        if (key && email && isEmailValid(email)) {
            setMessage(undefined);
            setIsBusy(true);
            const result = await testHeartlandRestaurantCredentials(key);
            setIsBusy(false);
            if (result.success.valid === true) {
                const posData: HeartlandRestaurantPosInfo = {
                    key,
                    email,
                };
                navigate(configInfo.heartlandRestaurantURL, {
                    state: posData,
                });
            } else {
                setMessage("Invalid Heartland Restaurant API key.");
            }
        } else {
            setMessage("Please fill out all fields.");
        }
    };

    return (
        <BoardingContainer posType={"heartland-restaurant"}>
            <TextField
                data-testid={"apiKeyContainer"}
                inputProps={{
                    [`data-testid`]: "apiKeyInput",
                }}
                label="Enter your Heartland API Key"
                value={key}
                onChange={setKey}
                onEnterPress={doCall}
                isDisabled={isBusy}
                isRequired
                validate={validateNotNull}
                margin={"dense"}
            />
            <>
                Need a free Heartland API Key? Reach out to your Heartland Authorized Dealer, call 833-844-HPOS (4767)
                or email hrpossupport@heartland.us
            </>
            <TextField
                data-testid={"emailContainer"}
                inputProps={{
                    [`data-testid`]: "emailInput",
                }}
                label="Email"
                validate={(v) => (v ? validateEmail(v) : validateNotNull(null))}
                value={email ?? ""}
                onChange={setEmail}
                onEnterPress={doCall}
                isDisabled={isBusy}
                isRequired
                margin={"dense"}
            />
            {message && <FormError message={message} />}
            <div className={classes.submitContainer}>
                <Button
                    data-testid={"submitBtn"}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={doCall}
                    disabled={!email || !key.trim() || isBusy}
                    startIcon={isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />}>
                    Connect with Heartland
                </Button>
            </div>
        </BoardingContainer>
    );
}
