import { IJurisdiction, LocationRecord, normalizeAddressWords, validateZip } from "@davo/types";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Select } from "./Select";
import { getJurisdictions } from "./services";
import { TextField } from "./TextField";
import { d30ToastError } from "./Toast";

const useStyles = makeStyles(() => ({
    outerContainer: {
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "space-around",
        alignItems: "flex-start",
    },
    editFormFields: {
        height: "100%",
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "space-around",
        alignItems: "flex-start",
    },
    selectField: {
        width: "100%",
        margin: "-5px 0px -5px 0px",
    },
    inputField: {
        width: "50vw",
        maxWidth: "400px",
        margin: "10px 0px 0px 0px",
    },
}));

export interface IAddressEditProps {
    location: LocationRecord;
    showCounty: boolean;
    showCancel: boolean;
    onSubmit: (data: LocationRecord) => Promise<void>;
    onCancel?: () => void;
}

export function AddressEditForm({ location, showCounty, showCancel, onSubmit, onCancel }: IAddressEditProps) {
    const classes = useStyles();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [states, setStates] = useState<IJurisdiction[]>([]);
    const [addressName, setAddressName] = useState<string>("");
    const [addressLineOne, setAddressLineOne] = useState<string>("");
    const [addressLineTwo, setAddressLineTwo] = useState<string>("");
    const [addressCity, setAddressCity] = useState<string>("");
    const [addressCounty, setAddressCounty] = useState<string>("");
    const [addressState, setAddressState] = useState<string>("");
    const [addressZip, setAddressZip] = useState<string>("");

    useEffect(() => {
        getJurisdictions()
            .then((jurisdictions) => setStates(jurisdictions))
            .catch((e) => d30ToastError("Problem getting jurisdictions.", e));
    }, []);

    useEffect(() => {
        if (location) {
            setAddressName(location.name ?? "");
            setAddressLineOne(location.address1 ?? "");
            setAddressLineTwo(location.address2 ?? "");
            setAddressCity(location.city ?? "");
            setAddressState(location.state ?? "");
            setAddressCounty(location.county ?? "");
            setAddressZip(location.zip ?? "");
        }
    }, [location]);

    if (!location) {
        return;
    }

    const _doSubmit = () => {
        setIsBusy(true);
        const editedAddressData: LocationRecord = {
            ...location,
            name: addressName.trim(),
            address1: addressLineOne.trim(),
            address2: addressLineTwo.trim(),
            city: addressCity.trim(),
            county: addressCounty,
            state: normalizeAddressWords(addressState),
            zip: addressZip,
        };

        onSubmit(editedAddressData).catch((e) => {
            d30ToastError("There was a problem updating the address.", e);
            setIsBusy(false);
        });
    };

    const _doCancel = () => {
        setIsBusy(false);
        setAddressName("");
        setAddressLineOne("");
        setAddressLineTwo("");
        setAddressCity("");
        setAddressState("");
        setAddressCounty("");
        setAddressZip("");
        if (onCancel) {
            onCancel();
        }
    };

    return (
        <div data-testid={"addressEditForm"}>
            <div className={classes.outerContainer}>
                <div className={classes.editFormFields}>
                    <div className={classes.inputField}>
                        <TextField
                            className="fs-exclude"
                            data-testid={"addressName"}
                            label="Name"
                            value={addressName}
                            onChange={setAddressName}
                            margin="dense"
                        />
                    </div>
                    <div className={classes.inputField}>
                        <TextField
                            className="fs-exclude"
                            data-testid={"addressLineOne"}
                            label="Address Line 1"
                            value={addressLineOne}
                            onChange={setAddressLineOne}
                            margin="dense"
                        />
                    </div>
                    <div className={classes.inputField}>
                        <TextField
                            className="fs-exclude"
                            data-testid={"addressLineTwo"}
                            label="Address Line 2"
                            value={addressLineTwo}
                            onChange={setAddressLineTwo}
                            margin="dense"
                        />
                    </div>
                    <div className={classes.inputField}>
                        <TextField
                            data-testid={"addressCity"}
                            label="City"
                            value={addressCity}
                            onChange={setAddressCity}
                            margin="dense"
                        />
                    </div>
                    {showCounty && (
                        <div className={classes.inputField}>
                            <TextField
                                data-testid={"addressCounty"}
                                label="County"
                                value={addressCounty}
                                onChange={setAddressCounty}
                                margin="dense"
                            />
                        </div>
                    )}
                    <div className={classes.selectField}>
                        <Select<IJurisdiction>
                            data-testid={"addressState"}
                            title="State"
                            options={states}
                            value={states.find(
                                (ba: IJurisdiction) => ba.abbreviatedName === normalizeAddressWords(addressState)
                            )}
                            label={(jurisdiction) => jurisdiction.fullName}
                            onChange={(val) => {
                                if (!val) {
                                    return;
                                }
                                setAddressState(val.abbreviatedName);
                            }}
                        />
                    </div>
                    <div className={classes.inputField}>
                        <TextField
                            className="fs-exclude"
                            data-testid={"addressZip"}
                            label="ZIP"
                            validate={validateZip}
                            value={addressZip}
                            onChange={setAddressZip}
                            margin="dense"
                        />
                    </div>
                </div>
            </div>

            <div style={{ marginTop: "20px", float: "right" }}>
                {showCancel && (
                    <Button
                        disabled={isBusy}
                        data-testid={"editAddressCancelButton"}
                        onClick={_doCancel}
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: "5px" }}>
                        {"Cancel"}
                    </Button>
                )}

                <Button
                    data-testid={"editAddressUpdateButton"}
                    startIcon={isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />}
                    disabled={
                        isBusy ||
                        !addressName ||
                        !addressLineOne ||
                        !addressCity ||
                        !addressState ||
                        !addressZip ||
                        addressName.trim() === "" ||
                        addressLineOne.trim() === "" ||
                        addressCity.trim() === "" ||
                        addressZip.trim() === "" ||
                        validateZip(addressZip) === "Invalid zip code."
                    }
                    onClick={_doSubmit}
                    variant="contained"
                    color="primary">
                    Update Address
                </Button>
            </div>
        </div>
    );
}
