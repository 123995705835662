import { IUseTax, LocationRecord, moneyFromCents } from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { ReactTable } from "./ReactTable";
import { calculateAmountTotal } from "./util";

export function UseTaxTable({ useTax, filterLocations }: { useTax?: IUseTax[]; filterLocations?: LocationRecord[] }) {
    const [canExpandUse, setCanExpandUse] = useState<boolean>(true);
    const [useRemapped, setUseRemapped] = useState<any[] | undefined>(undefined);

    useEffect(() => {
        if (useTax && useTax.length > 0 && filterLocations) {
            const mapLocationName = (val: any[]) => {
                return val.map((tax: any) => {
                    const location = filterLocations.find((l) => l.id === tax.locationId);
                    return {
                        ...tax,
                        locationName: location?.name ?? "",
                        city: location?.city,
                        createdBy: `${tax.user.firstName} ${tax.user.lastName}`,
                        date: DateTime.fromISO(tax.bizday).toFormat("MMMM"),
                    };
                });
            };

            setUseRemapped(mapLocationName(useTax));
        }
    }, [useTax, filterLocations]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Location",
                accessor: "name",
                disableSortBy: true,
                Cell: (data: any) => (
                    <div style={{ textAlign: "left" }}>
                        {data.cell.row.original.locationName} - {data.cell.row.original.city}
                    </div>
                ),
            },
            {
                Header: "Updated By",
                accessor: "createdBy",
                disableSortBy: true,
                Cell: (data: any) => <div style={{ textAlign: "left" }}>{data.cell.row.original.createdBy}</div>,
            },
            {
                Header: "Period",
                accessor: "bizday",
                disableSortBy: true,
                Cell: (data: any) => <div style={{ textAlign: "left" }}>{data.cell.row.original.date}</div>,
            },
            {
                Header: "Total",
                accessor: "amount",
                disableSortBy: true,
                Cell: (data: any) => (
                    <div style={{ textAlign: "left" }}>{moneyFromCents(data.cell.row.original.amount)}</div>
                ),
            },
        ];
    }, []);

    if (!useRemapped || useRemapped.length === 0) {
        return null;
    }
    return (
        <Accordion
            slotProps={{ transition: { unmountOnExit: true } }}
            style={{ border: "none", boxShadow: "none" }}
            expanded={canExpandUse}>
            <AccordionSummary
                onClick={() => setCanExpandUse(!canExpandUse)}
                expandIcon={<ExpandMoreIcon />}
                style={{ fontSize: "20px" }}>
                Use Tax Amounts
            </AccordionSummary>

            <AccordionDetails>
                <ReactTable
                    columns={columns}
                    data={useRemapped ?? []}
                    options={{
                        hideToolbar: true,
                    }}
                />
            </AccordionDetails>
            <div>
                <div style={{ textAlign: "right", fontWeight: "bold" }}>
                    <span>Total: ${moneyFromCents(calculateAmountTotal(useRemapped))}</span>
                </div>
            </div>
        </Accordion>
    );
}
