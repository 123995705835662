import { isPasswordValid, validatePassword } from "@davo/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import jwt from "jsonwebtoken";
import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { TextField } from "./TextField";
import { d30Toast, d30ToastError } from "./Toast";
import { useLoginContext } from "./context";
import { auth } from "./services";

export function ResetPassword() {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const navigate = useNavigate();
    const location = useLocation();
    const loginContext = useLoginContext();

    const [password, setPassword] = useState<string | undefined>(undefined);

    const redirect = () => {
        navigate("/");
    };

    useAsyncEffect(async () => {
        if (loginContext.user) {
            // log out existing session
            await auth.logout().finally(() => {
                loginContext.setUser(undefined);
            });
        }
    }, []);

    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (!token) {
        return <Navigate to="/" />;
    }

    const { address } = jwt.decode(token) as { address: string };

    const doSetPassword = () => {
        if (password) {
            auth.setPassword(address, token, password)
                .then(() => {
                    d30Toast("Password successfully reset!");
                })
                .then(() => redirect())
                .catch(() => d30ToastError("Problem resetting password."));
        }
    };

    return (
        <Dialog
            fullScreen={makeFullScreen}
            open={true}
            onClose={redirect}
            style={{ boxShadow: "none", padding: "10px", minWidth: "450px" }}>
            <DialogTitle>Reset Your DAVO Password</DialogTitle>
            <DialogContent>
                <DialogContentText className="fs-exclude">
                    Enter the new password for {address} below.
                </DialogContentText>
                <TextField
                    isPassword
                    label="Password"
                    value={password ?? ""}
                    onChange={setPassword}
                    validate={validatePassword}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={redirect} variant="outlined" color="primary" style={{ width: "150px" }}>
                    Cancel
                </Button>
                <Button
                    disabled={!isPasswordValid(password)}
                    onClick={doSetPassword}
                    variant="contained"
                    color="primary"
                    style={{ width: "150px" }}
                    autoFocus>
                    Reset
                </Button>
            </DialogActions>
        </Dialog>
    );
}
