import { LoadingWithLogo, useLoginContext } from "@davo/portal-common";
import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { useAccountContext, useAccountsContext } from "./context";
import { handleAccountChanges } from "./MerchantDonut";

export function FirstAccountChanger() {
    const navigate = useNavigate();

    const loginContext = useLoginContext();
    const accountsContext = useAccountsContext();
    const accountContext = useAccountContext();

    const urlAccountId = useMatch("/:accountId/*")?.params?.accountId;

    useAsyncEffect(async () => {
        await handleAccountChanges(
            accountsContext.accounts,
            accountContext.account,
            urlAccountId,
            loginContext.user,
            navigate,
            accountContext.switch
        );
    }, [loginContext.user, accountsContext.accounts, accountContext.account, urlAccountId]);

    if (!accountsContext.accounts || !accountContext.account) {
        return <LoadingWithLogo />;
    } else {
        // NOTE: we should never get here
        return null;
    }
}
