// every key needs to have a corresponding automated sms in core/Messages.ts
export enum TaxProfileFailureReasons {
    password = "password",
    twofactor = "twofactor",
    registration = "registration",
    pin = "pin",
    state = "state",
    webfile = "webfile",
    draft = "draft",
}

export type TaxProfileFailureReason = keyof typeof TaxProfileFailureReasons;

export const TaxProfileFailureReasonsDescriptions = {
    password: "Invalid username/password",
    twofactor: "Two factor authentication required",
    registration: "Business not registered",
    pin: "PIN required",
    state: "Invalid state account",
    webfile: "Invalid TX webfile number",
    draft: "Missing required fields",
} as { [key in TaxProfileFailureReason]: string };

export const TaxProfileFailureReasonKeys = Object.keys(TaxProfileFailureReasons) as TaxProfileFailureReason[];
