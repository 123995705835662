import { DateTime } from "luxon";
import { BusinessDay } from "./BusinessDay";

const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|([+-])([\d|:]*))?$/;

export const dsoStartDate = DateTime.fromISO("2015-01-01T12:00");

export type OptionalDateTime = DateTime | null | undefined;

type OptionalDateTimeOrBusinessDay = DateTime | BusinessDay | null | undefined;

export function dateReviver(key: string, value: any) {
    if (typeof value === "string" && reISO.exec(value)) {
        return DateTime.fromISO(value, { zone: "utc" });
    } else {
        return value;
    }
}

export function toSQLDate(date: OptionalDateTimeOrBusinessDay): string {
    if (!date) {
        throw Error("Invalid date");
    }
    const d = date.toSQLDate();
    if (d === null) {
        throw Error("Invalid date");
    }
    return d;
}

export function toSQL(
    date: OptionalDateTime,
    options?: {
        includeOffset: boolean;
        includeZone: boolean;
        includeOffsetSpace: boolean;
    }
): string {
    if (!date) {
        throw Error("Invalid date");
    }
    const d = date.toSQL(options);
    if (d === null) {
        throw Error("Invalid date");
    }
    return d;
}

export function toISO(
    date: OptionalDateTime,
    options?: {
        suppressMilliseconds: boolean;
    }
): string {
    if (!date) {
        throw Error("Invalid date");
    }
    const d = date.toISO(options);
    if (d === null) {
        throw Error("Invalid date");
    }
    return d;
}

export function toUTC(date: OptionalDateTime): DateTime {
    if (!date) {
        throw Error("Invalid date");
    }
    const d = date.toUTC();
    if (d === null) {
        throw Error("Invalid date");
    }
    return d;
}
