import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import { Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAccountContext } from "./context";
import bank from "./resources/davo_illustrations_network.png";

export const SettingsBankingEmptyState: React.FunctionComponent<{
    shouldRestrictMerchantEdit: boolean;
    restrictMerchantEditMessage?: string;
}> = ({ shouldRestrictMerchantEdit, restrictMerchantEditMessage }) => {
    const navigate = useNavigate();
    const accountContext = useAccountContext();

    return (
        <div style={{ textAlign: "center" }}>
            <img src={bank} style={{ maxWidth: "100%" }} alt={"Bank"} />
            <Typography style={{ fontSize: "20px", margin: "10px", maxWidth: "100%" }}>
                Connect your bank account
            </Typography>
            <Tooltip
                title={
                    "Note: Sales tax withholding is run daily as an ACH transaction against this bank account. DAVO does not modify merchant processor batch deposits."
                }>
                <Typography
                    style={{
                        fontSize: "14px",
                        maxWidth: "100%",
                        color: "#252631",
                        margin: "auto",
                        marginBottom: "30px",
                    }}>
                    {
                        "Connect your bank account and we will set aside your sales tax daily and hold it in a secure account for you."
                    }
                </Typography>
            </Tooltip>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                    data-testid={"connect-bank"}
                    disabled={shouldRestrictMerchantEdit}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => navigate(`/${accountContext.account?.id}/settings/company`)}>
                    Connect Bank Account
                </Button>
                {restrictMerchantEditMessage && (
                    <Tooltip title={restrictMerchantEditMessage} placement="top">
                        <HelpTwoToneIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} />
                    </Tooltip>
                )}
            </div>
        </div>
    );
};
