import { Typography } from "@mui/material";
import React from "react";
import { BoardingContainer } from "./BoardingContainer";

export function LegacySubscriptionPage() {
    return (
        <BoardingContainer posType={"clover"} tagLine={""}>
            <div style={{ justifyContent: "stretch" }}>
                <div>
                    <Typography style={{ marginTop: "20px", marginBottom: "20px" }}>
                        You are using a legacy version of our app, requiring that you uninstall and reinstall the app on
                        your Clover account.
                    </Typography>
                </div>

                <div style={{ paddingBottom: "10px", fontWeight: "bold" }}>Uninstall the legacy DAVO app:</div>
                <div style={{ paddingBottom: "5px", paddingLeft: "10px" }}>
                    1. From a web browser, log into your{" "}
                    <a
                        href={"https://www.clover.com/dashboard/login"}
                        target="_blank"
                        style={{ verticalAlign: "middle", textDecoration: "underline" }}
                        rel="noreferrer">
                        Clover Account
                    </a>
                </div>
                <div style={{ paddingBottom: "5px", paddingLeft: "25px" }}>
                    From the Menu, select <b>{`More Tools > My Apps`}</b>
                </div>
                <div style={{ paddingBottom: "5px", paddingLeft: "25px" }}>{`Select the DAVO app`}</div>
                <div
                    style={{
                        paddingBottom: "5px",
                        paddingLeft: "10px",
                    }}>{`2. At the top right, click the three dots`}</div>
                <div style={{ paddingBottom: "5px", paddingLeft: "25px" }}>
                    Choose <b>{`Uninstall App`}</b>
                </div>
                <div
                    style={{
                        paddingBottom: "5px",
                        paddingLeft: "25px",
                    }}>{`The app will uninstall automatically`}</div>
                <div style={{ paddingBottom: "10px", fontWeight: "bold" }}>Install the current DAVO app:</div>
                <div style={{ paddingBottom: "5px", paddingLeft: "10px" }}>
                    1. In your{" "}
                    <a
                        href={"https://www.clover.com/dashboard/login"}
                        target="_blank"
                        style={{ verticalAlign: "middle", textDecoration: "underline" }}
                        rel="noreferrer">
                        Clover Account
                    </a>
                    , from the Menu, select More Tools
                </div>
                <div
                    style={{
                        paddingBottom: "5px",
                        paddingLeft: "25px",
                    }}>{`Search for the DAVO Sales Tax app`}</div>
                <div style={{ paddingBottom: "5px", paddingLeft: "25px" }}>
                    Select <b>{`Install`}</b>
                </div>
                <div style={{ paddingBottom: "5px", paddingLeft: "25px" }}>
                    <b>{`Open`}</b> the DAVO app when install is complete
                </div>
                <div
                    style={{
                        paddingBottom: "5px",
                        paddingLeft: "10px",
                    }}>{`2. Check your email for a new DAVO invitation`}</div>
                <div
                    style={{
                        paddingBottom: "5px",
                        paddingLeft: "25px",
                    }}>{`Click the link in the email and login`}</div>
                <div>
                    <Typography style={{ marginTop: "20px" }}>
                        If you need help during this process please email{" "}
                        <a href={`mailto:support@davosalestax.com`} style={{ color: "inherit" }}>
                            support@davosalestax.com
                        </a>{" "}
                        or call <b>888.659.8432</b>
                    </Typography>
                </div>
            </div>
        </BoardingContainer>
    );
}
