export interface IJurisdiction {
    id: string;
    abbreviatedName: string;
    fullName: string;
    type: JurisdictionType;
}

export enum JurisdictionTypes {
    "STATE" = "STATE",
    "COUNTY" = "COUNTY",
    "CITY" = "CITY",
    "PROVINCE" = "PROVINCE",
    "PARISH" = "PARISH",
}

export type JurisdictionType = keyof typeof JurisdictionTypes;
