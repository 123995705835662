import { FilingAttachment } from "@davo/types";
import { getAttachment, getFiling } from "../services";

export const downloadFilingAttachmentFile = async (
    accountId: string,
    taxProfileId: string,
    filingId: string,
    attachment: FilingAttachment
) => {
    const file = await getAttachment(accountId, taxProfileId, filingId, attachment.id);
    const filing = await getFiling(accountId, taxProfileId, filingId);
    const a = document.createElement("a");
    a.href = URL.createObjectURL(file);
    const dlFilename = `attachment_${filing.filedDate}_${filing.taxProfile.legalName}_${filing.taxProfile.name}_${filing.taxProfile.state}_${filing.periodEnd}`;
    a.download = dlFilename.replace(/\W/g, "");
    a.click();
    URL.revokeObjectURL(a.href);
};
