import { IJurisdictionFilingType, IJurisdictionUIField } from "@davo/types";
import React from "react";
import { Select } from "./Select";

export interface ICredentialFlavorSelect {
    filingType: IJurisdictionFilingType | null | undefined;
    selectedFlavor: IJurisdictionUIField | undefined;
    handleCredentialFlavorChange: (flavor: IJurisdictionUIField) => void;
}

export const CredentialFlavorSelect: React.FunctionComponent<ICredentialFlavorSelect> = ({
    filingType,
    handleCredentialFlavorChange,
    selectedFlavor,
}) => {
    return filingType?.fields && filingType.fields.length > 1 ? (
        <Select<IJurisdictionUIField>
            data-testid={"subTypeCredentialType"}
            title="Credential Type"
            value={selectedFlavor}
            options={filingType.fields}
            onChange={(flavor) => {
                flavor && handleCredentialFlavorChange(flavor);
            }}
            label={(flavor) => flavor.jurisdictionFlavorGroupDescription ?? ""}
        />
    ) : null;
};
