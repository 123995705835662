import { auth, useLoginContext } from "@davo/portal-common";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

export function InvitationRequired() {
    const loginContext = useLoginContext();

    return (
        <Dialog
            open={true}
            onClose={() =>
                auth.logout().finally(() => {
                    loginContext.setUser(undefined);
                })
            }>
            <DialogTitle>Invitation required</DialogTitle>
            <DialogContent>
                Please contact your site administrator to invite you to an existing account. Or, link your Point of Sale
                to get started!
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                        auth.logout().finally(() => {
                            loginContext.setUser(undefined);
                        })
                    }>
                    Logout
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.open("https://www.davosalestax.com/integrations/", "_blank")}>
                    Link me up!
                </Button>
            </DialogActions>
        </Dialog>
    );
}
