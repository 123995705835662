import { getGroupLoginRequired } from "@davo/portal-common";
import { BusinessDay } from "@davo/types";
import { Alert, AlertTitle, Button, Popover } from "@mui/material";
import isNil from "lodash/isNil";
import React from "react";
import useAsyncEffect from "use-async-effect";
import { useAccountContext } from "./context";

export const LimitedTimeMerchantBanner = (): JSX.Element | null => {
    const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const spanRef = React.useRef();

    const accountContext = useAccountContext();
    const [shouldShowNY, setShouldShowNY] = React.useState<boolean>(false);

    useAsyncEffect(async () => {
        if (!accountContext.account) {
            return;
        }
        const isGroupLoginRequired = await getGroupLoginRequired(accountContext.account.id, "NY");

        if (
            !isNil(isGroupLoginRequired) &&
            accountContext.taxProfiles &&
            accountContext.taxProfiles.filter((tp) => tp.credentialsType === "SIMPLE" && tp.state === "NY").length >
                0 &&
            BusinessDay.today() >= BusinessDay.from(2024, 4, 29)
        ) {
            setShouldShowNY(true);
        } else {
            setShouldShowNY(false);
        }
    }, [accountContext.account]);

    if (shouldShowNY) {
        return (
            <div style={{ textAlign: "center" }} data-testid={"limited-time-merchant-banner"}>
                <Alert
                    style={{ marginBottom: "8px" }}
                    severity={"warning"}
                    action={
                        <Button
                            data-testid={"required-actions-btn"}
                            aria-describedby={"id"}
                            variant="text"
                            onClick={() => {
                                setAnchorEl(spanRef.current);
                                setIsPopoverOpen((isOpen) => !isOpen);
                            }}>
                            View steps
                        </Button>
                    }>
                    <AlertTitle>Action Required: Provide Tax Professional Online Service Authorization</AlertTitle>
                </Alert>
                <Popover
                    id={"id"}
                    open={isPopoverOpen}
                    anchorEl={anchorEl}
                    onClose={() => {
                        setAnchorEl(null);
                        setIsPopoverOpen(false);
                    }}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}>
                    <div
                        style={{
                            display: "flex",
                            flexFlow: "column wrap",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "15px 15px",
                        }}>
                        <div
                            style={{
                                fontSize: "20px",
                                lineHeight: "24px",
                                color: "#252631",
                                marginBottom: "10px",
                            }}>
                            Provide Tax Professional Online Service Authorization
                        </div>
                        <div
                            style={{
                                color: "#252631",
                                fontSize: "14px",
                                fontWeight: 300,
                                lineHeight: "21px",
                                marginTop: "10px",
                            }}>
                            <p style={{ padding: 0, margin: 0 }}>
                                DAVO by Avalara has created a <b>Tax Professional Online Service</b> account with the
                                New York State Department of Taxation and Finance to help streamline the returns filing
                                process and enhance your experience.
                            </p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexFlow: "column wrap",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: "10%",
                            }}>
                            <div
                                style={{
                                    color: "#252631",
                                    fontSize: "14px",
                                    fontWeight: 300,
                                    lineHeight: "21px",
                                    marginTop: "10px",
                                    alignSelf: "flex-start",
                                }}>
                                Please complete the following steps by May 31, 2024:
                            </div>
                            <div
                                style={{
                                    color: "#252631",
                                    fontSize: "14px",
                                    fontWeight: 300,
                                    lineHeight: "21px",
                                    marginTop: "10px",
                                    textAlign: "center",
                                }}>
                                <ol
                                    style={{
                                        color: "#252631",
                                        fontSize: "14px",
                                        fontWeight: 300,
                                        lineHeight: "21px",
                                        marginTop: "10px",
                                        textAlign: "left",
                                    }}>
                                    <li>
                                        Log into your <b>NY DOR</b> account at{" "}
                                        <a
                                            href={`https://my.ny.gov/LoginV4/login.xhtml`}
                                            target="_blank"
                                            rel="noreferrer">
                                            {"NY tax portal"}
                                        </a>
                                    </li>
                                    <li>
                                        Select <b>Tax Online Services</b>
                                    </li>
                                    <li>
                                        From the top-left, select <b>Services</b>
                                    </li>
                                    <li>
                                        Under the <b>Business</b> section, select <b>Tax professional summary</b>
                                    </li>
                                    <li>
                                        Select <b>Add Tax Professional</b>
                                    </li>
                                    <li>
                                        To search by a tax professional, select <b>Tax professional name</b> from the
                                        dropdown
                                    </li>
                                    <li>
                                        In the <b>Tax professional name</b> field, type <b>Avalara</b> and select{" "}
                                        <b>Search</b>
                                    </li>
                                    <li>
                                        Select <b>Add</b>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div
                            style={{
                                color: "#252631",
                                fontSize: "14px",
                                fontWeight: 300,
                                lineHeight: "21px",
                                marginTop: "10px",
                            }}>
                            <p style={{ padding: 0, margin: 0 }}>
                                {" "}
                                Please contact customer support when you have completed the above steps so we can update
                                your login information in our system.
                            </p>

                            <p style={{ padding: 0, margin: 0 }}>
                                If you have any questions or need assistance, please contact our support team at
                                888-659-8432 or email{" "}
                                <a href={`mailto:support@davosalestax.com`} style={{ color: "inherit" }}>
                                    support@davosalestax.com
                                </a>{" "}
                                .
                            </p>
                        </div>
                        <div
                            style={{
                                fontSize: "20px",
                                lineHeight: "14px",
                                color: "#252631",
                                marginBottom: "10px",
                                marginTop: "20px",
                            }}>
                            Benefits of Tax Professional Online Service account
                        </div>
                        <div
                            style={{
                                color: "#252631",
                                fontSize: "14px",
                                fontWeight: 300,
                                lineHeight: "21px",
                            }}>
                            <ul>
                                <li>
                                    The professional account enables filing continuity even when password requirements
                                    change or expire.
                                </li>
                                <li>
                                    DAVO by Avalara will no longer be required to use individual account credentials to
                                    complete returns online.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            setIsPopoverOpen(false);
                        }}
                        variant="outlined"
                        color="primary"
                        style={{ margin: "10px" }}>
                        Cancel
                    </Button>
                </Popover>
            </div>
        );
    }
    return null;
};
