export enum SampleEmailType {
    BusinessInvitation,
    UserInvitation,
    PartnerInvitation,

    DailySalesTaxReportSingleLocation,
    DailySalesTaxReportSingleLocationSalesNoTax,
    DailySalesTaxReportSingleLocationMissingBank,
    DailySalesTaxReportSingleLocationMissingTax,
    DailySalesTaxReportSingleLocationFailingTaxCredentials,
    DailySalesTaxReportSingleLocationUntestedTaxCredentials,
    DailySalesTaxReportSingleLocationMissingBankAndTax,
    DailySalesTaxReportSingleLocationBankAccountPaused,
    DailySalesTaxReportSingleLocationBankAccountUnpauseDate,
    DailySalesTaxReportSingleLocationBankAccountPausedMerchantPay,
    DailySalesTaxReportSingleLocationCloverNewPaymentBanner,

    DailySalesTaxReportMultipleLocations,
    DailySalesTaxReportMultipleLocationsMissingBank,
    DailySalesTaxReportMultipleLocationsMissingTax,
    DailySalesTaxReportMultipleLocationsFailingTaxCredentials,
    DailySalesTaxReportMultipleLocationsUntestedTaxCredentials,
    DailySalesTaxReportMultipleLocationsMissingBankAndTax,
    DailySalesTaxReportMultipleLocationsBankAccountPaused,
    DailySalesTaxReportMultipleLocationsBankAccountUnpauseDate,
    DailySalesTaxReportMultipleLocationsBankAccountPausedMerchantPay,
    DailySalesTaxReportMultipleLocationsCloverNewPaymentBanner,

    FilingConfirmationFullyPaid,
    FilingConfirmationPartialPayment,
    FilingConfirmationHasDiscount,
    FilingConfirmationHasMerchantNote,
    FilingConfirmationZeroPayment,
    FilingConfirmationNoSales,
    FilingConfirmationFullyPaidPrepayment,
    FilingConfirmationPartialPrepayment,
    FilingConfirmationZeroPrepayment,
    FilingConfirmationNewMerchantPartialPayment,
    FilingConfirmationPausedBankAccount,
    FilingConfirmationWashingtonMerchant,

    NoBankingSingleLocationMissingBank,
    NoBankingSingleLocationAwaitingDeactivation,
    NoBankingMultipleLocationsMissingBankAndTax,
    NoBankingMultipleLocationsAwaitingDeactivation,

    NoAccountSingleLocation,
    NoAccountMultipleLocations,

    NoTaxProfileSetupReminder,

    TaxProfileFailingCredsPassword,
    TaxProfileFailingCredsPasswordUpcoming,
    TaxProfileFailingCredsPasswordLate,
    TaxProfileFailingCredsPin,
    TaxProfileFailingCredsTwoFactor,
    TaxProfileFailingCredsRegistration,
    TaxProfileFailingCredsRegistrationState,
    TaxProfileFailingCredsRegistrationWebfile,

    BoardingNotification,
    DisconnectNotificationBoarded,
    DisconnectNotificationNotBoarded,
    ReconnectConfirmation,
    ReconnectNotification,

    BillingNotice,
    BillingNoticeNoCharge,
    BillingRefund,
    FirstFundingNotification,
    LocationDisconnect,
    ManualSetAsideEmail,
    ManualSetAsideReturnEmail,
    PasswordReset,
    AddOpsUser,
    SetPassword,

    BankAccountPaused,
    SlackEmail,

    RequireAdditionalData,
    NoMonthlyTax,

    NewBoardingWelcome,
    NewBoardingWelcomeQB,
    NewBoardingWelcomePartner,
    NewBoardingWelcomePartnerAndQB,
    NewBoardingWelcomeBeta,

    DailySummaryTwoLocationsNoBanks,
    DailySummaryOneLocationShowingGroupLoginNYBanner,
    DailySummaryTwoLocationsOneBank,
    DailySummaryTwoLocationsTwoBanks,
    DailySummaryTwoLocationsThreeBanks,
    DailySummaryFourLocationsTwoBanks,
    DailySummaryNoLocationsTwoBanks,
    DailySummaryTenLocationsTwoBanks,

    NoMonthlySales,

    FilingReturnedBasic,

    BillingFailure,
}
