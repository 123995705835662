import { Avatar, Card, CardHeader, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { ReactNode } from "react";

const useStyles = makeStyles(() => ({
    avatar: {
        backgroundColor: "#4d7cfe",
        boxShadow: "0 0 0 6px #e5ecff",
        width: "50px",
        height: "50px",
    },
}));

export const MerchantCard = ({
    title,
    subheader,
    icon,
}: {
    title: string;
    subheader: (string | JSX.IntrinsicElements["div"]) & ReactNode;
    icon: string;
}) => {
    const classes = useStyles();

    return (
        <Card>
            <CardHeader
                data-testid={"davo-card"}
                className={"truncated-card-header"}
                avatar={
                    <Avatar className={classes.avatar}>
                        <Icon>{icon}</Icon>
                    </Avatar>
                }
                title={title}
                subheader={subheader}
            />
        </Card>
    );
};
