export class PermissionError extends Error {
    private readonly _permission: string;
    private readonly _userId: string;

    constructor(m: string | undefined, permission: string, userId: string) {
        super(m || "message not provided");
        this._permission = permission;
        this._userId = userId;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, PermissionError.prototype);
    }

    permission() {
        return this._permission;
    }

    userId() {
        return this._userId;
    }

    toString() {
        return `message: ${this.message}, user: ${this.userId()}, permission: ${this.permission()}`;
    }
}

export class POSAuthError extends Error {}

export class ToastBoardingAuthError extends Error {}

export class ValidationError extends Error {}

export class TooManyFailedLoginsError extends Error {}

export class UnauthorizedRequestError extends Error {}

export class UnauthorizedError extends Error {}

export class MissingError extends Error {
    constructor(message?: string) {
        super(message); // 'Error' breaks prototype chain here
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    }
}
