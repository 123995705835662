import EmailValidator from "email-validator";
import { OptionalString } from "./Utils";

export const validateNotNull = (value: string | null) => (value ? undefined : "Field required");
export const validateRequired = (value: string | null | undefined) => (value ? undefined : "Field required");

export const validateEmail = (email: string | null) => (!isEmailValid(email) ? "Invalid email address" : undefined);
export const isEmailValid = (email: OptionalString) =>
    !email || (email && !EmailValidator.validate(email)) ? false : true;

export const strongPassword = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*\d))|((?=.*[A-Z])(?=.*\d)))(?=.{6,})/;

export const validatePassword = (password: string | null) => {
    if (!password || password.length < 8) {
        return "Password must be at least 8 characters.";
    } else if (!strongPassword.test(password)) {
        return "Please use mixed case, numbers, or special characters.";
    }
    return undefined;
};
export const isPasswordValid = (password: OptionalString) => {
    return !(!password || password.length < 8 || !strongPassword.test(password));
};

export const validatePhone = (value: OptionalString) => (!isPhoneValid(value) ? "Invalid phone number." : undefined);
export const isPhoneValid = (value: OptionalString) =>
    !value || (value && !/^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/im.test(value)) ? false : true;

export const validateZip = (value: string | null) =>
    value && !/^\d{5}$|^\d{5}-\d{4}$/.test(value) ? "Invalid zip code." : undefined; // 48501 or 48501-1111

// BANKING
export const defaultErrorMessageValidateRoutingNumber = "Invalid routing number.";
export const validateRoutingLength = (value: string | null) =>
    value && !/^\d{9}$/.test(value) ? defaultErrorMessageValidateRoutingNumber : undefined;

export const defaultErrorMessageValidateAccountNumber = "Invalid account number.";
export const validateAccountLength = (value: string | null) =>
    value && (!/^\d*$/.test(value) || value.length < 3) ? defaultErrorMessageValidateAccountNumber : undefined;

export const validateStringLength = (value: string | undefined, limit = 255) =>
    value && value.trim().length > limit ? `Limit of ${limit} characters.` : undefined;
