import { DateTime } from "luxon";
import { BillingEventType } from "./BillingDetail";
import { BusinessDay } from "./BusinessDay";

export interface IBillingEvent {
    id: string;
    description: string;
    amount: number; // integer pennies
    date: BusinessDay;
    created: DateTime;
    createdBy?: null | string;
    billingInvoiceId?: null | string;
    locationId?: null | string;
    taxable?: null | boolean;
    type?: null | BillingEventType;
    cloverMeteredBillingEventId?: null | string;
    cloverMeteredBillingEventReversedDate?: null | DateTime;
    accountId?: null | string;
    partnerId?: null | string;
}

export interface IBillingEventDetail extends IBillingEvent {
    locationName: string;
}
