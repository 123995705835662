import { IJurisdictionUIFieldType } from "./JurisdictionUIFieldType";

export interface IJurisdictionUIFieldDefinitionBase {
    jurisdictionFieldType: IJurisdictionUIFieldType;
    label: string;
    helpText?: null | string;
    toolTip?: null | string;
    validationRegex?: null | string;
    isVisible: boolean;
}
