import { auth, LoadingWithLogo, setLocationBankAccount, useLoginContext } from "@davo/portal-common";
import { IMerchantInvitationInfo, LocationRecord } from "@davo/types";
import * as Sentry from "@sentry/browser";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { useAccountContext, useAccountsContext, useLocationContext } from "../context";
import { checkBoardingHasAccess } from "../services/boarding";
import { getMerchantInvitationInfo, redeemInvitation } from "../services/invitation";
import { BoardingContainer } from "./BoardingContainer";
import { BankAccountSetup } from "./detail/BankAccountSetup";
import { CapturePhone } from "./detail/CapturePhone";
import { TaxProfileSetupNew } from "./detail/TaxProfileSetupNew";
import { AddAdditionalState } from "./flow/AddAdditionalState";
import { BoardingLogin } from "./flow/BoardingLogin";
import { SelectAccount } from "./flow/SelectAccount";
import { SelectBoardingLocations } from "./flow/SelectBoardingLocations";
import { Unavailable } from "./flow/Unavailable";
import { BoardingStatus } from "./types/BoardingStatus";
import { IMarketingInfo } from "./types/MarketingInfo";

export function MerchantInvitationLogin() {
    const loginContext = useLoginContext();
    const accountsContext = useAccountsContext();
    const accountContext = useAccountContext();
    const navigate = useNavigate();

    const [invitationInfo, setInvitationInfo] = useState<IMerchantInvitationInfo>();
    const [tagLine, setTagLine] = useState<string | undefined>(undefined);
    const [isRedeemed, setIsRedeemed] = useState<boolean>(false);
    const [status, setStatus] = useState<BoardingStatus>("login");
    const { code } = useParams();

    // email and phone should have already been extracted into the invitation...just using this to streamline first/last if we happen to have it
    const [marketingInfo, setMarketingInfo] = useState<IMarketingInfo>();
    const [selectedLocations, setSelectedLocations] = useState<LocationRecord[]>();
    const location = useLocation();
    const locationContext = useLocationContext();

    useEffect(() => {
        setMarketingInfo(JSON.parse(localStorage.getItem("marketingInfo") ?? "{}"));
    }, []);

    useEffect(() => {
        console.debug(`Status: ${status}`); // eslint-disable-line no-console
    }, [status]);

    useAsyncEffect(async () => {
        if (!code || isNil(location.search)) {
            return;
        }
        let info: IMerchantInvitationInfo;
        try {
            info = await getMerchantInvitationInfo(code);
        } catch (e: any) {
            console.error(e); // eslint-disable-line no-console
            Sentry.captureException(e);
            setStatus("unavailable");
            return;
        }

        const allowedAccess: { [locationId: string]: boolean } = {};
        await Promise.all(
            info.locations.map(async (l) => {
                allowedAccess[l.id] =
                    !loginContext.user || isNil(l.accountId) ? true : await checkBoardingHasAccess(code, l.posLocation);
            })
        );

        const locationsWithAccess = info.locations.filter((l) => l.active && allowedAccess[l.id]);
        if (isEmpty(locationsWithAccess)) {
            navigate("/");
        }

        // the reconnect screen prompting for T&Cs has happened and kicked us into boarding
        const params = new URLSearchParams(location.search);
        const isReconnected = params.get("reconnect") === "true";

        if (isReconnected && info.locations.length === 1 && info.locations[0].accountId) {
            await accountContext.switch(info.locations[0].accountId);
            setSelectedLocations([info.locations[0]]);
            if (!info.hasBankAccount) {
                setStatus("banking");
            } else if (!info.hasTaxProfiles) {
                setStatus("tax");
            }
        }

        setInvitationInfo(info);
    }, [code, location.search]);

    useAsyncEffect(async () => {
        // after login step
        if (!loginContext.user || !invitationInfo) {
            return;
        }

        if (status === "login") {
            if (loginContext.user.type !== "Merchant") {
                await auth.logout().finally(() => {
                    loginContext.setUser(undefined);
                });
                return;
            } else if (!loginContext.user.phone) {
                setStatus("get-phone");
                return;
            }
            if (invitationInfo.status === "redeemed") {
                if (invitationInfo.locations.length === 1 && invitationInfo.locations[0].accountId) {
                    // note: we have already checked we have access to this account/location when we loaded the invitation
                    await accountContext.switch(invitationInfo.locations[0].accountId);
                    setSelectedLocations(invitationInfo.locations);
                    if (!invitationInfo.hasBankAccount) {
                        setStatus("banking");
                        return;
                    } else if (!invitationInfo.hasTaxProfiles) {
                        setStatus("tax");
                        return;
                    }
                }
                navigate("/");
                return;
            } else {
                await accountContext.refresh();
                await accountsContext.refresh();
            }

            setStatus("select-locations");
        }
    }, [status, loginContext.user, invitationInfo]);

    const redeem = async (selectedAccountId: string | undefined) => {
        if (!invitationInfo || !selectedLocations || !code || isRedeemed) {
            return;
        }
        const { accountId } = await redeemInvitation(
            code,
            selectedAccountId,
            selectedLocations.map((l) => l.id)
        ).catch((e: any) => {
            Sentry.captureException(e);
            setStatus("unavailable");
            throw new Error(`Failed to redeem invitation: ${e.message}`);
        });
        if (accountId) {
            setIsRedeemed(true);
            await accountContext.switch(accountId);
            await accountsContext.refresh();

            // onboarding multiple or adding to existing account
            if (selectedLocations.length > 1 || !isNil(selectedAccountId)) {
                navigate("/");
            } else {
                setStatus("banking");
            }
        }
    };

    useEffect(() => {
        if (status === "login") {
            setTagLine("Please create an account or login.");
        } else {
            setTagLine("You are on your way to never worrying about sales tax again!");
        }
    }, [status]);

    if (!status || !code) {
        return null;
    }

    if (status === "unavailable") {
        return (
            <BoardingContainer posType={undefined} plusMerchantName={undefined} tagLine={tagLine}>
                <Unavailable
                    onContinue={() => {
                        window.location.replace("https://davosalestax.com/integrations");
                    }}
                />
            </BoardingContainer>
        );
    }

    if (!invitationInfo) {
        return <LoadingWithLogo />;
    }

    return (
        <div data-testid={"merchantInvitationLogin"}>
            <BoardingContainer posType={invitationInfo.type} plusMerchantName={invitationInfo.name} tagLine={tagLine}>
                {status === "login" && <BoardingLogin invitationInfo={invitationInfo} marketingInfo={marketingInfo} />}

                {status === "get-phone" && (
                    <CapturePhone marketingInfo={marketingInfo} onContinue={() => setStatus("select-locations")} />
                )}

                {status === "select-locations" && (
                    <SelectBoardingLocations
                        locations={invitationInfo.locations}
                        onContinue={(selected: LocationRecord[]) => {
                            setSelectedLocations(selected);
                            if (isEmpty(selected)) {
                                navigate("/");
                            } else {
                                setStatus("account-selection");
                            }
                        }}
                    />
                )}

                {status === "account-selection" && (
                    <SelectAccount
                        invitationInfo={invitationInfo}
                        onContinue={async (selectedId: string | undefined) => {
                            await redeem(selectedId);
                        }}
                        onCancel={() => setStatus("login")}
                    />
                )}

                {status === "banking" && (
                    <BankAccountSetup
                        location={selectedLocations![0]}
                        onSave={async (bankAccountId: string) => {
                            await setLocationBankAccount(
                                accountContext.account!.id,
                                selectedLocations![0].id,
                                bankAccountId
                            );
                            await accountContext.refresh();
                            setStatus("tax");
                            await locationContext.switch(selectedLocations![0].id);
                        }}
                    />
                )}

                {status === "tax" && (
                    <TaxProfileSetupNew
                        onCreate={() => setStatus("add-another")}
                        onCancel={() => setStatus("add-another")}
                        locationId={selectedLocations![0].id}
                    />
                )}

                {status === "add-another" && (
                    <AddAdditionalState
                        onContinue={(isAddAnother) => {
                            if (isAddAnother) {
                                setStatus("tax");
                            } else {
                                navigate("/");
                            }
                        }}
                    />
                )}
            </BoardingContainer>
        </div>
    );
}
