export interface IAvaTaxTransaction {
    addresses: IAvaTaxTransactionAddress[];
    adjustmentReason: string;
    batchCode: string;
    code: string;
    companyId: number;
    currencyCode: string;
    customerCode: string;
    customerUsageType: string;
    customerVendorCode: string;
    date: string; // "2019-10-29";
    entityUseCode: string;
    exchangeRate: number;
    exchangeRateCurrencyCode: string;
    exchangeRateEffectiveDate: string;
    exemptNo: string;
    id: number;
    lines: IAvaTaxTransactionLine[];
    locationCode: string;
    locked: false;
    modifiedDate: string; // "2021-04-21T16:00:36.0419817Z";
    modifiedUserId: 1124348;
    paymentDate: string; // "2019-10-29";
    purchaseOrderNo: string;
    reconciled: false;
    referenceCode: string;
    reportingLocationCode: string;
    salespersonCode: string;
    status: string;
    summary: IAvaTaxTransactionSummary[];
    taxDate: string; // "2019-10-29T00:00:00Z";
    totalAmount: number;
    totalDiscount: number;
    totalExempt: number;
    totalTax: number;
    totalTaxCalculated: number;
    totalTaxable: number;
    type: string;
    version: number;
}

export interface IAvaTaxTransactionLine {
    costInsuranceFreight: number;
    customerUsageType: string;
    description: string;
    details: IAvaTaxTransactionDetail[];
    discountAmount: number;
    entityUseCode: string;
    exemptAmount: number;
    exemptCertId: number;
    exemptNo: string;
    hsCode: string;
    id: number;
    isItemTaxable: false;
    itemCode: string;
    lineAmount: number;
    lineNumber: string;
    nonPassthroughDetails: [];
    quantity: number;
    ref1: string;
    ref2: string;
    reportingDate: string; // "2019-10-29";
    tax: 0.0;
    taxCalculated: number;
    taxCode: string;
    taxCodeId: number;
    taxDate: string;
    taxIncluded: false;
    taxableAmount: number;
    transactionId: number;
    vatCode: string;
    vatNumberTypeId: number;
}

export interface IAvaTaxTransactionDetail {
    country: string;
    exemptAmount: number;
    id: number;
    isFee: false;
    isNonPassThru: false;
    jurisCode: string;
    jurisName: string;
    jurisType: string;
    jurisdictionType: string;
    liabilityType: string;
    nonTaxableAmount: number;
    rate: number;
    rateType: string;
    rateTypeCode: string;
    region: string;
    reportingExemptUnits: number;
    reportingNonTaxableUnits: number;
    reportingTax: number;
    reportingTaxCalculated: number;
    reportingTaxableUnits: number;
    stateAssignedNo: string;
    tax: number;
    taxAuthorityTypeId: number;
    taxCalculated: number;
    taxName: string;
    taxSubTypeId: string;
    taxType: string;
    taxableAmount: number;
    transactionId: number;
    transactionLineId: number;
    unitOfBasis: string;
}

export interface IAvaTaxTransactionAddress {
    boundaryLevel: string;
    city: string;
    country: string;
    id: number;
    latitude: string;
    line1: string;
    line2: string;
    line3: string;
    longitude: string;
    postalCode: string;
    region: string;
    taxRegionId: number;
    transactionId: number;
}

export interface IAvaTaxTransactionSummary {
    country: string;
    exemption: number;
    jurisCode: string;
    jurisName: string;
    jurisType: string;
    nonTaxable: number;
    rate: number;
    rateType: string;
    region: string;
    stateAssignedNo: string;
    tax: number;
    taxAuthorityType: number;
    taxCalculated: number;
    taxName: string;
    taxSubType: string;
    taxType: string;
    taxable: number;
}
