export interface IUfsResponse {
    TaskID?: null | string;
    ErrorType?: null | string;
    ErrorStep?: null | string;
    isRetryable?: null | boolean;
    ErrorDetails?: null | IUfsError[];
}

export interface IUfsError {
    ErrorCode: string;
    ErrorDescription: string;
}

export interface IUfsCfdHeader {
    CompanyIdentifier: string;
    CountryCode: string;
    FilingFrequency: string;
    FilingMethod: string;
    FilingMonth: string;
    FilingYear: number;
    Jurisdiction: string;
    PeriodEnd: string;
    PeriodStart: string;
    SourceSystem: string;
    TaxFormCode: string;
    WithoutDiscount: boolean;
}

export interface IUfsCfdSummary {
    DiscountAmount: string;
    FEIN: string;
    GrossAmount: string;
    InterestAmount: string;
    NontaxableAmount: string;
    PenaltyAmount: string;
    PriorPaymentAmount: string;
    RemittanceAmount: string;
    Signature: string;
    TaxAmount: string;
}

export interface IUfsPayment {
    AccountHolderName: string;
    AccountType: string;
    AdditionalFields?: null | string;
    BankAccountNum: string;
    BankName: string;
    BankRoutingNum: string;
    PaymentDate: string;
    PaymentMethod: string;
}

export interface IUfsPreparer {
    firstName: string;
    lastName: string;
    title: string;
    phoneNumber: string;
    extension: number;
    faxNumber: string;
    department: string;
    emailAddress: string;
    businessName: string;
    businessAddress: string;
    businessCity: string;
    businessState: string;
    businessZip: string;
}

export interface IUfsCfd {
    Version?: string;
    Header: IUfsCfdHeader;
    returnsData: { [key: string]: any };
    pdfFeed: { [key: string]: any };
    summary: IUfsCfdSummary;
    inputDataChanges: string;
    logs: string;
    omittedPages: string;
    overrides: string;
    pdfFileKey: string;
    repeatGroups: string;
    ValidationResult: {
        TopLevelErrors: [];
        FieldErrors: {};
    };
}

export interface IUfsPayload {
    FilingSystem: string;
    CFD: IUfsCfd;
    FilingRequestData: string;
    Skyscraper: string | IUfsSkyscraper;
    Payment: IUfsPayment;
    PreparerDetails: IUfsPreparer;
    SerTransmissionType: string;
    discount?: null | string;
}

export const ufsBasePayLoad: IUfsPayload = {
    FilingSystem: "",
    CFD: {
        Version: "",
        Header: {
            CompanyIdentifier: "",
            CountryCode: "",
            FilingFrequency: "",
            FilingMethod: "ElectronicReturn",
            FilingMonth: "",
            FilingYear: 0,
            Jurisdiction: "",
            PeriodEnd: "",
            PeriodStart: "",
            SourceSystem: "DAVO",
            TaxFormCode: "",
            WithoutDiscount: true,
        },
        returnsData: {},
        pdfFeed: {},
        summary: {
            DiscountAmount: "0",
            FEIN: "",
            GrossAmount: "0",
            InterestAmount: "0",
            NontaxableAmount: "0",
            PenaltyAmount: "0",
            PriorPaymentAmount: "0",
            RemittanceAmount: "0",
            Signature: "",
            TaxAmount: "0",
        },
        inputDataChanges: "",
        logs: "",
        omittedPages: "",
        overrides: "",
        pdfFileKey: "",
        repeatGroups: "",
        ValidationResult: {
            TopLevelErrors: [],
            FieldErrors: {},
        },
    },
    FilingRequestData: "",
    Skyscraper: "",
    Payment: {
        AccountHolderName: "",
        AccountType: "",
        AdditionalFields: "",
        BankAccountNum: "",
        BankName: "",
        BankRoutingNum: "",
        PaymentDate: "",
        PaymentMethod: "",
    },
    PreparerDetails: {
        firstName: "",
        lastName: "",
        title: "",
        phoneNumber: "",
        extension: 0,
        faxNumber: "",
        department: "",
        emailAddress: "",
        businessName: "",
        businessAddress: "",
        businessCity: "",
        businessState: "",
        businessZip: "",
    },
    SerTransmissionType: "",
};

export interface IUfsSkyscraper {
    skyScraperRegion: string;
    mode: string;
    BulkAccount: string;
    bulkAccountDetails: {
        username: string;
        password: string;
    };
}

export interface IUfsStatus {
    FilingTaskId: string;
    FilingSystem: string;
    FilingSystemReferenceId: string;
    Status: string;
    FilingMode?: null | string;
    FilingSystemLevelStatus: string;
    Message?: null | string;
    StateReferenceId: string;
    ProcessedOnDateTime: string; // "2022-05-06T20:36:37.600685+00:00"
    Images?: null | string;
    FilingDetails: {
        ConfirmationId?: null | string;
        FilingData?: null | string;
        ConfirmationArtifacts?: null | string[];
        Error?: null | {
            ErrorType: string;
            ErrorMessage: string;
            ErrorStep: string;
            isRetryable: boolean;
            WebfileProcessingStatuses: string;
            ErrorDetails: IUfsStatusErrorDetails[];
        };
    };
}

export interface IUfsStatusErrorDetails {
    ErrorCode: string;
    ErrorDescription: string;
}

export interface IUfsStateConfig {
    filingMethod: string;
    formCode: string;
    formId: string;
    pdf: string;
    scanline?: null | string;
    version: string;
}
