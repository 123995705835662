import React, { useEffect, useState } from "react";
import { TextField } from "./TextField";

export interface INumberOnlyFieldProps {
    label: string;
    readOnly?: boolean;
    disabled?: boolean;
    value?: number;
    helperText?: string;
    onChange?: (v: number | undefined) => void;
    onBlur?: (v: number | undefined) => void;
    validate?: (v: number | undefined) => string | undefined;
    formatter?: (v: number | undefined) => string;
    onEnterPress?: () => void;
    autoFocus?: boolean;
    inputRef?: React.Ref<any>;
    fullWidth?: boolean;
    style?: any;
}

const noop = () => undefined;

const defaultFormatter = (value: number | undefined) => (value ? `${value}` : "");

const parse = (str: string) => +str.replace(/[$,]+/g, "");

export function NumberOnlyField({
    value,
    onChange = noop,
    validate = noop,
    onBlur = noop,
    formatter = defaultFormatter,
    ...rest
}: INumberOnlyFieldProps) {
    const [stringValue, setStringValue] = useState<string>("");

    useEffect(() => {
        setStringValue(formatter(value));
    }, [value]);

    const onStringChange = (str: string) => {
        const validValue = str.replace(/[^\d.,]/g, "");
        setStringValue(validValue);
        onChange(validValue === "" ? undefined : parse(validValue));
    };

    const validateString = (str: string) => {
        const val = parse(str);
        if (Number.isNaN(val)) {
            return "Invalid number.";
        }
        return validate(val);
    };

    const onStringBlur = () => {
        const numericValue = stringValue ? parse(stringValue) : undefined;
        const newValue = formatter(numericValue);
        setStringValue(newValue);
        onChange(numericValue);
        onBlur(numericValue);
    };

    return (
        <TextField
            value={stringValue}
            onChange={onStringChange}
            validate={validateString}
            onBlur={onStringBlur}
            {...rest}
        />
    );
}
