import { getBankAccountNachaRestricted, StatusDot } from "@davo/portal-common";
import { BankAccount, BankAccountNachaRestrictedMessage, getLocationsInfo, StatusLevel } from "@davo/types";
import { Button } from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";

export interface IBankAccountStatusProps {
    accountId: string;
    bankAccount?: BankAccount | null;
    onClick?: () => void;
    showToolTipOnly?: boolean;
    showButton?: boolean;
}

export function BankAccountStatus({
    accountId,
    bankAccount,
    onClick,
    showToolTipOnly,
    showButton,
}: IBankAccountStatusProps) {
    const [level, setLevel] = useState<StatusLevel>();
    const [message, setMessage] = useState<string>();
    const [nachaRestricted, setNachaRestricted] = useState<BankAccountNachaRestrictedMessage>();

    useAsyncEffect(async () => {
        const { isBankAccountPaused, bankAccountUnpauseDate } = getLocationsInfo([], bankAccount);
        if (bankAccount) {
            const restricted = await getBankAccountNachaRestricted(accountId, bankAccount.id);
            setNachaRestricted(restricted);

            if (restricted?.isNachaRestricted) {
                setLevel("error");
                setMessage("In order to re-enable this account, you have to provide a new bank account");
            } else {
                if (isBankAccountPaused && !bankAccountUnpauseDate) {
                    setLevel("error");
                    setMessage("Paused");
                } else if (isBankAccountPaused && bankAccountUnpauseDate) {
                    setLevel("ok");
                    setMessage(`Set-asides start ${bankAccountUnpauseDate}`);
                } else {
                    setLevel("ok");
                    setMessage("OK");
                }
            }
        } else {
            setLevel("error");
            setMessage("Missing Info");
        }
    }, [bankAccount]);

    if (!level || !message) {
        return null;
    }

    return (
        <span data-testid={"bank-status"}>
            <StatusDot
                isFocusable={true}
                level={level}
                message={!showToolTipOnly ? message : ""}
                toolTip={showToolTipOnly ? message : ""}
                onClick={onClick}
                linkLabel={""}
            />
            {bankAccount?.paused && showButton && !nachaRestricted?.isNachaRestricted && (
                <Button
                    onClick={onClick}
                    variant="outlined"
                    size={"small"}
                    color="primary"
                    data-testid={"reactivate-button"}>
                    Reactivate
                </Button>
            )}
        </span>
    );
}
