import { FormError, TextField } from "@davo/portal-common";
import {
    isEmailValid,
    isPhoneValid,
    IStorageMarketingInfo,
    validateEmail,
    validateNotNull,
    validatePhone,
} from "@davo/types";
import { Button, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { useConfigMerchantContext } from "../context";
import { BoardingContainer, sharedButtonContainer } from "./BoardingContainer";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
    ...sharedButtonContainer,
}));

export function LightspeedXSeriesBoarding() {
    const classes = useStyles();
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [phone, setPhone] = useState<string | undefined>(undefined);
    const [businessName, setBusinessName] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [status, setStatus] = useState<"initial" | "complete">("initial");
    const { merchantConfigInfo: configInfo } = useConfigMerchantContext();
    const [isPacificTz, setIsPacificTz] = useState<boolean>(false);

    useEffect(() => {
        const storageMarketingInfo: IStorageMarketingInfo = JSON.parse(localStorage.getItem("marketingInfo") ?? "{}");
        setPhone(storageMarketingInfo.phone);
        setEmail(storageMarketingInfo.email);
    }, []);

    useEffect(() => {
        // Lightspeed X-Series is based in NZ so we've seen some NZ merchants board.  Test and catch here so we can prevent boarding and change message.
        const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setIsPacificTz(browserTz.startsWith("Pacific"));
    }, []);

    const doCall = () => {
        if (isEmailValid(email) && isPhoneValid(phone) && !isEmpty(businessName)) {
            setMessage(undefined);
            setStatus("complete");
            const passThrough = Buffer.from(
                `{"email": "${email}", "phone": "${phone}", "name": "${businessName}"}`
            ).toString("base64");
            window.location.replace(`${configInfo.lightspeedXSeriesUrl}&state=${passThrough}`);
        } else {
            setMessage("Please fill out all fields.");
        }
    };

    return (
        <BoardingContainer posType={"lightspeed-x-series"}>
            {isPacificTz ? (
                <div style={{ textAlign: "center" }}>
                    <h1>DAVO only available in the United States</h1>
                    <span style={{ lineHeight: "1.7em" }}>
                        Sorry, but we currently only accept Lightspeed X-Series merchants located within the United
                        States.
                    </span>
                </div>
            ) : (
                status === "initial" && (
                    <>
                        <TextField
                            label="Business Name"
                            value={businessName ?? ""}
                            onChange={setBusinessName}
                            onEnterPress={doCall}
                            inputProps={{
                                [`data-testid`]: "nameInput",
                            }}
                            margin={"dense"}
                            isRequired
                            validate={validateNotNull}
                        />
                        <TextField
                            label="Phone Number"
                            value={phone ?? ""}
                            onChange={setPhone}
                            margin={"dense"}
                            isRequired
                            validate={(v) => (v ? validatePhone(v) : validateNotNull(null))}
                            onEnterPress={doCall}
                            inputProps={{
                                [`data-testid`]: "phoneInput",
                            }}
                        />
                        <TextField
                            label="Email"
                            value={email ?? ""}
                            margin={"dense"}
                            isRequired
                            validate={(v) => (v ? validateEmail(v) : validateNotNull(null))}
                            onChange={setEmail}
                            onEnterPress={doCall}
                            inputProps={{
                                [`data-testid`]: "emailInput",
                            }}
                        />
                        {message && <FormError message={message} />}
                        <div className={classes.submitContainer}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={doCall}
                                data-testid={"submitBtn"}
                                disabled={!email || !phone || !businessName}>
                                Connect with Lightspeed X-Series
                            </Button>
                        </div>
                    </>
                )
            )}
        </BoardingContainer>
    );
}
