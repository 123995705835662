import { FormError, TextField } from "@davo/portal-common";
import { validateNotNull } from "@davo/types";
import InfoIcon from "@mui/icons-material/Info";
import { Button, Theme, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useConfigMerchantContext } from "../context";
import { testRevelCredentials } from "../services/boarding";
import { BoardingContainer, sharedButtonContainer } from "./BoardingContainer";
import { RevelPosInfo } from "./types/PosInfoTypes";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
    ...sharedButtonContainer,
}));

export function RevelBoarding() {
    const classes = useStyles();
    const [apiKey, setApiKey] = useState<string | undefined>(undefined);
    const [apiSecret, setApiSecret] = useState<string | undefined>(undefined);
    const [prefix, setPrefix] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const { merchantConfigInfo: configInfo } = useConfigMerchantContext();
    const navigate = useNavigate();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const doCall = async () => {
        if (isBusy) {
            return;
        }

        if (apiKey && apiSecret && prefix) {
            const formattedPrefix = formatPrefix(prefix);
            setIsBusy(true);
            const result = await testRevelCredentials(formattedPrefix, apiKey, apiSecret);
            setIsBusy(false);
            if (result.success.valid === true) {
                const posData: RevelPosInfo = {
                    apiKey,
                    apiSecret,
                    prefix: formattedPrefix,
                };
                navigate(configInfo.revelInvitationURL, {
                    state: posData,
                });
            } else {
                setMessage("Invalid Revel credentials.");
            }
        } else {
            setMessage("Please fill out all fields.");
        }
    };

    const formatPrefix = (p: string) => {
        let fp = p.toLowerCase();
        fp = fp.replace("https://", "").replace("http://", "");
        fp = fp.replace("com", "").replace("/", "");
        fp = fp.replace('"', "").replace("'", "").replace("www.", "");
        return fp.replace(".revelup", "").replace(".", "");
    };

    return (
        <BoardingContainer posType={"revel"}>
            <TextField
                label="Revel URL prefix"
                value={prefix ?? ""}
                isRequired
                isDisabled={isBusy}
                validate={validateNotNull}
                onChange={setPrefix}
                onEnterPress={doCall}
                inputProps={{
                    [`data-testid`]: "prefixInput",
                }}
                InputProps={{
                    endAdornment: (
                        <Tooltip
                            title="
                                    To find your URL prefix, check your RevelUp account home page.
                                    You will find it in the URL at the top of your browser.
                                    For example: 'ABC Business' might see 'https://abcbusiness.revelup.com'.
                                    They would enter abcbusiness in the field below."
                            placement="top">
                            <InfoIcon fontSize={"medium"} color="primary" />
                        </Tooltip>
                    ),
                }}
            />
            <TextField
                label="API Key"
                value={apiKey ?? ""}
                onChange={setApiKey}
                onEnterPress={doCall}
                isRequired
                margin={"dense"}
                validate={validateNotNull}
                isDisabled={isBusy}
                inputProps={{
                    [`data-testid`]: "keyInput",
                }}
            />
            <TextField
                label="API Secret"
                isRequired
                validate={validateNotNull}
                isDisabled={isBusy}
                value={apiSecret ?? ""}
                onChange={setApiSecret}
                onEnterPress={doCall}
                margin={"dense"}
                inputProps={{
                    [`data-testid`]: "secretInput",
                }}
            />
            {message && <FormError message={message} />}
            <div className={classes.submitContainer}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={doCall}
                    data-testid={"submitBtn"}
                    disabled={!apiKey || !apiSecret || !prefix || isBusy}
                    startIcon={isBusy && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />}>
                    Connect with Revel
                </Button>
            </div>
        </BoardingContainer>
    );
}
