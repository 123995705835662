import { DateTime } from "luxon";

export interface ICron {
    id: string;
    name: string;
}

export interface ICronOperationsLog {
    id: string;
    cronId: string;
    startDate: DateTime;
    endDate?: null | DateTime;
    runWindowStartDate?: null | DateTime;
    runWindowEndDate?: null | DateTime;
}
