import davoLogo from "@davo/portal-common/lib/resources/davo_logo.png";
import { Grid, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useLocation } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { testQuickBooksCredentials } from "./services/boarding";

/* QuickBooks requires a disconnect link from their app store that will automatically disconnect an account.
This link is just a redirect to the url we list on the app config page with an included ?realmId= param identifying
which merchant is requesting a disconnect.  QuickBooksDisconnect here grabs that realmId and runs a testCreds on it
within the QB POS class, which will try to renew the creds.  If QB has revoked auth to those creds, the merchant
will be automatically disconnected, if no account exists with that id, it will be ignored, if the realmId exists
and the creds refresh works, they continue as active.  This is the cleanest way we could find to have an open
disconnect link that would process a valid disconnect, but not give away which ids are valid, and not be open to being
spammed by a list of realmIds. */

const useStyles = makeStyles(() => ({
    paper: {
        padding: "20px",
        height: "100%",
        textAlign: "center",
    },
    item: {
        maxWidth: "400px",
    },
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function QuickBooksDisconnect() {
    const classes = useStyles();
    const params = useQuery();

    useAsyncEffect(async () => {
        const realmId = params.get("realmId");
        if (realmId) {
            await testQuickBooksCredentials(realmId);
        }
    }, [params]);

    return (
        <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            style={{ padding: "10px" }}
            data-testid={"disconnect-grid"}>
            <Grid item xs={12} md={6} lg={6} className={classes.item}>
                <Paper className={classes.paper}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={davoLogo} style={{ width: "120px" }} alt="DAVO Logo" />
                    </div>
                    <h1>QuickBooks Disconnected</h1>
                    <Typography>
                        Sorry to see you go! We will be in contact to understand what to do with any tax we are
                        currently holding.
                        <br />
                        Please visit our
                        <a href="https://www.davosalestax.com/integrations/quickbooks/">QuickBooks</a> integration page
                        if you would like to reconnect.
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
}
