import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import { IconButton, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import React from "react";

export interface IVerifyAddressAlert {
    isValidated: boolean;
    hasAvataxValidatedAddress: boolean;
    launchVerifyAddressModal: (thing?: string | undefined) => void;
}

export const VerifyAddressAlert: React.FunctionComponent<IVerifyAddressAlert> = ({
    isValidated,
    hasAvataxValidatedAddress,
    launchVerifyAddressModal,
}) => (
    <Alert
        data-testid={"verifyAddressAlert"}
        style={{ margin: "6px 0" }}
        variant="standard"
        severity={isValidated ? "success" : "warning"}
        action={
            !isValidated &&
            hasAvataxValidatedAddress && (
                <Tooltip title={"Verify Location Address"}>
                    <IconButton
                        data-testid={"verifyLocationAddressIconBtn"}
                        sx={{ padding: "2px" }}
                        style={{ color: "inherit" }}
                        aria-label="Verify Location Address"
                        onClick={() => launchVerifyAddressModal()}
                        size="small">
                        <NotListedLocationIcon />
                    </IconButton>
                </Tooltip>
            )
        }>
        {`${isValidated ? "Verified" : "Unverified"} Address`}
    </Alert>
);
