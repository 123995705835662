import {
    Header,
    initiateFirstFundingForLocations,
    markFirstFundingDeclined,
    PaperComponent,
    Select,
    TextField,
} from "@davo/portal-common";
import { initialCap, moneyFromCents, validateNotNull } from "@davo/types";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export type FirstFundingBreakoutType = { locationId: string; locationName: string; amount: number; status: string };

export function FirstFundingSelfServeModal({
    firstFundingBreakout,
    isReadOnly,
    closeDialog,
}: {
    firstFundingBreakout: FirstFundingBreakoutType[];
    isReadOnly: boolean;
    closeDialog: (target?: boolean) => void;
}) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [status, setStatus] = useState<"declined" | "accepted" | "confirming" | undefined>(undefined);
    const [fundingList, setFundingList] = useState<FirstFundingBreakoutType[]>([]);
    const [declineReason, setDeclineReason] = useState<string | undefined>(undefined);
    const [declineOther, setDeclineOther] = useState<string | undefined>(undefined);

    useEffect(() => {
        setFundingList(firstFundingBreakout);
        if (firstFundingBreakout.filter((x) => x.status === "accepted").length > 0) {
            setStatus("accepted");
        } else if (firstFundingBreakout.filter((x) => x.status === "declined").length > 0) {
            setStatus("declined");
        }
    }, [firstFundingBreakout]);

    const renderAmountForLocationCount = () => {
        return (
            <>
                {" "}
                totaling <b>${moneyFromCents(fundingList.reduce((total, i) => total + i.amount, 0))}</b>{" "}
            </>
        );
    };

    const clickAccept = async () => {
        if (!isReadOnly) {
            await finalize(true);
        }
    };

    const clickDecline = async () => {
        if (!isReadOnly) {
            setStatus("confirming");
        }
    };

    const clickConfirmDecline = async () => {
        if (!isReadOnly && declineReason && (declineReason !== "OTHER" || declineOther)) {
            await finalize(false);
        }
    };

    const finalize = async (didAccept: boolean) => {
        if (!isBusy) {
            if (!isReadOnly) {
                try {
                    setIsBusy(true);
                    if (!didAccept && declineReason) {
                        await markFirstFundingDeclined(
                            fundingList.flatMap((y) => y.locationId),
                            declineReason === "OTHER" ? (declineOther ?? "OTHER") : declineReason
                        );
                    } else {
                        await initiateFirstFundingForLocations(fundingList.flatMap((y) => y.locationId));
                    }
                } catch (e) {
                    setIsBusy(false);
                } finally {
                    setIsBusy(false);
                }
            }
            closeDialog(true);
        }
    };

    function GetIcon() {
        if (status === "accepted") {
            return <CheckCircleIcon sx={{ color: "green" }} />;
        } else if (status === "declined") {
            return <CancelIcon sx={{ color: "red" }} />;
        } else {
            return <></>;
        }
    }

    function renderInitialDialogContent() {
        return (
            <div>
                <div style={{ display: "inline-flex" }}>
                    {GetIcon()}
                    {status === undefined ? "" : `Catch Up (${initialCap(status ?? "")})`}
                </div>
                <Typography variant="body1" color="textSecondary">
                    <p>Congratulations! Your tax credentials look good!</p>
                    <p>
                        Typically, we set aside your sales tax funds daily, but before you were our customer, we were
                        not doing this. This means that we have not collected your full sales tax amount to remit to the
                        jurisdiction.
                    </p>
                    <p>
                        We will initiate a one time transfer {renderAmountForLocationCount()} for the funds in this
                        filing period that we have not already set aside for. That means when it is time to file and
                        pay, you will not need to take any further action.
                    </p>
                    <p>
                        If you do not want to get caught up, we will still file on your behalf, but you will be
                        responsible for paying that portion of your sales tax funding to the jurisdiction directly.
                    </p>
                </Typography>
            </div>
        );
    }

    function renderInitialDialogActions() {
        return (
            <Stack justifyContent={"space-between"} direction="row">
                <Button variant="contained" color="primary" onClick={() => clickAccept()}>
                    Catch me up now
                </Button>
                <Button
                    variant={"outlined"}
                    color="primary"
                    size="small"
                    sx={{ marginLeft: "150px", marginTop: "25px" }}
                    onClick={() => clickDecline()}>
                    I will pay the jurisdiction myself
                </Button>
            </Stack>
        );
    }

    function renderReadOnlyContent() {
        if (!status) {
            return <Typography>Could not determine first funding status, please try again later.</Typography>;
        }
        return (
            <>
                <div style={{ display: "inline-flex" }}>
                    {GetIcon()}
                    {` Catch Up (${initialCap(status ?? "")})`}
                </div>
                <Typography>One or more of your locations required a first funding catchup.</Typography>
            </>
        );
    }

    function renderReadOnlyActions() {
        return (
            <Button variant="contained" color="primary" onClick={() => closeDialog(false)}>
                Close
            </Button>
        );
    }

    const firstFundingDeclineReasonCodes: string[] = ["PREPAYMENT", "FUNDING", "OTHER"];

    function renderConfirmingContent() {
        return (
            <>
                <Typography>
                    Are you sure?
                    <br />
                    <p>
                        We highly recommend funding your locations to avoid complications and extra fees associated with
                        late payments.
                    </p>
                    <p>
                        Since you are declining to fund a location, you are responsible for paying the uncollected
                        amount to the jurisdiction directly, as well as paying any penalties or interest resulting from
                        late payment.
                    </p>
                </Typography>
                <Select<string>
                    title="Reason"
                    value={declineReason}
                    onChange={setDeclineReason}
                    options={firstFundingDeclineReasonCodes}
                />
                {declineReason && declineReason === "OTHER" && (
                    <TextField
                        label="Tell us why you are declining"
                        value={declineOther ?? ""}
                        onChange={setDeclineOther}
                        validate={validateNotNull}
                    />
                )}
            </>
        );
    }

    function renderConfirmingActions() {
        return (
            <Stack justifyContent={"space-between"} direction="row">
                <Button variant="contained" color="primary" onClick={() => setStatus(undefined)}>
                    Back
                </Button>
                <Button
                    disabled={!declineReason || (declineReason === "OTHER" && !declineOther)}
                    variant={"outlined"}
                    color="primary"
                    size="small"
                    sx={{ marginLeft: "40px" }}
                    onClick={() => clickConfirmDecline()}>
                    I understand
                </Button>
            </Stack>
        );
    }

    return (
        <Dialog
            fullScreen={makeFullScreen}
            open={true}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            maxWidth={"lg"}
            style={{ boxShadow: "none", padding: "10px" }}
            onClose={(event, reason) => {
                if (isReadOnly || (reason !== "backdropClick" && reason !== "escapeKeyDown")) {
                    closeDialog(false);
                }
            }}>
            <DialogTitle id="draggable-dialog-title"></DialogTitle>
            <DialogContent>
                <Header>Fund your first filing</Header>
                <Box key="root" sx={{ width: 600 }}>
                    {!isReadOnly && status !== "confirming" && renderInitialDialogContent()}
                    {isReadOnly && status !== "confirming" && renderReadOnlyContent()}
                    {status === "confirming" && renderConfirmingContent()}
                </Box>
            </DialogContent>
            <DialogActions>
                {!isReadOnly && status !== "confirming" && renderInitialDialogActions()}
                {isReadOnly && status !== "confirming" && renderReadOnlyActions()}
                {status === "confirming" && renderConfirmingActions()}
            </DialogActions>
        </Dialog>
    );
}
