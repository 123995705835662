export interface ISerPayload {
    BankAccountNum: string;
    RoutingNumber: string;
    AccountHolderName: string;
    TaxFormCode: string;
    SendPayment: "false" | "true";
    TransmitBody: string;
    ExportOutputType: "SER";
    PaymentDate: string;
}

export interface ISerResponse {
    Accepted: boolean;
    Category: null | string;
    Export: string;
    ExportContentType: string;
    Message: null | string; // populated if there is an error
    RemittanceAmount: null | number;
    TransmissionId: string;
}

export interface IBulkfilerReturnData {
    formCode: string;
    taxDue: number;
    discount: number;
    remittance: number;
    outstanding: number;
    errors: string[];
    warnings?: string[];
    payload?: JSON;
}

export interface ISerCfdHeader {
    TaxFormCode: string;
    FilingYear: number;
    FilingMonth: number;
    PeriodStart: string;
    PeriodEnd: string;
    FilingFrequency: string;
    CompanyIdentifier: string;
}

export interface ISerStatus {
    ReceivedByTransmissionSvc: boolean;
    Status: SerStatus;
    Description: string;
}

export enum SerStatuses {
    "SENT" = "SENT",
    "ACCEPTED" = "ACCEPTED",
    "REJECTED" = "REJECTED",
    "UNKNOWN" = "UNKNOWN",
}

export type SerStatus = keyof typeof SerStatuses;

export interface ISerStateConfig {
    formCode: string;
    formId: string;
    version: string;
}
