export class AutoFilerError extends Error {
    private readonly _errorPDFs?: string[];
    private readonly _confirmationPDFs?: string[];

    constructor(message?: string, errorPDFs?: string[], confirmationPDFs?: string[]) {
        super(message || "message not provided");
        this._errorPDFs = errorPDFs;
        this._confirmationPDFs = confirmationPDFs;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, AutoFilerError.prototype);
    }

    errorPDFs() {
        return this._errorPDFs?.join();
    }

    confirmationPDFs() {
        return this._confirmationPDFs?.join();
    }

    toString() {
        return `message: ${this.message}, errorPDF: ${this.errorPDFs()}, confirmationPDF: ${this.confirmationPDFs()}`;
    }
}

export class AutoFilerValidationError extends Error {
    private readonly _errorPDFs?: string[];
    private readonly _confirmationPDFs?: string[];

    constructor(message?: string, errorPDFs?: string[]) {
        super(message || "message not provided");
        this._errorPDFs = errorPDFs;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, AutoFilerValidationError.prototype);
    }

    errorPDFs() {
        return this._errorPDFs?.join();
    }

    toString() {
        return `message: ${this.message}, errorPDF: ${this.errorPDFs()}`;
    }
}

export class AutoFilerCredentialError extends Error {
    constructor() {
        super("Invalid login credentials");

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, AutoFilerCredentialError.prototype);
        this.name = "AutoFilerCredentialError";
    }

    errorPDFs() {
        return;
    }

    toString() {
        return `message: ${this.message}, errorPDF: Not provided`;
    }
}
