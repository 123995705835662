import { UserScreenMode } from "@davo/types";
import { useTheme } from "@mui/material";
import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

export function Loading() {
    const theme = useTheme();
    return (
        <div className="davo-loading" data-testid={"davo-loading"} style={{ textAlign: "center" }}>
            {theme.palette.mode !== ("dark" as UserScreenMode) && (
                <PulseLoader size={12} color={"#1a2f5b"} loading={true} />
            )}
            {theme.palette.mode === ("dark" as UserScreenMode) && (
                <PulseLoader size={12} color={"#ffffff"} loading={true} />
            )}
        </div>
    );
}
