import React, { FunctionComponent } from "react";
import "./RenderAddress.css";

export interface IRenderAddress extends React.HTMLProps<HTMLElement> {
    name?: string | undefined;
    address1: string | null | undefined;
    address2: string | null | undefined;
    city: string | null | undefined;
    state: string | null | undefined;
    zip: string | null | undefined;
    county?: string | null | undefined;
    timezone?: string | null | undefined;
}

export const RenderAddress: FunctionComponent<IRenderAddress> = ({
    name,
    address1,
    address2,
    city,
    state,
    zip,
    county,
    timezone,
    ...props
}) => {
    return (
        <address className={["displayAddressBlock", "fs-exclude"].join(" ")} {...props}>
            {name && <div>{name}</div>}
            {address1 && <div>{address1}</div>}
            {address2 && <div>{address2}</div>}
            <div>
                {city && <span>{`${city}, `}</span>}
                {state && <span>{`${state} `}</span>}
                {zip && <span>{zip}</span>}
            </div>
            {county && <div>County: {county}</div>}
            {timezone && <div>Time Zone: {timezone}</div>}
        </address>
    );
};
