import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import React from "react";

export interface ITakeGiveBackToggle {
    isTakeGiveBack: boolean;
    setIsTakeGiveBack: (isChecked: boolean) => void;
}

export const TakeGiveBackToggle: React.FunctionComponent<ITakeGiveBackToggle> = ({
    isTakeGiveBack,
    setIsTakeGiveBack,
}) => {
    return (
        <FormGroup data-testid={"takeGiveBackToggleContainer"} style={{ marginTop: "10px", marginBottom: "10px" }}>
            <FormControlLabel
                control={
                    <Switch
                        data-testid={"takeGiveBackToggle"}
                        checked={isTakeGiveBack}
                        onChange={(event, isChecked) => setIsTakeGiveBack(isChecked)}
                    />
                }
                label="Take give back profile"
            />
        </FormGroup>
    );
};
