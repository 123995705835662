import { darkStatusColors, statusColors, StatusLevel } from "@davo/types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import WarningIcon from "@mui/icons-material/Warning";
import { Tooltip, Typography, useTheme } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import "./StatusDot.css";

export interface IStatusDot {
    message?: string;
    destination?: string;
    level: StatusLevel;
    linkLabel?: string;
    onClick?: any;
    toolTip?: string;
    isFocusable?: boolean;
}

export const StatusDot: React.FunctionComponent<IStatusDot> = ({
    message,
    destination,
    level,
    linkLabel = "Fix",
    onClick,
    toolTip,
    isFocusable,
}) => {
    const [color, setColor] = useState<string>("ok");
    const msgId = uuid();
    const theme = useTheme();

    useEffect(() => {
        if (theme.palette.mode === "dark") {
            setColor(darkStatusColors[level]);
        } else {
            setColor(statusColors[level]);
        }
    }, [level, theme]);

    if (!level) {
        return null;
    }

    const content = (testId: string) => {
        let StatusIcon: typeof SvgIcon;

        switch (level) {
            case "error": {
                StatusIcon = ErrorIcon;
                break;
            }
            case "warning": {
                StatusIcon = WarningIcon;
                break;
            }
            case "inactive": {
                StatusIcon = LinkOffIcon;
                break;
            }
            default: {
                StatusIcon = CheckCircleIcon;
            }
        }

        return (
            <span data-testid={testId} className={testId}>
                <StatusIcon
                    tabIndex={isFocusable && !!toolTip ? 0 : undefined}
                    aria-label={`${level} status`}
                    aria-hidden={!isFocusable}
                    data-testid={`connection-status-${level}`}
                    className={[`da-${level}`, "da-connection-status-icon"].join(" ")}
                    style={{ color }}
                />
                {message && (
                    <span id={`${msgId}`} style={{ color }}>
                        {message}
                    </span>
                )}
            </span>
        );
    };

    return (
        <div
            {...((level === "error" || level === "warning") && {
                "aria-live": "polite",
            })}
            style={{
                color,
            }}
            data-testid={"statusDotContainer"}
            className={"statusDotContainer"}>
            {toolTip && (
                <Tooltip title={toolTip} placement="top">
                    {content("tooltipMsgContainer")}
                </Tooltip>
            )}
            {!toolTip && content("inlineMsgContainer")}
            {destination && (
                <Link aria-describedby={msgId} to={destination} className={"fixLink"} data-testid={"fixLink"}>
                    {linkLabel}
                </Link>
            )}
            {onClick && (
                <Typography
                    onClick={onClick}
                    style={{
                        verticalAlign: "middle",
                        textDecoration: "underline",
                        color: "#4D7CFE",
                        cursor: "pointer",
                    }}>
                    {linkLabel}
                </Typography>
            )}
        </div>
    );
};
