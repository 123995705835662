import { Button, Typography } from "@mui/material";
import React from "react";

export interface IUnavailableType {
    onContinue: () => void;
    errorMessage?: string;
}

export function Unavailable({ onContinue, errorMessage }: IUnavailableType) {
    return (
        <div data-testid={"unavailable"}>
            <Typography style={{ marginBottom: "20px", textAlign: "center" }}>
                There was an error while connecting our systems.
            </Typography>
            <Typography style={{ textAlign: "center" }}>
                {errorMessage ?? "Please connect DAVO to your point of sale device again to kick the process off anew."}
            </Typography>
            <div style={{ textAlign: "center" }}>
                <Button
                    data-testid={"continue"}
                    variant="contained"
                    color="primary"
                    style={{
                        marginTop: "20px",
                        marginBottom: "10px",
                        cursor: "pointer",
                    }}
                    onClick={() => onContinue()}>
                    Continue
                </Button>
            </div>
        </div>
    );
}
