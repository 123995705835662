import { FilingDetails } from "./Account";
import { BOOLEAN, NUMBER, ResolveObject, STRING } from "./MetaType";

export enum FilingFrequencies {
    "quarter",
    "month",
    "annual",
    "quarter_mpp",
    "semiannual",
    "semimonthly",
    "nypromptax",
    "fiscal_annual",
    "month_mpp",
    "annual_mpp",
    "fiscal_annual_mpp",
    "month_expected_halves",
    "month_weekly_prepayment",
    "quarter_smpp",
}

export type FilingFrequency = keyof typeof FilingFrequencies;

export interface IFilingFrequencyData {
    dueDOM?: number;
    prepaymentDueDOM1?: number;
    prepaymentDueDOM2?: number;
    prepaymentDueDOM3?: number;
    startMonth?: number;
    legacy?: boolean;
}

export type FilingFrequencyMap = { [index: string]: IFilingFrequencyData };

export const hasPrepayment = (frequency: FilingFrequency): frequency is FilingFrequency =>
    frequency === "quarter_mpp" ||
    frequency === "annual_mpp" ||
    frequency === "nypromptax" ||
    frequency === "month_mpp" ||
    frequency === "fiscal_annual_mpp" ||
    frequency === "month_expected_halves" ||
    frequency === "month_weekly_prepayment" ||
    frequency === "quarter_smpp";

export const hasMidMonthPayment = (frequency: string): frequency is FilingFrequency =>
    frequency === "nypromptax" ||
    frequency === "month_mpp" ||
    frequency === "month_expected_halves" ||
    frequency === "month_weekly_prepayment" ||
    frequency === "quarter_smpp" ||
    frequency === "semimonthly";

export const hasMidMonthPaymentOrIsWeirdCA = (filing: FilingDetails) => {
    return (
        hasMidMonthPayment(filing.frequency) ||
        (filing.jurisdiction === "CA" &&
            filing.frequency === "quarter_mpp" &&
            filing.filingDueDate.month === 6 &&
            (filing.prepayment ?? false))
    );
};

export const DisconnectBankRemovalState = ["WV", "NE", "ME"];

export const MetaFilingFrequencyInfo = {
    state: STRING(),
    frequency: STRING(),
    dueDayOfMonth: NUMBER().optional(),
    startMonth: NUMBER().optional(),
    legacy: BOOLEAN(),
    prepaymentOneDayOfMonth: NUMBER().optional(),
    prepaymentTwoDayOfMonth: NUMBER().optional(),
    prepaymentThreeDayOfMonth: NUMBER().optional(),
};

export type FilingFrequencyInfo = ResolveObject<typeof MetaFilingFrequencyInfo>;
