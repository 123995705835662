export interface IJurisdictionRateTaxBreakoutByType {
    [key: string]: {
        amount: number;
        tax: number;
        rateTypeDescription?: null | string;
        rateTypeExternalSiteTag?: null | string;
    };
}

export interface IJurisdictionRateType {
    id: string;
    jurisdictionId: string;
    name: string;
    tag: string;
    externalSiteTag?: null | string;
    taxRateBreakdown?: null | { [key: string]: string };
    active: boolean;
}
