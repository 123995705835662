import {
    NoticeType,
    NoticeTypeKeys,
    NoticeTypes,
    TaxPeriod,
    TaxProfile,
    TaxProfileRecord,
    toDisplayDateString,
    toPennies,
    truncateString,
} from "@davo/types";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUpload";
import { Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import React, { useCallback, useEffect, useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import useAsyncEffect from "use-async-effect";
import { v4 as uuid } from "uuid";
import { Loading } from "./Loading";
import { NumberField } from "./NumberField";
import { PaperComponent } from "./PaperComponent";
import Select from "./Select";
import {
    addNotice,
    addNoticeFile,
    getFilingPeriodOptionsForTaxProfile,
    getTaxProfiles,
    requestFVSInit,
    validateAmount,
} from "./services";
import { TextField } from "./TextField";
import { d30Toast, d30ToastError } from "./Toast";

export function AddNoticeModal({
    accountId,
    isDisabled,
    refresh,
}: {
    accountId: string;
    isDisabled: boolean;
    refresh: () => void;
}) {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [canBeSaved, setCanBeSaved] = useState<boolean>(false);
    const [taxProfileOptions, setTaxProfileOptions] = useState<TaxProfileRecord[]>();
    const [taxProfile, setTaxProfile] = useState<TaxProfileRecord>();
    const [taxPeriodOptions, setTaxPeriodOptions] = useState<TaxPeriod[]>();
    const [taxPeriod, setTaxPeriod] = useState<TaxPeriod>();
    const [type, setType] = useState<NoticeType | undefined>();
    const [taxDue, setTaxDue] = useState<number>();
    const [penalty, setPenalty] = useState<number>();
    const [interest, setInterest] = useState<number>();
    const [noteFromMerchant, setNoteFromMerchant] = useState<string>();
    const [fileId, setFileId] = useState<string | undefined>();
    const [uploading, setUploading] = useState<{ [key: string]: boolean }>({});
    const [mode, setMode] = useState<"upload" | "form">("upload");

    const maxSize = 25 * 1024 * 1024; // 25 MB
    const acceptedFileTypes = { "application/pdf": [".pdf"] };

    useAsyncEffect(async () => {
        const tps: TaxProfile[] = await getTaxProfiles(accountId);
        const records: TaxProfileRecord[] = [];
        for (const x of tps) {
            records.push({ ...x, accountId });
        }
        setTaxProfileOptions(records);
    }, [accountId]);

    useAsyncEffect(async () => {
        if (!taxProfile) {
            return;
        }
        setTaxPeriodOptions(await getFilingPeriodOptionsForTaxProfile(accountId, taxProfile.id));
    }, [taxProfile]);

    useEffect(() => {
        setCanBeSaved(
            !!(
                !isBusy &&
                (taxDue || taxDue === 0) &&
                (interest || interest === 0) &&
                (penalty || penalty === 0) &&
                type &&
                taxProfile &&
                taxPeriod &&
                fileId
            )
        );
    }, [isBusy, taxDue, interest, penalty, type, taxProfile, taxPeriod, fileId]);

    useEffect(() => {
        if (taxProfileOptions?.length === 1) {
            setTaxProfile(taxProfileOptions[0]);
        }
    }, [isShowing, taxProfileOptions]);

    const show = () => {
        setMode("upload");
        setPenalty(undefined);
        setType(undefined);
        setTaxDue(undefined);
        setInterest(undefined);
        setTaxProfile(undefined);
        setUploading({});
        setIsBusy(false);
        setIsShowing(true);
        setNoteFromMerchant(undefined);
    };

    const doProcess = () => {
        if (!canBeSaved) {
            return;
        }
        setIsBusy(true);
        setIsShowing(false);

        // these are checked in canBeSaved, so ! should be fine
        addNotice(
            taxProfile!.id,
            fileId!,
            taxPeriod!.start,
            taxPeriod!.end,
            taxDue,
            penalty,
            interest,
            type,
            noteFromMerchant
        )
            .then(() => {
                d30Toast(`Notice added.`);
                refresh();
            })
            .catch((e: any) => d30ToastError(e.message))
            .finally(() => setIsBusy(false));
    };

    const uploadFile = useCallback(
        (file: File) => {
            setIsBusy(true);
            setUploading({ [file.name]: true });
            let validationId: string;
            const corrilationId = uuid();
            requestFVSInit(file.name, corrilationId)
                .then(async (fvs) => {
                    if (!fvs) throw new Error("Could not validate file, please try again.");
                    const url = fvs.url;
                    const fields = fvs.fields;
                    validationId = fvs.validationId;
                    const formData = new FormData();
                    for (const x of Object.keys(fields)) {
                        formData.append(x, fields[x]);
                    }
                    formData.append("file", file);
                    // send file to FVS here, this should 204
                    await fetch(url, {
                        body: formData,
                        method: "POST",
                    });

                    d30Toast("File uploading: " + file.name);
                })
                .catch((e) => {
                    setMode("upload");
                    d30ToastError("Error uploading: " + file.name, e);
                })
                .finally(() => {
                    if (!isNil(validationId)) {
                        addNoticeFile(file.name, validationId)
                            .then((message) => {
                                setFileId(message.fileId);
                                setMode("form");
                            })
                            .catch((e: any) => d30ToastError(e.message))
                            .finally(() => {
                                setIsBusy(false);
                            });
                    }
                    setUploading({ [file.name]: false });
                    setIsBusy(false);
                });
        },
        [uploading, setUploading]
    );

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (Array.isArray(acceptedFiles)) {
                acceptedFiles.forEach(uploadFile);
            }
        },
        [uploadFile]
    );

    if (!taxProfileOptions) {
        return null;
    }

    return (
        <>
            <Button
                variant="contained"
                disabled={isDisabled}
                onClick={show}
                size="small"
                style={{
                    padding: "7px 9px",
                    margin: "5px",
                }}
                color="primary">
                Add New Notice
            </Button>
            {isShowing && (
                <Dialog
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    open={true}
                    onClose={() => {
                        setIsShowing(false);
                    }}>
                    <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                        Add Notice...
                    </DialogTitle>
                    <div style={{ padding: "20px" }}>
                        <Typography variant={"body1"}>
                            {mode === "upload" ? "Upload the notice pdf you received" : "Tell us more about the notice"}
                        </Typography>
                        {isBusy && (
                            <>
                                <Loading />
                            </>
                        )}
                        {!isBusy && mode === "upload" && (
                            <>
                                <>
                                    <div
                                        style={{
                                            alignItems: "center",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                        }}>
                                        <Dropzone
                                            onDrop={onDrop}
                                            maxSize={maxSize}
                                            maxFiles={10}
                                            accept={acceptedFileTypes}
                                            onDropRejected={(files: FileRejection[]) => {
                                                const fileNames = files.map((f) => f.file.name).join(", ");
                                                d30ToastError(
                                                    `Unable to upload ${fileNames}. Ensure it's a PDF file and less than 25 MB.`
                                                );
                                            }}>
                                            {({ getRootProps, getInputProps, isDragActive }) => (
                                                <section>
                                                    <div
                                                        style={{
                                                            borderRadius: "50%",
                                                            border: "2px dashed blue",
                                                            width: "150px",
                                                            height: "150px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            alignContent: "center",
                                                            textAlign: "center",
                                                        }}
                                                        {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <p>
                                                            {isDragActive
                                                                ? "Drop now!"
                                                                : "Drag 'n' drop notices here, or click to select files"}
                                                        </p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                </>
                            </>
                        )}
                        {!isBusy && mode === "form" && (
                            <>
                                <div style={{ alignItems: "center" }}>
                                    {Object.keys(uploading).map((f, i) => (
                                        <div key={i}>
                                            <CloudUploadTwoToneIcon
                                                color="primary"
                                                style={{ verticalAlign: "middle" }}
                                            />
                                            {truncateString(f, 30)}
                                        </div>
                                    ))}
                                </div>
                                {taxProfileOptions && taxProfileOptions.length > 1 && (
                                    <Select<TaxProfileRecord>
                                        noneLabel={`Select a tax profile...`}
                                        value={taxProfile}
                                        onChange={setTaxProfile}
                                        options={taxProfileOptions}
                                        label={(l) => l.name}
                                        showFullWidth={true}
                                        title={"Tax Profile"}
                                    />
                                )}
                                {taxPeriodOptions && taxPeriodOptions.length > 1 && (
                                    <Select<TaxPeriod>
                                        noneLabel={`Select the period your notice applies to`}
                                        value={taxPeriod}
                                        onChange={setTaxPeriod}
                                        options={taxPeriodOptions}
                                        label={(p) => `${toDisplayDateString(p.start)} - ${toDisplayDateString(p.end)}`}
                                        showFullWidth={true}
                                        title={"Filing Period"}
                                    />
                                )}
                                <Select<NoticeType>
                                    title="Notice Type"
                                    value={type}
                                    onChange={setType}
                                    options={NoticeTypeKeys}
                                    label={(typeId: NoticeType) => NoticeTypes[typeId]}
                                />
                                <NumberField
                                    label="Tax Due"
                                    value={taxDue}
                                    onChange={(x) => setTaxDue(toPennies(x))}
                                    validate={validateAmount}
                                />
                                <NumberField
                                    label="Penalties"
                                    value={penalty}
                                    onChange={(x) => setPenalty(toPennies(x))}
                                    validate={validateAmount}
                                />
                                <NumberField
                                    label="Interest"
                                    value={interest}
                                    onChange={(x) => setInterest(toPennies(x))}
                                    validate={validateAmount}
                                />
                                <TextField
                                    label={"Anything else we should know?"}
                                    value={noteFromMerchant ?? ""}
                                    onChange={setNoteFromMerchant}
                                    isMultiline={true}
                                    rows={2}
                                />
                            </>
                        )}
                    </div>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={() => setIsShowing(false)}>
                            Cancel
                        </Button>
                        {mode === "form" && (
                            <Button variant="outlined" color="primary" onClick={() => setMode("upload")}>
                                Previous
                            </Button>
                        )}
                        <Button variant="contained" disabled={!canBeSaved} color="primary" onClick={doProcess}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
