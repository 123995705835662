export interface IJurisdictionUIAdditionalTaxProfile {
    id: string;
    jurisdictionFilingTypeId: string;
    pageTitle?: null | string;
    copyLegalName: boolean;
    showLegalName: boolean;
    copyStateTaxId: boolean;
    showStateTaxId: boolean;
    copyFederalTaxId: boolean;
    showFederalTaxId: boolean;
    copyFrequency: boolean;
    showFrequency: boolean;
    copyAdditionalStateMetadata: boolean;
    showAdditionalStateMetadata: boolean;
    copyAdditionalStateMetadata2: boolean;
    showAdditionalStateMetadata2: boolean;
    copyAdditionalStateMetadata3: boolean;
    showAdditionalStateMetadata3: boolean;
}

export type LinkedTaxProfileType = {
    parentTaxProfileId: string;
    jurisdictionUIAdditionalTaxProfile: IJurisdictionUIAdditionalTaxProfile;
};
