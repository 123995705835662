import React from "react";
import { DavoLink } from "./DavoLink";

interface IRecentlyViewedAccounts {
    recentlyViewed: string[];
}

export const RecentlyViewedAccounts: React.FunctionComponent<IRecentlyViewedAccounts> = ({ recentlyViewed }) => {
    return recentlyViewed && recentlyViewed.length ? (
        <div style={{ marginTop: "200px" }}>
            <h3>RECENTLY VIEWED ACCOUNTS</h3>
            {recentlyViewed.map((r) => (
                <span style={{ marginBottom: "10px", display: "block" }} key={r}>
                    <DavoLink to={`/accounts/${r.split(":")[0]}`}>{r.split(":")[1]}</DavoLink>
                </span>
            ))}
        </div>
    ) : null;
};
