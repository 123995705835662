import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

/**
 * This class creates a react router <Link> that respects Material UI theming
 */
export function DavoLink({ to, children, target, style }: { to: string; children: any; target?: string; style?: any }) {
    return (
        <Typography color={"primary"} style={style}>
            <Link style={{ color: "inherit" }} to={to} target={target}>
                {children}
            </Link>
        </Typography>
    );
}
