export interface IJurisdictionUIFieldType {
    id: string;
    tag: JurisdictionUIFieldTag;
}

export enum JurisdictionUIFieldTags {
    "USERNAME" = "USERNAME",
    "PASSWORD" = "PASSWORD",
    "PIN" = "PIN",
    "LEGAL_NAME" = "LEGAL_NAME",
    "FEIN" = "FEIN",
    "STATE_TAX_ID" = "STATE_TAX_ID",
    "ADDITIONAL_STATE_METADATA" = "ADDITIONAL_STATE_METADATA",
    "ADDITIONAL_STATE_METADATA_TWO" = "ADDITIONAL_STATE_METADATA_TWO",
    "ADDITIONAL_STATE_METADATA_THREE" = "ADDITIONAL_STATE_METADATA_THREE",
}

export type JurisdictionUIFieldTag = keyof typeof JurisdictionUIFieldTags;
